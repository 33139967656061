import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, startWith, take } from 'rxjs';
import { City } from 'src/app/models/City';
import { Country } from 'src/app/models/Country';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { CityService } from 'src/app/services/city/city.service';
import { CountryService } from 'src/app/services/country/country.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { environment } from 'src/environments/environment';
import { clone } from 'src/app/util/Util';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { LocaleObject } from 'src/app/models/Locale';
import { ProjectService } from 'src/app/services/project/project.service';
import { Project, ProjectModule } from 'src/app/models/Project';
import { BaseComponent } from 'src/app/components/base.component';
import { MatDialog } from '@angular/material/dialog';
import { OnboardingModalComponent } from 'src/app/components/modals/onboarding-modal/onboarding-modal.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';

@Component({
  selector: 'app-details-client',
  templateUrl: './details-client.component.html',
  styleUrls: ['./details-client.component.scss']
})
export class DetailsClientComponent extends BaseComponent implements OnInit {

  public projectId!: string;
  public projectData!: Project;

  public languages: string[] = [];
  public countries: Country[] = [];

  public cities: City[] = [];
  public filteredCities!: Observable<City[]>;
  public filteredPostalCodes!: Observable<City[]>;

  public citySet = false;
  public currentCity!: City;
  public currentCountry!: string;
  public currentLang!: string;

  public clientCities: City[] = [];
  public filteredClientCities!: Observable<City[]>;
  public filteredClientPostalCodes!: Observable<City[]>;

  public clientCitySet = false;
  public currentClientCity!: City;
  public currentClientCountry!: string;

  public sameAddress = false;

  public projectDetails!: UntypedFormGroup;

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private nikoApi: NikoApiService,
    private translate: TranslateService,
    private countryService: CountryService,
    private cityService: CityService,
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private projectService: ProjectService,
    private dialog: MatDialog
  ) { super(); }

  ngOnInit(): void {
    this.openOnboardingModal();

    this.SetSideBarContent();

    // Get countries
    this.countryService.getCountries().subscribe((r:Country[]) => {
      this.countries = r.sort((a,b) => a.order - b.order);
    })

    this.currentCountry = this.nikoApi.countryCode.toUpperCase();
    this.currentClientCountry = this.nikoApi.countryCode.toUpperCase();

    // Get languages
    this.nikoApi.getLocales().subscribe((r:LocaleObject[]) => {
      r.forEach(locale => {
        this.languages.push(locale.locale);
      })
    });

    this.translate.currentLang ? this.currentLang = this.translate.currentLang : this.currentLang = this.translate.defaultLang;

    this.projectId = this.activatedRoute.snapshot.params['project_id'];    
    this.getProjectData();
  }

  /**
   * Get project data to show current answers
   */
  private getProjectData() {
    this.projectService.getProjectById(this.projectId).subscribe(r => {
      this.projectData = r;

      this.projectDetails = new UntypedFormGroup({
        country: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.country')?.value, [Validators.required]),
        language: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.language')?.value, [Validators.required]),
    
        greeting: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.greeting')?.value),
        email: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.email')?.value, [Validators.email, Validators.required]),
        phone: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.phone')?.value),
        firstname: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.firstname')?.value, [Validators.required]),
        lastname: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.lastname')?.value, [Validators.required]),
    
        contact_address: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.contact_address')?.value, [Validators.required]),
        contact_address2: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.contact_address2')?.value),
        contact_zip: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.contact_zip')?.value, [Validators.required]),
        contact_city: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.contact_city')?.value, [Validators.required]),
        contact_country: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.contact_country')?.value, [Validators.required]),
      
        name: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.name')?.value, [Validators.required]),
        startdate: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.startdate')?.value),
    
        address: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.address')?.value, [Validators.required]),
        address2: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.address2')?.value),
        zip: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.zip')?.value, [Validators.required]),
        city: new UntypedFormControl(this.projectData.properties.find(p => p.name == 'project.city')?.value, [Validators.required])      
      });

      if(!this.projectDetails.get('language')?.value){this.projectDetails.get('language')?.setValue(this.currentLang); this.saveProperty('language');}

      // Sync current country with country input
      if(!this.projectDetails.get('country')?.value) {
        this.projectDetails.get('country')?.setValue(this.currentCountry); this.saveProperty('country');
      } else {
        this.currentCountry = this.projectDetails.get('country')?.value;
      }

      // Sync current country with contact_country input
      if(!this.projectDetails.get('contact_country')?.value) {
        this.projectDetails.get('contact_country')?.setValue(this.currentClientCountry); this.saveProperty('contact_country');
      } else {
        this.currentClientCountry = this.projectDetails.get('contact_country')?.value;
      }

      // If zip and city are already set, input fields are regarded as valid
      if(this.projectDetails.get('zip')?.value && this.projectDetails.get('city')?.value) {this.citySet = true;}
      if(this.projectDetails.get('contact_zip')?.value && this.projectDetails.get('contact_city')?.value) {this.clientCitySet = true;}

      this.sameAddress = (this.projectData.properties.find(p => p.name == 'project.sameaddress')?.value && this.projectData.properties.find(p => p.name == 'project.sameaddress')?.value! == 'true') ? true : false;

      this.getCities();
    });
  }

  /**
   * Get list of cities
   */
  private getCities() {
    this.addSubscription(
    this.cityService.getCities(this.currentCountry).subscribe((r:City[]) => {
      console.log(this.currentCountry, r);
      this.cities = r.sort((a,b) => parseInt(a.postalCode) - parseInt(b.postalCode));

      if(this.projectDetails) {
        this.filteredCities = this.projectDetails.get('city')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterCities(value, this.cities).slice(0,5)),
        );
  
        this.filteredPostalCodes = this.projectDetails.get('zip')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterPostalCodes(value, this.cities).slice(0,5)),
        );
      }

    }));

    this.addSubscription(
    this.cityService.getClientCities(this.currentClientCountry).subscribe((r:City[]) => {
      console.log(this.currentClientCountry, r);
      this.clientCities = r.sort((a,b) => parseInt(a.postalCode) - parseInt(b.postalCode));

      if(this.projectDetails) {
        this.filteredClientCities = this.projectDetails.get('contact_city')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterCities(value, this.clientCities).slice(0,5)),
        );
  
        this.filteredClientPostalCodes = this.projectDetails.get('contact_zip')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterPostalCodes(value, this.clientCities).slice(0,5)),
        );
      }

    }));
  }

  /**
   * Filter citylist to show only results matching the filter value
   * @param value filter value
   * @returns citylist containing only the filtered values
   */
  private filterCities(value: string, cities: City[]): City[] {
    return clone(cities).filter(city => {
      return city.name.toLowerCase().includes(value.toLowerCase());
    });
  }

  /**
   * Filter citylist to show only results matching the filter value
   * @param value filter value
   * @returns citylist containing only the filtered values
   */
  private filterPostalCodes(value: string, cities: City[]): City[] {
    return clone(cities).filter(city => {
      return city.postalCode.includes(value);
    });
  }

  // Clear city and zip form fields if dialogoptions are required, but input was typed
  @HostListener("document:click", ["$event"])
  clickedOutside() {
    if (!this.citySet && this.projectDetails) {
      this.projectDetails.get("city")!.patchValue('');
      this.projectDetails.get("zip")!.patchValue('');
    }

    if (!this.clientCitySet && this.projectDetails) {
      this.projectDetails.get("contact_city")!.patchValue('');
      this.projectDetails.get("contact_zip")!.patchValue('');
    }
  }

  /**
   * Save one or multiple property values to backend
   * @param propertyName name of the formcontrol and the property to be saved
   */
  public saveProperty(propertyName: string) {
    let entries: string[] = [];
    
    // If contact address changes, sameAddress is set to false
    if(propertyName == 'contact_address' || propertyName == 'contact_address2' || propertyName == 'contact_zip' || propertyName == 'contact_city' || propertyName == 'contact_country') {
      this.propertyService.setProperty(this.projectId, ['project.sameaddress:false']);
      this.sameAddress = false;
    }

    // City / zip property
    if (propertyName == 'city' || propertyName == 'zip') { 

      let postalCode = '';
      let cityName = '';

      // Changes to the city or zip property using the dialogoptions also update the other property value.
      if(propertyName == 'city') { 
      // If city is changed

        cityName = this.projectDetails!.get('city')!.value;

        if(cityName) {
          if(this.currentCity.name.toLowerCase() == this.projectDetails!.get('city')!.value.toLowerCase()){
            this.projectDetails!.get('zip')!.setValue(this.currentCity.postalCode);
          }
        } else {
          this.projectDetails!.get('zip')!.setValue('');
        }
        postalCode = this.projectDetails!.get('zip')!.value;

      } else if(propertyName == 'zip') { 
      // If zip is changed
        postalCode = this.projectDetails!.get('zip')!.value;

        if(postalCode) {
          if(this.currentCity.postalCode == this.projectDetails!.get('zip')!.value){
            this.projectDetails!.get('city')!.setValue(this.currentCity.name);
          }   
        } else {
          this.projectDetails!.get('city')!.setValue('');
        }

        cityName = this.projectDetails!.get('city')!.value;
      }
        
      entries.push('project.zip:' + postalCode);
      entries.push('project.city:' + cityName);

      this.propertyService.setProperty(this.projectId, entries);

    // Country property
    } else if(propertyName == 'country') {
      this.currentCountry = this.projectDetails!.get('country')!.value;
      this.propertyService.setProperty(this.projectId, ['project.country:' + this.currentCountry]);
      this.citySet = false;
      this.getCities();

      // Other properties
    } else if (propertyName == 'contact_city' || propertyName == 'contact_zip') { 

      let postalCode = '';
      let cityName = '';

      // Changes to the city or zip property using the dialogoptions also update the other property value.
      if(propertyName == 'contact_city') { 
      // If city is changed

        cityName = this.projectDetails!.get('contact_city')!.value;

        if(cityName) {
          if(this.currentClientCity.name.toLowerCase() == this.projectDetails!.get('contact_city')!.value.toLowerCase()){
            this.projectDetails!.get('contact_zip')!.setValue(this.currentClientCity.postalCode);
          }  
        } else {
          this.projectDetails!.get('contact_zip')!.setValue('');
        }
        postalCode = this.projectDetails!.get('contact_zip')!.value;

      } else if(propertyName == 'contact_zip') { 
      // If zip is changed
        postalCode = this.projectDetails!.get('contact_zip')!.value;

        if(postalCode) {
          if(this.currentClientCity.postalCode == this.projectDetails!.get('contact_zip')!.value){
            this.projectDetails!.get('contact_city')!.setValue(this.currentClientCity.name);
          }      
        } else {
          this.projectDetails!.get('contact_city')!.setValue('');
        }

        cityName = this.projectDetails!.get('contact_city')!.value;
      }
        
      entries.push('project.contact_zip:' + postalCode);
      entries.push('project.contact_city:' + cityName);

      this.propertyService.setProperty(this.projectId, entries);

    // Contact country property
    } else if(propertyName == 'contact_country') {
      this.currentClientCountry = this.projectDetails!.get('contact_country')!.value;
      this.propertyService.setProperty(this.projectId, ['project.contact_country:' + this.currentClientCountry]);
      this.clientCitySet = false;
      this.getCities();

    // Other properties
    } else { 
      //console.log(['project.' + propertyName + (this.projectDetails!.get(propertyName)!.value ? (':' + this.projectDetails!.get(propertyName)!.value) : '')]);
      this.propertyService.setProperty(this.projectId, ['project.' + propertyName + (this.projectDetails!.get(propertyName)!.value ? (':' + this.projectDetails!.get(propertyName)!.value) : '')]);
    }
  }


  /**
   * Match contact address to installation address
   * @param sameAddress boolean indicates wether contact and installation address should be identical
   */
   public matchContactAddress(sameAddress: boolean) {
    this.sameAddress = sameAddress;

    if(sameAddress) {
      this.projectDetails.get('address')?.setValue(this.projectDetails.get('contact_address')?.value || '');
      this.projectDetails.get('address2')?.setValue(this.projectDetails.get('contact_address2')?.value || '');
      this.projectDetails.get('zip')?.setValue(this.projectDetails.get('contact_zip')?.value || '');
      this.projectDetails.get('city')?.setValue(this.projectDetails.get('contact_city')?.value || '');
      this.projectDetails.get('country')?.setValue(this.projectDetails.get('contact_country')?.value || '');
  
      // If zip and city are already set, input fields are regarded as valid
      if(this.projectDetails.get('zip')?.value && this.projectDetails.get('city')?.value) {this.citySet = true;}
      //console.log('cityset', this.citySet);

      let entries: string[] = [];

      entries.push('project.sameaddress:' + sameAddress);
      entries.push('project.address:' + this.projectDetails!.get('address')!.value);
      entries.push('project.address2:' + this.projectDetails!.get('address2')!.value);
      entries.push('project.city:' + this.projectDetails!.get('city')!.value);
      entries.push('project.zip:' + this.projectDetails!.get('zip')!.value);
      entries.push('project.country:' + this.projectDetails!.get('country')!.value);

      this.propertyService.setProperty(this.projectId, entries);

    } else {

      // Reset cities to the correct list for the country
      this.currentCountry = this.projectDetails!.get('country')!.value;
      this.currentClientCountry = this.projectDetails!.get('contact_country')!.value;
      this.getCities();
      
      this.propertyService.setProperty(this.projectId, ['project.sameaddress:' + sameAddress]);
    }
  }


  /**
   * Open onboarding modal
   */
   openOnboardingModal() {
    const dialogRef = this.dialog.open(OnboardingModalComponent, {
      autoFocus: false, 
      data: {
        'step':  ProjectModule.INTAKE,
        'background': '/assets/pictures/onboarding/onboarding_installer.jpg'
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      width: '900px'
    });

    dialogRef.afterClosed().subscribe(r => {
      /* if (r && r.room) {
        this.removeRoom(r.room.id)
      } */
    });
  }

      /**
   * Open tooltip modal
   */
      public openTooltip(title: string = '', text: string) {
        const dialogRef = this.dialog.open(TooltipModalComponent, {
          autoFocus: false, 
          data: {
            'title': title,
            'text': text
          },
          maxWidth: '90vw',
          maxHeight: '90vh',
          minWidth: '775px'
        });
      }

  /**
   * Add dynamic content for sidebar navigation
   */
   SetSideBarContent() {
    this.sideBarContent = [];

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_CLIENT_DETAILS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.DETAIL_CLIENT'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_BUILDINGTYPE'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.BUILDINGTYPE'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_UTILITIES'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.UTILITIES'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_ROOMS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.ROOMS'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.SUMMARY'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });
  }
}