<app-overview-page-template>
  
  <div id="content">
    <div class="flex space-between margin-b-30">
      <h1 class="no-margin">{{ 'PREFERENCES.TITLE' | translate }}</h1>
      <div class="button-wrapper">
        <div class="btn btn-transparent btn-small btn-icon"><mat-icon class="icon icon--small"></mat-icon></div>
      </div>
    </div>  

    <div class="content-container">
      <label class="tooltip-trigger">
        {{ 'PREFERENCES.COUNTRY_LABEL' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PREFERENCES.COUNTRY_LABEL','PREFERENCES.COUNTRY.INFO')"></span>
      </label>
      <mat-form-field>
        <mat-select [formControl]="country" disableRipple>
          <mat-option *ngFor="let country of countries" [value]="country['code']">{{  'PREFERENCES.COUNTRIES.' + country['code'] | uppercase | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="content-container">
      <label class="tooltip-trigger">
        {{ 'PREFERENCES.LANGUAGE_LABEL' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PREFERENCES.LANGUAGE_LABEL','PREFERENCES.LANGUAGE.INFO')"></span>
      </label>
      <mat-form-field>
        <mat-select [formControl]="language" disableRipple>
          <mat-option *ngFor="let option of languages" [value]="option">{{  'PREFERENCES.LANGUAGES.' + option | uppercase | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="content-container pointer" [routerLink]="'/' + APP_ROUTES['CONTACT'].value" >
      <label class="tooltip-trigger">
        {{ 'PREFERENCES.INSTALLER_DETAILS' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PREFERENCES.INSTALLER_DETAILS','PREFERENCES.INSTALLER.INFO')"></span>
      </label>
      <button>
        <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
      </button>
    </div>

    <div class="content-container pointer">
      <label>{{ 'PREFERENCES.RESET_ONBOARDING' | translate }}</label>

      <button (click)="resetOnboarding()" class="btn btn-primary btn-small">{{ 'PREFERENCES.RESET' | translate }}</button>
        
    </div>


    <div class="content-container pointer">
      <label class="tooltip-trigger">
        {{ 'PREFERENCES.PRICES' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PREFERENCES.PRICES','PREFERENCES.PRICES.INFO')"></span>
      </label>
      <div class="check-button pointer">
        <div (click)="togglePrices(!showPrices);" class="btn-icon">
          <mat-icon *ngIf="showPrices" [svgIcon]="'check'" class="icon"></mat-icon>
          <mat-icon *ngIf="!showPrices" [svgIcon]="'check_unchecked'" class="icon"></mat-icon>
        </div>
      </div>
        
    </div>

    <p *ngIf="appVersion" class="app-version">{{ appVersion }}</p>

  </div>
</app-overview-page-template>