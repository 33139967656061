<div id="topbar" [class.block-shadow]="!overviewpage" [style.color]="overviewpage ? COLORS.white : COLORS.black"
  [style.background-color]="overviewpage ? COLORS.black : COLORS.white">

  <app-navigation *ngIf="overviewpage" [color]="overviewpage ? COLORS.white : COLORS.black"></app-navigation>

  <div class="flex flex-center" gaCategory="Topbar">
    <div *ngIf="functionalitiesPage" class="functionalities">
      <p class="font-s">{{ 'TOPBAR.FUNCTIONALITIES' | translate }}</p>
      <p class="text-right"><mat-icon style="color:white;" svgIcon="bag" class="icon icon--small"></mat-icon>
      <span class="font-s grey6-text">{{selectedUsecases.length}} {{ 'TOPBAR.ITEMS' | translate}}</span></p>
    </div>
    <div *ngIf="!overviewpage && routerlinkNext" class="button-wrapper">
      <button gaEvent="navigate" gaLabel="next - {{routerlinkNext}}" class="btn btn-primary btn-small" [routerLink]="routerlinkNext">{{ breadcrumbNext }}</button>
    </div>
  </div>

  <button gaCategory="Topbar"  gaEvent="navigate" gaLabel="prev - {{routerlinkPrev}}" class="btn btn-small btn-icon btn-transparent" *ngIf="!overviewpage && routerlinkPrev" [style.color]="overviewpage ? COLORS.white : COLORS.black" [routerLink]="routerlinkPrev">
    <mat-icon svgIcon="back" class="icon icon--small"></mat-icon>
    {{ breadcrumbPrev }}
  </button>

  <img gaCategory="Navigation" gaEvent="navigate" gaLabel="logo - {{projectId ? 'Project status' : 'Project list'}}" [routerLink]="'/'+ APP_ROUTES['PROJECTLIST'].value" *ngIf="overviewpage" id="logo" src="/assets/logo/niko_logo.svg" alt="Niko logo">
</div>
<div gaCategory="Topbar" *ngIf="overviewpage && breadcrumbBack && routerlinkBack" id="backbar" class="block-shadow" [style.color]="COLORS.black">

  <button gaEvent="navigate" gaLabel="back - {{routerlinkBack}}" *ngIf="breadcrumbBack" class="btn btn-small btn-icon btn-transparent" [routerLink]="routerlinkBack">
    <mat-icon svgIcon="back" class="icon icon--small"></mat-icon>
    {{ breadcrumbBack }}
  </button>

  <div *ngIf="!breadcrumbBack"><mat-icon class="icon icon--small"></mat-icon></div>

</div>