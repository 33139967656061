
  <div id="variable-component">
    <div mat-dialog-title>
      <button (click)="dialogRef.close()" class="btn-close">
        <mat-icon class="icon icon--small">close</mat-icon>
      </button>
  
      <h2 class="h1 margin-b">{{ 'PROJECT_VARIABLES.ADD' | translate }}</h2>
    </div>

    <div mat-dialog-content class="variables-container">

      <ng-container *ngFor="let variable of variables;let last = last" >
        <ng-container *ngIf="variable.values && (!variable.values![data.room.typeXid] || variable.values![data.room.typeXid] == 0 || variable.values![data.room.typeXid] == null)">
          <div class="variable-wrapper">
            <div class="variable-content">
              <div class="variable-picture">
                <img [src]="variable.image!" class="icon">
              </div>
              <div>
                <p>{{ variable.text }}</p>
              </div>
            </div>
            <div class="edit-buttons pointer">
              <mat-icon (click)="setVariableValue(variable, null)" *ngIf="variable.values && variable.values![data.room.typeXid] == 0"  [svgIcon]="'check'" class="icon"></mat-icon>
              <mat-icon (click)="setVariableValue(variable, 1)" *ngIf="variable.values && variable.values![data.room.typeXid] == null"  [svgIcon]="'check_unchecked'" class="icon"></mat-icon>
            </div>
          </div>
          <hr *ngIf="!last">
        </ng-container>
      </ng-container>
    </div>

    <div class="button-wrapper">
      <button (click)="dialogRef.close()" class="btn btn-border btn-small margin-r">{{ 'PROJECT_ROOMEDIT.CANCEL' | translate }}</button>
      <button (click)="saveVariables()" class="btn btn-primary btn-small">{{ 'PROJECT_VARIABLES.CONFIRM' | translate }}</button>
    </div>

  </div>