import { Injectable } from '@angular/core';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(
    private nikoApi: NikoApiService
  ) { }

  public setProperty(projectId: string,entries: string[]) {
    this.nikoApi.setProperty(projectId, entries).subscribe(r => {
      return r;
    });
  }
}