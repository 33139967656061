import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Project, Property } from 'src/app/models/Project';
import { Room, RoomInstance } from 'src/app/models/Room';
import { Calculation, InstallType, CalculationEntry, Article } from 'src/app/models/System';
import { Variable } from 'src/app/models/Variable';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { RoomService } from 'src/app/services/room/room.service';
import { SystemService } from 'src/app/services/system/system.service';
import { clone, cloneObject, projectPropertyValue, slugify } from 'src/app/util/Util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/User';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends BaseComponent implements OnInit {

  public hideCalculationLog = true;

  public userData!: User;
  public showPrices = true;

  public projectId!: string;
  public projectArchived: boolean = true;
  public projectData!: Project;

  public calculation?: Calculation;
  public installTypes?: InstallType[];
  public installType?: InstallType;

  public roomList!: Room[];

  public general?: RoomInstance;
  public rooms!: RoomInstance[];

  public combineRooms = false;

  public totalPrice!: string;
  public roomPrice: {
    [roomId: string]: string
  } = {};

  public toggleSelected: {
    [roomId: string]: boolean
  } = {};

  public sideBarContent: AppRouteObject[] = [];

  constructor(    
    private translate: TranslateService,
    public systemService: SystemService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private propertyService: PropertyService,
    private roomService: RoomService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) {
    super();

    this.hideCalculationLog = environment.production;
  }

  ngOnInit(): void {
    this.getUserData();

    this.SetSideBarContent();

    this.projectId = this.activatedRoute.snapshot.params['project_id'];

    if(!this.projectService.staleCalculation) {
      // If no calculation is available, redirect to calculation component
      this.router.navigate(['/' + APP_ROUTES['PROJECT'].value.replace(':project_id', this.projectId) + '/' + APP_ROUTES['RESULTS_CALCULATION'].value]);
    } else {
      // If calculation from systems component is available, proceed
      this.calculation = cloneObject(this.projectService.staleCalculation);
      this.projectService.staleCalculation = undefined;

      this.addSubscription(this.systemService.getInstallTypes().subscribe((r) => {
        this.installTypes = r;

        this.getProjectData();
      }));
    }
  }

  /**
   * Get User data to show current settings
   */
   private getUserData() {
    this.userData = this.userService.user.getValue();

    // If no user settings are available, redirect to login
    if(!this.userData.email) {
      this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
    } else if(this.userData.settings && this.userData.settings.find(p => p.name == 'user.showprices')) { 
      this.showPrices = this.userData.settings.find(p => p.name == 'user.showprices')?.value == 'true';
    }
   }


  /**
   * Get project data to show current answers
   */
  private getProjectData() {
    this.addSubscription(this.projectService.getProjectById(this.projectId).subscribe(projectData => {
        // Project Archive Status
        this.projectArchived = (projectPropertyValue(projectData.properties, 'archived') == 'true');
        this.projectData = projectData;

        // Get roomlist
        let buildingType = this.projectData.properties.find(p => p.name == 'project.buildingtype')!.value;
        this.addSubscription(this.roomService.getRooms(buildingType).pipe(take(1)).subscribe(roomList => {
              
        // Add room data to room instances 
        let rooms = clone(this.projectData.rooms) as RoomInstance[];
        this.addRoomDataToRoomInstances(roomList, rooms);

        // Select chosen installtype
        this.checkInstallType(projectPropertyValue(projectData.properties, 'installtype')!);
      }));
    }));
  }

  /**
   * Get info about selected install type
   */
  public checkInstallType(typeId: string) {
    this.installType = this.installTypes?.find(r => r.xid == typeId);

    this.calculateInstallTypeTotal(typeId);
  }

  /**
   * Create list of unique article numbers with prices and article count
   */
  public getArticleList(installTypeId: string, roomId?: string): Article[] {
    let calculationData = this.calculation;
    let articles: Article[] = [];
    let entries: CalculationEntry[] = [];
    let articleList: Article[] = [];
    let totalPrice = 0;

    if(calculationData && calculationData.results && 
      calculationData!.results!.find(r => r.installType.xid == installTypeId) && 
      calculationData!.results!.find(r => r.installType.xid == installTypeId)!.entries && 
      calculationData!.results!.find(r => r.installType.xid == installTypeId)!.entries!.length > 0) {
      entries = calculationData.results!.find(r => r.installType.xid == installTypeId)!.entries!;
    }

    if(calculationData && calculationData.articles && calculationData!.articles.length > 0) {
      articleList = calculationData.articles;    
    }

    // If a room id is given, only show articles for roomId
    if(roomId) {
      if(roomId == 'general') {
        entries = entries.filter(entry => (entry.room == undefined || entry.room == null || !entry.room));
      } else {
        entries = entries.filter(entry => entry.room == roomId);
      }
    }


    if(articleList.length > 0 && entries.length > 0) {
      // Array of article Ids
      let articleIds = entries.map(entry => entry.article); 

      let uniqueArticleIds = [...new Set(articleIds)];

      // Object with unique article Ids and amount of occurance
      const articleCount = entries.reduce((acc: any, curr: any) => (acc[curr.article] = (acc[curr.article] || null) + curr.count, acc), {}); 


      for(var articleId of uniqueArticleIds){

        let currentArticleType = clone(articleList).find(article => article.number == articleId)!;

        let article = new Article;
        article.image = currentArticleType.image;
        article.name = currentArticleType.name;        
        article.description = currentArticleType.description;        
        article.number = articleId;
        article.price = currentArticleType.price;
        article.type = currentArticleType.type;
        article.count = articleCount[articleId];
        article.site = currentArticleType.site;

        totalPrice = totalPrice + (article.price * article.count!);

        articles.push(article);
      }
    }

    if(roomId) {
      this.roomPrice[roomId] = totalPrice.toFixed(2);
    }

    // Sort articles by article type and number
    articles = articles.sort((a,b) => {
      let articleA: string = slugify(a.type + '-' + a.number);
      let articleB: string = slugify(b.type + '-' + b.number);
      //console.log(articleA);

      let result = (articleA > articleB) ? 1 : ((articleB > articleA) ? -1 : 0);
      return result;
    });
    //console.log(articles);

    return articles;
  }

  /**
   * Calculate total installtype price
   */
   public calculateInstallTypeTotal(type: string): string {
    let calculationData = this.calculation;
    let articles: Article[] = [];
    let entries: CalculationEntry[] = [];
    let articleList: Article[] = [];
    let totalPrice = 0;

    if(calculationData && calculationData.results && 
      calculationData!.results!.find(r => r.installType.xid == type) && 
      calculationData!.results!.find(r => r.installType.xid == type)!.entries && 
      calculationData!.results!.find(r => r.installType.xid == type)!.entries!.length > 0) {
      entries = calculationData.results!.find(r => r.installType.xid == type)!.entries!;
    }

    if(calculationData && calculationData.articles && calculationData!.articles.length > 0) {
      articleList = calculationData.articles;    
    }

    if(articleList.length > 0 && entries.length > 0) {
      // Array of article Ids
      let articleIds = entries.map(entry => entry.article); 

      let uniqueArticleIds = [...new Set(articleIds)];

      // Object with unique article Ids and amount of occurance
      const articleCount = entries.reduce((acc: any, curr: any) => (acc[curr.article] = (acc[curr.article] || null) + curr.count, acc), {}); 


      for(var articleId of uniqueArticleIds){

        let currentArticleType = articleList.find(article => article.number == articleId)!;

        let article = new Article;
        article.image = currentArticleType.image;
        article.name = currentArticleType.name;        
        article.description = currentArticleType.description;        
        article.number = articleId;
        article.price = currentArticleType.price;
        article.type = currentArticleType.type;
        article.count = articleCount[articleId];
        article.site = currentArticleType.site;

        totalPrice = totalPrice + (article.price * article.count!);

        articles.push(article);
      }
    }

    this.totalPrice = totalPrice.toFixed(2);
    
    return this.totalPrice;
  }

  /**
   * Add required data froom the roomlist to the room instances
   * @param roomList list of room types
   * @param rooms list of room instances
   */
  public addRoomDataToRoomInstances(roomList: Room[], rooms: RoomInstance[]) {
    this.roomList = roomList;

    for(var roomInstance of rooms){
      
      let iterations = roomList.length;

      for(var room of roomList){
        // Set room image and default room title
        if(roomInstance.typeXid == room.xid) {
          roomInstance.imageFull = room.imageFull;
          roomInstance.location = room.location;
          roomInstance.title = room.name.toLowerCase().replace(/([a-z])/i, (str:string, firstLetter:string) => firstLetter.toUpperCase());

          break;
        }

        // Remove roominstance from array if the roomtype doesn't exist (anymore)
        // If the roomtype exists, the break has stopped the forloop
        if (!--iterations) {
          //console.log('last iterations', roomInstance.id);
          rooms = rooms.filter(instance => {
            return instance.id != roomInstance.id
          });
          //console.log(rooms);
        }
      }

      this.rooms = rooms;
    }
  }

  /**
   * Copy the calculation log to the clipboard
   * @param log the calculated log text to add to the clipboard
   */
   copyLog(log:string) {
    navigator.clipboard.writeText(log).then( () => {
      this.snackBar.open('The calculation log has been copied to your clipboard.', undefined, {
        duration: 3000,
        horizontalPosition: 'right',
        panelClass: 'snackbar'
    });    }).catch( () => {
      this.snackBar.open('Something went wrong. The log was not copied to your clipboard.', undefined, {
        duration: 3000,
        horizontalPosition: 'right',
        panelClass: 'snackbar'
    });    });
  }

  /**
   * Sort rooms alphabetically
   */
     sortRoomsAlphabetically(rooms: RoomInstance[]) {
      //console.log(this.rooms);
        return rooms.sort((a,b) => ((a.name ? a.name : a.title) > (b.name ? b.name : b.title)) ? 1 : (((b.name ? b.name : b.title) > (a.name ? a.name : a.title)) ? -1 : 0));
    }

  /**
   * Add dynamic content for sidebar navigation
   */
  SetSideBarContent() {
    this.sideBarContent = [];

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_CALCULATION'].value, 
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.CALCULATION'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });


    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_INSTALLTYPES'].value,
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.INSTALLTYPE'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_PRODUCTS'].value, 
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.PRODUCTS'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });
  }

}

/* export const calculationOffline = {
  "articles": [
      {
          "number": "157-66901",
          "name": "Afwerkingsset voor stopcontact met randaarde en beschermingsafsluiters, champagne coated",
          "image": "https://www.niko.eu/niko/images/157-66901.jpg",
          "price": 19.99
      },
      {
          "number": "124-52001",
          "name": "Enkelvoudige drukknop met led voor Niko Home Control, dark brown",
          "image": "https://www.niko.eu/niko/images/124-52001.jpg",
          "price": 19.99
      },
      {
          "number": "161-66601",
          "name": "Afwerkingsset voor stopcontact met penaarde en beschermingsafsluiters, inbouwdiepte 28,5 mm, black coated",
          "image": "https://www.niko.eu/niko/images/161-66601.jpg",
          "price": 19.99
      },
      {
          "number": "170-33105",
          "name": "Sokkel voor stopcontact met penaarde en beschermingsafsluiters, inbouwdiepte 28,5 mm, 16 A/250 Vac, insteekklemmen",
          "image": "https://www.niko.eu/niko/images/170-33105.jpg",
          "price": 19.99
      },
      {
          "number": "550-14116",
          "name": "Enkelvoudige muurprint met sokkel voor Niko Home Control, 60 x 71 mm, schroefbevestiging",
          "image": "https://www.niko.eu/niko/images/550-14116.jpg",
          "price": 19.99
      },
      {
          "number": "700-36805",
          "name": "Spuitwaterdicht stopcontact 16 A/250 Vac met randaarde, beschermingsafsluiters en met insteekklemmen, grey",
          "image": "https://www.niko.eu/niko/images/700-36805.jpg",
          "price": 19.99
      },
      {
          "number": "104-76100",
          "name": "Enkelvoudige afdekplaat Niko Original greige",
          "image": "https://www.niko.eu/niko/images/104-76100.jpg",
          "price": 19.99
      },
      {
          "number": "170-34200",
          "name": "Sokkel voor stopcontact met randaarde, inbouwdiepte 28,5mm, 16 A/250 Vac, schroefklemmen",
          "image": "https://www.niko.eu/niko/images/170-34200.jpg",
          "price": 19.99
      },
      {
          "number": "550-22002",
          "name": "Videobuitenpost voor Niko Home Control, met 2 verlichte aanraaktoetsen",
          "image": "https://www.niko.eu/niko/images/550-22002.jpg",
          "price": 19.99
      },
      {
          "number": "700-36600",
          "name": "Spuitwaterdicht stopcontact 16 A/250 Vac met penaarde, beschermingsafsluiters en met schroefklemmen, grey",
          "image": "https://www.niko.eu/niko/images/700-36600.jpg",
          "price": 19.99
      },
      {
          "number": "124-52002",
          "name": "Tweevoudige drukknop met led voor Niko Home Control, dark brown",
          "image": "https://www.niko.eu/niko/images/124-52002.jpg",
          "price": 19.99
      },
      {
          "number": "700-73000",
          "name": "Spuitwaterdichte enkelvoudige inbouwdoos voor een functie, grey",
          "image": "https://www.niko.eu/niko/images/700-73000.jpg",
          "price": 19.99
      }
  ],
  "results": [
      {
          "installType": {
              "xid": "53847",
              "name": "bus"
          },
          "entries": [
              {
                  "room": "30e98ff0851c3cd1f66d899f41ca148d08acab94",
                  "article": "157-66901",
                  "count": 4
              },
              {
                  "room": "2af4886ec62738a5c6432b044edf69850c4fb306",
                  "article": "124-52001",
                  "count": 2
              },
              {
                  "room": "d52a4d1954572fbf28f10ef0e90afc738b2157af",
                  "article": "124-52001",
                  "count": 1
              },
              {
                  "room": "2af4886ec62738a5c6432b044edf69850c4fb306",
                  "article": "161-66601",
                  "count": 6
              },
              {
                  "room": "c922ec977b1a26a28ba5b9fd50c3a90a8d62c6b5",
                  "article": "170-33105",
                  "count": 1
              },
              {
                  "room": "d52a4d1954572fbf28f10ef0e90afc738b2157af",
                  "article": "550-14116",
                  "count": 1
              },
              {
                  "room": "bfa815227008c9573356ee20e78114ed3053ff25",
                  "article": "157-66901",
                  "count": 5
              },
              {
                  "room": "743fb9274df97e101da1586cbbeaa6f593089b86",
                  "article": "700-36805",
                  "count": 1
              },
              {
                  "room": "bfa815227008c9573356ee20e78114ed3053ff25",
                  "article": "104-76100",
                  "count": 11
              },
              {
                  "room": "2af4886ec62738a5c6432b044edf69850c4fb306",
                  "article": "550-14116",
                  "count": 2
              },
              {
                  "room": "c922ec977b1a26a28ba5b9fd50c3a90a8d62c6b5",
                  "article": "157-66901",
                  "count": 1
              },
              {
                  "room": "30e98ff0851c3cd1f66d899f41ca148d08acab94",
                  "article": "104-76100",
                  "count": 8
              },
              {
                  "room": "bfa815227008c9573356ee20e78114ed3053ff25",
                  "article": "161-66601",
                  "count": 5
              },
              {
                  "room": "d52a4d1954572fbf28f10ef0e90afc738b2157af",
                  "article": "161-66601",
                  "count": 1
              },
              {
                  "room": "2af4886ec62738a5c6432b044edf69850c4fb306",
                  "article": "170-33105",
                  "count": 6
              },
              {
                  "room": "c922ec977b1a26a28ba5b9fd50c3a90a8d62c6b5",
                  "article": "170-34200",
                  "count": 1
              },
              {
                  "room": "c922ec977b1a26a28ba5b9fd50c3a90a8d62c6b5",
                  "article": "161-66601",
                  "count": 1
              },
              {
                  "room": "2af4886ec62738a5c6432b044edf69850c4fb306",
                  "article": "104-76100",
                  "count": 14
              },
              {
                  "room": "d52a4d1954572fbf28f10ef0e90afc738b2157af",
                  "article": "550-22002",
                  "count": 1
              },
              {
                  "room": "c922ec977b1a26a28ba5b9fd50c3a90a8d62c6b5",
                  "article": "550-14116",
                  "count": 1
              },
              {
                  "room": "d52a4d1954572fbf28f10ef0e90afc738b2157af",
                  "article": "104-76100",
                  "count": 3
              },
              {
                  "room": "bfa815227008c9573356ee20e78114ed3053ff25",
                  "article": "170-34200",
                  "count": 5
              },
              {
                  "room": "c922ec977b1a26a28ba5b9fd50c3a90a8d62c6b5",
                  "article": "124-52001",
                  "count": 1
              },
              {
                  "room": "743fb9274df97e101da1586cbbeaa6f593089b86",
                  "article": "700-36600",
                  "count": 1
              },
              {
                  "room": "30e98ff0851c3cd1f66d899f41ca148d08acab94",
                  "article": "170-33105",
                  "count": 4
              },
              {
                  "room": "30e98ff0851c3cd1f66d899f41ca148d08acab94",
                  "article": "161-66601",
                  "count": 4
              },
              {
                  "room": "30e98ff0851c3cd1f66d899f41ca148d08acab94",
                  "article": "170-34200",
                  "count": 4
              },
              {
                  "room": "bfa815227008c9573356ee20e78114ed3053ff25",
                  "article": "170-33105",
                  "count": 5
              },
              {
                  "room": "bfa815227008c9573356ee20e78114ed3053ff25",
                  "article": "124-52002",
                  "count": 1
              },
              {
                  "room": "743fb9274df97e101da1586cbbeaa6f593089b86",
                  "article": "700-73000",
                  "count": 2
              },
              {
                  "room": "2af4886ec62738a5c6432b044edf69850c4fb306",
                  "article": "170-34200",
                  "count": 6
              },
              {
                  "room": "d52a4d1954572fbf28f10ef0e90afc738b2157af",
                  "article": "170-34200",
                  "count": 1
              },
              {
                  "room": "bfa815227008c9573356ee20e78114ed3053ff25",
                  "article": "550-14116",
                  "count": 1
              },
              {
                  "room": "d52a4d1954572fbf28f10ef0e90afc738b2157af",
                  "article": "170-33105",
                  "count": 1
              },
              {
                  "room": "2af4886ec62738a5c6432b044edf69850c4fb306",
                  "article": "157-66901",
                  "count": 6
              },
              {
                  "room": "c922ec977b1a26a28ba5b9fd50c3a90a8d62c6b5",
                  "article": "104-76100",
                  "count": 3
              },
              {
                  "room": "d52a4d1954572fbf28f10ef0e90afc738b2157af",
                  "article": "157-66901",
                  "count": 1
              }
          ]
      }
  ],
  "transcript": ""
} */