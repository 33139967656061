<div id="topbar">

  <!-- step 1 buttons -->
  <div gaCategory="Onboarding - Welcome" class="button-wrapper" *ngIf="activeStep == 1">
    <button gaEvent="navigate" gaLabel="back" class="btn btn-small btn-icon btn-transparent" (click)="logout()">
      {{ 'ONBOARDING.BACK' | translate }}
    </button>

    <button gaEvent="navigate" gaLabel="next" class="btn btn-primary btn-small" (click)="activeStep = 2">
      {{ 'ONBOARDING.NEXT' | translate }}
    </button>
  </div>

  <!-- step 2 buttons -->
  <div gaCategory="Onboarding - Company" class="button-wrapper" *ngIf="activeStep == 2">
    <button gaEvent="navigate" gaLabel="back" class="btn btn-small btn-icon btn-transparent" (click)="activeStep = 1">
      {{ 'ONBOARDING.BACK' | translate }}
    </button>

    <button gaEvent="navigate" gaLabel="next" class="btn btn-primary btn-small" (click)="checkOnboarding()">
      {{ 'ONBOARDING.NEXT' | translate }}
    </button>
  </div>

  <img id="logo" src="/assets/logo/niko_logo.svg" alt="Niko logo">
</div>

<div class="onboarding-wrapper">

<!-- Step 1: Welcome -->
<ng-container *ngIf="activeStep == 1">
  <div class="image-banner" [style.background-image]="'url(/assets/pictures/onboarding/onboarding_welcome.jpg)'"></div>

  <div id="onboarding-welcome" class="onboarding-container" gaCategory="Onboarding - Welcome" >
    <div class="onboarding-content">
      <h1 class="title">{{ "ONBOARDING.WELCOME.TITLE" | translate }} {{userData.firstname}}</h1>
      <p class="margin-b">{{ "ONBOARDING.WELCOME.INTRO" | translate }}</p>
      <ul class="margin-b-60">
        <li>{{ "ONBOARDING.WELCOME.LIST1" | translate }}</li>
        <li>{{ "ONBOARDING.WELCOME.LIST2" | translate }}</li>
        <li>{{ "ONBOARDING.WELCOME.LIST3" | translate }}</li>
        <li>{{ "ONBOARDING.WELCOME.LIST4" | translate }}</li>
      </ul>
      <div>
        <label class="checkbox">{{'ONBOARDING.WELCOME.HIDE' | translate}}
          <input autocomplete="new-password" #hideOnboarding [checked]="onboardingStatus == 'hidden'" type="checkbox" (click)="hideOnboarding.checked ? hideOnboardingOnProfile(true) : hideOnboardingOnProfile(false)">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</ng-container>

<!-- Step 2: Company details -->
<ng-container  *ngIf="activeStep == 2">
  <div id="onboarding-company" class="onboarding-container" gaCategory="Onboarding - Company" >
    <div class="onboarding-content">
      <h1 class="title tooltip-trigger">{{ "ONBOARDING.COMPANY.TITLE" | translate }}
        <span class="tooltip-btn" (click)="openTooltip('ONBOARDING.COMPANY.TITLE','INSTALLER_DETAILS.INFO')"></span>
      </h1>
      
      <form [formGroup]="installerDetails" *ngIf="installerDetails" autocomplete="off">
        <div class="form-wrapper">
          <div class="input45 order1">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.NAME' | translate }}</mat-label>
              <input autocomplete="new-password" gaEvent="form_input" gaLabel="name"  matInput formControlName="name" (focusout)="saveProperty('name')">
              <mat-error>{{ 'INSTALLER_DETAILS.ERROR.NAME' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="input35 order2">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.VAT' | translate }}</mat-label>
              <input 
                placeHolderCharacter=" "
                [prefix]="installerDetails.get('country')!.value + ' '" 
                [mask]="
                  installerDetails.get('country')!.value == 'BE' ? '0000.000.000' : 
                  (installerDetails.get('country')!.value == 'NL' ? '000000000S00' : 
                  (installerDetails.get('country')!.value == 'DK' ? '00 00 00 00' : 
                  'A*'))" 
                [showMaskTyped]="
                  installerDetails.get('country')!.value == 'BE' || 
                  installerDetails.get('country')!.value == 'NL' ||
                  installerDetails.get('country')!.value == 'DK' 
                  ? true : false" 
              autocomplete="new-password" gaEvent="form_input" gaLabel="vat"  matInput formControlName="vat" (focusout)="saveProperty('vat')">
              <mat-error>{{ 'INSTALLER_DETAILS.ERROR.VAT' | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div class="input35 order3">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.PHONE' | translate }}</mat-label>
              <input autocomplete="new-password" matInput formControlName="phone" (focusout)="saveProperty('phone')">
            </mat-form-field>
          </div>
          <div class="input55 order4">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.EMAIL' | translate }}</mat-label>
              <input autocomplete="new-password" type="email" matInput formControlName="email" (focusout)="saveProperty('email')">
            </mat-form-field>
          </div>

          <div class="input55 order5">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.ADDRESS' | translate }}</mat-label>
              <input autocomplete="new-password" gaEvent="form_input" gaLabel="address" matInput formControlName="address" (focusout)="saveProperty('address')">
            </mat-form-field>
          </div>
          <div class="input55 order7">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.ADDRESS2' | translate }}</mat-label>
              <input autocomplete="new-password" gaEvent="form_input" gaLabel="address2" matInput formControlName="address2" (focusout)="saveProperty('address2')">
            </mat-form-field>
          </div>
          <div class="input35 order6">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.ZIP' | translate }}</mat-label>
              <input autocomplete="new-password" gaEvent="form_input" gaLabel="zip" matInput formControlName="zip" [matAutocomplete]="zipAutocomplete" appAutocomplete="trigger"
                (keyup)="citySet = false"
                (focusout)="cities.length > 0 || citySet = true; cities.length > 0 || saveProperty('zip')">
                <!-- Only  save typed input if no cities were available for the automcomplete -->
            </mat-form-field>
            <mat-autocomplete #zipAutocomplete="matAutocomplete">
              <mat-option *ngFor="let city of filteredPostalCodes | async"
                (click)="currentCity = city; citySet = true; saveProperty('zip')"
                [value]="city.postalCode">
                {{city.postalCode + ' - ' + city.name }}

              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="input35 order8">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.CITY' | translate }}</mat-label>
              <input autocomplete="new-password" gaEvent="form_input" gaLabel="city" matInput formControlName="city" [matAutocomplete]="cityAutocomplete" appAutocomplete="trigger"
                (keyup)="citySet = false"
                (focusout)="cities.length > 0 || citySet = true; cities.length > 0 || saveProperty('city');">
                <!-- Only  save typed input if no cities were available for the automcomplete -->
                <mat-error>{{ 'INSTALLER_DETAILS.ERROR.CITY' | translate }}</mat-error>
            </mat-form-field>
            <mat-autocomplete #cityAutocomplete="matAutocomplete">
              <mat-option *ngFor="let city of filteredCities | async" 
                (click)="currentCity = city; citySet = true; saveProperty('city')" 
                [value]="city.name">
                {{city.postalCode + ' - ' + city.name }}

              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="input35 order9" gaEvent="form_input" gaLabel="country" >
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.COUNTRY' | translate }}</mat-label>
              <mat-select autocomplete="new-password" formControlName="country">
                <mat-option (click)="saveProperty('country')" *ngFor="let country of countries" [value]="country['code']">
                  {{ 'PREFERENCES.COUNTRIES.' +
                  country['code'] | uppercase | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>

    </div>
  </div>
</ng-container>

  
</div>