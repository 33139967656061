import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { Finishing, LOCATION } from 'src/app/models/Finishing';
import { InteriorType } from 'src/app/models/InteriorType';
import { Room, RoomInstance } from 'src/app/models/Room';
import { Ternary } from 'src/app/models/Subject';
import { FinishingService } from 'src/app/services/finishing/finishing.service';
import { InteriorTypeService } from 'src/app/services/interior-type/interior-type.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { RoomService } from 'src/app/services/room/room.service';
import { clone, cloneObject, mergeArrays } from 'src/app/util/Util';

export interface DialogData {
  'projectId': string;
  'room': RoomInstance;
  'location': LOCATION;
  'roomString': string;
}

@Component({
  selector: 'app-finishing-modal',
  templateUrl: './finishing-modal.component.html',
  styleUrls: ['./finishing-modal.component.scss']
})
export class FinishingModalComponent extends BaseComponent implements OnInit {

  public projectId!: string;

  public generalFinishing!: Finishing;
  public finishings!: Finishing[];

  public room!: RoomInstance;
  public location!: LOCATION;

  private roomString: string = '';

  constructor(
    private finishingService: FinishingService,
    private roomService: RoomService,
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<FinishingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      super();
  }

  ngOnInit(): void {
    this.room = cloneObject(this.data.room);
    this.location = this.data.location;

    let finishings$ = this.finishingService.getFinishings();

    let projectId = this.data.projectId;  
    this.projectId = projectId;

    let projectData$ = this.projectService.getProjectById(projectId);

    // Join interior type list and projectdata after both observables have completed
    forkJoin({finishings: finishings$, projectData: projectData$ }).subscribe(r => {

      let finishingRanges = r.finishings;

      // Remove finishingranges based on room location
      if(this.location == LOCATION.OUTDOOR) {
        finishingRanges = r.finishings.filter(finishing => finishing.location.includes(LOCATION.OUTDOOR));
      } else {
        finishingRanges = r.finishings.filter(finishing => !finishing.location.includes(LOCATION.OUTDOOR));
      }

      // Set selected status for all finishings
      finishingRanges.forEach(range => {
        range.finishings?.forEach(finishing => {

          finishing.title = range.title + ' - ' + finishing.title;
          
          // Set finishing selected based on project finishing data
          let finishingData = r.projectData.finishings;
          if(finishingData && finishingData.length > 0 ) {
            finishing.selected = finishingData?.find((data:Finishing) => data.xid === finishing.xid)?.selected || Ternary.FALSE;
          } else {
            finishing.selected = Ternary.FALSE;
          }
          
          if(finishing.selected == Ternary.SELECTED) {
            this.generalFinishing = finishing; 
          }

          // Set selected finishing based on room specific finishing data if available
          if ((this.room.finishingIndoor && this.room.finishingIndoor !== '') 
          || (this.room.finishingOutdoor && this.room.finishingOutdoor !== '')) {
              if(finishing.xid == this.room.finishingIndoor || finishing.xid == this.room.finishingOutdoor) {
                finishing.selected = Ternary.SELECTED;
              } else if(finishing.selected == Ternary.SELECTED) {
                finishing.selected = Ternary.TRUE;
              }
          }
        })
      });

      // Show all available finishings for outdoor rooms, and just the enabled finishings for indoor rooms
      if(this.location == LOCATION.OUTDOOR) {
        this.finishings = finishingRanges.flatMap(x => x.finishings!.filter(y => y));
      } else {
        this.finishings = finishingRanges.flatMap(x => x.finishings!.filter(y => (y.selected === Ternary.TRUE || y.selected === Ternary.SELECTED)));
      }

    });
  }

  /**
   * Set finishing status
   * @param finishing finishing of which status changes
   * @param select Ternary on or selected
   */
  selectFinishing(finishing: Finishing) {  
    let selectedFinishing = this.finishings.find(finishing => finishing.selected == Ternary.SELECTED);
    
    if(selectedFinishing) {
      selectedFinishing.selected = Ternary.TRUE;
    } 

    finishing.selected = Ternary.SELECTED;

    let roomString = 'id=' + this.room.id;

    // Add room name to room string if available
    if(this.room.name && this.room.name != 'null') {
      roomString = roomString + '&name=' + this.room.name;
    }

    if(this.location == LOCATION.OUTDOOR) {
      this.room.finishingOutdoor = finishing.xid;
      this.room.outdoorFinishing = finishing;

      // If outdoor finishing is different from the general outdoor finishing, add it to the room string
      // otherwise, reset to general outdoor finishing by leaving it from the room string
      if(finishing.xid != this.generalFinishing.xid) {
        roomString = roomString + '&finishing_outdoor=' + finishing.xid;
      }

      // Add existing finishing data for indoor to room string
      if(this.room.finishingIndoor && this.room.finishingIndoor != 'null') {
        roomString = roomString + '&finishing_indoor=' + this.room.finishingIndoor;
      }
    } else {
      this.room.finishingIndoor = finishing.xid;
      this.room.indoorFinishing = finishing;
      
      // If finishing is not the same as the general finishing, add it to the room string
      // otherwise, reset to general finishing by leaving it from the room string
      if(finishing.xid != this.generalFinishing.xid) {
        roomString = roomString + '&finishing_indoor=' + finishing.xid;
      }

      // Add existing finishing data for outdoor to room string
      if(this.room.finishingOutdoor && this.room.finishingOutdoor != 'null') {
        roomString = roomString + '&finishing_outdoor=' + this.room.finishingOutdoor;
      }
    }

    this.roomString = roomString;
  }

  saveFinishing() {
    this.roomService.editRoom(this.projectId, this.roomString);

    this.data.room = this.room;
    this.dialogRef.close(this.data);
  }
}