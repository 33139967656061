import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { debounceTime, distinctUntilChanged, first, take } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { OnboardingModalComponent } from 'src/app/components/modals/onboarding-modal/onboarding-modal.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { ProjectModule } from 'src/app/models/Project';
import { Subject, Ternary } from 'src/app/models/Subject';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { clone, mergeArrays } from 'src/app/util/Util';
import { TopicDetailComponent } from '../topic-detail/topic-detail.component';

@Component({
  selector: 'app-inspiration',
  templateUrl: './inspiration.component.html',
  styleUrls: ['./inspiration.component.scss']
})
export class InspirationComponent extends BaseComponent implements OnInit {

  public projectId!: string;

  public subjects:Subject[] = [];
  public sessionSubjects:Subject[] = [];
  public selectedSubjects:Subject[] = [];

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private translate: TranslateService,
    private subjectService: SubjectService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private gaService: GoogleAnalyticsService
    ) {
      super();
  }

  ngOnInit(): void {

    let projectId = this.activatedRoute.snapshot.params['project_id'];    
    this.projectId = projectId;

    this.openOnboardingModal();

    this.showSubjects();
  }

  /**
   * Show available subjects with user selection
   */
   showSubjects() {
    this.addSubscription(this.subjectService.cachedSubjects.pipe(debounceTime(500), distinctUntilChanged()).subscribe((r: Subject[]) => {
      this.subjects = r;

      this.selectedSubjects = this.subjects.filter(x => x.selected === Ternary.TRUE);
  
      this.SetSideBarContent(this.selectedSubjects);
    }));
  }

      /**
   * Open onboarding modal
   */
       openOnboardingModal() {
        const dialogRef = this.dialog.open(OnboardingModalComponent, {
          autoFocus: false, 
          data: {
            'step':  ProjectModule.FUNCTIONALITIES,
            'background': '/assets/pictures/onboarding/onboarding_installer.jpg'
          },
          maxWidth: '90vw',
          maxHeight: '90vh',
          width: '900px'
        });
    
        dialogRef.afterClosed().subscribe(r => {
          /* if (r && r.room) {
            this.removeRoom(r.room.id)
          } */
        });
      }
    /**
   * Open tooltip modal
   */
        public openTooltip(title: string = '', text: string) {
          const dialogRef = this.dialog.open(TooltipModalComponent, {
            autoFocus: false, 
            data: {
              'title': title,
              'text': text
            },
            maxWidth: '90vw',
            maxHeight: '90vh',
            minWidth: '775px'
          });
        }

  /**
   * Add dynamic content for sidebar navigation
   */
  SetSideBarContent(subjects: Subject[]) {
    this.sideBarContent = [];
    
    subjects.forEach(subject => {
      this.sideBarContent.push({
        route: '../../' + APP_ROUTES['FUNCTIONALITIES_USECASES'].value.replace(':topic_id', subject.xid), 
        breadcrumb: subject.title,
        complete: false,
        active: false,
        available: true,
        subitem: true
      });
    })
    
    this.sideBarContent.unshift({
      route: '../../' + APP_ROUTES['FUNCTIONALITIES_SUBJECTS'].value, 
      breadcrumb: this.translate.instant('PROJECT_FUNCTIONALITIES.NAV.SUBJECTS'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FUNCTIONALITIES_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_FUNCTIONALITIES.NAV.SUMMARY'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });

    //console.log(this.sideBarContent);
  }

  /**
   * Set subject to required status and pass subject usecase selection values to backend
   * as required: as saved in cachedSubjects if set to TRUE, all off if set to FALSE
   * @param id subject id
   * @param status required status for the given subject
   */
  SetSubjectSelectedStatus(id: string, status: Ternary) {
    let currentSubject = this.subjects.find(x => x.xid === id);
    currentSubject!.selected = status;

    this.selectedSubjects = this.subjects.filter(x => x.selected == Ternary.TRUE);
    this.SetSideBarContent(this.selectedSubjects);

    let useCaseString = '';

    //console.log('before sessionsubjects');
    this.addSubscription(this.subjectService.sessionSubjects.pipe(take(1)).subscribe(sessionSubjects => {
      //console.log('sessionsubjects');
      // turn off all usecases on server if subject set to FALSE
      if(status == Ternary.FALSE) { 
        // Backup current usecase selection settings first
        let subjects = clone(this.subjects);

        sessionSubjects.filter(x => x.xid === id)[0].useCases = mergeArrays(sessionSubjects.filter(x => x.xid === id)[0].useCases!, subjects.filter(x => x.xid === id)[0].useCases!);
        this.subjectService.sessionSubjects.next(sessionSubjects);


        this.subjects.filter(x => x.xid === id)[0].useCases!.forEach(useCase => {
          useCase.selected = Ternary.FALSE;
          useCaseString = useCaseString + Ternary.FALSE + '=' + useCase.xid + '&';
        });
        //console.log('usecases turned off',this.subjects);


      // turn on sessionSubjects usecases on server if subject set to TRUE
      }  else { 
        this.sessionSubjects = sessionSubjects;

        this.sessionSubjects.filter(x => x.xid === id)[0].useCases!.forEach(useCase => {
          useCaseString = useCaseString + useCase.selected + '=' + useCase.xid + '&';
        })

        this.subjects.filter(x => x.xid === id)[0].useCases = mergeArrays(this.subjects.filter(x => x.xid === id)[0].useCases!, this.sessionSubjects.filter(x => x.xid === id)[0].useCases!);
        //console.log('usecases turned on',this.subjects);
      }

      //Subject analytics
      this.gaService.event('set_' + status, 'Topic Status', currentSubject!.title);

      //console.log(this.subjects);
      this.subjectService.cachedSubjects.next(this.subjects);
      this.subjectService.selectSubjectState(this.projectId, status + '='+ id);
      this.subjectService.selectUsecaseState(this.projectId, useCaseString);
    }));
  }

  /**
   * Open topic detail modal
   * @param topicId id of the topic to be opened in detail modal
   */
  openDialog(topicId: string) {
    this.dialog.open(TopicDetailComponent, {
      data: {
        'topic_id': topicId,
      },
      autoFocus: false, 
      width: '80vh',
      maxHeight: '80vh',
      maxWidth: '80vw'
    });
  }
}
