<div id="navigation" gaCategory="Navigation">
  <button gaEvent="toggle_nav" [gaLabel]="!menuOpen ? 'open navigation menu' : 'close navigation menu'" id="menu-btn" (click)="menuOpen = !menuOpen" [style.color]="color">
    <mat-icon *ngIf="!menuOpen" svgIcon="hamburger" id="hamburger" class="icon"></mat-icon>
    <mat-icon *ngIf="menuOpen" svgIcon="close" id="close" class="icon"></mat-icon>
  </button>
  <div id="nav-wrapper" [class.sidebar]="sidebar" *ngIf="menuOpen">
    <ul>
      <li *ngIf="projectId">
        <button gaEvent="navigate" gaLabel="Project details" [routerLink]="'/'+ APP_ROUTES['PROJECT'].value.replace(':project_id', projectId)">
          <mat-icon svgIcon="product_list" class="icon"></mat-icon>
          {{ 'NAVIGATION.MENU.DETAILS' | translate}}
        </button>
      </li>
      <li>
        <button gaEvent="navigate" routerLinkActive="active" gaLabel="Project list" [routerLink]="'/'+ APP_ROUTES['PROJECTLIST'].value">
          <mat-icon svgIcon="overview" class="icon"></mat-icon>
          {{ 'NAVIGATION.MENU.OVERVIEW' | translate}}
        </button>
      </li>
      <li>
        <button gaEvent="navigate" routerLinkActive="active" gaLabel="Preferences" [routerLink]="'/'+ APP_ROUTES['PREFERENCES'].value">
          <mat-icon svgIcon="settings" class="icon"></mat-icon>
          {{ 'NAVIGATION.MENU.PREFERENCES' | translate}}
        </button>
      </li>
      <li>
        <button gaEvent="button_click" routerLinkActive="active" gaLabel="logout" (click)="logout()">
          <mat-icon svgIcon="logout" class="icon"></mat-icon>
          {{ 'NAVIGATION.MENU.LOGOUT' | translate}}
        </button>
      </li>
    </ul>
  </div>
</div>