
  <div id="spaces-component">
    <div mat-dialog-title>
      <button (click)="dialogRef.close()" class="btn-close">
        <mat-icon class="icon icon--small">close</mat-icon>
      </button>
  
  
      <div class="flex flex-end margin-b">
        <mat-form-field>
          <mat-label>{{ 'PROJECT_SPACES.MODAL.SEARCH' | translate}}</mat-label>
          <input autocomplete="new-password" matInput [formControl]="search" (keyup)="findRoom()">
        </mat-form-field>
      </div>
  
      <h2 class="h1 margin-b">{{ 
        (this.search.value && this.search.value != '') ? ('PROJECT_SPACES.MODAL.RESULTS' | translate) + ' \'' + resultString + '\'' : ('PROJECT_SPACES.MODAL.ALL_RESULTS' | translate) }}</h2>
    </div>

    <div mat-dialog-content class="space-container">

      <ng-container *ngIf="rooms.length > 0">
        <ng-container *ngFor="let room of rooms">

          <div class="space" [class.active]="space == room.xid" (click)="space = room.xid">
            <div class="content-wrapper pointer">
              <div *ngIf="+room.count > 0" class="count">{{ room.count }}</div>
              <div class="space-wrapper">
                <img [src]="room.imageFull" class="icon icon--large">
                <p class="font-l text-center">{{ room.name }}</p>
              </div>
            </div>
    
            <div class="edit-wrapper">
              <div class="edit-info">
                <div class="amount">{{ room.count }}</div>
                <p class="text-center">{{ room.name }}</p>
              </div>
              <div class="edit-buttons">
                <div (click)="removeRoom(room)" class="btn-round-icon edit-remove">
                  <mat-icon class="icon icon--small">remove</mat-icon>
                </div>
                <div (click)="addRoom(room, 1)" class="btn-round-icon edit-add">
                  <mat-icon class="icon icon--small">add</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      
      <p *ngIf="rooms.length == 0">{{ 'PROJECT_SPACES.MODAL.NO_ROOMS' | translate}}</p>

    </div>
  </div>