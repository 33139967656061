<app-project-page-template [functionalitiesPage]="true" [breadcrumb]="'PROJECT_FUNCTIONALITIES.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_FUNCTIONALITIES.INSPIRATION.BREADCRUMB_PREV' | translate" 
  [routerlinkPrev]="'../../'"
  [breadcrumbNext]="(selectedSubjects.length > 0 ? 'PROJECT_FUNCTIONALITIES.INSPIRATION.BREADCRUMB_NEXT' : 'PROJECT_FUNCTIONALITIES.NO_TOPICS.BREADCRUMB_NEXT') | translate"
  [routerlinkNext]="selectedSubjects.length > 0 ? '../../' + APP_ROUTES['FUNCTIONALITIES_USECASES'].value.replace(':topic_id', selectedSubjects[0].xid) : '../../' + APP_ROUTES['FUNCTIONALITIES_SUMMARY'].value"
  [sideBarContent]="sideBarContent">

  <div id="content">

    <h1 class="tooltip-trigger">
      {{'PROJECT_FUNCTIONALITIES.INSPIRATION.TITLE' | translate}}
      <span class="tooltip-btn" (click)="openTooltip('PROJECT_FUNCTIONALITIES.INSPIRATION.TITLE','PROJECT_FUNCTIONALITIES.INSPIRATION.SUBTITLE.INFO')"></span></h1>
    <p class="margin-b dark-grey">{{'PROJECT_FUNCTIONALITIES.INSPIRATION.SUBTITLE' | translate}}</p>

    <div class="inspiration-container">

      <div *ngFor="let subject of subjects" class="inspiration-wrapper pointer" (click)="openDialog(subject.xid)">

        <div class="inspiration-picture">
          <img [src]="subject.imageFull!">
        </div>

        <div class="inspiration-info content-wrapper">
          <p class="font-l">{{ subject.title }}</p>
          <div class="check-buttons">
            <div (click)="SetSubjectSelectedStatus(subject.xid, Ternary.FALSE); $event.stopPropagation();"
              [class.active]="subject.selected == Ternary.FALSE" class="btn-round-icon check-false ">
              <mat-icon class="icon icon--small">close</mat-icon>
            </div>
            <div (click)="SetSubjectSelectedStatus(subject.xid, Ternary.TRUE); $event.stopPropagation();"
              [class.active]="subject.selected == Ternary.TRUE" class="btn-round-icon check-true ">
              <mat-icon class="icon icon--small">check</mat-icon>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</app-project-page-template>