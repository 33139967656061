import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { appRouterConfig } from './AppRouterConfig';

// Guards

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRouterConfig,
      { onSameUrlNavigation: 'reload',      
        anchorScrolling: "enabled",
        scrollPositionRestoration: 'enabled'
      })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
