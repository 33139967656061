import { Injectable } from '@angular/core';
import { LocalStorageKeys } from './local-storage-keys';

/**
 * Service that acts as interface to use the session storage
 * @author thijsvm
 */
@Injectable({providedIn: 'root'})
export class LocalStorageService {

    private storage: Storage = localStorage;

    constructor() {

    }

    /**
     * Save a string to local storage.
     * @param key to identify the value
     * @param value to store
     */
    public saveItem(key: LocalStorageKeys, value: string): void {
        this.storage.setItem(key, value);
    }

    /**
     * Save an object to local storage.
     * @param key to identify the value
     * @param value to store
     */
    public saveObject<T>(key: LocalStorageKeys, value: T): void {
        this.storage.setItem(key, JSON.stringify(value));
    }

    /**
     * Retrieve a nullable string from local storage.
     * @param key associated to the value
     * @returns the value associated to the provided key or null when key not found
     */
    public getItem(key: LocalStorageKeys): string | null{
        return this.storage.getItem(key);
    }

    /**
     * Retrieve a nullable object of type T from local storage.
     * @param key associated to the value
     * @returns the value associated to the provided key or null when key not found
     */
    public getObject<T>(key: LocalStorageKeys): T | null{
        let value = this.storage.getItem(key);
        if (value) {
            return JSON.parse(value) as T;
        }
        return null;
    }

    /**
     * Remove a value associated to specified key.
     * @param key associated to the value
     */
    public remove(key: LocalStorageKeys): void {
        this.storage.removeItem(key);
    }

    /**
     * Remove all items from local storage.
     */
    public clearStorage(): void {
        this.storage.clear();
    }
}
