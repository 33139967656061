<div id="onboarding-modal" gaCategory="Onboarding Modal - Project status">
  <div>
    
    <!-- Modal close button -->
    <button gaEvent="button_click" gaLabel="modal_close" (click)="closeDialog()" class="btn-close">
      <mat-icon class="icon icon--small">close</mat-icon>
    </button>

    <div class="onboarding-content margin-b-50">

      <!-- Modal content -->
      <ng-container>
        <h1 class="title modal-content margin-b-100" class="tooltip-trigger">{{ "ONBOARDING.STATUS_" + activeStep + ".TITLE" | translate }}
        <span class="tooltip-btn" (click)="openTooltip('ONBOARDING.STATUS_' + activeStep + '.TITLE','PROJECT.INFO')"></span></h1>
  
        <div class="modal-image margin-b-100 text-center" [class.modal-content]="activeStep == 4">
          <img [src]="'assets/svg-elements/projectstatus4.svg'" *ngIf="activeStep == 4" />

          <div class="status-container" *ngIf="activeStep != 4">
            <!-- Status Intake -->
            <div class="status-wrapper" 
              [class.active]="activeStep == 1"
              [class.finished]="activeStep == 2 || activeStep == 3">
              <div class="dot"></div>
              <div id="intake-btn" class="btn btn-circle btn-secondary">
                <mat-icon svgIcon="intake" class="icon"></mat-icon>
              </div>
              <p>{{ "PROJECT.INTAKE" | translate}}</p>
            </div>
    
            <!-- Status Functionalities -->
            <div class="status-wrapper"  
            [class.disabled]="activeStep == 1"
            [class.active]="activeStep == 2">
              <div class="dot"></div>
              <div id="requirements-btn" class="btn btn-circle btn-secondary">
                <mat-icon svgIcon="requirements" class="icon"></mat-icon>
              </div>
              <p>{{ "PROJECT.FUNCTIONALITIES" | translate}}</p>
    
              <!-- Adjustments warning -->
              <label fragment="issues" class="error-adjustment" *ngIf="activeStep == 3">
                <mat-icon svgIcon="error" class="icon icon-error icon--small"></mat-icon> 1 {{ "PROJECT.ADJUSTMENT" | translate }}
              </label>
            </div>
    
            <!-- Status Finishing -->
            <div class="status-wrapper" 
            [class.disabled]="activeStep == 1">
              <div class="dot"></div>
              <div id="home-btn" class="btn btn-circle btn-secondary">
                <mat-icon svgIcon="home" class="icon"></mat-icon>
              </div>
              <p>{{ "PROJECT.APPEARANCE" | translate}}</p>
    
              <!-- Adjustments warning -->
              <label fragment="issues" class="error-adjustment" *ngIf="activeStep == 3">
                <mat-icon svgIcon="error" class="icon icon-error icon--small"></mat-icon> 1 {{ "PROJECT.ADJUSTMENT" | translate }}
              </label>
            </div>
    
            <!-- Status Configuration -->
            <div class="status-wrapper"
            [class.disabled]="activeStep != 3"
            [class.active]="activeStep == 3">
              <div class="dot"></div>
              <div id="config-btn" class="btn btn-circle btn-secondary">
                <mat-icon svgIcon="config" class="icon"></mat-icon>
              </div>
              <p>{{ "PROJECT.CONFIGURATION" | translate}}</p>
            </div>
    
            <!-- Status Summary -->
            <div class="status-wrapper"
            [class.disabled]="true">
              <div class="dot"></div>
              <div id="overview-btn" class="btn btn-circle btn-secondary">
                <mat-icon svgIcon="clipboard" class="icon"></mat-icon>
              </div>
              <p>{{ "PROJECT.SUMMARY" | translate}}</p>
            </div>
          </div>
        </div>
  
        <p class="modal-text margin-b modal-content" [innerHTML]="'ONBOARDING.STATUS_' + activeStep + '.HTML' | translate"></p>
      </ng-container>

      <!-- Hide onboarding for this step in user settings and session -->
      <div class="modal-content">
        <label class="checkbox">{{'ONBOARDING.WELCOME.HIDE' | translate}}
          <input autocomplete="new-password" #hideStatusOnboarding [checked]="onboardingStatus == 'hidden'" type="checkbox" (click)="hideStatusOnboarding.checked ? hideOnboardingOnProfile(true) : hideOnboardingOnProfile(false)">
          <span class="checkmark"></span>
        </label>
      </div>

    </div>
  </div>

  <!-- Modal proceed button -->
  <div class="button-wrapper">
    <button gaEvent="button_click" [gaLabel]="activeStep < 4 ? 'show slide ' + (activeStep + 1) :'confirm'" (click)="activeStep < 4 ? activeStep = activeStep + 1 : closeDialog()" class="btn btn-secondary btn-small">
      {{ "ONBOARDING.STATUS_" + activeStep + ".CONFIRM" | translate }}
    </button>
  </div>

</div>