import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { COLORS } from 'src/app/constants/Colors';
import { APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends BaseComponent implements OnInit  {

  public COLORS = COLORS;

  public menuOpen = false;

  public projectId?: string;

  @Input() color: string = COLORS.white;

  @Input() sidebar: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) { super(); }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.params['project_id'];
  }

  public logout() {
    this.userService.logout();
    this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
  }
}
