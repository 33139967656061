<app-project-page-template [breadcrumb]="'PROJECT_INTAKE.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_UTILITIES.BREADCRUMB_PREV' | translate" 
  [routerlinkPrev]="'../../' + APP_ROUTES['INTAKE_BUILDINGTYPE'].value"
  [breadcrumbNext]="'PROJECT_UTILITIES.BREADCRUMB_NEXT' | translate"
  [routerlinkNext]="'../../' + APP_ROUTES['INTAKE_ROOMS'].value"
  [sideBarContent]="sideBarContent">
  <div id="content-wrapper">
    <div id="content" *ngIf="projectData">

      <h1 class="tooltip-trigger">
        {{ 'PROJECT_UTILITIES.TITLE' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PROJECT_UTILITIES.TITLE','PROJECT_UTILITIES.INFO')"></span>
      </h1>
      <p class="margin-b dark-grey">{{ 'PROJECT_UTILITIES.SUBTITLE' | translate }}</p>

      <div class="utility-container margin-b">

        <div class="content-container">
          <div class="choice-card">
            <mat-icon svgIcon="electricity" class="icon icon--large"></mat-icon>
            <p class="font-l">{{ 'PROJECT_UTILITIES.ELECTRICITY' | translate }}</p>
            <div class="check-buttons">
              <div (click)="saveProperty('electricity', Ternary.FALSE)"
                [class.active]="propertyValue('electricity')! == Ternary.FALSE" class="btn-round-icon check-false">
                <mat-icon class="icon icon--small">close</mat-icon>
              </div>
              <div (click)="saveProperty('electricity', Ternary.MAYBE)"
                [class.active]="!propertyValue('electricity') || propertyValue('electricity')! == Ternary.MAYBE"
                class="btn-round-icon check-question">
                <mat-icon class="icon icon--small">question_mark</mat-icon>
              </div>
              <div (click)="saveProperty('electricity', Ternary.TRUE)"
                [class.active]="propertyValue('electricity')! == Ternary.TRUE" class="btn-round-icon check-true">
                <mat-icon class="icon icon--small">check</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="content-container">
          <div class="choice-card">
            <mat-icon svgIcon="water" class="icon icon--large"></mat-icon>
            <p class="font-l">{{ 'PROJECT_UTILITIES.WATER' | translate }}</p>
            <div class="check-buttons">
              <div (click)="saveProperty('water', Ternary.FALSE)"
                [class.active]="propertyValue('water')! == Ternary.FALSE" class="btn-round-icon check-false">
                <mat-icon class="icon icon--small">close</mat-icon>
              </div>
              <div (click)="saveProperty('water', Ternary.MAYBE)"
                [class.active]="!propertyValue('water') || propertyValue('water')! == Ternary.MAYBE"
                class="btn-round-icon check-question">
                <mat-icon class="icon icon--small">question_mark</mat-icon>
              </div>
              <div (click)="saveProperty('water', Ternary.TRUE)"
                [class.active]="propertyValue('water')! == Ternary.TRUE" class="btn-round-icon check-true">
                <mat-icon class="icon icon--small">check</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="content-container">
          <div class="choice-card">
            <mat-icon svgIcon="gas" class="icon icon--large"></mat-icon>
            <p class="font-l">{{ 'PROJECT_UTILITIES.GAS' | translate }}</p>
            <div class="check-buttons">
              <div (click)="saveProperty('gas', Ternary.FALSE)" [class.active]="propertyValue('gas')! == Ternary.FALSE"
                class="btn-round-icon check-false">
                <mat-icon class="icon icon--small">close</mat-icon>
              </div>
              <div (click)="saveProperty('gas', Ternary.MAYBE)"
                [class.active]="!propertyValue('gas') || propertyValue('gas')! == Ternary.MAYBE" class="btn-round-icon check-question">
                <mat-icon class="icon icon--small">question_mark</mat-icon>
              </div>
              <div (click)="saveProperty('gas', Ternary.TRUE)" [class.active]="propertyValue('gas')! == Ternary.TRUE"
                class="btn-round-icon check-true">
                <mat-icon class="icon icon--small">check</mat-icon>
              </div>
            </div>
          </div>
        </div>

      </div>

      <hr>
      <h2 class="h1 margin-b-30">{{ 'PROJECT_UTILITIES.CONDITIONAL_UTILITIES.TITLE' | translate }}</h2>

      <p class="margin-b dark-grey tooltip-trigger">
        {{ 'PROJECT_UTILITIES.CONDITIONAL_UTILITIES' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PROJECT_UTILITIES.CONDITIONAL_UTILITIES.TITLE','PROJECT_UTILITIES.CONDITIONAL_UTILITIES.INFO')"></span>
      </p>

      <div class="question-container">
        <div class="question-wrapper content-container">
          <div class="choice-card">
            <p class="font-l">{{ 'PROJECT_UTILITIES.METER' | translate }}</p>
            <div class="check-buttons">
              <div (click)="saveProperty('digitalenergymeter', Ternary.FALSE)"
                [class.active]="propertyValue('digitalenergymeter')! == Ternary.FALSE"
                class="btn-round-icon check-false">
                <mat-icon class="icon icon--small">close</mat-icon>
              </div>
              <div (click)="saveProperty('digitalenergymeter', Ternary.MAYBE)"
                [class.active]="!propertyValue('digitalenergymeter') || propertyValue('digitalenergymeter')! == Ternary.MAYBE" class="btn-round-icon check-question">
                <mat-icon class="icon icon--small">question_mark</mat-icon>
              </div>
              <div (click)="saveProperty('digitalenergymeter', Ternary.TRUE)"
                [class.active]="propertyValue('digitalenergymeter')! == Ternary.TRUE" class="btn-round-icon check-true">
                <mat-icon class="icon icon--small">check</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="question-wrapper content-container">
          <div class="choice-card">
            <p class="font-l">{{ 'PROJECT_UTILITIES.SOLARPANELS' | translate }}</p>
            <div class="check-buttons">
              <div (click)="saveProperty('solarpanels', Ternary.FALSE)"
                [class.active]="propertyValue('solarpanels')! == Ternary.FALSE" class="btn-round-icon check-false">
                <mat-icon class="icon icon--small">close</mat-icon>
              </div>
              <div (click)="saveProperty('solarpanels', Ternary.MAYBE)"
                [class.active]="!propertyValue('solarpanels') || propertyValue('solarpanels')! == Ternary.MAYBE" class="btn-round-icon check-question">
                <mat-icon class="icon icon--small">question_mark</mat-icon>
              </div>
              <div (click)="saveProperty('solarpanels', Ternary.TRUE)"
                [class.active]="propertyValue('solarpanels')! == Ternary.TRUE" class="btn-round-icon check-true">
                <mat-icon class="icon icon--small">check</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr>      
      <h2 class="h1 margin-b-30">{{ 'PROJECT_UTILITIES.EXTRA_INFO.TITLE' | translate }}</h2>

      <p class="margin-b dark-grey">{{ 'PROJECT_UTILITIES.EXTRA_INFO' | translate }}</p>

      <mat-form-field>
        <mat-label>{{ 'PROJECT_UTILITIES.EXTRA_INFO.LABEL' | translate }}</mat-label>
        <textarea matInput maxlength="500" 
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize" [formControl]="extraInfo" [placeholder]="'PROJECT_UTILITIES.EXTRA_INFO.PLACEHOLDER' | translate" (focusout)="saveProperty('extrainformation', extraInfo.value)"></textarea>
      </mat-form-field>

    </div>
  </div>
</app-project-page-template>