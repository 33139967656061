<app-project-page-template 
  [breadcrumb]="'PROJECT_RESULTS.BREADCRUMB' | translate" 
  [breadcrumbPrev]="'PROJECT_RESULTS.INSTALLTYPE.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../../' + APP_ROUTES['RESULTS_CALCULATION'].value" 
  [breadcrumbNext]="(projectData && propertyValue() || calculation && calculation.results?.length == 0) ? ('PROJECT_RESULTS.INSTALLTYPE.BREADCRUMB_NEXT' | translate) : ''"
  [routerlinkNext]="projectData && propertyValue() ? '../../' + APP_ROUTES['RESULTS_PRODUCTS'].value : (calculation && calculation.results?.length == 0 ? '../../' : '')"
  [sideBarContent]="sideBarContent">

  <div id="content" *ngIf="projectData">

    <h1>{{ 'PROJECT_RESULTS.INSTALLTYPE.TITLE' | translate }}</h1>
    
    <div class="margin-b-50">
      <p *ngIf="calculation && calculation.results && calculation.results.length > 0" class="dark-grey tooltip-trigger">{{ 'PROJECT_RESULTS.INSTALLTYPE.SUBTITLE' | translate }}
      <span class="tooltip-btn" (click)="openTooltip('PROJECT_RESULTS.INSTALLTYPE.TITLE','PROJECT_RESULTS.INSTALLTYPE.SUBTITLE.INFO')"></span>
      </p>
      <p *ngIf="calculation && calculation.results?.length == 0" class="warning">{{ 'PROJECT_RESULTS.INSTALLTYPE.UNAVAILABLE' | translate }}</p>
    </div>

    <div class="systems-container" *ngIf="installTypes">
      
      <ng-container *ngFor="let installType of installTypes">
        <div class="flex flex-center margin-b">

          <!-- radio button-->
          <div (click)="saveProperty(installType.xid)" class="btn-icon" *ngIf="installTypeAvailable(installType.xid)">
            <mat-icon *ngIf="propertyValue() && propertyValue() == installType.xid"  [svgIcon]="'radio'" class="icon"></mat-icon>
            <mat-icon *ngIf="!propertyValue() || propertyValue() != installType.xid" [svgIcon]="'radio_unchecked'" class="icon"></mat-icon>
          </div>

          <!-- spacer for disabled installtypes -->
          <div class="btn-icon margin-l-30" *ngIf="!installTypeAvailable(installType.xid)"></div>

          <!-- System -->
          <div class="system-wrapper content-container">
          
            <!-- Selected flag -->
            <button *ngIf="propertyValue() && propertyValue() == installType.xid" class="btn-selected btn btn-primary btn-small default">
              {{ 'PROJECT_RESULTS.INSTALLTYPE.SELECTED' | translate}}
            </button>

            <!-- Installtype info -->
            <div [class.disabled]="!installTypeAvailable(installType.xid)" class="system-picture">
              <img [src]="installType.image!">
            </div>

            <div class="system-information content-wrapper">
              <div class="flex space-between margin-b-30">
                <h2 [class.disabled]="!installTypeAvailable(installType.xid)">{{ installType.title ? (installType.title | capitalize) : '' }}</h2>
                <p *ngIf="!installTypeAvailable(installType.xid)" class="font-s warning flex flex-center margin-l no-wrap"><mat-icon svgIcon="error" class="icon icon--small"></mat-icon>&nbsp;{{ 'PROJECT_RESULTS.INSTALLTYPE.INCOMPATIBLE' | translate}} </p>
              </div>
              <div class="cms" [class.disabled]="!installTypeAvailable(installType.xid)" [class.margin-b-20]="showPrices && installTypeAvailable(installType.xid)" [innerHTML]="installType.description"></div>

              <!-- Price -->
              <div class="project-price flex flex-end baseline" *ngIf="showPrices && installTypeAvailable(installType.xid)">
                <p>{{ 'PROJECT_RESULTS.INSTALLTYPE.PRICE' | translate}}</p>
                <p><strong>{{ (installTypePrice[installType.xid] | number : '1.2-2')?.replace(',','')?.replace('.',',') }} €</strong></p>
              </div>

            </div>

          </div>
        </div>
      </ng-container>

    </div>

    <ng-container *ngIf="!hideCalculationLog && this.calculation?.transcript">
      <hr>
      <div class="content-wrapper margin-b"> 
        <div class="flex space-between">
          <h2 class="no-margin" (click)="toggleSelected['log'] = !toggleSelected['log']">
            <mat-icon svgIcon="clipboard" class="icon icon--medium"></mat-icon> {{ 'CALCULATION_LOG' | translate }}
          </h2>

          <div class="action-container flex space-between">
            <button (click)="copyLog(this.calculation!.transcript!)" class="btn-round-icon action-copy">
              <mat-icon svgIcon="copy" class="icon"></mat-icon>
            </button>
            <button (click)="toggleSelected['log'] = false" *ngIf="toggleSelected['log']" class="btn-expand btn-transparent btn-icon">
              <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
            </button>
            <button (click)="toggleSelected['log'] = true" *ngIf="!toggleSelected['log']" class="btn-expand btn-transparent btn-icon">
              <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
            </button>
          </div>
        </div>
        <ng-container *ngIf="toggleSelected['log']">
          <hr>
          <div id="calculations">
            <!-- <ngx-json-viewer [json]="someObject" [depth]="3"></ngx-json-viewer> -->
            <pre [innerHTML]="this.calculation!.transcript"></pre>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</app-project-page-template>