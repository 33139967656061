import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/components/base.component';
import { Room, RoomInstance } from 'src/app/models/Room';
import { Variable } from 'src/app/models/Variable';
import { clone } from 'src/app/util/Util';

export interface DialogData {
  'room': RoomInstance;
  'entries': string[];
}

@Component({
  selector: 'app-variable-modal',
  templateUrl: './variable-modal.component.html',
  styleUrls: ['./variable-modal.component.scss']
})
export class VariableModalComponent extends BaseComponent implements OnInit {
  
  public variables: Variable[] = [];

  constructor(
    public dialogRef: MatDialogRef<VariableModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData

  ) {
    super();
   }

  ngOnInit(): void {
    if(this.data.room?.variables) {
      this.variables = clone(this.data.room.variables);
    }
  }

  setVariableValue(selectedVar: Variable, value: number | null) {
    this.variables?.forEach(variable => {
      if(variable.xid == selectedVar.xid) {
        variable.values![this.data.room.typeXid] = value;
      }
    });

    // If value is 1, add variable to addedVariables array, otherwise remove it
    if(value && value == 1) {
      this.data.entries.push(selectedVar.xid + ':' + this.data.room.id + ':' + 1);
    } else {
      this.data.entries = this.data.entries.filter(entry => entry.includes(selectedVar.xid) == false);
    }
  }

  // Pass updated variables back to parent component
  saveVariables() {
    this.data.room.variables = this.variables;
    this.dialogRef.close(this.data)
  }
}
