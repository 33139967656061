import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { COLORS } from 'src/app/constants/Colors';
import { Subject, Ternary, Usecase } from 'src/app/models/Subject';
import { APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { clone } from 'src/app/util/Util';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent extends BaseComponent implements OnInit {

  public COLORS = COLORS;
  public selectedUsecases: Usecase[] = [];

  public projectId!: string;

  @Input() overviewpage: boolean = true;
  @Input() functionalitiesPage: boolean = false;

  @Input() breadcrumbBack?: string;
  @Input() routerlinkBack?: string;

  @Input() breadcrumbPrev!: string;
  @Input() breadcrumbNext!: string;
  @Input() routerlinkPrev!: string;
  @Input() routerlinkNext!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private subjectService: SubjectService,
  ) { super(); }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.params['project_id'];

    if(this.functionalitiesPage) { this.countSelectedFunctionalities() }
  }

  /**
   * Count selected usecases from cached subjects on functionalities pages
   */
  public countSelectedFunctionalities() {
      this.addSubscription(this.subjectService.cachedSubjects.subscribe(r => {
        let selectedUseCaseArray = clone(r) as Subject[];
        this.selectedUsecases = selectedUseCaseArray.flatMap(x => x.useCases!.filter(y => (y.selected === Ternary.TRUE || y.selected === Ternary.MAYBE)));
      }));
  }
}


