import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { Finishing, FinishingRange, finishings, LOCATION } from 'src/app/models/Finishing';
import { Ternary } from 'src/app/models/Subject';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinishingService {

  public finishings!: Finishing[];
  public generalFinishing?: Finishing;
  public outdoorFinishing?: Finishing;
  public secondaryFinishings?: Finishing[];

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get finishing list
   * @returns Observable list of finishings
   */
  public getFinishings() {
    if(environment.mockData) {
      return of(finishings);
    } else {
      return this.nikoApi.getFinishings().pipe(
        map((finishingList) => {
          //this.cachedInteriorTypes.next(InteriorTypeList.InteriorTypeList);
          //console.log(finishingList);
          return finishingList;
        })
      );
    }
  }

  public selectFinishings(projectId: string, finishingString: string) {
    this.nikoApi.selectFinishings(projectId, finishingString).subscribe(r => {
      return r;
    });
  }

  /**
   * Translate finishing project data and finishing product data into arrays of finishings, indoor- and outdoor finishing ranges,
   * general, outdoor and secondary finishings
   * @param finishingData finishing project data
   * @param finishings product finishing ranges
   */
  public getFinishingData(finishingData: Finishing[], finishings: FinishingRange[]) {
      
      // INDOOR
      let finishingRanges = finishings.filter(finishing => !finishing.location.includes(LOCATION.OUTDOOR));

      finishingRanges.forEach(range => {
        range.finishings?.forEach(finishing => {
          finishing.title = range.title + ' - ' + finishing.title;

          if (finishingData && finishingData.length > 0) {
            finishing.selected = finishingData?.find((data: Finishing) => data.xid === finishing.xid)?.selected || Ternary.FALSE;
          } else {
            finishing.selected = Ternary.FALSE;
          }
        })
      });

      this.finishings = finishingRanges.flatMap(x => x.finishings!.filter(y => (y.selected === Ternary.TRUE || y.selected === Ternary.SELECTED)));
      this.generalFinishing = this.finishings.find(finishing => finishing.selected == Ternary.SELECTED);
      this.secondaryFinishings = this.finishings.filter(finishing => finishing.selected == Ternary.TRUE);

      // OUTDOOR
      let outdoorRanges = finishings.filter(finishing => finishing.location.includes(LOCATION.OUTDOOR))

      outdoorRanges.forEach(range => {
        range.finishings?.forEach(finishing => {
          finishing.title = range.title + ' - ' + finishing.title;

          if (finishingData && finishingData.length > 0) {
            finishing.selected = finishingData?.find((data: Finishing) => data.xid === finishing.xid)?.selected || Ternary.FALSE;
          } else {
            finishing.selected = Ternary.FALSE;
          }
        })
      });

      this.outdoorFinishing = outdoorRanges.flatMap(x => x.finishings!.filter(y => y.selected === Ternary.SELECTED))[0];

  }
}