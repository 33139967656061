import { Routes } from '@angular/router';
import { APP_ROUTES } from './AppRoutes';

// PAGES
import { ExamplePageComponent } from 'src/app/pages/example-page/example-page.component';
import { ProjectListComponent } from 'src/app/pages/project-list/project-list.component';
import { PreferencesComponent } from 'src/app/pages/preferences/preferences.component';
import { ProjectDetailComponent } from 'src/app/pages/project-detail/project-detail.component';
import { SpacesComponent } from 'src/app/pages/project-intake/spaces/spaces.component';
import { UtilitiesComponent } from 'src/app/pages/project-intake/utilities/utilities.component';
import { InspirationComponent } from 'src/app/pages/project-requirements/inspiration/inspiration.component';
import { TopicComponent } from 'src/app/pages/project-requirements/topic/topic.component';
import { TopicDetailComponent } from 'src/app/pages/project-requirements/topic-detail/topic-detail.component';
import { UsecaseDetailComponent } from 'src/app/pages/project-requirements/usecase-detail/usecase-detail.component';
import { SpaceComponent } from 'src/app/pages/project-spaces/space/space.component';
import { OverviewSpaceComponent } from 'src/app/pages/project-overview/space/space.component';
import { EmailComponent } from 'src/app/pages/project-overview/email/email.component';
import { FunctionalitiesComponent } from 'src/app/pages/project-requirements/functionalities/functionalities.component';
import { SystemsComponent } from 'src/app/pages/project-summary/systems/systems.component';
import { ProductsComponent } from 'src/app/pages/project-summary/products/products.component';
import { FinishingComponent } from 'src/app/pages/project-finishing/finishing/finishing.component';
import { DocumentsComponent } from 'src/app/pages/documents/documents.component';
import { CatalogComponent } from 'src/app/pages/project-finishing/catalog/catalog.component';
import { RecommendedComponent } from 'src/app/pages/project-finishing/recommended/recommended.component';
import { SelectedComponent } from 'src/app/pages/project-finishing/selected/selected.component';
import { ChosenComponent } from 'src/app/pages/project-finishing/chosen/chosen.component';
import { BuildingTypeComponent } from 'src/app/pages/project-intake/building-type/building-type.component';
import { IntakeSummaryComponent } from 'src/app/pages/project-intake/intake-summary/intake-summary.component';
import { OutsideComponent } from 'src/app/pages/project-finishing/outside/outside.component';
import { LoginComponent } from 'src/app/components/login/login.component';

// SERVICES
import { SubjectResolverService } from 'src/app/services/subject/subject-resolver.service';
import { ArchiveGuard } from 'src/app/guards/archive/archive.guard';
import { ProjectModule } from 'src/app/models/Project';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { OnboardingComponent } from 'src/app/components/onboarding/onboarding.component';
import { ContactComponent } from 'src/app/pages/preferences/contact/contact.component';
import { DetailsClientComponent } from 'src/app/pages/project-intake/details-client/details-client.component';
import { CalculationComponent } from 'src/app/pages/project-summary/calculation/calculation.component';
import { ResultsSummaryComponent } from 'src/app/pages/project-summary/results-summary/results-summary.component';

// GUARDS

export const appRouterConfig: Routes = [
    {
        path: '',
        redirectTo: APP_ROUTES['PROJECTLIST'].value,
        pathMatch: 'full',
        title: 'Niko project wizard - Project list'
    },
    {
        path: APP_ROUTES['HOME'].value,
        component: ExamplePageComponent,
        title: 'Niko project wizard - Home'
    },
    {
        path: APP_ROUTES['PREFERENCES'].value,
        component: PreferencesComponent,
        title: 'Niko project wizard - Preferences'
    },
    {
        path: APP_ROUTES['CONTACT'].value,
        component: ContactComponent,
        title: 'Niko project wizard - Contact'
    },


    {
        path: APP_ROUTES['LOGIN'].value,
        component: LoginComponent,
        title: 'Niko project wizard - Login'
    },


    {
        path: APP_ROUTES['ONBOARDING'].value,
        component: OnboardingComponent,
        title: 'Niko project wizard - Onboarding'
    },


    {
        path: APP_ROUTES['ERROR'].value,
        component: ErrorComponent,
        title: 'Niko project wizard - Error'
    },


    {
        path: APP_ROUTES['PROJECTLIST'].value,
        component: ProjectListComponent,
        title: 'Niko project wizard - Project list'
    },
    {
        path: APP_ROUTES['PROJECT'].value,
        children: [
            {
                path: '',
                component: ProjectDetailComponent,
                title: 'Niko project wizard - Project'
            },


            {
                path: APP_ROUTES['DOCUMENTS'].value,
                component: DocumentsComponent,
                title: 'Niko project wizard - Documents'
            },

            
            
            {
                path: APP_ROUTES['INTAKE_CLIENT_DETAILS'].value,
                component: DetailsClientComponent,
                title: 'Niko project wizard - Intake - Client details',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.INTAKE
                }
            },
            {
                path: APP_ROUTES['INTAKE_ROOMS'].value,
                component: SpacesComponent,
                title: 'Niko project wizard - Intake - Rooms',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.INTAKE
                }
            },
            {
                path: APP_ROUTES['INTAKE_UTILITIES'].value,
                component: UtilitiesComponent,
                title: 'Niko project wizard - Intake - Utilities',
                canActivate: [ArchiveGuard],
                resolve: {subjects: SubjectResolverService},
                data: {
                    module: ProjectModule.INTAKE
                }
            },
            {
                path: APP_ROUTES['INTAKE_BUILDINGTYPE'].value,
                component: BuildingTypeComponent,
                title: 'Niko project wizard - Intake - Building type',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.INTAKE
                }
            },
            {
                path: APP_ROUTES['INTAKE_SUMMARY'].value,
                component: IntakeSummaryComponent,
                title: 'Niko project wizard - Intake - Summary'
            },
        
        
            {
                path: APP_ROUTES['FUNCTIONALITIES_SUBJECTS'].value,
                component: InspirationComponent,
                title: 'Niko project wizard - Functionalities - Subjects',
                resolve: {subjects: SubjectResolverService},
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.FUNCTIONALITIES
                } 
            },
            {
                path: APP_ROUTES['FUNCTIONALITIES_USECASES'].value,
                component: TopicComponent,
                title: 'Niko project wizard - Functionalities - Usecases',
                data: { 
                    reuseRoute: true, 
                    module: ProjectModule.FUNCTIONALITIES
                },
                resolve: {subjects: SubjectResolverService},
                canActivate: [ArchiveGuard]
            },
            {
                path: APP_ROUTES['FUNCTIONALITIES_SUMMARY'].value,
                component: FunctionalitiesComponent,
                title: 'Niko project wizard - Functionalities - Summary',
                resolve: {subjects: SubjectResolverService}
            },
        
        
            {
                path: APP_ROUTES['ROOMCONFIGURATION_VARIABLES'].value,
                component: SpaceComponent,
                title: 'Niko project wizard - Room Configuration',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.ROOMCONFIGURATION
                }
            },
        
            
            {
                path: APP_ROUTES['FINISHING_STYLES'].value,
                component: FinishingComponent,
                title: 'Niko project wizard - Finishings - Interior styles',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.FINISHING
                }
            },
            {
                path: APP_ROUTES['FINISHING_CATALOG'].value,
                component: CatalogComponent,
                title: 'Niko project wizard - Finishings - Catalog',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.FINISHING
                }
            },
            {
                path: APP_ROUTES['FINISHING_RECOMMENDED'].value,
                component: RecommendedComponent,
                title: 'Niko project wizard - Finishings - Recommended',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.FINISHING
                }
            },
            {
                path: APP_ROUTES['FINISHING_GENERAL_INDOOR'].value,
                component: SelectedComponent,
                title: 'Niko project wizard - Finishings - Indoor',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.FINISHING
                }
            },
            {
                path: APP_ROUTES['FINISHING_GENERAL_OUTDOOR'].value,
                component: OutsideComponent,
                title: 'Niko project wizard - Finishings - Outdoor',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.FINISHING
                }
            },
            {
                path: APP_ROUTES['FINISHING_SUMMARY'].value,
                component: ChosenComponent,
                title: 'Niko project wizard - Finishings - Summary'
            },
        
            {
                path: APP_ROUTES['RESULTS_CALCULATION'].value,
                component: CalculationComponent,
                title: 'Niko project wizard - Results - Calculating',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.RESULTS
                }
            },
            {
                path: APP_ROUTES['RESULTS_INSTALLTYPES'].value,
                component: SystemsComponent,
                title: 'Niko project wizard - Results - Systems',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.RESULTS
                }
            },
            {
                path: APP_ROUTES['RESULTS_PRODUCTS'].value,
                component: ProductsComponent,
                title: 'Niko project wizard - Results - Products',
                canActivate: [ArchiveGuard],
                data: {
                    module: ProjectModule.RESULTS
                }
            },
            {
                path: APP_ROUTES['RESULTS_SUMMARY'].value,
                component: ResultsSummaryComponent,
                title: 'Niko project wizard - Results - Summary'
            }
        ]
    },


    {
        path: '**',
        redirectTo: '/' + APP_ROUTES['PROJECTLIST'].value
    }
];