<app-project-page-template [breadcrumb]="'PROJECT_RESULTS.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_RESULTS.CALCULATION_FAILED.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../../' + APP_ROUTES['RESULTS_CALCULATION'].value"
  [breadcrumbNext]="'PROJECT_RESULTS.CALCULATION_FAILED.BREADCRUMB_NEXT' | translate" [routerlinkNext]="'../../'"
  [sideBarContent]="sideBarContent">

  <div id="content" >

    <h1>{{ 'PROJECT_RESULTS.CALCULATION_FAILED.TITLE' | translate }}</h1>
    
    <div class="margin-b-50">
      <p *ngIf="calculation && calculation.status == 'failure'" class="warning">{{ 'PROJECT_RESULTS.CALCULATION_FAILED.SUBTITLE' | translate }}</p>
      <p *ngIf="calculation && calculation.status == 'error'" class="warning">{{ 'PROJECT_RESULTS.CALCULATION_FAILED_TECHNICAL.SUBTITLE' | translate }}</p>
      <p *ngIf="!calculation || !calculation.status || (calculation.status != 'failure' && calculation.status != 'error')" class="warning">{{ 'PROJECT_RESULTS.CALCULATION_FAILED_TIMEOUT.SUBTITLE' | translate }}</p>
    </div>

    <ng-container *ngIf="!hideCalculationLog && this.calculation?.transcript">
      <div class="content-wrapper margin-b"> 
        <div class="flex space-between">
          <h2 class="no-margin" (click)="toggleSelected['log'] = !toggleSelected['log']">
            <mat-icon svgIcon="clipboard" class="icon icon--medium"></mat-icon> {{ 'CALCULATION_LOG' | translate }}
          </h2>

          <div class="action-container flex space-between">
            <button (click)="copyLog(this.calculation!.transcript!)" class="btn-round-icon action-copy">
              <mat-icon svgIcon="copy" class="icon"></mat-icon>
            </button>
            <button (click)="toggleSelected['log'] = false" *ngIf="toggleSelected['log']" class="btn-expand btn-transparent btn-icon">
              <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
            </button>
            <button (click)="toggleSelected['log'] = true" *ngIf="!toggleSelected['log']" class="btn-expand btn-transparent btn-icon">
              <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
            </button>
          </div>
        </div>
        <ng-container *ngIf="toggleSelected['log']">
          <hr>
          <div id="calculations">
            <!-- <ngx-json-viewer [json]="someObject" [depth]="3"></ngx-json-viewer> -->
            <pre [innerHTML]="this.calculation!.transcript"></pre>
          </div>
        </ng-container>
      </div>
    </ng-container>

  </div>
</app-project-page-template>