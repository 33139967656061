  <div id="onboarding-modal" gaCategory="Onboarding Modal - {{ this.data.step }}">

    <!-- Background is optional. If tthe background url is empty, the modal is shown as text-only. -->
    <div *ngIf="this.data.background" class="background" [style.background-image]="'url(/assets/pictures/onboarding/onboarding_' + this.data.step.toLowerCase() + '.jpg)'"></div>
    
    <div>
      
      <!-- Modal close button -->
      <button gaEvent="button_click" gaLabel="modal_close" (click)="closeDialog()" class="btn-close">
        <mat-icon class="icon icon--small" [class.white]="
        this.data.step.toLowerCase() == ProjectModule.INTAKE.toLowerCase() ||
        this.data.step.toLowerCase() == ProjectModule.FINISHING.toLowerCase() ||
        this.data.step.toLowerCase() == ProjectModule.ROOMCONFIGURATION.toLowerCase()
        ">close</mat-icon>
      </button>
  
      <div class="onboarding-content margin-b-50" [class.content-with-background]="this.data.background">
        
        <!-- Modal content -->
        <h1 class="title">{{ "ONBOARDING." + this.data.step.toUpperCase() + ".TITLE" | translate }}</h1>
        <p class="margin-b">{{ "ONBOARDING." + this.data.step.toUpperCase() + ".INTRO" | translate }}</p>
        <ul class="margin-b-60">
          <li *ngFor="let listItem of onboardingListItems" >{{ listItem['ITEM'] | translate }}</li>
        </ul>

        <!-- Hide onboarding for this step in user settings and session -->
        <div>
          <label class="checkbox">{{'ONBOARDING.WELCOME.HIDE' | translate}}
            <input autocomplete="new-password" #hideOnboarding [checked]="onboardingStatus == 'hidden'" type="checkbox" (click)="hideOnboarding.checked ? hideOnboardingOnProfile(true) : hideOnboardingOnProfile(false)">
            <span class="checkmark"></span>
          </label>
        </div>

      </div>
    </div>

    <!-- Modal proceed button -->
    <div class="button-wrapper">
      <button gaEvent="button_click" gaLabel="confirm" (click)="closeDialog()" class="btn btn-secondary btn-small">{{ "ONBOARDING." + this.data.step.toUpperCase() + ".CONFIRM" | translate }}</button>
    </div>

  </div>