<app-project-page-template [breadcrumb]="'PROJECT_INTAKE.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_SUMMARY.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../../' + APP_ROUTES['INTAKE_ROOMS'].value"
  [breadcrumbNext]="'PROJECT_SUMMARY.BREADCRUMB_NEXT' | translate" 
  [routerlinkNext]="'../../'"
  [sideBarContent]="sideBarContent">
  <div id="content">
    <ng-container *ngIf="projectData">

      <div class="relative">
        <div class="title-wrapper">
          <h1 class="no-margin">{{ 'PROJECT_SUMMARY.TITLE' | translate }}</h1>
        </div>
        <p class="margin-b dark-grey">{{ 'PROJECT_SUMMARY.SUBTITLE' | translate }}</p>
      </div>


      <!-- INTAKE DETAILS-->
      <div class="content-wrapper margin-b">
        <ng-container> 

          <div class="flex space-between">
            <h2 class="no-margin">{{ 'PROJECT_SUMMARY.DETAILS' | translate }}</h2>

            <div class="action-container flex space-between">
              <button (click)="toggleSelected['details'] = !toggleSelected['details']" *ngIf="!toggleSelected['details']" class="btn-expand btn-transparent btn-icon">
                <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
              </button>
              <button (click)="toggleSelected['details'] = !toggleSelected['details']" *ngIf="toggleSelected['details']" class="btn-expand btn-transparent btn-icon">
                <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
              </button>
            </div>
          </div>

          <div *ngIf="!toggleSelected['details']" class="summary-container details-container">
            <div>
              <h3>{{ 'PROJECT_DETAIL.CLIENT' | translate }}</h3>
              
              <table>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.LANG' | translate}}</td>
                  <td class="text-right">{{'PREFERENCES.LANGUAGES.' + propertyValue('language') | uppercase | translate }}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.GREETING' | translate}}</td>
                  <td class="text-right">{{propertyValue('greeting')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.FNAME' | translate}}</td>
                  <td class="text-right">{{propertyValue('firstname')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.LNAME' | translate}}</td>
                  <td class="text-right">{{propertyValue('lastname')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.PHONE' | translate}}</td>
                  <td class="text-right">{{propertyValue('phone')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.EMAIL' | translate}}</td>
                  <td class="text-right">{{propertyValue('email')}}</td>
                </tr>
              </table>
              <hr>
            </div>
            <div>
              <h3>{{ 'PROJECT_DETAIL.CONTACT' | translate }}</h3>

              <table>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.ADDRESS' | translate}}</td>
                  <td class="text-right">{{propertyValue('contact_address')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.ADDRESS2' | translate}}</td>
                  <td class="text-right">{{propertyValue('contact_address2')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.CITY' | translate}}</td>
                  <td class="text-right" *ngIf="propertyValue('contact_zip')">{{propertyValue('contact_zip') + ' - ' + propertyValue('contact_city')}}</td>
                </tr>              
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.COUNTRY' | translate}}</td>
                  <td class="text-right">{{ 'PREFERENCES.COUNTRIES.' + propertyValue('contact_country') | uppercase | translate }}</td>
                </tr>
              </table>
              <hr>
            </div>
            <div>
              <h3>{{ 'PROJECT_DETAIL.TITLE' | translate}}</h3>

              <table>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.NAME' | translate}}</td>
                  <td class="text-right">{{propertyValue('name')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.DATE' | translate}}</td>
                  <td class="text-right">{{propertyValue('startdate') | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="buildingType">
                  <td class="grey6-text">{{'PROJECT_BUILDINGTYPE.TITLE' | translate}}</td>
                  <td class="text-right">{{ buildingType | translate | lowercase }}</td>
                </tr>
                <tr *ngIf="constructionType">
                  <td class="grey6-text">{{'PROJECT_CONSTRTYPE.TITLE' | translate}}</td>
                  <td class="text-right">{{ constructionType | translate | lowercase }}</td>
                </tr>
              </table>
              <hr>
            </div>
            <div>
              <h3>{{ 'PROJECT_DETAIL.INSTALLATION' | translate }}</h3>
              
              <table>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.ADDRESS' | translate}}</td>
                  <td class="text-right">{{propertyValue('address')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.ADDRESS2' | translate}}</td>
                  <td class="text-right">{{propertyValue('address2')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.CITY' | translate}}</td>
                  <td class="text-right" *ngIf="propertyValue('zip')">{{propertyValue('zip') + ' - ' + propertyValue('city')}}</td>
                </tr>
                <tr>
                  <td class="grey6-text">{{'PROJECT_DETAIL.COUNTRY' | translate}}</td>
                  <td class="text-right">{{ 'PREFERENCES.COUNTRIES.' + propertyValue('country') | uppercase | translate }}</td>
                </tr>
              </table>
            </div>
            <div *ngIf="propertyValue('extrainformation')">
              <hr>
              <h3>{{ 'PROJECT_SUMMARY.EXTRA_INFO' | translate }}</h3>
              <p class="preformatted" [innerHTML]="propertyValue('extrainformation')"></p>
            </div>
          </div>
        </ng-container>

      </div>

      <!-- END - INTAKE DETAILS-->

      <!-- INTAKE UTILITIES-->
      <div class="content-wrapper margin-b">
        <ng-container> 

          <div class="flex space-between">
            <h2 class="no-margin">{{ 'PROJECT_SUMMARY.UTILITIES' | translate }}</h2>

            <div class="action-container flex space-between">
              <button (click)="toggleSelected['utilities'] = !toggleSelected['utilities']" *ngIf="!toggleSelected['utilities']" class="btn-expand btn-transparent btn-icon">
                <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
              </button>
              <button (click)="toggleSelected['utilities'] = !toggleSelected['utilities']" *ngIf="toggleSelected['utilities']" class="btn-expand btn-transparent btn-icon">
                <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
              </button>
            </div>
          </div>

          <div *ngIf="!toggleSelected['utilities']" class="summary-container utilities-container">
            <div>
              <table>
                <tr>
                  <td>{{'PROJECT_UTILITIES.ELECTRICITY' | translate}}</td>
                  <td class="block-right">
                    <div 
                      *ngIf="propertyValue('electricity')! == Ternary.FALSE" class="btn-round-icon default check-false">
                      <mat-icon class="icon icon--small">close</mat-icon>
                    </div>
                    <div 
                      *ngIf="!propertyValue('electricity') || propertyValue('electricity')! == Ternary.MAYBE"
                      class="btn-round-icon default check-question warning">
                      <mat-icon class="icon icon--small">question_mark</mat-icon>
                    </div>
                    <div 
                      *ngIf="propertyValue('electricity')! == Ternary.TRUE" class="btn-round-icon default check-true">
                      <mat-icon class="icon icon--small">check</mat-icon>
                    </div></td>
                </tr>
                <tr>
                  <td>{{'PROJECT_UTILITIES.WATER' | translate}}</td>
                  <td class="block-right">
                    <div 
                      *ngIf="propertyValue('water')! == Ternary.FALSE" class="btn-round-icon default check-false">
                      <mat-icon class="icon icon--small">close</mat-icon>
                    </div>
                    <div 
                      *ngIf="!propertyValue('water') || propertyValue('water')! == Ternary.MAYBE"
                      class="btn-round-icon default check-question warning">
                      <mat-icon class="icon icon--small">question_mark</mat-icon>
                    </div>
                    <div 
                      *ngIf="propertyValue('water')! == Ternary.TRUE" class="btn-round-icon default check-true">
                      <mat-icon class="icon icon--small">check</mat-icon>
                    </div></td>
                </tr>
                <tr>
                  <td>{{'PROJECT_UTILITIES.GAS' | translate}}</td>
                  <td class="block-right">
                    <div 
                      *ngIf="propertyValue('gas')! == Ternary.FALSE" class="btn-round-icon default check-false">
                      <mat-icon class="icon icon--small">close</mat-icon>
                    </div>
                    <div 
                      *ngIf="!propertyValue('gas') || propertyValue('gas')! == Ternary.MAYBE"
                      class="btn-round-icon default check-question warning">
                      <mat-icon class="icon icon--small">question_mark</mat-icon>
                    </div>
                    <div 
                      *ngIf="propertyValue('gas')! == Ternary.TRUE" class="btn-round-icon default check-true">
                      <mat-icon class="icon icon--small">check</mat-icon>
                    </div></td>
                </tr>
                <tr></tr>
                <tr>
                  <td>{{'PROJECT_SUMMARY.METER' | translate}}</td>
                  <td class="block-right">
                    <div 
                      *ngIf="propertyValue('digitalenergymeter')! == Ternary.FALSE" class="btn-round-icon default check-false">
                      <mat-icon class="icon icon--small">close</mat-icon>
                    </div>
                    <div 
                      *ngIf="!propertyValue('digitalenergymeter') || propertyValue('digitalenergymeter')! == Ternary.MAYBE"
                      class="btn-round-icon default check-question warning">
                      <mat-icon class="icon icon--small">question_mark</mat-icon>
                    </div>
                    <div 
                      *ngIf="propertyValue('digitalenergymeter')! == Ternary.TRUE" class="btn-round-icon default check-true">
                      <mat-icon class="icon icon--small">check</mat-icon>
                    </div></td>
                </tr>
                <tr>
                  <td>{{'PROJECT_SUMMARY.SOLARPANELS' | translate}}</td>
                  <td class="block-right">
                    <div 
                      *ngIf="propertyValue('solarpanels')! == Ternary.FALSE" class="btn-round-icon default check-false">
                      <mat-icon class="icon icon--small">close</mat-icon>
                    </div>
                    <div 
                      *ngIf="!propertyValue('solarpanels') || propertyValue('solarpanels')! == Ternary.MAYBE"
                      class="btn-round-icon default check-question warning">
                      <mat-icon class="icon icon--small">question_mark</mat-icon>
                    </div>
                    <div 
                      *ngIf="propertyValue('solarpanels')! == Ternary.TRUE" class="btn-round-icon default check-true">
                      <mat-icon class="icon icon--small">check</mat-icon>
                    </div></td>
                </tr>

              </table>
            </div>
          </div>
        </ng-container>

      </div>

      <!-- END - INTAKE UTILITIES-->

      <!-- INTAKE ROOMS-->
      <div class="content-wrapper margin-b">
        <ng-container> 

          <div class="flex space-between">
            <h2 class="no-margin">{{ 'PROJECT_SUMMARY.ROOMS' | translate }}</h2>

            <div class="action-container flex space-between">
              <button (click)="toggleSelected['rooms'] = !toggleSelected['rooms']" *ngIf="!toggleSelected['rooms']" class="btn-expand btn-transparent btn-icon">
                <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
              </button>
              <button (click)="toggleSelected['rooms'] = !toggleSelected['rooms']" *ngIf="toggleSelected['rooms']" class="btn-expand btn-transparent btn-icon">
                <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
              </button>
            </div>
          </div>

          <div *ngIf="!toggleSelected['rooms']" class="summary-container room-container">
            <ng-container *ngIf="rooms && rooms.length > 0">
              <table>
                <tr>
                  <th></th>
                  <th>{{ 'PROJECT_SUMMARY.ROOM_TYPE' | translate}}</th>
                  <th>{{ 'PROJECT_SUMMARY.ROOM_AMOUNT' | translate}}</th>
                </tr>
          
                <ng-container *ngFor="let room of rooms">
                  <tr>
                    <td class="image-cell"><img [src]="room.imageFull" class="icon icon--medium"></td>
                    <td class="room-name">{{room.name}}</td>
                    <td class="room-count">{{room.count}}</td>
                  </tr>
                </ng-container>
              </table>
            </ng-container>
            <ng-container *ngIf="!rooms || rooms.length == 0" >
              <p>{{'PROJECT_SUMMARY.NO_ROOMS' | translate}}</p>
            </ng-container>
          </div>
        </ng-container>

      </div>

      <!-- END - INTAKE ROOMS-->




    </ng-container>
  </div>
</app-project-page-template>