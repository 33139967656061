import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { LocalStorageKeys } from '../local-storage/local-storage-keys';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { SessionStorageKeys } from '../session-storage/session-storage-keys';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: BehaviorSubject<User> = new BehaviorSubject<User>(new User);

  constructor(
    private nikoApi: NikoApiService,
    private session: SessionStorageService,
    private localStorage: LocalStorageService
  ) { }

  /**
   * Login by requesting the user profile with basic auth
   * @param user userdata used for login. If parameter is empty, login will be attempted with session user details
   * @returns userdata
   */
  public login(user?: User): Observable<any> {

    if(user && user.username && user.password) {
      let userData = btoa(user.username + ':' + user.password);
      this.session.saveItem(SessionStorageKeys.USER, userData);
    }

    return this.nikoApi.login();
  }

  /**
   * Logout by invalidating the user session 
   * and removing user specific session items
   */
   public logout() {
    this.session.remove(SessionStorageKeys.USER);
    this.localStorage.remove(LocalStorageKeys.ONBOARDING);
    
    return this.nikoApi.logout().subscribe(r => r);
  }
}
