import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-page-template',
  templateUrl: './overview-page-template.component.html',
  styleUrls: ['./overview-page-template.component.scss']
})
export class OverviewPageTemplateComponent implements OnInit {

  @Input() breadcrumbBack?: string;
  @Input() routerlinkBack?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
