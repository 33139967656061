import { Finishing, LOCATION } from "./Finishing";
import { Variable } from "./Variable";

export class Room {
  xid!: string;
  name!: string;
  imageFull!: string;
  count!: string;
  location!: LOCATION[];
}

export class RoomInstance {
  id!: string;
  typeXid!: string;
  name?: string;
  title!: string;;
  imageFull?: string;
  variables?: Variable[];
  location?: LOCATION[];
  indoorFinishing?: Finishing;
  finishingIndoor?: string;
  outdoorFinishing?: Finishing;
  finishingOutdoor?: string;
}

export const rooms: Room[] = 
  [
    {
      "xid": "53574",
      "name": "entrance hall",
      "imageFull": "https://test.mdm.niko.eu/DSA/rooms/image-thumb__575544__DSA-Icons/Icon_garage.png",
      "location": [ LOCATION.INDOOR ],
      "count": "1"
    },
    {
      "xid": "53550",
      "name": "living room with TV corner",
      "imageFull": "https://test.mdm.niko.eu/DSA/rooms/image-thumb__575544__DSA-Icons/Icon_garage.png",
      "location": [ LOCATION.INDOOR ],
      "count": "1"
    },
    {
      "xid": "53552",
      "name": "kitchen",
      "imageFull": "https://test.mdm.niko.eu/DSA/rooms/image-thumb__575544__DSA-Icons/Icon_garage.png",
      "location": [ LOCATION.INDOOR ],
      "count": "1"
    },
    {
      "xid": "53553",
      "name": "master bedroom",
      "imageFull": "https://test.mdm.niko.eu/DSA/rooms/image-thumb__575544__DSA-Icons/Icon_garage.png",
      "location": [ LOCATION.INDOOR ],
      "count": "1"
    },
    {
      "xid": "53554",
      "name": "children's bedroom",
      "imageFull": "https://test.mdm.niko.eu/DSA/rooms/image-thumb__575544__DSA-Icons/Icon_garage.png",
      "location": [ LOCATION.INDOOR ],
      "count": "2"
    },
    {
      "xid": "53555",
      "name": "bathroom",
      "imageFull": "https://test.mdm.niko.eu/DSA/rooms/image-thumb__575544__DSA-Icons/Icon_garage.png",
      "location": [ LOCATION.INDOOR ],
      "count": "1"
    },
    {
      "xid": "53564",
      "name": "toilet",
      "imageFull": "https://test.mdm.niko.eu/DSA/rooms/image-thumb__575544__DSA-Icons/Icon_garage.png",
      "location": [ LOCATION.INDOOR ],
      "count": "1"
    }
  ]
;