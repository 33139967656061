
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { Room, RoomInstance } from 'src/app/models/Room';
import { ProjectService } from 'src/app/services/project/project.service';
import { RoomService } from 'src/app/services/room/room.service';
import { clone, mergeArrays } from 'src/app/util/Util';


export interface DialogData {
  'projectId': string;
  'roomList': Room[];
  'rooms': RoomInstance[];
}

@Component({
  selector: 'app-addrooms-modal',
  templateUrl: './addrooms-modal.component.html',
  styleUrls: ['./addrooms-modal.component.scss']
})
export class AddroomsModalComponent extends BaseComponent implements OnInit {

  public space = '';

  public resultString = 'All results'

  public search = new UntypedFormControl(null);

  public projectId!: string;
  public roomList!: Room[];

  constructor(
    public roomService: RoomService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<AddroomsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      super();
      dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.data));
  }

  ngOnInit(): void {
    this.roomList = clone(this.data.roomList);
  }

  /**
   * Filter matching room types
   */
  findRoom() {
    if(this.search.value && this.search.value != '') {
      this.resultString = 'Results for \'' + this.search.value + '\'';

      this.roomList = clone(this.data.roomList).filter(room => {
        return room.name.toLowerCase().includes(this.search.value.toLowerCase());
      })
    } else {
      this.resultString = 'All results';

      this.roomList = clone(this.data.roomList);
    }
  }

  /**
   * Add room 
   */
  addRoom(room: Room, amount: number) {
    room.count = (+room.count + amount).toString();

    let roomString = 'typeCount' + '=' + room.xid + ':' + amount + '&';

    this.roomService.addRoom(this.data.projectId, roomString).subscribe(r => {
      this.data.rooms = this.data.rooms.concat(r);
    });  
  }
  
  /**
   * Remove the last matching room that counts against this dialog's room count
   */
  removeRoom(room: Room) {
    if(+room.count > 0 ) {
      room.count = (+room.count - 1).toString();
      
      let roomRemoveId = this.data.rooms.filter((roomInstance) => roomInstance.typeXid == room.xid ).pop()?.id;

      if(roomRemoveId && roomRemoveId != '') {
        this.data.rooms = this.data.rooms.filter((roomInstance) => roomInstance.id != roomRemoveId );

        this.roomService.removeRoom(this.data.projectId, roomRemoveId).subscribe(r => r);  
      }

    } else {
      room.count = '0'
    };
  }
}
