<app-project-page-template [breadcrumb]="'PROJECT_RESULTS.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_RESULTS.CALCULATION.BREADCRUMB_PREV' | translate" 
  [routerlinkPrev]="'../../'"
  [sideBarContent]="sideBarContent">

  <div id="content">
    <h1>{{ 'PROJECT_RESULTS.CALCULATION.TITLE' | translate }}</h1>
    <p class="margin-b dark-grey margin-b-100">{{ 'PROJECT_RESULTS.CALCULATION.SUBTITLE' | translate }}</p>

    <!-- progress bar -->
    <div class="flex-col flex-center margin-l margin-r">
      <mat-icon class="icon icon--xl margin-b-30" svgIcon="settings"></mat-icon>

      <p class="font-medium margin-b-30">{{ 'PROJECT_RESULTS.CALCULATION.CALCULATING' | translate }}</p>

      <div class="progress-bar margin-b">
        <div class="progress-bar-filled" [style]="'width:' + progressBar + '%'">
          <!-- {{progressBar}}% -->
        </div>
      </div>

      <div class="policy-wrapper">
        <p class="font-m greyed-out" [innerHTML]="'POLICIES.DISCLAIMER_TOOL' | translate">
        </p>
      </div>
    </div>
  </div>
</app-project-page-template>