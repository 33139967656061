<app-project-page-template [breadcrumb]="'PROJECT_INTAKE.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_SPACES.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../../' + APP_ROUTES['INTAKE_UTILITIES'].value"
  [breadcrumbNext]="'PROJECT_SPACES.BREADCRUMB_NEXT' | translate"
  [routerlinkNext]="'../../' + APP_ROUTES['INTAKE_SUMMARY'].value" [sideBarContent]="sideBarContent">
  <div id="content">
    <ng-container *ngIf="projectData">

      <!-- <h2 class="h1">{{ 'PROJECT_SPACES.FLOORS' | translate }}</h2>
        <p class="margin-b dark-grey">{{ 'PROJECT_SPACES.FLOORS_INTRO' | translate }}</p>

        <div class="floors-container">
          <div class="edit-wrapper">
            <div class="edit-info">
              <div class="amount">2</div>
              <p>{{ 'PROJECT_SPACES.FLOORS_AMOUNT' | translate }}</p>
            </div>
            <div class="edit-buttons">
              <div class="btn-round-icon edit-remove"><mat-icon class="icon icon--small">remove</mat-icon></div>
              <div class="btn-round-icon edit-add"><mat-icon class="icon icon--small">add</mat-icon></div>
            </div>
          </div>
        </div> -->

      <div class="relative">
        <div class="click-close" (click)="space = ''"></div>

        <div class="title-wrapper">
          <h1 class="no-margin">{{ 'PROJECT_SPACES.TITLE' | translate }}</h1>
          <button
            *ngIf="(buildingType && buildingType != '') && ( !projectData.status.accessibleSteps || !projectData.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION') )"
            class="btn-modal btn-round-icon grey"
            (click)="(!projectData.status.accessibleSteps || !projectData.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION')) ? openDialog() : false">
            <mat-icon class="icon">add</mat-icon>
          </button>
        </div>

        <!-- Rooms can be added, deleted or changed through this component during the intake -->
        <p *ngIf="!projectData.status.accessibleSteps || !projectData.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION')"
          class="margin-b dark-grey tooltip-trigger">
          {{ 'PROJECT_SPACES.SUBTITLE' | translate }}
          <span class="tooltip-btn" (click)="openTooltip('PROJECT_SPACES.TITLE','PROJECT_SPACES.SUBTITLE.INFO')"></span>
        </p>

        <!-- When the room configurator is unlocked, rooms can't be changed through this component anymore -->
        <p *ngIf="projectData.status.accessibleSteps && projectData.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION')"
          class="margin-b dark-grey warning">{{ "PROJECT_SPACES.WARNING" | translate }}</p>

      </div>

      <div class="space-container">
        <div class="click-close" (click)="space = ''"></div>

        <ng-container *ngIf="selectedRooms.length > 0">
          <ng-container *ngFor="let room of selectedRooms">

            <div class="space" [class.active]="space == room.xid">
              <div class="content-wrapper pointer" (click)="space = room.xid" [class.inactive]="+room.count == 0"
                [class.disabled]="projectData.status.accessibleSteps && projectData.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION')">
                <div *ngIf="+room.count > 0" class="count">{{ room.count }}</div>
                <div class="space-wrapper">
                  <img [src]="room.imageFull" class="icon icon--large">
                  <p class="font-l text-center">{{ room.name }}</p>
                </div>
              </div>

              <div class="edit-wrapper">
                <div class="edit-info">
                  <div class="amount">{{ room.count }}</div>
                  <p class="text-center">{{ room.name }}</p>
                </div>
                <div class="edit-buttons">
                  <div
                    (click)="(!projectData.status.accessibleSteps || !projectData.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION')) ? removeRoom(room) : false"
                    class="btn-round-icon edit-remove">
                    <mat-icon class="icon icon--small">remove</mat-icon>
                  </div>
                  <div
                    (click)="(!projectData.status.accessibleSteps || !projectData.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION')) ? addRoom(room, 1) : false"
                    class="btn-round-icon edit-add">
                    <mat-icon class="icon icon--small">add</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <p class="warning" *ngIf="selectedRooms.length == 0 && (!buildingType || buildingType == '')">{{'PROJECT_SPACES.NO_BUILDING_TYPE'
          | translate}} </p>
        <p *ngIf="selectedRooms.length == 0 && buildingType">{{'PROJECT_SPACES.NO_ROOMS' | translate}} </p>

      </div>
    </ng-container>
  </div>
</app-project-page-template>