<app-project-page-template 
[breadcrumb]="'PROJECT_FINISHINGS.SELECTED.BREADCRUMB' | translate" 
[breadcrumbPrev]="'PROJECT_FINISHINGS.SELECTED.BREADCRUMB_PREV' | translate" 
[routerlinkPrev]="'../../' + APP_ROUTES['FINISHING_RECOMMENDED'].value" 
[breadcrumbNext]="'PROJECT_FINISHINGS.SELECTED.BREADCRUMB_NEXT' | translate" 
[routerlinkNext]="'../../' + APP_ROUTES['FINISHING_GENERAL_OUTDOOR'].value"
[sideBarContent]="sideBarContent">
  
  <div id="content">
  
    <h1 class="tooltip-trigger">
      {{ 'PROJECT_FINISHINGS.SELECTED.TITLE' | translate }}
      <span class="tooltip-btn" (click)="openTooltip('PROJECT_FINISHINGS.SELECTED.TITLE','PROJECT_FINISHINGS.SELECTED.TITLE.INFO')"></span>
    </h1>
    <hr>
    <h3 class="margin-b dark-grey">{{ 'PROJECT_FINISHINGS.SELECTED.SUBTITLE' | translate }}</h3>

    <div class="finishing-container" *ngIf="finishings && finishings.length > 0">

      <ng-container *ngFor="let finishing of finishings" >
        <div class="content-container relative" *ngIf="finishing.selected != Ternary.FALSE">
          <button class="btn-selected btn btn-primary btn-small default" *ngIf="finishing.selected == Ternary.SELECTED">
            {{'PROJECT_FINISHINGS.SELECTED.LABEL_GENERAL' | translate}}
          </button>
  
          <div class="finishing-wrapper">
            <img class="finishing-img" 
            [src]="finishing.images ? finishing.images[0] : ''">
            <div class="text-wrapper">
              <p class="font-l margin-b-20">{{ finishing.title }}</p>
              <div class="check-button pointer">
                <div (click)="selectFinishing(finishing)" class="btn-icon">
                  <mat-icon *ngIf="finishing.selected == Ternary.SELECTED"  [svgIcon]="'radio'" class="icon"></mat-icon>
                  <mat-icon *ngIf="finishing.selected == Ternary.TRUE" [svgIcon]="'radio_unchecked'" class="icon"></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </ng-container>

    </div>
    <p *ngIf="!finishings || finishings?.length == 0">{{'PROJECT_FINISHINGS.SELECTED.NO_FINISHINGS' | translate}}</p>


  </div>
</app-project-page-template>