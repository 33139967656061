import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { OnboardingModalComponent } from 'src/app/components/modals/onboarding-modal/onboarding-modal.component';
import { Finishing, LOCATION } from 'src/app/models/Finishing';
import { InteriorType, interiorTypes } from 'src/app/models/InteriorType';
import { Project, ProjectModule } from 'src/app/models/Project';
import { Subject, Ternary } from 'src/app/models/Subject';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { FinishingService } from 'src/app/services/finishing/finishing.service';
import { InteriorTypeService } from 'src/app/services/interior-type/interior-type.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { mergeArrays } from 'src/app/util/Util';

@Component({
  selector: 'app-finishing',
  templateUrl: './finishing.component.html',
  styleUrls: ['./finishing.component.scss']
})
export class FinishingComponent extends BaseComponent implements OnInit {

  public projectId!: string;
  public projectData!: Project;

  public interiorTypes!: InteriorType[];
  public selectedInteriorTypes!: InteriorType[];
  public currentIndex = 0;

  public galleryId = 'myLightbox';
  public items!: GalleryItem[];

  public sideBarContent: AppRouteObject[] = [];

  @ViewChildren('typeGallery') public typeGalleries!: QueryList<ElementRef>;
  @ViewChild('typeGalleryContainer') public typeGalleryContainer!: ElementRef<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private interiorTypeService: InteriorTypeService,
    private finishingService: FinishingService,
    private projectService: ProjectService,
    private translate: TranslateService,
    public gallery: Gallery,
    private dialog: MatDialog
  ) { super(); }

  ngOnInit(): void {
    this.openOnboardingModal();

    let interiorTypes$ = this.interiorTypeService.getInteriorTypes();

    let finishings$ = this.finishingService.getFinishings();

    let projectId = this.activatedRoute.snapshot.params['project_id'];
    this.projectId = projectId;
    let projectData$ = this.projectService.getProjectById(projectId);

    // Join interior type list and projectdata after both observables have completed
    this.addSubscription(forkJoin({ interiorTypes: interiorTypes$, finishings: finishings$, projectData: projectData$ }).subscribe(r => {

      let interiorTypes = r.interiorTypes;
      let interiorTypeData = r.projectData.interiorTypes;

      this.projectData = r.projectData;

      this.interiorTypes =
        interiorTypes && interiorTypes.map(type => {
          type.selected = interiorTypeData.find((data: InteriorType) => data.xid === type.xid)?.selected || Ternary.FALSE;
          return type;
        });

      this.selectedInteriorTypes = this.interiorTypes.filter(type => type.selected == Ternary.TRUE);
        
      this.finishingService.getFinishingData(r.projectData.finishings, r.finishings);

      this.SetSideBarContent();
    }));
  }

  /**
   * Scroll left to the next incomplete type gallery picture
   * @param id id of the type gallery to be scrolled
   */
  scrollLeft(id: string) {     
    let typeGallery = this.typeGalleries.find(g => g.nativeElement['id'] == id)!;
    let scrollDistance = Math.floor(this.typeGalleryContainer.nativeElement.scrollWidth / 345) * 345;
    typeGallery.nativeElement.scrollLeft -= scrollDistance;
  }  

  /**
   * Scroll right to the next incomplete type gallery picture
   * @param id id of the type gallery to be scrolled
   */
  scrollRight(id: string) {     
    let typeGallery = this.typeGalleries.find(g => g.nativeElement['id'] == id)!;
    let scrollDistance = Math.floor(this.typeGalleryContainer.nativeElement.scrollWidth / 345) * 345;
    typeGallery.nativeElement.scrollLeft += scrollDistance;
  }

  /**
   * Change active interior type
   * @param next if true, then the next interiortype is loaded, if false, the previous one
   */
  changeInteriorType(next: boolean) {
    if (next) {
      (this.currentIndex == this.interiorTypes.length - 1) ? this.currentIndex = 0 : this.currentIndex = this.currentIndex + 1;
    } else {
      (this.currentIndex == 0) ? this.currentIndex = this.interiorTypes.length - 1 : this.currentIndex = this.currentIndex - 1;
    }
  }

  /**
   * Set active interior type status
   * @param interiorType interiortype of which status changes
   * @param select Ternary on or off
   */
  selectInteriorType(interiorType: InteriorType, select: Ternary) {
    let interiorTypeString = select + "=" + interiorType.xid;

    this.selectedInteriorTypes = this.interiorTypes.filter(type => type.selected == Ternary.TRUE);

    this.interiorTypeService.selectInteriorTypes(this.projectId, interiorTypeString);
  }

  /**
   * Open onboarding modal
   */
  openOnboardingModal() {
    const dialogRef = this.dialog.open(OnboardingModalComponent, {
      autoFocus: false, 
      data: {
        'step':  ProjectModule.FINISHING,
        'background': '/assets/pictures/onboarding/onboarding_installer.jpg'
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      width: '900px'
    });

    dialogRef.afterClosed().subscribe(r => {
      /* if (r && r.room) {
        this.removeRoom(r.room.id)
      } */
    });
  }
    

  /**
   * Add dynamic content for sidebar navigation
   */
  SetSideBarContent() {
    this.sideBarContent = [];

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_STYLES'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.STYLES'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_CATALOG'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.CATALOG'),
      complete: false,
      active: false,
      available: true,
      subitem: true
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_RECOMMENDED'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.RECOMMENDED'),
      complete: false,
      active: false,
      available: true,
      subitem: true
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_GENERAL_INDOOR'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.INDOOR'),
      complete: false,
      active: false,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0,
      subitem: false
    });

    console.log(this.finishingService.generalFinishing);
    console.log(this.finishingService.generalFinishing !== undefined);

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_GENERAL_OUTDOOR'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.OUTDOOR'),
      complete: false,
      active: false,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0 && this.finishingService.generalFinishing !== undefined,
      subitem: false
    });

    console.log(this.finishingService.outdoorFinishing);
    console.log(this.finishingService.outdoorFinishing !== undefined);

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_SUMMARY'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.SUMMARY'),
      complete: false,
      active: false,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0 && this.finishingService.generalFinishing !== undefined && this.finishingService.outdoorFinishing !== undefined,
      subitem: false
    });
  }
}
