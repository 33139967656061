<app-project-page-template [breadcrumb]="'PROJECT_FUNCTIONALITIES.BREADCRUMB' | translate" [breadcrumbPrev]="'PROJECT_FUNCTIONALITIES.SUMMARY.BREADCRUMB_PREV' | translate" [routerlinkPrev]="'../../'+ APP_ROUTES['FUNCTIONALITIES_SUBJECTS'].value" [breadcrumbNext]="'PROJECT_FUNCTIONALITIES.SUMMARY.BREADCRUMB_NEXT' | translate" [routerlinkNext]="'../../'" [sideBarContent]="sideBarContent">
  <div id="content">
  
    <h1>{{ 'PROJECT_FUNCTIONALITIES.SUMMARY.TITLE' | translate }}</h1>
    <p class="margin-b dark-grey">{{ 'PROJECT_FUNCTIONALITIES.SUMMARY.SUBTITLE' | translate }}</p>

    <div class="content-wrapper margin-b-70" *ngIf="selectedUsecases.length > 0">
      <button (click)="toggleSelected = false" *ngIf="toggleSelected" class="btn-expand btn-transparent btn-icon"><mat-icon svgIcon="expand_less" class="icon"></mat-icon></button>
      <button (click)="toggleSelected = true" *ngIf="!toggleSelected" class="btn-expand btn-transparent btn-icon"><mat-icon svgIcon="expand_more" class="icon"></mat-icon></button>

        <h2><mat-icon class="icon icon--small">check</mat-icon>{{'PROJECT_FUNCTIONALITIES.SUMMARY.SELECTED' | translate}}</h2>
        <p class=" greyed-out">{{selectedUsecases.length}} {{'PROJECT_FUNCTIONALITIES.SUMMARY.ITEMS' | translate}}</p>
      <div *ngIf="toggleSelected" class="functionalities-container">
        <ng-container *ngFor="let subject of selectedSubjects">
          <ng-container *ngIf="subjectContainsSelectedUsecases(subject)">
            <hr>
            <h3>{{subject.title}}</h3>
            <ng-container  *ngFor="let usecase of subject.useCases;let last = last">
              <ng-container *ngIf="usecase.selected == Ternary.TRUE">
                <div class="topic-wrapper">
                  <div class="topic-picture">
                    <img [src]="usecase.imageThumb!">
                  </div>
                  <div class="topic-content">
                    <h4 class="margin-b-10">{{usecase.title}}</h4>
                    <div class="grey6-text" [innerHTML]="usecase.info"></div>
                  </div>
                  <div class="check-buttons" *ngIf="!projectArchived">
                    <div (click)="usecase.selected = Ternary.FALSE; selectUsecaseState(usecase)" class="btn-round-icon check-false"><mat-icon class="icon icon--small">close</mat-icon></div>
                    <div (click)="usecase.selected = Ternary.MAYBE; selectUsecaseState(usecase)" class="btn-round-icon check-question"><mat-icon class="icon icon--small">question_mark</mat-icon></div>
                    <div (click)="usecase.selected = Ternary.TRUE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.TRUE" class="btn-round-icon check-true"><mat-icon class="icon icon--small">check</mat-icon></div>
                  </div>
                </div>
                <hr *ngIf="!last">
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="content-wrapper margin-b-70" *ngIf="maybeUsecases.length > 0">
      <button (click)="toggleMaybe = false" *ngIf="toggleMaybe" class="btn-expand btn-transparent btn-icon"><mat-icon svgIcon="expand_less" class="icon"></mat-icon></button>
      <button (click)="toggleMaybe = true" *ngIf="!toggleMaybe" class="btn-expand btn-transparent btn-icon"><mat-icon svgIcon="expand_more" class="icon"></mat-icon></button>

      <h2><mat-icon class="icon icon--small">question_mark</mat-icon>{{'PROJECT_FUNCTIONALITIES.SUMMARY.MAYBE' | translate}}</h2> 
      <p class=" greyed-out">{{maybeUsecases.length}} {{'PROJECT_FUNCTIONALITIES.SUMMARY.ITEMS' | translate}}</p>
      <div *ngIf="toggleMaybe" class="functionalities-container">
        <ng-container *ngFor="let subject of maybeSubjects">
          <hr>
          <h3>{{subject.title}}</h3>
          <ng-container  *ngFor="let usecase of subject.useCases;let last = last">
            <div class="topic-wrapper">
              <div class="topic-picture">
                <img [src]="usecase.imageThumb!">
              </div>
              <div class="topic-content">
                <h4 class="margin-b-10">{{usecase.title}}</h4>
                <div class="grey6-text" [innerHTML]="usecase.info"></div>
              </div>
              <div class="check-buttons" *ngIf="!projectArchived">
                <div (click)="usecase.selected = Ternary.FALSE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.FALSE" class="btn-round-icon check-false"><mat-icon class="icon icon--small">close</mat-icon></div>
                <div (click)="usecase.selected = Ternary.MAYBE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.MAYBE" class="btn-round-icon check-question"><mat-icon class="icon icon--small">question_mark</mat-icon></div>
                <div (click)="usecase.selected = Ternary.TRUE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.TRUE" class="btn-round-icon check-true"><mat-icon class="icon icon--small">check</mat-icon></div>
              </div>
            </div>
            <hr *ngIf="!last">
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="content-wrapper" *ngIf="possibleSubjects.length > 0">
      <button (click)="togglePossible = false" *ngIf="togglePossible" class="btn-expand btn-transparent btn-icon"><mat-icon svgIcon="expand_less" class="icon"></mat-icon></button>
      <button (click)="togglePossible = true" *ngIf="!togglePossible" class="btn-expand btn-transparent btn-icon"><mat-icon svgIcon="expand_more" class="icon"></mat-icon></button>

      <h2><mat-icon class="icon icon--small">close</mat-icon>{{'PROJECT_FUNCTIONALITIES.SUMMARY.POSSIBLE' | translate}}</h2> 
      <p class=" greyed-out">{{possibleUsecases.length}} {{'PROJECT_FUNCTIONALITIES.SUMMARY.ITEMS' | translate}}</p>
      <div *ngIf="togglePossible" class="functionalities-container">
        <ng-container *ngFor="let subject of possibleSubjects">
          <hr>
          <h3>{{subject.title}}</h3>
          <ng-container  *ngFor="let usecase of subject.useCases;let last = last">
            <div class="topic-wrapper">
              <div class="topic-picture">
                <img [src]="usecase.imageThumb!">
              </div>
              <div class="topic-content">
                <h4 class="margin-b-10">{{usecase.title}}</h4>
                <div class="grey6-text" [innerHTML]="usecase.info"></div>
              </div>
              <div class="check-buttons" *ngIf="!projectArchived">
                <div (click)="usecase.selected = Ternary.FALSE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.FALSE" class="btn-round-icon check-false"><mat-icon class="icon icon--small">close</mat-icon></div>
                <div (click)="usecase.selected = Ternary.MAYBE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.MAYBE" class="btn-round-icon check-question"><mat-icon class="icon icon--small">question_mark</mat-icon></div>
                <div (click)="usecase.selected = Ternary.TRUE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.TRUE" class="btn-round-icon check-true"><mat-icon class="icon icon--small">check</mat-icon></div>
              </div>
            </div>
            <hr *ngIf="!last">
          </ng-container>
        </ng-container>
      </div>
    </div>

  </div>
</app-project-page-template>