import { Injectable } from '@angular/core';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Set user settings on backend
   * @param entries settingstrings to be set
   */
  public setSetting(entries: string[]) {
    this.nikoApi.setSetting(entries).subscribe(r => {
      return r;
    });
  }
}