import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { catchError, distinctUntilChanged, map, Observable, scan, tap } from 'rxjs';
import { Room, RoomInstance, rooms } from 'src/app/models/Room';
import { Variable } from 'src/app/models/Variable';
import { Country } from 'src/app/models/Country';
import { Locale, LocaleObject } from 'src/app/models/Locale';
import { Subject } from 'src/app/models/Subject';
import { environment } from 'src/environments/environment';
import { InteriorType } from 'src/app/models/InteriorType';
import { FinishingRange } from 'src/app/models/Finishing';
import { City } from 'src/app/models/City';
import { DocumentType, Project } from 'src/app/models/Project';
import { ConstructionType } from 'src/app/models/ConstructionType';
import { BuildingType } from 'src/app/models/BuildingType';
import { PartnerConnection } from 'src/app/models/PartnerConnection';
import { User } from 'src/app/models/User';
import { Calculation } from 'src/app/models/System';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})
export class NikoApiService {

  public locale!: Locale;
  public countryCode!: string;

  private loginPath = environment.endpoint + '/user/profile';
  private logoutPath = environment.endpoint + '/user/logout';

  private localeListPath = environment.endpoint + '/locale/list';
  private countryListPath = environment.endpoint + '/country/list';
  private cityListPath = environment.endpoint + '/city/list';

  private projectFindPath = environment.endpoint + '/project/find';
  private projectRetrievePath = environment.endpoint + '/project/retrieve';
  private projectCreatePath = environment.endpoint + '/project/create';

  private calculateProjectPath = environment.endpoint + '/calculation/execute'

  private propertySetPath = environment.endpoint + '/property/set';

  private settingSetPath = environment.endpoint + '/setting/set';

  private constructionTypeListPath = environment.endpoint + '/projectType/list';
  private buildingTypeListPath = environment.endpoint + '/buildingType/list';

  private partnerConnectionPath = environment.endpoint + '/partnerConnection/list';

  private roomListPath = environment.endpoint + '/roomType/list';
  private roomAddPath = environment.endpoint + '/room/add';
  private roomEditPath = environment.endpoint + '/room/modify';
  private roomRemovePath = environment.endpoint + '/room/remove';

  private usecaseListPath = environment.endpoint + '/usecase/list';
  private usecaseSelectPath = environment.endpoint + '/usecase/select';
  private subjectSelectPath = environment.endpoint + '/subject/select';

  private interiorTypeListPath = environment.endpoint + '/interiorType/list';
  private interiorTypeSelectPath = environment.endpoint + '/interiorType/select';

  private finishingListPath = environment.endpoint + '/finishing/list';
  private finishingSelectPath = environment.endpoint + '/finishing/select';

  private VariablePath = environment.endpoint + '/variable/list';
  private variableSetPath = environment.endpoint + '/variable/set';

  private installTypeListPath = environment.endpoint + '/installType/list';

  private generateDocumentPath =  environment.endpoint + '/document/create';
  private downloadDocumentPath =  environment.endpoint + '/document/download';
  private deleteDocumentPath =  environment.endpoint + '/document/delete';
  private documentEditPath = environment.endpoint + '/document/modify';

  private renderer: Renderer2;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private injector: Injector,
    private gaService: GoogleAnalyticsService,
        @Inject(DOCUMENT) private document: Document,
        rendererFactory: RendererFactory2
  ) { 
    this.renderer = rendererFactory.createRenderer(null, null);
  }

    /**
     * Login
     * @user User login data
     */
     public login(): Observable<any> {

      //let loginPath = this.loginPath + '?username='+ user.username + '&password='+ user.password;
      let loginPath = this.loginPath;
  
      return this.http.post<any>(loginPath, null)
      //return this.http.post<any>(loginPath, user)
        .pipe(
          map((data) => {
            // Set user id for google analytics
            this.gaService.set({'user_id': data.id});
            this.gaService.event('user_login', 'Login', data.id, undefined, undefined, {
              'first': (data.settings && data.settings.length > 0) ? false : true,
              'user_name': data.firstname + ' ' + data.lastname,
              'email': data.email,
            });
            return data;
          }),
          catchError((err) => {
            console.error(err);
            throw err;
          })
        );
    }

    /**
     * Logout
     */
    public logout(): Observable<any> {

      this.gaService.set({'user_id': null});

      let logoutPath = this.logoutPath;
  
      return this.http.post<any>(logoutPath, null)
      //return this.http.post<any>(loginPath, user)
        .pipe(
          catchError((err) => {
            console.error(err);
            throw err;
          })
        );
    }











  /**
   * Get Locales
   * @returns Observable list of locales
   */
  public getLocales(): Observable<LocaleObject[]> {
    return this.http.post<LocaleObject[]>(this.localeListPath, null)
      .pipe(
        catchError((err) => {
          console.error(err);
          //console.log(err.status);
          throw err;
        })
      );
  }

  /**
   * Get countries
   * @returns Observable list of countries
   */
   public getCountries(): Observable<Country[]> {

    let countryListPath = this.countryListPath;
    let counryListBody = 'locale=' + this.locale;

    return this.http.post<Country[]>(countryListPath, counryListBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
   * Get citties
   * @returns Observable list of cities
   */
   public getCities(countryCode: string): Observable<City[]> {

    let cityListPath = this.cityListPath;
    let cityListBody = 'locale=' + this.locale + '&country=' + countryCode;

    return this.http.post<City[]>(cityListPath, cityListBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }










  /**
   * Get project info by project id
   * @returns project information
   */
  public getProjectById(projectId: string): Observable<Project> {

    let projectRetrievePath = this.projectRetrievePath;
    let projectRetrieveBody = 'id='+ projectId;
    
    return this.http.post<any>(projectRetrievePath, projectRetrieveBody)
      .pipe(
        map((data) => {
          // Set user id for google analytics
          this.gaService.set({'project_id': data.id});
          this.gaService.event('project_load', 'Project', data.id);
          return data;
        }),
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
   * Get project list
   */
  public getProjects(): Observable<any> {
    return this.http.post<any>(this.projectFindPath, null)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
   * Create project
   */
  public createProject(): Observable<any> {
    return this.http.post<any>(this.projectCreatePath, null)
      .pipe(
        map((data) => {
          // Set user id for google analytics
          this.gaService.set({'project_id': data.id});
          this.gaService.event('project_create', 'Project', data.id);
          return data;
        }),
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  } 

  /**
  * Set Property
  */
   public setProperty(projectId: string, entries: string[]): Observable<any> {

    let propertySetPath = this.propertySetPath;
    let propertySetBody = "project=" + projectId;

    entries.forEach(entry => {
      propertySetBody = propertySetBody + "&entry=" + entry;
    });

    return this.http.post<any>(propertySetPath, propertySetBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }









  /**
  * Set Setting
  */
   public setSetting(entries: string[]): Observable<any> {
    
    let settingSetPath = this.settingSetPath;
    let settingSetBody = '';

    entries.forEach(entry => {
      settingSetBody = settingSetBody + "entry=" + entry + '&';
    });

    settingSetBody = settingSetBody.slice(0, -1);

    return this.http.post<any>(settingSetPath, settingSetBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }










  /**
   * Get project info by project id
   * @returns project information
   */
   public calculateProjectLogById(projectId: string): Observable<HttpEvent<string>> {

    let calculateProjectPath = this.calculateProjectPath + '?project='+ projectId + '&articles=no'

    return this.http.get(calculateProjectPath, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text' // Important to read out the content from the stream
    })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }


  /**
   * Get project info by project id
   * @returns project information
   */
   public calculateProjectResultsById(projectId: string): Observable<HttpEvent<string>> {

    // Reset project installype on changes to the project
    this.setProperty(projectId, ['project.installtype']).subscribe(r => r);

    let calculateProjectPath = this.calculateProjectPath + '?project='+ projectId

    return this.http.get(calculateProjectPath, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text' // Important to read out the content from the stream
    })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }










  /**
  * Get ConstructionTypeList
  * @returns Observable list of Construction types
  */
   public getConstructionTypes(): Observable<ConstructionType[]> {

    let constructionTypeListPath = this.constructionTypeListPath;
    let constructionTypeListBody = 'locale=' + this.locale;

    return this.http.post<ConstructionType[]>(constructionTypeListPath, constructionTypeListBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
  * Get BuildingTypeList
  * @returns Observable list of Building types
  */
  public getBuildingTypes(): Observable<BuildingType[]> {

    let buildingTypeListPath = this.buildingTypeListPath;
    let buildingTypeListBody = 'locale=' + this.locale;

    return this.http.post<BuildingType[]>(buildingTypeListPath, buildingTypeListBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }











  /**
  * Get PartnerConnections
  * @returns Observable list of Partner connections
  */
   public getPartnerConnections(): Observable<PartnerConnection[]> {

    let partnerConnectionPath = this.partnerConnectionPath;
    let partnerConnectionBody = 'locale=' + this.locale;

    return this.http.post<PartnerConnection[]>(partnerConnectionPath, partnerConnectionBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }










  /**
  * Get RoomList
  * @returns Observable list of rooms
  */
  public getRooms(buildingType: string): Observable<Room[]> {

    let roomListPath = this.roomListPath;
    let roomListBody = 'locale=' + this.locale + '&buildingType=' + buildingType;

    return this.http.post<Room[]>(roomListPath, roomListBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
  * Add room to project
  * @returns room instance
  */
  public addRoom(projectId: string, roomString: string): Observable<RoomInstance[]> {

    // Reset project installype on changes to the project
    this.setProperty(projectId, ['project.installtype']).subscribe(r => r);


    let roomAddPath = this.roomAddPath;
    let roomAddBody = 'project=' + projectId + '&' + roomString;

    return this.http.post<RoomInstance[]>(roomAddPath, roomAddBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
  * Modify room 
  */
  public editRoom(projectId: string, roomString: string): Observable<RoomInstance[]> {

    let roomEditPath = this.roomEditPath;
    let roomEditBody = 'project=' + projectId + '&' + roomString;

    return this.http.post<RoomInstance[]>(roomEditPath, roomEditBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
  * Remove room instance from project
  */
  public removeRoom(projectId: string, roomId: string) {

    // Reset project installype on changes to the project
    this.setProperty(projectId, ['project.installtype']).subscribe(r => r);


    let roomRemovePath = this.roomRemovePath;
    let roomRemoveBody = 'project=' + projectId + '&id=' + roomId;

    return this.http.post<void[]>(roomRemovePath, roomRemoveBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }












  /**
   * Get subjects
   * @returns Observable list of subjects
   */
  public getSubjects(): Observable<Subject[]> {

    this.spinner.show();

    let usecaseListPath = this.usecaseListPath;
    let usecaseListBody = 'locale=' + this.locale + '&country=' + this.countryCode;

    return this.http.post<Subject[]>(usecaseListPath, usecaseListBody)
      .pipe(
        tap(() => {
          this.spinner.hide();
        }),
        catchError((err) => {
          this.spinner.hide();
          console.error(err);
          throw err;
        })
      );
  }

  /**
   * Set usecase state
   */
  public SelectSubjectState(projectId: string, subjectStateString: string): Observable<any> {

    // Reset project installype on changes to the project
    this.setProperty(projectId, ['project.installtype']).subscribe(r => r);

    // Reset project roomsconfigurated property on changes to functionalitties and finishings
    this.setProperty(projectId, ['project.roomsconfigurated']).subscribe(r => r);


    let subjectSelectPath = this.subjectSelectPath;
    let subjectSelectBody = 'project=' + projectId + '&' + subjectStateString;

    return this.http.post<any>(subjectSelectPath, subjectSelectBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
   * Set usecase state
   */
  public selectUsecaseState(projectId: string, usecaseStateString: string): Observable<any> {

    // Reset project installype on changes to the project
    this.setProperty(projectId, ['project.installtype']).subscribe(r => r);

    // Reset project roomsconfigurated property on changes to functionalitties and finishings
    this.setProperty(projectId, ['project.roomsconfigurated']).subscribe(r => r);


    let usecaseSelectPath = this.usecaseSelectPath;
    let usecaseSelectBody = 'project=' + projectId + '&' + usecaseStateString;

    return this.http.post<any>(usecaseSelectPath, usecaseSelectBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }











  /**
  * Get InteriorTypeList
  * @returns Observable list of interior types
  */
  public getInteriorTypes(): Observable<InteriorType[]> {

    let interiorTypeListPath = this.interiorTypeListPath;
    let interiorTypeListBody = 'locale=' + this.locale;

    return this.http.post<InteriorType[]>(interiorTypeListPath, interiorTypeListBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
   * Set state for interior types
   */
  public selectInteriorTypes(projectId: string, interiorTypeString: string): Observable<any> {

    let interiorTypeSelectPath = this.interiorTypeSelectPath;
    let interiorTypeSelectBody = 'project=' + projectId + '&' + interiorTypeString;

    return this.http.post<any>(interiorTypeSelectPath, interiorTypeSelectBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
  * Get finishing list
  * @returns Observable list of finishings
  */
  public getFinishings(): Observable<FinishingRange[]> {

    let finishingListPath = this.finishingListPath;
    let finishingListBody = 'locale=' + this.locale + '&country=' + this.countryCode;

    // TODO DOUBLECHECK WITH COUNTRYCODE
    return this.http.post<FinishingRange[]>(finishingListPath, finishingListBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
   * Set state for finishings
   */
  public selectFinishings(projectId: string, finishingString: string): Observable<any> {

    // Reset project installype on changes to the project
    this.setProperty(projectId, ['project.installtype']).subscribe(r => r);

    // Reset project roomsconfigurated property on changes to functionalitties and finishings
    this.setProperty(projectId, ['project.roomsconfigurated']).subscribe(r => r);


    let finishingSelectPath = this.finishingSelectPath;
    let finishingSelectBody = 'project=' + projectId + '&' + finishingString;

    return this.http.post<any>(finishingSelectPath, finishingSelectBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }











  /**
  * Get Variable[]
  * @returns Observable list of variables
  */
   public getVariables(): Observable<Variable[]> {

    let variablePath = this.VariablePath;
    let variableBody = 'locale=' + this.locale;

    return this.http.post<Variable[]>(variablePath, variableBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
  * Set Variables
  */
  public setVariables(projectId: string, clear: boolean, entries: string[]): Observable<any> {

    // Reset project installype on changes to the project
    this.setProperty(projectId, ['project.installtype']).subscribe(r => r);

    // Set project roomsconfigurated property on changes to functionalitties and finishings
    this.setProperty(projectId, ['project.roomsconfigurated:true']).subscribe(r => r);

    let variableSetPath = this.variableSetPath;
    let variableSetBody = 
      "project=" + projectId +
      "&clear=" + clear;

    entries.forEach(entry => {
      variableSetBody = variableSetBody + "&entry=" + entry;
    });


    return this.http.post<any>(variableSetPath, variableSetBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }











  /**
  * Get InstallType[]
  * @returns Observable list of installTypes
  */
   public getInstallTypes(): Observable<any[]> {

    let installTypeListPath = this.installTypeListPath;
    let installTypeListBody = 'locale=' + this.locale;

    return this.http.post<Variable[]>(installTypeListPath, installTypeListBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }











  /**
   * Generate document with given type for given project
   * @returns document id
   */
   public generateDocument(projectId: string, type: DocumentType): Observable<{id: string}> {

    let generateDocumentPath = this.generateDocumentPath;
    let generateDocumentBody = 'project=' + projectId + '&type=' + type;

    console.log(generateDocumentPath);
    return this.http.post<any>(generateDocumentPath, generateDocumentBody)
      .pipe(
        map((data) => {
          this.gaService.event('document_create', 'Document', data.id, undefined, undefined, {
            'type': type,
            'project': projectId
          });
          return data;
        }),
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }


  /**
   * Download document with given id
   * @returns document download
   */
   public downloadDocument(documentId: string) {

    let downloadDocumentPath = this.downloadDocumentPath + '?id='+ documentId;
    
    window.location.assign(downloadDocumentPath);

    // Clicking on appended element is blocked as popup after user action trust window closes (2 seconds)
  }


  /**
   * Delete document with given id
   */
   public deleteDocument(documentId: string): Observable<any> {

    let deleteDocumentPath = this.deleteDocumentPath;
    let deleteDocumentBody = 'id='+ documentId;


      this.gaService.event('document_delete', 'Document', documentId);

    return this.http.post<any>(deleteDocumentPath, deleteDocumentBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  /**
  * Modify room 
  */
  public editDocument(projectId: string, documentString: string): Observable<Document> {

    let documentEditPath = this.documentEditPath;
    let documentEditBody = documentString;
    console.log(documentString);

    return this.http.post<Document>(documentEditPath, documentEditBody)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }


}

export interface Download {
  content: Object | null;
  progress: number;
  state: "PENDING" | "IN_PROGRESS" | "DONE";
}