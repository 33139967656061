<app-overview-page-template>
  <div id="content">
    <div class="flex space-between margin-b-30">

      <h1 class="no-margin">{{ "PROJECTS.TITLE" | translate }}</h1>

      <div class="button-wrapper">

        <!-- Archive switch -->
        <div class="switch-button">
          <input #archive class="switch-button-checkbox" type="checkbox" 
          [checked]="projectService.projectListState == ProjectState.ARCHIVED" 
          (click)="toggleArchive()" 
          (change)="true">
          <label class="switch-button-on"><span>{{'PROJECTS.LABEL_ARCHIVED' | translate}}</span></label>
          <label class="switch-button-off"><span>{{'PROJECTS.LABEL_ACTIVE' | translate}}</span></label>
        </div>

        <!-- New project button-->
        <button class="btn-round-icon secondary" (click)="createProject()">
          <mat-icon>add</mat-icon>
        </button>

      </div>

    </div>

    <table class="content-container no-margin no-padding">
      <tr>
        <th class="pointer" [class.yellow-text]="sort == 'name'" (click)="sortProjects('name')">
          {{ "PROJECTS.NAME" | translate }}&nbsp;
          <mat-icon *ngIf="ascending_name" class="icon icon--small">expand_more</mat-icon>
          <mat-icon *ngIf="!ascending_name" class="icon icon--small">expand_less</mat-icon>
        </th>
        <th class="pointer" [class.yellow-text]="sort == 'address'" (click)="sortProjects('address')">
          {{ "PROJECTS.ADDRESS" | translate }}&nbsp;
          <mat-icon *ngIf="ascending_address" class="icon icon--small">expand_more</mat-icon>
          <mat-icon *ngIf="!ascending_address" class="icon icon--small">expand_less</mat-icon>
        </th>
        <th class="pointer" [class.yellow-text]="sort == 'modified'" (click)="sortProjects('modified')">
          {{ "PROJECTS.MODIFIED" | translate }}&nbsp;
          <mat-icon *ngIf="ascending_modified" class="icon icon--small">expand_more</mat-icon>
          <mat-icon *ngIf="!ascending_modified" class="icon icon--small">expand_less</mat-icon>
        </th>
        <th class="pointer" [class.yellow-text]="sort == 'status'" (click)="sortProjects('status')">
          {{ "PROJECTS.STATUS" | translate }}&nbsp;
          <mat-icon *ngIf="ascending_status" class="icon icon--small">expand_more</mat-icon>
          <mat-icon *ngIf="!ascending_status" class="icon icon--small">expand_less</mat-icon>
        </th>
        <th class="icon-cell"></th>
      </tr>

      <!--Archived projects-->
      <ng-container *ngIf="projects && projects!.length > 0 && archive.checked">
        <ng-container *ngFor="let project of projects">
          <ng-container *ngIf="propertyValueArchived(project) && propertyValueArchived(project) == 'true'">
            <tr class="pointer" [routerLink]="'/'+ APP_ROUTES['PROJECT'].value.replace(':project_id', project.id)">
              <td class="ellipsis">
                {{ (project.properties && projectPropertyValue(project.properties, 'name')) ? projectPropertyValue(project.properties, 'name') : '-'}}
              </td>

              <td>
                <span [innerHTML]="getAddress(project)"></span>
              </td>

              <!-- <td>29/06/2022 - <span class="greyed-out">08:45</span></td> -->
              <td>{{ project.modified | date: 'dd/MM/yyyy'}} - <span class="greyed-out">{{ project.modified | date: 'HH:mm'}}</span></td>

              <td><span class="yellow-text uppercase">{{ 'STEP.' + project.activeStep | translate }}</span></td>

              <td class="icon-cell">
                <button>
                  <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>

      <!--Active projects-->
      <ng-container *ngIf="projects && projects!.length > 0 && !archive.checked">
        <ng-container *ngFor="let project of projects">
          <ng-container *ngIf="!propertyValueArchived(project) || propertyValueArchived(project) == 'false'">
            <tr class="pointer" [routerLink]="'/'+ APP_ROUTES['PROJECT'].value.replace(':project_id', project.id)">
              <td class="ellipsis">
                {{ (project.properties && projectPropertyValue(project.properties, 'name')) ? projectPropertyValue(project.properties, 'name') : '-'}}
              </td>

              <td>
                <span [innerHTML]="getAddress(project)"></span>
              </td>

              <!-- <td>29/06/2022 - <span class="greyed-out">08:45</span></td> -->
              <td>{{ project.modified | date: 'dd/MM/yyyy'}} - <span class="greyed-out">{{ project.modified | date: 'HH:mm'}}</span></td>

              <td><span class="yellow-text uppercase">{{ 'STEP.' + project.activeStep | translate }}</span></td>

              <td class="icon-cell">
                <button>
                  <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>

    </table>

  </div>
</app-overview-page-template>