<div *ngIf="currentSubject" gaCategory="Subject Detail Modal">

    <div class="topic-wrapper relative">
      <div mat-dialog-title class="topic-title-wrapper no-margin">

        <button gaEvent="button_click" gaLabel="modal_close" (click)="dialogRef.close()" class="btn-close">
          <mat-icon class="icon icon--small">close</mat-icon>
        </button>

        <h1 class="no-margin">{{currentSubject.title}}</h1>

      </div>
      <div mat-dialog-content>
        <div class="topic-image-wrapper">
          <ng-container *ngIf="currentSubject.video">
            <video class="topic-image" preload="auto" controls [poster]="currentSubject.imageFull">
              <source type="video/mp4" [src]="currentSubject.video" />
            </video>
          </ng-container>
          <img *ngIf="!currentSubject.video" class="topic-image" src="{{currentSubject.imageFull}}">
        </div>
        <div class="topic-info content-wrapper cms" [innerHTML]="currentSubject.info">
        </div>
      </div>
    </div>
  </div>