
  <div id="room-component" *ngIf="this.data.room">
    <div mat-dialog-title class="margin-b">
      <button (click)="dialogRef.close()" class="btn-close">
        <mat-icon class="icon icon--small">close</mat-icon>
      </button>
  
      <h2 class="h1">{{ this.data.room.title }}</h2>
    </div>

    <div mat-dialog-content class="room-container">
      <p class="dark-grey margin-b-70">{{ 'PROJECT_ROOMEDIT.INTRO' | translate }} {{this.data.room.title | lowercase}}?</p>
      <div class="flex flex-start">
        <div class="room-picture margin-r">
          <img src="{{this.data.room.imageFull}}" class="icon icon--medium">
        </div>

        <mat-form-field>
          <input autocomplete="new-password" matInput [formControl]="name" maxlength="30" [placeholder]="'PROJECT_ROOMEDIT.PLACEHOLDER' | translate">
        </mat-form-field>
      </div>
    </div>

    <div class="button-wrapper">
      <button (click)="dialogRef.close()" class="btn btn-border btn-small margin-r">{{ 'PROJECT_ROOMEDIT.CANCEL' | translate }}</button>
      <button (click)="setRoomName()" class="btn btn-primary btn-small">{{ 'PROJECT_ROOMEDIT.CONFIRM' | translate }}</button>
    </div>

  </div>