<app-project-page-template [functionalitiesPage]="true" [breadcrumb]="'PROJECT_FUNCTIONALITIES.BREADCRUMB' | translate"
  [breadcrumbPrev]="(previousSubject?.title ? 'PROJECT_FUNCTIONALITIES.TOPICS.PREV' : 'PROJECT_FUNCTIONALITIES.TOPICS.BREADCRUMB_PREV') | translate" 
  [routerlinkPrev]="previousSubject?.xid ? '../../../../' + APP_ROUTES['FUNCTIONALITIES_USECASES'].value.replace(':topic_id', previousSubject!.xid) : '../../../../' + APP_ROUTES['FUNCTIONALITIES_SUBJECTS'].value"
  [breadcrumbNext]="(nextSubject?.title ? 'PROJECT_FUNCTIONALITIES.TOPICS.NEXT' : 'PROJECT_FUNCTIONALITIES.TOPICS.BREADCRUMB_NEXT') | translate"
  [routerlinkNext]="nextSubject?.xid ? '../../../../' + APP_ROUTES['FUNCTIONALITIES_USECASES'].value.replace(':topic_id', nextSubject!.xid) : '../../../../' + APP_ROUTES['FUNCTIONALITIES_SUMMARY'].value"
  [sideBarContent]="sideBarContent">
  
  <div *ngIf="currentSubject" id="content">
    <div class="margin-b-30">
      <div class="title-wrapper">
        <h1 class="no-margin">{{currentSubject.title}}</h1>
        <a class="font-s font-underline pointer"
        (click)="openDialog(currentSubject.xid)">{{'PROJECT_FUNCTIONALITIES.TOPICS.SUBJECT' | translate}}</a>
      </div>
    </div>


    <div class="topic-container" *ngIf="usecasesWithoutPartners.length > 0">
      <hr>

      <h2 class="h3 margin-b">{{ 'PROJECT_FUNCTIONALITIES.TOPICS.TITLE' | translate}}</h2>
      <p class="margin-b dark-grey tooltip-trigger">
        {{'PROJECT_FUNCTIONALITIES.TOPICS.SUBTITLE' | translate}}
      
        <span class="tooltip-btn" (click)="openTooltip('PROJECT_FUNCTIONALITIES.TOPICS.TITLE','PROJECT_FUNCTIONALITIES.TOPICS.SUBTITLE.INFO')"></span>
      </p>

      <!-- Usecases without partners -->
      <ng-container *ngFor="let usecase of usecasesWithoutPartners">
        <div class="topic-wrapper content-container  margin-b-30" *ngIf="!usecase.partnerConnections || usecase.partnerConnections.length == 0">
          <div class="topic-picture">
            <img [src]="usecase.imageFull!">
          </div>
          <div class="content-wrapper">
            <h3 class="margin-b-30">{{usecase.title}}</h3>
            <div [innerHTML]="usecase.info"></div>
          </div>
          <div class="check-buttons">
            <div (click)="usecase.selected = Ternary.FALSE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.FALSE"
              class="btn-round-icon check-false">
              <mat-icon class="icon icon--small">close</mat-icon>
            </div>
            <div (click)="usecase.selected = Ternary.MAYBE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.MAYBE"
              class="btn-round-icon check-question">
              <mat-icon class="icon icon--small">question_mark</mat-icon>
            </div>
            <div (click)="usecase.selected = Ternary.TRUE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.TRUE"
              class="btn-round-icon check-true">
              <mat-icon class="icon icon--small">check</mat-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>


    <div *ngIf="usecasesWithPartners.length > 0">
      <hr>

      <h2 class="h3 margin-b">{{ 'PROJECT_FUNCTIONALITIES.PARTNERS.TITLE' | translate}}</h2>
      
      <!-- Usecases with partners -->
      <div *ngIf="usecasesWithPartners && usecasesWithPartners.length > 0" class="margin-b-70">
        <p class="margin-b dark-grey">{{'PROJECT_FUNCTIONALITIES.PARTNERS.SUBTITLE' | translate}}</p>      

        <ng-container *ngFor="let usecase of usecasesWithPartners">
          <div class="topic-wrapper content-container margin-b-30" 
          *ngIf="usecase.partnerConnections && usecase.partnerConnections.length > 0">
            <div class="topic-picture">
              <img [src]="usecase.imageFull!">
            </div>
            <div class="content-wrapper">
              <h3 class="margin-b-30">{{usecase.title}}</h3>
              <div [innerHTML]="usecase.info"></div>
            </div>
            <div class="check-buttons">
              <div (click)="usecase.selected = Ternary.FALSE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.FALSE"
                class="btn-round-icon check-false">
                <mat-icon class="icon icon--small">close</mat-icon>
              </div>
              <div (click)="usecase.selected = Ternary.MAYBE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.MAYBE"
                class="btn-round-icon check-question">
                <mat-icon class="icon icon--small">question_mark</mat-icon>
              </div>
              <div (click)="usecase.selected = Ternary.TRUE; selectUsecaseState(usecase)" [class.active]="usecase.selected == Ternary.TRUE"
                class="btn-round-icon check-true">
                <mat-icon class="icon icon--small">check</mat-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      
      <div class="partner-container">

        <!-- Partners -->
        <ng-container *ngIf="partners && partners.length > 0"> 
          <h2 class="h3 margin-b">{{ 'PROJECT_FUNCTIONALITIES.PARTNERS.COMPATIBLE.TITLE' | translate}}</h2>
          
          <ng-container>
            <div class="flex space-between margin-b">
              <p class="dark-grey">{{'PROJECT_FUNCTIONALITIES.PARTNERS.COMPATIBLE.SUBTITLE' | translate}}</p>      
              <p *ngIf="!compatiblePartners || compatiblePartners.length == 0" class="font-s warning flex flex-center no-wrap margin-l"><mat-icon svgIcon="error" class="icon icon--small"></mat-icon>&nbsp;{{ 'PROJECT_FUNCTIONALITIES.PARTNERS.INCOMPATIBLE' | translate}} </p>
            </div>
          </ng-container>
          
          <div class="content-wrapper margin-b">

              <ng-container *ngFor="let partner of partners" >
                <div class="partner-wrapper"
                [class.greyedout]="this.incompatiblePartners?.includes(partner)">
                  <div class="partner-content">
                    <div class="partner-picture">
                      <img [src]="partner.image ? partner.image : ''" class="icon">
                    </div>
                    <div class="partner-description">
                      <p>{{ partner.description }}</p>
                    </div>

                    <div *ngIf="this.incompatiblePartners?.includes(partner)">
                      <a class="font-s font-underline pointer" (click)="checkChosenPartnerCompatibility(partner)">{{ 'PROJECT_FUNCTIONALITIES.PARTNERS.SHOW_INCOMPATIBLE' | translate }}</a>
                    </div>
                  </div>
                </div>
              </ng-container>

          </div>
        </ng-container>

      </div>

      <!-- Partner not in the list -->
      <a class="font-s font-underline pointer" target="_blank" href="https://www.niko.eu/partners">{{'PROJECT_FUNCTIONALITIES.PARTNERS.NOT_LISTED' | translate}}</a>
    </div>

  </div>
</app-project-page-template>