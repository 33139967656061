export class Country {
  order!: number;
  code!: string;
  name!: string;
}

export const countries:Country[] = [
    {
        "order": 1,
        "code": "BE",
        "name": "Belgium"
    },
    {
        "order": 8,
        "code": "DK",
        "name": "DK"
    },
    {
        "order": 9,
        "code": "FR",
        "name": "France"
    },
    {
        "order": 2,
        "code": "NL",
        "name": "Netherlands"
    }
]