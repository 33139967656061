import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, startWith } from 'rxjs';
import { City } from 'src/app/models/City';
import { Country } from 'src/app/models/Country';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { CityService } from 'src/app/services/city/city.service';
import { CountryService } from 'src/app/services/country/country.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { environment } from 'src/environments/environment';
import { clone, setUserSettingValue, userSettingValue } from 'src/app/util/Util';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { LocaleObject } from 'src/app/models/Locale';
import { Project, Property } from 'src/app/models/Project';
import { BaseComponent } from 'src/app/components/base.component';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/User';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { SessionStorageKeys } from 'src/app/services/session-storage/session-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MatDialog } from '@angular/material/dialog';
import { TooltipModalComponent } from '../modals/tooltip-modal/tooltip-modal.component';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent extends BaseComponent implements OnInit {

  public userSettingValue = userSettingValue;

  public userData!: User;

  public languages: string[] = [];
  public countries: Country[] = [];
  public cities: City[] = [];
  public filteredCities!: Observable<City[]>;
  public filteredPostalCodes!: Observable<City[]>;
  
  public citySet = false;
  public currentCity!: City;

  public currentCountry!: string;
  public currentLang!: string;
  
  public installerDetails!: UntypedFormGroup;

  public onboardingStatus = 'visible';

  public activeStep = 1;

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private nikoApi: NikoApiService,
    private translate: TranslateService,
    private countryService: CountryService,
    private cityService: CityService,
    private router: Router,
    private userService: UserService,
    private settingsService: SettingsService,
    private session: SessionStorageService,
    private localStorage: LocalStorageService,
    private gaService: GoogleAnalyticsService,
    private dialog: MatDialog
  ) { super(); }

  ngOnInit(): void {
    this.gaService.set({'project_id': null});

    // Get countries
    this.countryService.getCountries().subscribe((r:Country[]) => {
      this.countries = r.sort((a,b) => a.order - b.order);
    })

    this.currentCountry = this.nikoApi.countryCode;

    // Get languages
    this.nikoApi.getLocales().subscribe((r:LocaleObject[]) => {
      r.forEach(locale => {
        this.languages.push(locale.locale);
      })
    });

    this.translate.currentLang ? this.currentLang = this.translate.currentLang : this.currentLang = this.translate.defaultLang;

    this.getUserData();
  }

  /**
   * logout
   */
   public logout() {
    this.userService.logout();
    this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
  }

  /**
   * Check if onboarding form is valid to proceed
   */
  public checkOnboarding() {
    if(this.installerDetails.valid) {
      this.router.navigate(['/', APP_ROUTES['PROJECTLIST'].value]);
    } else {
      this.installerDetails.markAllAsTouched();
    }
  }

  /**
   * Get User data to show current answers
   */
  private getUserData() {
    this.userData = this.userService.user.getValue();

    // If no user settings are available, redirect to login
    if(!this.userData.email) {
      this.nikoApi.logout();
      this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
    } else if(!this.userData.settings) {
      // Empty form fields
      this.installerDetails = new UntypedFormGroup({
        name: new UntypedFormControl(null, [Validators.required]),
        vat: new UntypedFormControl(null, [Validators.required]),
        phone: new UntypedFormControl(null, [Validators.required]),
        email: new UntypedFormControl(null, [Validators.required]),
    
        address: new UntypedFormControl(null),
        address2: new UntypedFormControl(null),
        zip: new UntypedFormControl(null, [Validators.required]),
        city: new UntypedFormControl(null, [Validators.required]),
        country: new UntypedFormControl(null, [Validators.required])
      });
  
      // Set default country until changed by user
      if(!this.installerDetails.get('country')?.value){this.installerDetails.get('country')?.setValue(this.currentCountry); this.saveProperty('country');}

      this.getCities();
    } else {
      // Fill form fields with available input
      this.installerDetails = new UntypedFormGroup({
        name: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.name')?.value, [Validators.required]),
        vat: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.vat')?.value, [Validators.required]),
        phone: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.phone')?.value),
        email: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.email')?.value, [Validators.required]),
    
        address: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.address')?.value),
        address2: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.address2')?.value),
        zip: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.zip')?.value, [Validators.required]),
        city: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.city')?.value, [Validators.required]),
        country: new UntypedFormControl(this.userData.settings.find(p => p.name == 'user.country')?.value, [Validators.required])
      });

      // If no email is found in user settings, set email to toplevel user email
      if(!this.installerDetails.get('email')?.value){this.installerDetails.get('email')?.setValue(this.userData.email); this.saveProperty('email');}
  
      // If no country is found in user settings, set default country until changed by user
      // and ync current country with country input
      if(!this.installerDetails.get('country')?.value) {
        this.installerDetails.get('country')?.setValue(this.currentCountry); this.saveProperty('country');
      } else {
        this.currentCountry = this.installerDetails.get('country')?.value;
      }

      // If zip and city are already set, input fields are regarded as valid
      if(this.installerDetails.get('zip')?.value && this.installerDetails.get('city')?.value) {this.citySet = true;}

      this.onboardingStatus = this.userData.settings.find(p => p.name == 'user.onboarding')?.value ? this.userData.settings.find(p => p.name == 'user.onboarding')?.value! : 'visible'

      this.getCities();
    }
  }

  /**
   * Get list of cities
   */
  private getCities() {
    this.cityService.getCities(this.currentCountry).subscribe((r:City[]) => {
      this.cities = r.sort((a,b) => parseInt(a.postalCode) - parseInt(b.postalCode));

      if(this.installerDetails) {
        this.filteredCities = this.installerDetails.get('city')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterCities(value).slice(0,5)),
        );
  
        this.filteredPostalCodes = this.installerDetails.get('zip')!.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterPostalCodes(value).slice(0,5)),
        );
      }
    });
  }

  /**
   * Filter citylist to show only results matching the filter value
   * @param value filter value
   * @returns citylist containing only the filtered values
   */
  private filterCities(value: string): City[] {
    return clone(this.cities).filter(city => {
      return city.name.toLowerCase().includes(value.toLowerCase());
    });
  }

  /**
   * Filter citylist to show only results matching the filter value
   * @param value filter value
   * @returns citylist containing only the filtered values
   */
  private filterPostalCodes(value: string): City[] {
    return clone(this.cities).filter(city => {
      return city.postalCode.includes(value);
    });
  }

  // Clear city and zip form fields if dialogoptions are required, but input was typed
  @HostListener("document:click", ["$event"])
  clickedOutside() {
    if (!this.citySet && this.installerDetails) {
      this.installerDetails.get("city")!.patchValue('');
      this.installerDetails.get("zip")!.patchValue('');
    }
  }

  /**
   * toggle hiding onboarding on user profile settings
   */
  public hideOnboardingOnProfile(hide: boolean) {
    
    this.gaService.event('checkbox_toggle', 'Onboarding - Welcome', hide ? 'hide onboarding' : 'show onboarding');

    if(hide) {
      this.onboardingStatus = 'hidden';
    } else {
      this.onboardingStatus = 'visible';
    }

    this.settingsService.setSetting(['user.onboarding:' + this.onboardingStatus]);

    setUserSettingValue(this.userData.settings, 'user.onboarding', this.onboardingStatus);
    this.userService.user.next(this.userData);

    let onboardingPropertyArray = (this.localStorage.getObject(LocalStorageKeys.ONBOARDING) ? this.localStorage.getObject(LocalStorageKeys.ONBOARDING) : []) as Property[];
    let onboardingProperty = new Property;
    onboardingProperty.name = 'user.onboarding';
    onboardingProperty.value = this.onboardingStatus;

    if(onboardingPropertyArray.some(x => x.name == onboardingProperty.name)) {
      onboardingPropertyArray = onboardingPropertyArray.filter(x => x.name != onboardingProperty.name)
    } 

    onboardingPropertyArray.push(onboardingProperty);
    
    this.localStorage.saveObject(LocalStorageKeys.ONBOARDING, onboardingPropertyArray);
  }

  /**
   * Save one or multiple property values to backend
   * @param propertyName name of the formcontrol and the property to be saved
   */
  public saveProperty(propertyName: string) {

    let entries: string[] = [];

    // City / zip property
    if (propertyName == 'city' || propertyName == 'zip') { 

      let postalCode = '';
      let cityName = '';

      // Changes to the city or zip property using the dialogoptions also update the other property value.
      if(propertyName == 'city') { 
      // If city is changed

        cityName = this.installerDetails!.get('city')!.value;

        if(cityName) {
          if(this.currentCity.name.toLowerCase() == this.installerDetails!.get('city')!.value.toLowerCase()){
            this.installerDetails!.get('zip')!.setValue(this.currentCity.postalCode);
          }
        } else {
          this.installerDetails!.get('zip')!.setValue('');
        }
        postalCode = this.installerDetails!.get('zip')!.value;

      } else if(propertyName == 'zip') { 
      // If zip is changed
        postalCode = this.installerDetails!.get('zip')!.value;

        if(postalCode) {
          if(this.currentCity.postalCode == this.installerDetails!.get('zip')!.value){
            this.installerDetails!.get('city')!.setValue(this.currentCity.name);
          }    
        } else {
          this.installerDetails!.get('city')!.setValue('');
        }

        cityName = this.installerDetails!.get('city')!.value;
      }
        
      entries.push('user.zip:' + postalCode);
      entries.push('user.city:' + cityName);

      this.settingsService.setSetting(entries);

      let user = this.userData;
      setUserSettingValue(user.settings, 'user.zip', postalCode);
      setUserSettingValue(user.settings, 'user.city', cityName);

      this.userService.user.next(user);

    // Country property
    } else if(propertyName == 'country') {
      this.currentCountry = this.installerDetails!.get('country')!.value;
      this.settingsService.setSetting(['user.country:' + this.currentCountry]);
      this.citySet = false;
      this.getCities();

      let user = this.userData;
      setUserSettingValue(user.settings, 'user.country', this.currentCountry);
      this.userService.user.next(user);

    // Other properties
    } else { 
      this.settingsService.setSetting(['user.' + propertyName + (this.installerDetails!.get(propertyName)!.value ? (':' + this.installerDetails!.get(propertyName)!.value) : '')]);
    
      let user = this.userData;
      setUserSettingValue(user.settings, 'user.' + propertyName, this.installerDetails!.get( propertyName )!.value);

      this.userService.user.next(user);
    }
  }

  /**
   * Open tooltip modal
   */
    public openTooltip(title: string = '', text: string) {
      const dialogRef = this.dialog.open(TooltipModalComponent, {
        autoFocus: false, 
        data: {
          'title': title,
          'text': text
        },
        maxWidth: '90vw',
        maxHeight: '90vh',
        minWidth: '775px'
      });
    }

}