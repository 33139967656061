<app-overview-page-template 
[breadcrumbBack]="'INSTALLER_DETAILS.BACK' | translate"
[routerlinkBack]="'/' + APP_ROUTES['PREFERENCES'].value"
>

  <div id="content">
    <div class="inner-content">

      <div class="flex space-between margin-b">
        <h1 class="no-margin tooltip-trigger">
          {{ 'INSTALLER_DETAILS.TITLE' | translate }}
            <span class="tooltip-btn" (click)="openTooltip('INSTALLER_DETAILS.TITLE','INSTALLER_DETAILS.INFO')"></span>
        </h1>
        <div class="button-wrapper">
          <div class="btn btn-transparent btn-small btn-icon"><mat-icon class="icon icon--small"></mat-icon></div>
        </div>
      </div>  
      
      <form [formGroup]="installerDetails" *ngIf="installerDetails" autocomplete="off">
        <div class="form-wrapper">
          <div class="input45 order1">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.NAME' | translate }}</mat-label>
              <input autocomplete="new-password" matInput formControlName="name "matInput formControlName="name" (focusout)="saveProperty('name')">
              <mat-error>{{ 'INSTALLER_DETAILS.ERROR.NAME' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="input35 order2">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.VAT' | translate }}</mat-label>
              <input 
                placeHolderCharacter=" "
                [prefix]="installerDetails.get('country')!.value + ' '" 
                [mask]="
                  installerDetails.get('country')!.value == 'BE' ? '0000.000.000' : 
                  (installerDetails.get('country')!.value == 'NL' ? '000000000S00' : 
                  (installerDetails.get('country')!.value == 'DK' ? '00 00 00 00' : 
                  'A*'))" 
                [showMaskTyped]="
                  installerDetails.get('country')!.value == 'BE' || 
                  installerDetails.get('country')!.value == 'NL' ||
                  installerDetails.get('country')!.value == 'DK' 
                  ? true : false" 
                autocomplete="new-password" matInput formControlName="vat" matInput formControlName="vat" (focusout)="saveProperty('vat')">
              <mat-error>{{ 'INSTALLER_DETAILS.ERROR.VAT' | translate }}</mat-error>
            </mat-form-field>
          </div>

        <div class="input35 order3">
          <mat-form-field class="full-width">
            <mat-label>{{ 'INSTALLER_DETAILS.PHONE' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="phone" (focusout)="saveProperty('phone')">
          </mat-form-field>
        </div>
        <div class="input55 order4">
          <mat-form-field class="full-width">
            <mat-label>{{ 'INSTALLER_DETAILS.EMAIL' | translate }}</mat-label>
            <input autocomplete="new-password" type="email" matInput formControlName="email" (focusout)="saveProperty('email')">
          </mat-form-field>
        </div>

          <div class="input55 order5">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.ADDRESS' | translate }}</mat-label>
              <input autocomplete="new-password" matInput formControlName="address" matInput formControlName="address" (focusout)="saveProperty('address')">
            </mat-form-field>
          </div>
          <div class="input55 order7">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.ADDRESS2' | translate }}</mat-label>
              <input autocomplete="new-password" matInput formControlName="address2" matInput formControlName="address2" (focusout)="saveProperty('address2')">
            </mat-form-field>
          </div>
          <div class="input35 order6">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.ZIP' | translate }}</mat-label>
              <input autocomplete="new-password" matInput formControlName="zip" [matAutocomplete]="zipAutocomplete" appAutocomplete="trigger"
                (keyup)="citySet = false"
                (focusout)="cities.length > 0 || citySet = true; cities.length > 0 || saveProperty('zip');">
                <!-- Only  save typed input if no cities were available for the automcomplete -->
            </mat-form-field>
            <mat-autocomplete #zipAutocomplete="matAutocomplete">
              <mat-option *ngFor="let city of filteredPostalCodes | async"
                (click)="currentCity = city; citySet = true; saveProperty('zip')"
                [value]="city.postalCode">
                {{city.postalCode + ' - ' + city.name }}

              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="input35 order8">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.CITY' | translate }}</mat-label>
              <input autocomplete="new-password" matInput formControlName="city" [matAutocomplete]="cityAutocomplete" appAutocomplete="trigger"
                (keyup)="citySet = false"
                (focusout)="cities.length > 0 || citySet = true; cities.length > 0 || saveProperty('city');">
                <!-- Only  save typed input if no cities were available for the automcomplete -->
                <mat-error>{{ 'INSTALLER_DETAILS.ERROR.CITY' | translate }}</mat-error>
            </mat-form-field>
            <mat-autocomplete #cityAutocomplete="matAutocomplete">
              <mat-option *ngFor="let city of filteredCities | async" 
                (click)="currentCity = city; citySet = true; saveProperty('city')" 
                [value]="city.name">
                {{city.postalCode + ' - ' + city.name }}

              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="input35 order9">
            <mat-form-field class="full-width">
              <mat-label>{{ 'INSTALLER_DETAILS.COUNTRY' | translate }}</mat-label>
              <mat-select autocomplete="new-password" formControlName="country" >
                <mat-option (click)="saveProperty('country')" *ngFor="let country of countries" [value]="country['code']">
                  {{ 'PREFERENCES.COUNTRIES.' +
                  country['code'] | uppercase | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>

    </div>

  </div>
</app-overview-page-template>
