import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { cities, City } from 'src/app/models/City';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  public cityList: BehaviorSubject<City[]> = new BehaviorSubject<City[]>([]);
  public clientCityList: BehaviorSubject<City[]> = new BehaviorSubject<City[]>([]);

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get CityList
   * @returns Observable list of cities
   */
  public getCities(countryCode: string): BehaviorSubject<City[]> {
    if(environment.mockData) {
      this.cityList.next(cities);
  } else {
    this.nikoApi.getCities(countryCode).pipe(take(1)).subscribe((r: City[]) => {
      //console.log(r);
      this.cityList.next(r);
    }); 
  }

    return this.cityList;
  }
  
  public getClientCities(countryCode: string): BehaviorSubject<City[]> {
    if(environment.mockData) {
      this.clientCityList.next(cities);
  } else {
    this.nikoApi.getCities(countryCode).pipe(take(1)).subscribe((r: City[]) => {
      //console.log(r);
      this.clientCityList.next(r);
    }); 
  }

    return this.clientCityList;
  }
}
