import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { COLORS } from './constants/Colors';
import { Locale, LocaleObject } from './models/Locale';
import { User } from './models/User';
import { NikoApiService } from './modules/api/niko-api.service';
import { APP_ROUTES } from './modules/app-routing/AppRoutes';
import { SessionStorageKeys } from './services/session-storage/session-storage-keys';
import { SessionStorageService } from './services/session-storage/session-storage.service';
import { SubjectService } from './services/subject/subject.service';
import { UserService } from './services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'Niko project wizard';

  private currentLang = environment.defaultLanguage;
  private currentCountry = environment.defaultCountry;

  public userData!: User;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private nikoApi: NikoApiService,
    private subjectService: SubjectService,
    private session: SessionStorageService,
    private userService: UserService,
    private router: Router
  ) {
    // Get session user preferences if available
    let sessionLang = this.session.getItem(SessionStorageKeys.LOCALE);
    let sessionCountry = this.session.getItem(SessionStorageKeys.COUNTRY);
    
    if(sessionLang && sessionLang != '') {
      this.currentLang = sessionLang;
    }
    this.nikoApi.locale = this.currentLang as Locale;

    if(sessionCountry && sessionCountry != '') {
      this.currentCountry = sessionCountry;
    }
    this.nikoApi.countryCode = this.currentCountry;

    this.getTranslation();
    
    this.getUserData();
  
    // Count navigations in session
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
        let navigations = parseInt(session.getItem(SessionStorageKeys.NAVIGATIONS)!, 10) || 0;
        navigations++;
        sessionStorage.setItem(SessionStorageKeys.NAVIGATIONS, navigations.toString());
    });
  }

    /**
   * Get User data to show current settings
   */
     private getUserData() {
      this.userData = this.userService.user.getValue();
  
      // If no user settings are available, redirect to login
      if(!this.userData.email) {
        this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
      }
     }
  

  /**
   * Get all available locales from Niko API and replace default language 
   * file used by ngx-translate by online language file
   */
  public getTranslation() {
    this.nikoApi.getLocales().subscribe((locales: LocaleObject[]) => {
      
      // clear current translation service languages
      this.translate.langs = [];

      locales.forEach((locale: LocaleObject) => {

        // save available languages to translation services
        this.translate.langs.push(locale.locale);

        if(locale.locale == this.currentLang) {
          this.http.get(locale.translations).subscribe(langFile => {
            locale.langFile = langFile;
            this.translate.setTranslation(locale.locale, langFile, false);
            this.translate.use(locale.locale);
          });
        }
      });
    });
  }
}
