import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { BuildingType } from 'src/app/models/BuildingType';
import { Project, Property } from 'src/app/models/Project';
import { Room, RoomInstance } from 'src/app/models/Room';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { RoomService } from 'src/app/services/room/room.service';
import { SpacesModalComponent } from './spaces-modal/spaces-modal.component';

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.scss']
})
export class SpacesComponent extends BaseComponent implements OnInit {

  public space = '';

  public projectId!: string;
  public projectData!: Project;

  public buildingType!: string;

  public rooms:Room[] = [];
  public roomData:RoomInstance[] = [];
  public selectedRooms:Room[] = [];
  public inactiveRooms:Room[] = [];

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    public roomService: RoomService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private translate: TranslateService
    ) {
      super();
    }

  ngOnInit(): void {
    this.SetSideBarContent();

    this.showRooms();
  }
  
  /**
   * Show project rooms
   */
    showRooms() {
    this.space = '';
        
    let projectId = this.activatedRoute.snapshot.params['project_id'];    
    this.projectId = projectId;

    this.projectService.getProjectById(projectId).subscribe(projectData => {

      this.projectData = projectData;
      this.roomData = projectData.rooms;

      if(projectData.properties.find((p:Property) => p.name == 'project.buildingtype')) {
        this.buildingType = projectData.properties.find((p:Property) => p.name == 'project.buildingtype')!.value;

        this.roomService.getRooms(this.buildingType).subscribe(roomList => {
          this.rooms = roomList;
  
          // Pass all room defaults to backend if projectdata rooms are still empty
          if(!this.roomData || this.roomData.length == 0) {
            this.InitRooms();
          } else {
            this.ReplaceDefaults();
          }
    
          this.selectedRooms = this.rooms.filter(x => parseInt(x.count) > 0).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          this.inactiveRooms = this.rooms.filter(x => parseInt(x.count) == 0).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        });
      }
    });
  }

  /**
   * Init room defaults on backend.
   */
  InitRooms() {
    let rooms = this.rooms;
    let roomString = '';

    // Array of roomIds
    let roomIds = rooms.map(room => room.xid); 

    // Object with unique roomIds and amount of occurance in room defaults
    const roomCount = roomIds.reduce((acc: any, curr: any) => {
      acc[curr] = acc[curr] || rooms.find(room => room.xid == curr)!.count;
      return acc;
    }, {}); 

    Object.keys(roomCount).forEach(roomId => {
      roomString = roomString + 'typeCount' + '=' + roomId + ':' + roomCount[roomId] + '&';
    })
    
    this.roomService.addRoom(this.projectId, roomString).subscribe(r => {
      this.roomData = r;
    });
  }
  
  /**
   * Replace room defaults with projectdata rooms
   */
  ReplaceDefaults() {
    let roomData = this.roomData;

    // Array of roomIds
    let currentRoomIds = this.roomData.map(room => room.typeXid); 

    // Object with unique roomIds and amount of occurance
    const roomCount = currentRoomIds.reduce((acc: any, curr: any) => (acc[curr] = (acc[curr] || 0) + 1, acc), {}); 

    this.rooms.forEach(room => {
      if(roomCount[room.xid] > 0) {
        room.count = roomCount[room.xid].toString();
      } else {
        room.count = '0';
      }
    })

    this.roomService.cachedRooms.next(this.rooms);
  }

  /**
   * Replace room defaults with projectdata rooms
   */
  addRoom(room: Room, amount: number) {
    room.count = (+room.count + amount).toString();

    let roomString = 'typeCount' + '=' + room.xid + ':' + amount + '&';

    this.roomService.addRoom(this.projectId, roomString).subscribe(r => {
      this.roomData = this.roomData.concat(r);
    });  
  }

  /**
   * Replace room defaults with projectdata rooms
   */
  removeRoom(room: Room) {
    if(+room.count > 0 ) {
      room.count = (+room.count - 1).toString();

      let roomId = '';
      //this.roomData.find(roomInstance => roomInstance.typeXid == room.xid)?.id;
      
      this.roomData.some((roomInstance, index) => {
        if (roomInstance.typeXid == room.xid) {
          this.roomData.splice(index, 1);
          roomId = roomInstance.id;
          return true;
        } else {
          return false
        }
      });
  
      this.roomService.removeRoom(this.projectId, roomId).subscribe(r => r);  
    } else {
      room.count = '0'
    };
  }

  /**
   * Open rooms modal
   */
   openDialog() {
    const dialogRef = this.dialog.open(SpacesModalComponent, {
      autoFocus: false, 
      data: {
        'projectId': this.projectId,
        'inactiveRooms': this.inactiveRooms,
        'roomData': this.roomData
      },
      width: '90vw',
      height: '90vh'
    });

    dialogRef.afterClosed().subscribe(r => {
      this.showRooms();
    });
  }
    /**
   * Open tooltip modal
   */
    public openTooltip(title: string = '', text: string) {
      const dialogRef = this.dialog.open(TooltipModalComponent, {
        autoFocus: false, 
        data: {
          'title': title,
          'text': text
        },
        maxWidth: '90vw',
        maxHeight: '90vh',
        minWidth: '775px'
      });
    }
  
  /**
   * Add dynamic content for sidebar navigation
   */
   SetSideBarContent() {
    this.sideBarContent = [];
    
    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_CLIENT_DETAILS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.DETAIL_CLIENT'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_BUILDINGTYPE'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.BUILDINGTYPE'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });
    
    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_UTILITIES'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.UTILITIES'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_ROOMS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.ROOMS'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.SUMMARY'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });
  }
}

