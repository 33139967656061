
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/components/base.component';
import { Room, RoomInstance } from 'src/app/models/Room';
import { Variable } from 'src/app/models/Variable';
import { RoomService } from 'src/app/services/room/room.service';

export interface DialogData {
  'projectId': string;
  'room': RoomInstance;
}

@Component({
  selector: 'app-room-modal',
  templateUrl: './room-modal.component.html',
  styleUrls: ['./room-modal.component.scss']
})
export class RoomModalComponent extends BaseComponent implements OnInit {

  public name!:UntypedFormControl; 

  constructor(
    public dialogRef: MatDialogRef<RoomModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private roomService: RoomService
  ) {
    super();
    dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.data));
   }

  ngOnInit(): void {
    console.log(this.data);
    this.name = new UntypedFormControl(this.data.room.name, [Validators.maxLength(30)]);
  }

  setRoomName() {
    if(this.name.valid) {
      let name = this.name.value.toLowerCase().replace(/([a-z])/i, (str:string, firstLetter:string) => firstLetter.toUpperCase());

      this.data.room.name = name;
  
      let roomString = 'id=' + this.data.room.id

      // Add room name to room string if available
      if(this.data.room.name && this.data.room.name != 'null') {
        roomString = roomString + '&name=' + this.data.room.name;
      }
      // Add existing finishing data for outdoor to room string
      if(this.data.room.finishingOutdoor && this.data.room.finishingOutdoor != 'null') {
        roomString = roomString + '&finishing_outdoor=' + this.data.room.finishingOutdoor;
      }
      // Add existing finishing data for indoor to room string
      if(this.data.room.finishingIndoor && this.data.room.finishingIndoor != 'null') {
        roomString = roomString + '&finishing_indoor=' + this.data.room.finishingIndoor;
      }
  
      this.roomService.editRoom(this.data.projectId, roomString);
    }

    this.dialogRef.close(this.data);
  }
}
