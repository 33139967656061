
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { APP_ROUTES } from '../../app-routing/AppRoutes';

@Injectable()
export class AuthenticationErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) {}

    /**
     * Redirect to login page on 401 http errors
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            catchError((error) => {

                /* console.log('AuthenticationErrorInterceptor', request);

                console.log(error);
                console.log(error instanceof HttpErrorResponse);
                console.log(error.status); */

                if (error instanceof HttpErrorResponse && error.status === 401) {
                    /* console.log('Intercepted http response with status 401'); */
                    const token = this.authService.getToken();
                    this.goToLogin();
                } else {
                    console.log('AUTHERROR');
                    this.router.navigate(['/', APP_ROUTES['ERROR'].value]);
                }
                
                return throwError(() => error);
            })
        );
    }
    
    private goToLogin() {
        this.authService.resetToken();
        this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
    }
}