import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { Finishing, FinishingRange, LOCATION } from 'src/app/models/Finishing';
import { InteriorType } from 'src/app/models/InteriorType';
import { Ternary } from 'src/app/models/Subject';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { FinishingService } from 'src/app/services/finishing/finishing.service';
import { InteriorTypeService } from 'src/app/services/interior-type/interior-type.service';
import { ProjectService } from 'src/app/services/project/project.service';

@Component({
  selector: 'app-selected',
  templateUrl: './selected.component.html',
  styleUrls: ['./selected.component.scss']
})
export class SelectedComponent extends BaseComponent implements OnInit {

  public projectId!: string;

  public interiorTypes!: InteriorType[];
  public finishings!: Finishing[];
  public currentIndex = 0;

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private finishingService: FinishingService,
    private interiorTypeService: InteriorTypeService,
    private projectService: ProjectService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private gaService: GoogleAnalyticsService
  ) { super(); }

  ngOnInit(): void {
    

    let finishings$ = this.finishingService.getFinishings();

    let projectId = this.activatedRoute.snapshot.params['project_id'];
    this.projectId = projectId;
    let projectData$ = this.projectService.getProjectById(projectId);

    // Join interior type list and projectdata after both observables have completed
    this.addSubscription(forkJoin({ finishings: finishings$, projectData: projectData$ }).subscribe(r => {

      let finishingRanges = r.finishings.filter(finishing => !finishing.location.includes(LOCATION.OUTDOOR));
      let finishingData = r.projectData.finishings;
      //console.log(finishingRanges);

      // Finishingranges
      /* finishingRanges.forEach(finishingRange => {
        let finishings = finishingRange.finishings;
        finishingRange.finishings = finishings && finishings.map(finishing => {
          console.log(finishingData);
          finishing.selected = (finishingData.find((data:Finishing) => data.xid === finishing.xid) && finishingData.find((data:Finishing) => data.xid === finishing.xid).selected) || Ternary.FALSE;
          return finishing;
        });
      }); */
      finishingRanges.forEach(range => {
        range.finishings?.forEach(finishing => {
          //finishing.title = range.title + ' - ' + finishing.title;

          if (finishingData && finishingData.length > 0) {
            finishing.selected = finishingData?.find((data: Finishing) => data.xid === finishing.xid)?.selected || Ternary.FALSE;
          } else {
            finishing.selected = Ternary.FALSE;
          }
        })
      });

      this.finishings = finishingRanges.flatMap(x => x.finishings!.filter(y => (y.selected === Ternary.TRUE || y.selected === Ternary.SELECTED)));
      //console.log(this.finishings);

      // Translate finishing data
      this.finishingService.getFinishingData(r.projectData.finishings, r.finishings)
      this.SetSideBarContent();
    }));
  }

  /**
   * Set finishing status
   * @param finishing finishing of which status changes
   * @param select Ternary on or selected
   */
  selectFinishing(finishing: Finishing) {
    let finishingString = '';

    let currentGenerals = this.finishings!.filter(finishing => finishing.selected == Ternary.SELECTED);

    if (currentGenerals && currentGenerals.length > 0) {
      currentGenerals.forEach(currentGeneral => {
        if (finishing.xid != currentGeneral.xid) {
          currentGeneral.selected = Ternary.TRUE;
          finishingString += Ternary.TRUE + "=" + currentGeneral.xid + ':indoor' + '&'
        }
      });
    }


    this.gaService.event('set_general', 'Finishing Status', finishing.title);

    finishing.selected = Ternary.SELECTED;
    finishingString += Ternary.SELECTED + "=" + finishing.xid + ':indoor';

    this.finishingService.selectFinishings(this.projectId, finishingString);
  }

      /**
   * Open tooltip modal
   */
      public openTooltip(title: string = '', text: string) {
        const dialogRef = this.dialog.open(TooltipModalComponent, {
          autoFocus: false, 
          data: {
            'title': title,
            'text': text
          },
          maxWidth: '90vw',
          maxHeight: '90vh',
          minWidth: '775px'
        });
      }

  /**
   * Add dynamic content for sidebar navigation
   */
  SetSideBarContent() {
    this.sideBarContent = [];
    
    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_STYLES'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.STYLES'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_CATALOG'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.CATALOG'),
      complete: true,
      active: false,
      available: true,
      subitem: true
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_RECOMMENDED'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.RECOMMENDED'),
      complete: true,
      active: false,
      available: true,
      subitem: true
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_GENERAL_INDOOR'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.INDOOR'),
      complete: false,
      active: true,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0,
      subitem: false
    });

    console.log(this.finishingService.generalFinishing);
    console.log(this.finishingService.generalFinishing !== undefined);

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_GENERAL_OUTDOOR'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.OUTDOOR'),
      complete: false,
      active: false,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0 && this.finishingService.generalFinishing !== undefined,
      subitem: false
    });

    console.log(this.finishingService.outdoorFinishing);
    console.log(this.finishingService.outdoorFinishing !== undefined);

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_SUMMARY'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.SUMMARY'),
      complete: false,
      active: false,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0 && this.finishingService.generalFinishing !== undefined && this.finishingService.outdoorFinishing !== undefined,
      subitem: false
    });
  }
}
