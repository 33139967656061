<app-project-page-template 
[breadcrumb]="'PROJECT_FINISHINGS.SELECTED.BREADCRUMB' | translate" 
[breadcrumbPrev]="'PROJECT_FINISHINGS.OUTDOOR.BREADCRUMB_PREV' | translate" 
[routerlinkPrev]="'../../' + APP_ROUTES['FINISHING_GENERAL_INDOOR'].value" 
[breadcrumbNext]="'PROJECT_FINISHINGS.OUTDOOR.BREADCRUMB_NEXT' | translate" 
[routerlinkNext]="'../../' + APP_ROUTES['FINISHING_SUMMARY'].value"
[sideBarContent]="sideBarContent">
  
  <div id="content" *ngIf="outdoorFinishings && outdoorFinishings.length > 0">
  
    <h1>{{ 'PROJECT_FINISHINGS.OUTDOOR.TITLE' | translate }}</h1>

    <ng-container *ngFor="let range of outdoorRanges" >
      <hr>

      <h3 class="margin-b-60 dark-grey">{{ range.title }}</h3>
      <p class="margin-b dark-grey" [innerHTML]="range.description"></p>

      <div class="finishing-container">

        <div class="content-container relative" *ngFor="let finishing of range.finishings" >
          <button class="btn-selected btn btn-primary btn-small default" *ngIf="finishing.selected == Ternary.SELECTED">
            {{'PROJECT_FINISHINGS.OUTDOOR.LABEL_GENERAL' | translate}}
          </button>
  
          <div class="finishing-wrapper">
            <img class="finishing-img" 
            [src]="finishing.images ? finishing.images[0] : ''">
            <div class="text-wrapper">
              <p class="font-l margin-b-20">{{ finishing.title }}</p>
              <div class="check-button pointer">
                <div (click)="selectFinishing(finishing)" class="btn-icon">
                  <mat-icon *ngIf="finishing.selected == Ternary.SELECTED"  [svgIcon]="'radio'" class="icon"></mat-icon>
                  <mat-icon *ngIf="finishing.selected != Ternary.SELECTED" [svgIcon]="'radio_unchecked'" class="icon"></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
        
    </ng-container>

  </div>
</app-project-page-template>