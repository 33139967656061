import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { NikoSystem } from 'src/app/models/System';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  public system:NikoSystem = NikoSystem.CLASSIC;

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get RoomList
   * @returns Observable list of Rooms
   */
  public getInstallTypes() {
    return this.nikoApi.getInstallTypes().pipe(
      map((InstallTypeList) => {
        return InstallTypeList;
      })
    );
  }
}