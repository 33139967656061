import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { Subject, Ternary } from 'src/app/models/Subject';
import { APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { SubjectService } from 'src/app/services/subject/subject.service';

export interface DialogData {
  'topic_id': string;
}

@Component({
  selector: 'app-topic-detail',
  templateUrl: './topic-detail.component.html',
  styleUrls: ['./topic-detail.component.scss']
})
export class TopicDetailComponent extends BaseComponent implements OnInit {

  public selected = true;

  public heating = false;
  public heating_selected = false;

  public subjects:Subject[] = [];
  public selectedSubjects:Subject[] = [];
  public currentSubject!:Subject;
  public previousSubject!:Subject;
  public nextSubject!:Subject;

  constructor(
    private subjectService: SubjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<TopicDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      super();
  }

  ngOnInit(): void {
    // This component needs to be reused when navigating to another topic/subject
    this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };

    this.addSubscription(this.subjectService.cachedSubjects.pipe(debounceTime(500), distinctUntilChanged()).subscribe((r:Subject[]) => {  

      // Ordered array of all subjects
      this.subjects = r;
      
      // Subjects which are selected by default
      this.selectedSubjects = r.filter(x => x.selected === Ternary.TRUE);

      // Current subject
      let currentSubjectId = this.data['topic_id'];
      let currentIndex: number;
      this.currentSubject = r.filter((x,i) => {
        // Array index of current subject is required to get next and previous subjects
        if (x.xid === currentSubjectId) { currentIndex = i }; 
        return x.xid === currentSubjectId;
      })[0];

      // Previous subject in array
      let previousIndex = currentIndex! - 1;
      this.previousSubject = this.subjects[previousIndex];

      // Next subject in array
      let nextIndex = currentIndex! + 1;
      this.nextSubject = this.subjects[nextIndex];
    }));
  }
}