import { Injectable } from '@angular/core';
import { SessionStorageKeys } from './session-storage-keys';

/**
 * Service that acts as interface to use the session storage
 * @author thijsvm
 */
@Injectable({providedIn: 'root'})
export class SessionStorageService {

    private storage: Storage = sessionStorage;

    constructor() {

    }

    /**
     * Save a string to session storage.
     * @param key to identify the value
     * @param value to store
     */
    public saveItem(key: SessionStorageKeys, value: string): void {
        this.storage.setItem(key, value);
    }

    /**
     * Save an object to session storage.
     * @param key to identify the value
     * @param value to store
     */
    public saveObject<T>(key: SessionStorageKeys, value: T): void {
        this.storage.setItem(key, JSON.stringify(value));
    }

    /**
     * Retrieve a nullable string from session storage.
     * @param key associated to the value
     * @returns the value associated to the provided key or null when key not found
     */
    public getItem(key: SessionStorageKeys): string | null{
        return this.storage.getItem(key);
    }

    /**
     * Retrieve a nullable object of type T from session storage.
     * @param key associated to the value
     * @returns the value associated to the provided key or null when key not found
     */
    public getObject<T>(key: SessionStorageKeys): T | null{
        let value = this.storage.getItem(key);
        if (value) {
            return JSON.parse(value) as T;
        }
        return null;
    }

    /**
     * Retrieve a nullable object of type T from session storage.
     * @param key associated to the value
     * @returns the value associated to the provided key or null when key not found
     */
    public remove(key: SessionStorageKeys): void {
        this.storage.removeItem(key);
    }

    /**
     * Remove a value associated to specified key.
     * @param key associated to the value
     */
    public clearStorage(): void {
        this.storage.clear();
    }
}
