import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Project, ProjectState } from 'src/app/models/Project';
import { Calculation, CalculationResult } from 'src/app/models/System';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { isHttpDownloadProgressEvent, isHttpResponse } from 'src/app/util/Util';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public projectListState:ProjectState = ProjectState.ACTIVE;
  
  public calculation?: Calculation;
  public staleCalculation?: Calculation;

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Retrieve Project by id
   * @returns Project data
   */
  public getProjectById(id: string): Observable<Project> {
    return this.nikoApi.getProjectById(id);
  }

  /**
   * Get project list
   */
    public getProjects(): Observable<any> {
    return this.nikoApi.getProjects();
  }

  /**
   * Create project
   */
  public createProject(): Observable<any> {
    return this.nikoApi.createProject();
  }


  /**
   * Calculate Project by id
   * @returns Calculation log
   */
   public calculateProjectLogById(id: string): Observable<Calculation> {
    return this.nikoApi.calculateProjectLogById(id).pipe(
      map((e: HttpEvent<string>) => {
        if(isHttpDownloadProgressEvent(e)) {
          /* console.log('calculateProjectLogById()');
          console.log('intermediate HTTP event result:', e); */

          if(e.partialText) {
            try {
              let progressString: string = '['+e.partialText.toString().split('}{').join('},{')+']';
              //console.log('intermediate HTTP event progress string:', progressString);

              let progressArray: Calculation[] = JSON.parse(progressString) as Calculation[];
              //console.log('intermediate HTTP event parsed progress item array:', progressArray);

              let progress: Calculation = progressArray[progressArray.length - 1];
              //console.log('intermediate HTTP event progress item:', progress);

              return progress;
            } catch (error) {
              //console.log('incomplete json');
            }
          }
        } 
        
        return e as Calculation;
      }));
  }

  /**
   * Calculate Project Results by id
   * While calculating, the client wil keep receiving intermediate httpevents about the progress
   * @returns Calculation
   */
   public calculateProjectResultsById(id: string): Observable< Calculation> {
    return this.nikoApi.calculateProjectResultsById(id).pipe(
      map((e: HttpEvent<string>) => {
        if(isHttpDownloadProgressEvent(e)) {
          /* console.log('calculateProjectResultsById()');
          console.log('intermediate HTTP event result:', e); */

          if(e.partialText) {
            try {
              let progressString: string = '['+e.partialText.toString().split('}{').join('},{')+']';
              //console.log('intermediate HTTP event progress string:', progressString);

              let progressArray: Calculation[] = JSON.parse(progressString) as Calculation[];
              //console.log('intermediate HTTP event parsed progress item array:', progressArray);

              let progress: Calculation = progressArray[progressArray.length - 1];
              //console.log('intermediate HTTP event progress item:', progress);

              return progress;
            } catch (error) {
              //console.log('incomplete json');
            }
          }
        } 
        
        return e as Calculation;
      }));
  }
}
