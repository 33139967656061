export enum COLORS {
    white =         '#FFFFFF',
    grey1 =         '#F6F6F6',
    grey2 =         '#E5E5E5',
    grey5 =         '#CCCCCC',
    grey4 =         '#B2B2B2',
    grey3 =         '#999999',
    grey6 =         '#7F7F7F',
    grey7 =         '#4C4C4C',
    black =         '#1A1A1A',
    red =           '#FD222C',
    yellow =        '#FFC73C'
}