import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Locale, LocaleObject } from 'src/app/models/Locale';
import { ProjectModule, Property } from 'src/app/models/Project';
import { User } from 'src/app/models/User';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SessionStorageKeys } from 'src/app/services/session-storage/session-storage-keys';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { UserService } from 'src/app/services/user/user.service';
import { BaseComponent } from '../base.component';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { TooltipModalComponent } from '../modals/tooltip-modal/tooltip-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  @Output() loggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();

  public loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
    terms: new UntypedFormControl('', [Validators.requiredTrue])
  });

  public formChecked = false;
  public accountNotFound = false;

  public autoLogin = false;
  public showLoginForm = false;

  public languages: string[] = [];
  public language: UntypedFormControl = new UntypedFormControl(this.nikoApi.locale);

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private userService: UserService,
    private settingsService: SettingsService,
    private session: SessionStorageService,
    private localStorage: LocalStorageService,
    private nikoApi: NikoApiService,
    private router: Router,
    private location: Location,
    private gaService: GoogleAnalyticsService,
    private dialog: MatDialog
  ) {
    super();
    // Try to login with session data
    let authorizationData = this.session.getItem(SessionStorageKeys.USER);
    console.log('authdata from sessions: ', authorizationData);

    if(authorizationData) {

      let user = new User;
      console.log('autologin');

      this.addSubscription(this.nikoApi.logout().subscribe({
        next: () => {
          this.addSubscription(this.userService.login(user).subscribe({
            next: (data) => {
              this.autoLogin = true;
              this.applyUserPreferences(data);
            },
            error: (e) => {
              console.log('login failed, show form');
              this.showLoginForm = true
            }
          }));
        },
        error: () => {
          this.addSubscription(this.userService.login(user).subscribe({
            next: (data) => {
              this.autoLogin = true;
              this.applyUserPreferences(data);
            },
            error: (e) => {
              console.log('login failed, show form');
              this.showLoginForm = true
            }
          }));
        }
      }));
    } else {
      // get languages from backend
      this.nikoApi.getLocales().subscribe((r:LocaleObject[]) => {
        r.forEach(locale => {
          this.languages.push(locale.locale);
        })

        // save available languages to translation services
        this.translate.langs = this.languages;

        // Set up language change handler
        this.language.valueChanges.subscribe((value: string) => {
          //console.log(value);
          this.changeLang(value);
        });

        // show login form
        this.showLoginForm = true;
      });
    }
  }

  ngOnInit(): void {
    this.gaService.set({'project_id': null});
  }

  /**
   * Temporary login through basic authentication
   */
  public login(e:Event) {
    e.preventDefault();

    this.userService.logout();

    this.formChecked = true;

    let user = new User;
    user.username = this.loginForm.get('username')?.value;
    user.password = this.loginForm.get('password')?.value;
    
    if(this.loginForm.valid) {
      this.addSubscription(this.userService.login(user).subscribe({
        next: (data) => {
          this.applyUserPreferences(data);
        },
        error: (e) => this.accountNotFound = true
      }));
    }
  }

  /**
   * Apply the users preferences to the application
   * @param data user profile data
   */
  public applyUserPreferences(data: any) {
    if(data && data.settings && data.settings.length > 0) {

      let user = this.userService.user.getValue();
      user.settings = data.settings ? data.settings : [];
      user.firstname = data.firstname;
      user.lastname = data.lastname;
      user.email = data.email;
      this.userService.user.next(user);

      let language = this.userSettingsValue(data.settings, 'locale');

      // Save chosen language as user language
      this.settingsService.setSetting(['user.locale:' + this.nikoApi.locale]);

      let countryCode = this.userSettingsValue(data.settings, 'country');
      if(language && language != '') {
        this.nikoApi.locale = language as Locale;
        this.session.saveItem(SessionStorageKeys.LOCALE, language);
  
        this.addSubscription(this.nikoApi.getLocales().subscribe((locales: LocaleObject[]) => {
          locales.forEach((locale: LocaleObject) => {
            if(locale.locale == language) {
              this.addSubscription(this.http.get(locale.translations).subscribe(langFile => {
                locale.langFile = langFile;
                this.translate.setTranslation(locale.locale, langFile, false);
                this.translate.use(locale.locale);
              }));
            }
          });
        }));
      } else {
        // Save default language
        this.settingsService.setSetting(['user.locale:' + this.nikoApi.locale]);
      }
      if(countryCode && countryCode != '') {
        // apply user country to app
        this.nikoApi.countryCode = countryCode;
        this.session.saveItem(SessionStorageKeys.COUNTRY, countryCode);
      } else {
        // Save default country as user country
        this.settingsService.setSetting(['user.country:' + this.nikoApi.countryCode]);
      }
    } else {
      let user = this.userService.user.getValue();
      let localeProperty = new Property; localeProperty.name = 'user.locale'; localeProperty.value = this.nikoApi.locale;
      let countryProperty = new Property; countryProperty.name = 'user.country'; countryProperty.value = this.nikoApi.countryCode;
      user.settings = [];
      user.settings.push(localeProperty, countryProperty)
      this.userService.user.next(user);

      this.settingsService.setSetting(['user.locale:' + this.nikoApi.locale, 'user.country:' + this.nikoApi.countryCode]);        
      
      this.session.saveItem(SessionStorageKeys.LOCALE, this.nikoApi.locale);
      this.session.saveItem(SessionStorageKeys.COUNTRY, this.nikoApi.countryCode);
    }

    this.toggleOnboardingOnProfile(data);
  }

  /**
   * Get value for a specific user setting
   * @param settings Settings to be checked
   * @param name name of the required setting
   * @returns value of the required setting
   */
  public userSettingsValue(settings: Property[], name: string): string | undefined {
    if(settings) {
        return settings.find(p => p.name == 'user.' + name)?.value;
    }
      
    return '';
  }

  /**
   * Get all available locales from Niko API, replace selected language 
   * file used by ngx-translate by online language file and load the selected language
   * * @param language language selected
   */
  public changeLang(language: string): void {
    console.log(language);
    console.log(this.translate.langs);
    if (this.translate.langs.indexOf(language) > -1) {
      
      this.nikoApi.locale = language as Locale;
      this.session.saveItem(SessionStorageKeys.LOCALE, language);

      this.nikoApi.getLocales().subscribe((locales: LocaleObject[]) => {
        locales.forEach((locale: LocaleObject) => {
          if(locale.locale == language) {
            this.http.get(locale.translations).subscribe(langFile => {
              locale.langFile = langFile;
              this.translate.setTranslation(locale.locale, langFile, false);
              this.translate.use(locale.locale);
            });
          }
        });
      });
    }
  }

    /**
   * Open tooltip modal
   */
    public openTooltip(title: string = '', text: string) {
      const dialogRef = this.dialog.open(TooltipModalComponent, {
        autoFocus: false, 
        data: {
          'title': title,
          'text': text
        },
        maxWidth: '90vw',
        maxHeight: '90vh',
        minWidth: '775px'
      });
    }

    /**
   * toggle hiding onboarding for user based on user profile settings
   */
     public toggleOnboardingOnProfile(data: any) {

      // Set onboarding settings in session if available in user settings
      let onboardingPropertyArray: Property[] = [];
      
      if(this.userSettingsValue(data.settings, 'onboarding') == 'hidden') {
        let onboardingProperty = new Property;
        onboardingProperty.name = 'user.onboarding';
        onboardingProperty.value = 'hidden';
        onboardingPropertyArray.push(onboardingProperty);
      }

      if(this.userSettingsValue(data.settings, 'onboarding.' + ProjectModule.INTAKE) == 'hidden') {
        let onboardingProperty = new Property;
        onboardingProperty.name = 'user.onboarding.' + ProjectModule.INTAKE;
        onboardingProperty.value = 'hidden';
        onboardingPropertyArray.push(onboardingProperty);
      }

      if(this.userSettingsValue(data.settings, 'onboarding.' + ProjectModule.FUNCTIONALITIES) == 'hidden') {
        let onboardingProperty = new Property;
        onboardingProperty.name = 'user.onboarding.' + ProjectModule.FUNCTIONALITIES;
        onboardingProperty.value = 'hidden';
        onboardingPropertyArray.push(onboardingProperty);
      }

      if(this.userSettingsValue(data.settings, 'onboarding.' + ProjectModule.FINISHING) == 'hidden') {
        let onboardingProperty = new Property;
        onboardingProperty.name = 'user.onboarding.' + ProjectModule.FINISHING;
        onboardingProperty.value = 'hidden';
        onboardingPropertyArray.push(onboardingProperty);
      }

      if(this.userSettingsValue(data.settings, 'onboarding.' + ProjectModule.ROOMCONFIGURATION) == 'hidden') {
        let onboardingProperty = new Property;
        onboardingProperty.name = 'user.onboarding.' + ProjectModule.ROOMCONFIGURATION;
        onboardingProperty.value = 'hidden';
        onboardingPropertyArray.push(onboardingProperty);
      }

      if(this.userSettingsValue(data.settings, 'onboarding.' + ProjectModule.RESULTS) == 'hidden') {
        let onboardingProperty = new Property;
        onboardingProperty.name = 'user.onboarding.' + ProjectModule.RESULTS;
        onboardingProperty.value = 'hidden';
        onboardingPropertyArray.push(onboardingProperty);
      }

      if(this.userSettingsValue(data.settings, 'onboarding.projectstatus') == 'hidden') {
        let onboardingProperty = new Property;
        onboardingProperty.name = 'user.onboarding.projectstatus';
        onboardingProperty.value = 'hidden';
        onboardingPropertyArray.push(onboardingProperty);
      }
            
      this.localStorage.saveObject(LocalStorageKeys.ONBOARDING, onboardingPropertyArray);

      // Check if user should be directed to onboarding to complete user profile
      if(this.autoLogin) {
        const totalNavigations = parseInt(sessionStorage.getItem(SessionStorageKeys.NAVIGATIONS)!, 10);
        if (totalNavigations > 3) { 
            this.location.back();    // Or history.back()
        } else {
          this.router.navigate(['/', APP_ROUTES['PROJECTLIST'].value]);
        }
      } else if(this.userSettingsValue(data.settings, 'onboarding') == 'hidden' && 
        this.userSettingsValue(data.settings, 'name') &&
        this.userSettingsValue(data.settings, 'vat') &&
        this.userSettingsValue(data.settings, 'email') &&
        this.userSettingsValue(data.settings, 'zip') &&
        this.userSettingsValue(data.settings, 'city') &&
        this.userSettingsValue(data.settings, 'country')) 
      {
        this.router.navigate(['/', APP_ROUTES['PROJECTLIST'].value]);
      } else {
        this.router.navigate(['/', APP_ROUTES['ONBOARDING'].value]);
      }
    }
}
