import { Component, Input, OnInit } from '@angular/core';
import { COLORS } from 'src/app/constants/Colors';
import { AppRouteObject } from 'src/app/modules/app-routing/AppRoutes';

@Component({
  selector: 'app-project-page-template',
  templateUrl: './project-page-template.component.html',
  styleUrls: ['./project-page-template.component.scss']
})
export class ProjectPageTemplateComponent implements OnInit {

  public COLORS = COLORS;

  @Input() functionalitiesPage: boolean = false;

  @Input() breadcrumb: string = '';
  @Input() breadcrumbPrev: string = '';
  @Input() breadcrumbNext: string = '';
  @Input() routerlinkPrev: string = '';
  @Input() routerlinkNext: string = '';

  @Input() sideBarContent: AppRouteObject[] = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
