import { Ternary } from "./Subject";

export class FinishingRange {
    xid!: string;
    title!: string;
    description!: string | null;
    finishings!: Finishing[] | null;
    location!: LOCATION[];
}

export class Finishing {
    xid!: string;
    title!: string;
    primaryColour!: FinishingColour;
    secondaryColour!: FinishingColour;
    images!: string[] | null;
    selected?: Ternary;
    default!: boolean;
    usedInRoom?: boolean;
}

export class FinishingColour {
    number!: string;
    image!: string;
}

export enum LOCATION {
    INDOOR = 'indoor',
    OUTDOOR = 'outdoor'
}

export const finishings: FinishingRange[] =
[
    {
        "xid": "33552",
        "title": "Niko Original",
        "description": "<p>Are you attracted to <strong>soft lines</strong> and <strong>mild colours</strong>? Then Niko Original is just what you are looking for. You are choosing a finish with a discreet presence.</p>\n",
        "location": [
            LOCATION.INDOOR
        ],
        "finishings": [
            {
                "xid": "59641",
                "title": "light grey",
                "primaryColour": {
                    "number": "102",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560211__DSA-Icons/Color_102.jpg"
                },
                "secondaryColour": {
                    "number": "102",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560211__DSA-Icons/Color_102.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/102/image-thumb__577788__DSA-Finishings/10210261105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/102/image-thumb__577790__DSA-Finishings/10210261105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61063",
                "title": "greige",
                "primaryColour": {
                    "number": "104",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560214__DSA-Icons/Color_104.jpg"
                },
                "secondaryColour": {
                    "number": "104",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560214__DSA-Icons/Color_104.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/104/image-thumb__577794__DSA-Finishings/10410461105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/104/image-thumb__577796__DSA-Finishings/10410461105.png"
                ],
                
                "default": false
            },
            {
                "xid": "59639",
                "title": "cream",
                "primaryColour": {
                    "number": "100",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560209__DSA-Icons/Color_100.jpg"
                },
                "secondaryColour": {
                    "number": "100",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560209__DSA-Icons/Color_100.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/100/image-thumb__577776__DSA-Finishings/10010061105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/100/image-thumb__577778__DSA-Finishings/10010061105.png"
                ],
                
                "default": false
            },
            {
                "xid": "59640",
                "title": "white",
                "primaryColour": {
                    "number": "101",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560210__DSA-Icons/Color_101.jpg"
                },
                "secondaryColour": {
                    "number": "101",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560210__DSA-Icons/Color_101.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/101/image-thumb__577781__DSA-Finishings/10110161105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/101/image-thumb__577783__DSA-Finishings/10110161105.png"
                ],
                
                "default": false
            }
        ]
    },
    {
        "xid": "33553",
        "title": "Niko Pure",
        "description": "<p>Do you love an <strong>ultra-sleek design</strong> in timeless materials? Then you will love the refined look of Niko Pure. A series made from special and authentic materials, including bamboo, stainless steel, Bakelite® and aluminium. Your guarantee of an elegant and sustainable finish. Affordable top design.</p>\n",
        "location": [
            LOCATION.INDOOR
        ],
        "finishings": [
            {
                "xid": "59646",
                "title": "stainless steel on anthracite",
                "primaryColour": {
                    "number": "150",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560225__DSA-Icons/Color_150.jpg"
                },
                "secondaryColour": {
                    "number": "122",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560220__DSA-Icons/Color_122.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/150/image-thumb__577883__DSA-Finishings/15012261105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/150/image-thumb__577886__DSA-Finishings/15012261105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61064",
                "title": "alu grey",
                "primaryColour": {
                    "number": "155",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560231__DSA-Icons/Color_155.jpg"
                },
                "secondaryColour": {
                    "number": "122",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560220__DSA-Icons/Color_122.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/155/image-thumb__577916__DSA-Finishings/15512261105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/155/image-thumb__577921__DSA-Finishings/15512261105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61065",
                "title": "alu steel grey",
                "primaryColour": {
                    "number": "220",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560259__DSA-Icons/Color_220.jpg"
                },
                "secondaryColour": {
                    "number": "220",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560259__DSA-Icons/Color_220.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/220/image-thumb__577974__DSA-Finishings/22022061105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/220/image-thumb__577973__DSA-Finishings/22022061105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61066",
                "title": "alu black",
                "primaryColour": {
                    "number": "158",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560238__DSA-Icons/Color_158.jpg"
                },
                "secondaryColour": {
                    "number": "154",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560229__DSA-Icons/Color_154.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/158/image-thumb__577939__DSA-Finishings/15815461105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/158/image-thumb__577945__DSA-Finishings/15815461105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61067",
                "title": "steel white",
                "primaryColour": {
                    "number": "154",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560229__DSA-Icons/Color_154.jpg"
                },
                "secondaryColour": {
                    "number": "154",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560229__DSA-Icons/Color_154.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/154/image-thumb__577906__DSA-Finishings/15415461105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/154/image-thumb__577908__DSA-Finishings/15415461105.png"
                ],
                
                "default": false
            },
            {
                "xid": "59647",
                "title": "alu gold",
                "primaryColour": {
                    "number": "221",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560260__DSA-Icons/Color_221.jpg"
                },
                "secondaryColour": {
                    "number": "221",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560260__DSA-Icons/Color_221.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/221/image-thumb__577982__DSA-Finishings/22122161105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/221/image-thumb__577980__DSA-Finishings/22122161105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61069",
                "title": "steel champagne",
                "primaryColour": {
                    "number": "157",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560235__DSA-Icons/Color_157.jpg"
                },
                "secondaryColour": {
                    "number": "157",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560235__DSA-Icons/Color_157.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/157/image-thumb__577929__DSA-Finishings/15715761105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/157/image-thumb__577934__DSA-Finishings/15715761105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61068",
                "title": "steel black",
                "primaryColour": {
                    "number": "161",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560241__DSA-Icons/Color_161.jpg"
                },
                "secondaryColour": {
                    "number": "161",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560241__DSA-Icons/Color_161.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/161/image-thumb__577955__DSA-Finishings/16116161105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/161/image-thumb__577956__DSA-Finishings/16116161105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61070",
                "title": "stainless steel on white",
                "primaryColour": {
                    "number": "250",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__577764__DSA-Icons/Color_250.jpg"
                },
                "secondaryColour": {
                    "number": "154",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560229__DSA-Icons/Color_154.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/250/image-thumb__577997__DSA-Finishings/25015461105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/250/image-thumb__577999__DSA-Finishings/25015461105.png"
                ],
                
                "default": false
            },
            {
                "xid": "59645",
                "title": "dark bamboo on gold",
                "primaryColour": {
                    "number": "257",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560274__DSA-Icons/Color_257.jpg"
                },
                "secondaryColour": {
                    "number": "221",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560260__DSA-Icons/Color_221.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/257/image-thumb__578006__DSA-Finishings/25722161105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/257/image-thumb__578007__DSA-Finishings/25722161105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61073",
                "title": "liquid snow white",
                "primaryColour": {
                    "number": "241",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560271__DSA-Icons/Color_241.jpg"
                },
                "secondaryColour": {
                    "number": "101",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560210__DSA-Icons/Color_101.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/241/image-thumb__577989__DSA-Finishings/24110161105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/241/image-thumb__577987__DSA-Finishings/24110161105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61072",
                "title": "liquid black",
                "primaryColour": {
                    "number": "242",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__577763__DSA-Icons/Color_242.jpg"
                },
                "secondaryColour": {
                    "number": "200",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560255__DSA-Icons/Color_200.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/242/image-thumb__577996__DSA-Finishings/24220061105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/242/image-thumb__577994__DSA-Finishings/24220061105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61074",
                "title": "natural red",
                "primaryColour": {
                    "number": "152",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560226__DSA-Icons/Color_152.jpg"
                },
                "secondaryColour": {
                    "number": "122",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560220__DSA-Icons/Color_122.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/152/image-thumb__577899__DSA-Finishings/15212261105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/152/image-thumb__577902__DSA-Finishings/15212261105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61071",
                "title": "Bakelite® piano black",
                "primaryColour": {
                    "number": "200",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560255__DSA-Icons/Color_200.jpg"
                },
                "secondaryColour": {
                    "number": "200",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560255__DSA-Icons/Color_200.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/200/image-thumb__577961__DSA-Finishings/20020061105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/200/image-thumb__577960__DSA-Finishings/20020061105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61075",
                "title": "natural soft grey",
                "primaryColour": {
                    "number": "159",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560239__DSA-Icons/Color_159.jpg"
                },
                "secondaryColour": {
                    "number": "122",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560220__DSA-Icons/Color_122.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/159/image-thumb__577949__DSA-Finishings/15912261105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/159/image-thumb__577951__DSA-Finishings/15912261105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61076",
                "title": "bamboo",
                "primaryColour": {
                    "number": "156",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560233__DSA-Icons/Color_156.jpg"
                },
                "secondaryColour": {
                    "number": "122",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560220__DSA-Icons/Color_122.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/156/image-thumb__577924__DSA-Finishings/15612261105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/156/image-thumb__577925__DSA-Finishings/15612261105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61077",
                "title": "dark bamboo on anthracite",
                "primaryColour": {
                    "number": "256",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560273__DSA-Icons/Color_256.jpg"
                },
                "secondaryColour": {
                    "number": "122",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560220__DSA-Icons/Color_122.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/256/image-thumb__578005__DSA-Finishings/25612261105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/256/image-thumb__578001__DSA-Finishings/25612261105.png"
                ],
                
                "default": false
            }
        ]
    },
    {
        "xid": "33554",
        "title": "Niko Intense",
        "description": "<p>Niko Intense, a series that you need to feel. Niko Intense is all about touch. The <strong>sleek shape</strong> and <strong>soft lines</strong> radiate intensity. With these switches, you are choosing a distinctly warm character.</p>\n",
        "location": [
            LOCATION.INDOOR
        ],
        "finishings": [
            {
                "xid": "61061",
                "title": "matt black",
                "primaryColour": {
                    "number": "130",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560224__DSA-Icons/Color_130.jpg"
                },
                "secondaryColour": {
                    "number": "161",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560241__DSA-Icons/Color_161.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/130/image-thumb__577876__DSA-Finishings/13016161105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/130/image-thumb__577879__DSA-Finishings/13016161105.png"
                ],
                
                "default": true
            },
            {
                "xid": "59644",
                "title": "anthracite",
                "primaryColour": {
                    "number": "122",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560220__DSA-Icons/Color_122.jpg"
                },
                "secondaryColour": {
                    "number": "122",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560220__DSA-Icons/Color_122.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/122/image-thumb__577854__DSA-Finishings/12212261105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/122/image-thumb__577858__DSA-Finishings/12212261105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61060",
                "title": "dark brown",
                "primaryColour": {
                    "number": "124",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560222__DSA-Icons/Color_124.jpg"
                },
                "secondaryColour": {
                    "number": "124",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560222__DSA-Icons/Color_124.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/124/image-thumb__577868__DSA-Finishings/12412461105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/124/image-thumb__577871__DSA-Finishings/12412461105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61059",
                "title": "bronze",
                "primaryColour": {
                    "number": "123",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560221__DSA-Icons/Color_123.jpg"
                },
                "secondaryColour": {
                    "number": "123",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560221__DSA-Icons/Color_123.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/123/image-thumb__577863__DSA-Finishings/12312361105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/123/image-thumb__577866__DSA-Finishings/12312361105.png"
                ],
                
                "default": false
            },
            {
                "xid": "59643",
                "title": "sterling",
                "primaryColour": {
                    "number": "121",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560218__DSA-Icons/Color_121.jpg"
                },
                "secondaryColour": {
                    "number": "121",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560218__DSA-Icons/Color_121.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/121/image-thumb__577849__DSA-Finishings/12112161105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/121/image-thumb__577852__DSA-Finishings/12112161105.png"
                ],
                
                "default": false
            },
            {
                "xid": "59642",
                "title": "white",
                "primaryColour": {
                    "number": "120",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560217__DSA-Icons/Color_120.jpg"
                },
                "secondaryColour": {
                    "number": "101",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560210__DSA-Icons/Color_101.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/120/image-thumb__577839__DSA-Finishings/12010161105P.png",
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/120/image-thumb__577844__DSA-Finishings/12010161105.png"
                ],
                
                "default": false
            },
            {
                "xid": "61062",
                "title": "lunar white",
                "primaryColour": {
                    "number": "131",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__577762__DSA-Icons/Color_131.jpg"
                },
                "secondaryColour": {
                    "number": "154",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560229__DSA-Icons/Color_154.jpg"
                },
                "images": null,
                
                "default": false
            }
        ]
    },
    {
        "xid": "61081",
        "title": "Niko Hydro",
        "description": null,
        "location": [
            LOCATION.OUTDOOR
        ],
        "finishings": [
            {
                "xid": "61058",
                "title": "grey",
                "primaryColour": {
                    "number": "700",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560276__DSA-Icons/Color_700.jpg"
                },
                "secondaryColour": {
                    "number": "700",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560276__DSA-Icons/Color_700.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/700/image-thumb__578428__DSA-Finishings/70031200P_boxNL.png"
                ],
                
                "default": false
            },
            {
                "xid": "61057",
                "title": "white",
                "primaryColour": {
                    "number": "701",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560277__DSA-Icons/Color_701.jpg"
                },
                "secondaryColour": {
                    "number": "701",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560277__DSA-Icons/Color_701.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/701/image-thumb__578426__DSA-Finishings/70131200P_DoosWartel.png"
                ],
                
                "default": false
            },
            {
                "xid": "61056",
                "title": "black",
                "primaryColour": {
                    "number": "761",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560278__DSA-Icons/Color_761.jpg"
                },
                "secondaryColour": {
                    "number": "761",
                    "image": "https://dsa.dev.niko.tcl.rocks/api/pimcore/LineDrawings/Colours/image-thumb__560278__DSA-Icons/Color_761.jpg"
                },
                "images": [
                    "https://dsa.dev.niko.tcl.rocks/api/pimcore/Images/Product/761/image-thumb__578427__DSA-Finishings/76131200P_boxNL.png"
                ],
                
                "default": true
            }
        ]
    }
]