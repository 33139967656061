import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthenticationErrorInterceptor } from './interceptors/authentication-error.interceptor';
import { AuthorizationHeaderInterceptor } from './interceptors/authorization-header.interceptor';

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationErrorInterceptor,
            multi: true
        },{
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHeaderInterceptor,
            multi: true
        }
    ]
})
export class AuthenticationModule { }