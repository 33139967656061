<app-project-page-template [breadcrumb]="'PROJECT_INTAKE.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_BUILDINGTYPE.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../../' + APP_ROUTES['INTAKE_CLIENT_DETAILS'].value"
  [breadcrumbNext]="'PROJECT_BUILDINGTYPE.BREADCRUMB_NEXT' | translate"
  [routerlinkNext]="'../../' + APP_ROUTES['INTAKE_UTILITIES'].value"
  [sideBarContent]="sideBarContent">
  <div id="content-wrapper">
    
    <div id="content" *ngIf="projectData">

      <h1 class="tooltip-trigger">
        {{ 'PROJECT_BUILDINGTYPE.TITLE' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PROJECT_BUILDINGTYPE.TITLE','PROJECT_BUILDINGTYPE.INFO')"></span>
      </h1>

      <!-- Building type needs to be set before rooms can be added to the project-->
      <p *ngIf="!projectData.rooms || projectData.rooms.length == 0" class="margin-b dark-grey">
        {{ 'PROJECT_BUILDINGTYPE.SUBTITLE' | translate }}
      </p>

      <!-- Building type can't be changed if the project already contains room data -->
      <p *ngIf="projectData.rooms && projectData.rooms.length > 0" class="margin-b dark-grey warning tooltip-trigger">
        {{ "PROJECT_BUILDINGTYPE.WARNING" | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PROJECT_BUILDINGTYPE.TITLE','PROJECT_BUILDINGTYPE.WARNING.INFO')"></span>
      </p>

      <div class="utility-container">

        <div class="content-container" *ngFor="let buildingType of buildingTypes" [class.disabled]="projectData.rooms && projectData.rooms.length > 0">
          <div class="choice-card">
            <img [src]="buildingType.image ? buildingType.image : ''" class="icon icon--large">
            <p class="font-l">{{ buildingType.title }}</p>
            <div class="check-button">
              <div (click)="(!projectData.rooms || projectData.rooms.length == 0) ? saveProperty(buildingType.xid, 'buildingtype') : false" class="btn-icon pointer" [class.disabled]="projectData.rooms && projectData.rooms.length > 0">
                <mat-icon *ngIf="projectPropertyValue(projectData.properties,'buildingtype') && projectPropertyValue(projectData.properties,'buildingtype') == buildingType.xid"  [svgIcon]="'radio'" class="icon" ></mat-icon>
                <mat-icon *ngIf="!projectPropertyValue(projectData.properties,'buildingtype') || projectPropertyValue(projectData.properties,'buildingtype') != buildingType.xid" [svgIcon]="'radio_unchecked'" class="icon" ></mat-icon>
              </div>
            </div>
          </div>
        </div>

      </div>

      <hr>

      <h1 class="tooltip-trigger">
        {{ 'PROJECT_CONSTRTYPE.TITLE' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PROJECT_CONSTRTYPE.TITLE','PROJECT_CONSTRTYPE.INFO')"></span>
      </h1>
      <p class="margin-b dark-grey">{{ 'PROJECT_CONSTRTYPE.SUBTITLE' | translate }}</p>

      <div class="utility-container">

        <div class="content-container" *ngFor="let projectType of projectTypes">
          <div class="choice-card">
            <img [src]="projectType.image ? projectType.image : ''" class="icon icon--large">
            <p class="font-l">{{ projectType.title }}</p>
            <div class="check-button pointer">
              <div (click)="saveProperty(projectType.xid, 'projecttype')" class="btn-icon">
                <mat-icon *ngIf="projectPropertyValue(projectData.properties,'projecttype') && projectPropertyValue(projectData.properties,'projecttype') == projectType.xid"  [svgIcon]="'radio'" class="icon"></mat-icon>
                <mat-icon *ngIf="!projectPropertyValue(projectData.properties,'projecttype') || projectPropertyValue(projectData.properties,'projecttype') != projectType.xid" [svgIcon]="'radio_unchecked'" class="icon"></mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</app-project-page-template>