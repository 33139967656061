import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { constructiontypes } from 'src/app/models/ConstructionType';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConstructionTypeService {

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get ConstructionTypeList
   * @returns Observable list of ConstructionTypes
   */
  public getConstructionTypes() {
    if(environment.mockData) {
      return of(constructiontypes);
    } else {
      return this.nikoApi.getConstructionTypes().pipe(
        map((ConstructionTypeList) => {
          return ConstructionTypeList;
        })
      );
    }
  }
}