import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule} from '@angular/material/dialog';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DomSanitizer } from '@angular/platform-browser';

import { AutocompleteDirective } from 'src/app/directives/autocomplete/autocomplete.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AutocompleteDirective
  ],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  exports: [
    AutocompleteDirective,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ]
})
export class MaterialModule {
  // Load custom icons to use with Material Icons
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    Object.keys(ICONS).forEach((key: string) => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(ICONS[key]));
    });
  }
}

// Custom icon definition (to use with MatIconModule)
const ICONS: any = {
  back:             '../assets/icons/back.svg',
  bag:              '../assets/icons/bag.svg',
  check:            '../assets/icons/check.svg',
  copy:             '../assets/icons/copy.svg',
  check_unchecked:  '../assets/icons/check_unchecked.svg',
  clipboard:        '../assets/icons/clipboard.svg',
  close:            '../assets/icons/close.svg',
  config:           '../assets/icons/config.svg',
  commercial_doc:   '../assets/icons/commercial_doc.svg',
  electricity:      '../assets/icons/electricity.svg',
  error:            '../assets/icons/error.svg',
  error_filled:     '../assets/icons/error_filled.svg',
  expand_less:      '../assets/icons/expand_less.svg',
  expand_more:      '../assets/icons/expand_more.svg',
  file_csv:         '../assets/icons/file-csv.svg',
  file_pdf:         '../assets/icons/file-pdf.svg',
  gas:              '../assets/icons/gas.svg',
  general:          '../assets/icons/general.svg',
  hamburger:        '../assets/icons/hamburger.svg',
  home:             '../assets/icons/home.svg',
  intake:           '../assets/icons/intake.svg',
  logout:           '../assets/icons/logout.svg',
  next:             '../assets/icons/next.svg',
  niko:             '../assets/icons/niko.svg',
  overview:         '../assets/icons/overview.svg',
  pencil:           '../assets/icons/pencil.svg',
  product_list:     '../assets/icons/product_list.svg',
  radio:            '../assets/icons/radio.svg',
  radio_unchecked:  '../assets/icons/radio_unchecked.svg',
  requirements:     '../assets/icons/requirements.svg',
  search:           '../assets/icons/search.svg',
  trash:            '../assets/icons/trash.svg',
  settings:         '../assets/icons/settings.svg',
  share:            '../assets/icons/share.svg',
  water:            '../assets/icons/water.svg',


  newconstruction:   '../assets/icons/newconstruction.svg',
  refurbishment:    '../assets/icons/refurbishment.svg',
  renovation:       '../assets/icons/renovation.svg',

  attic:            '../assets/icons/rooms/attic.svg',
  barbecue:            '../assets/icons/rooms/barbecue.svg',
  basement:            '../assets/icons/rooms/basement.svg',
  bathroom:            '../assets/icons/rooms/bathroom.svg',
  bedroom:            '../assets/icons/rooms/bedroom.svg',
  bikestorage:            '../assets/icons/rooms/bikestorage.svg',
  dressing:            '../assets/icons/rooms/dressing.svg',
  entryhall:            '../assets/icons/rooms/entryhall.svg',
  garage:            '../assets/icons/rooms/garage.svg',
  garden:            '../assets/icons/rooms/garden.svg',
  greenery:            '../assets/icons/rooms/greenery.svg',
  kitchen:            '../assets/icons/rooms/kitchen.svg',
  livingroom:            '../assets/icons/rooms/livingroom.svg',
  lobby:            '../assets/icons/rooms/lobby.svg',
  patio:            '../assets/icons/rooms/patio.svg',
  pond:            '../assets/icons/rooms/pond.svg',
  poolroom:            '../assets/icons/rooms/poolroom.svg',
  sauna:            '../assets/icons/rooms/sauna.svg',
  shower:            '../assets/icons/rooms/shower.svg',
  studio:            '../assets/icons/rooms/studio.svg',
  swimmingpool:            '../assets/icons/rooms/swimmingpool.svg',
  workplace:            '../assets/icons/rooms/workplace.svg',
};

