import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class OverviewSpaceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
