import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { debounceTime, distinctUntilChanged, first } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { PartnerConnection, partners } from 'src/app/models/PartnerConnection';
import { Project } from 'src/app/models/Project';
import { Subject, Ternary, Usecase } from 'src/app/models/Subject';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { clone, projectPropertyValue } from 'src/app/util/Util';
import { TopicDetailComponent } from '../topic-detail/topic-detail.component';
import { PartnerCompatibilityModalComponent } from './partner-compatibility-modal/partner-compatibility-modal.component';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent extends BaseComponent implements OnInit {

  public subjects: Subject[] = [];
  public selectedSubjects: Subject[] = [];
  public currentSubject!: Subject;
  public previousSubject?: Subject;
  public previousSubjects?: Subject[] = [];
  public nextSubject?: Subject;
  
  public usecasesWithPartners: Usecase[] = [];
  public selectedUsecasesWithPartners: Usecase[] = [];
  public unselectedUsecasesWithPartners: Usecase[] = [];

  public usecasesWithoutPartners: Usecase[] = [];
  public incompatibleUsecases: Usecase[] = [];

  public partners!: PartnerConnection[];
  public selectedPartners?: PartnerConnection[];
  public unselectedPartners?: PartnerConnection[];
  public compatiblePartners?: PartnerConnection[];
  public incompatiblePartners?: PartnerConnection[];
  public chosenPartner?: PartnerConnection;  

  public project!: Project;
  public projectId!: string;
  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private translate: TranslateService,
    private subjectService: SubjectService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private gaService: GoogleAnalyticsService,
    private titleService:Title
  ) {
    super();
  }

  ngOnInit(): void {
    // This component needs to be reused when navigating to another topic/subject
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

    this.projectId = this.activatedRoute.snapshot.params['project_id'];

    this.addSubscription(this.projectService.getProjectById(this.projectId).subscribe(r => {
      this.project = r;
      this.showUsecases();
    }));
  }
  

  /**
   * Show available usecases with user selection
   */
  showUsecases() {
    this.addSubscription(this.subjectService.cachedSubjects.pipe(debounceTime(500), distinctUntilChanged()).subscribe((r: Subject[]) => {

      // Ordered array of all subjects
      this.subjects = this.subjectService.disableUsecasesByConditions(this.projectId, r, this.project.properties);

      // Subjects which are selected
      this.selectedSubjects = r.filter(x => x.selected === Ternary.TRUE);
      
      let currentSubjectId = this.activatedRoute.snapshot.params['topic_id'];

      if (this.selectedSubjects.filter(x => x.xid === currentSubjectId).length > 0) {

        // Current subject
        let currentIndex: number;
        this.currentSubject = this.selectedSubjects.filter((x, i) => {
          // Array index of current subject is required to get next and previous subjects
          if (x.xid === currentSubjectId) { currentIndex = i };
          return x.xid === currentSubjectId;
        })[0];

        this.titleService.setTitle('Niko project wizard - Functionalities - Usecases - ' + this.currentSubject.title);

        // Previous subject in array
        let previousIndex = currentIndex! - 1;
        this.previousSubject = this.selectedSubjects[previousIndex];

        for(let i = 0; i < currentIndex!; i++) {
          console.log(this.selectedSubjects[i])
          this.previousSubjects?.push(this.selectedSubjects[i]);
        }

        // Next subject in array
        let nextIndex = currentIndex! + 1;
        this.nextSubject = this.selectedSubjects[nextIndex];
      } else {

        // Current subject
        this.currentSubject = r.filter(x => x.xid === currentSubjectId)[0];
        // Next subject is the first subject in the array of selected subjects
        this.nextSubject = this.selectedSubjects[0];
      }

      // Partners for selected and not selected usecases
      // conditionallyDisabled usecases are not included
      this.usecasesWithPartners = this.currentSubject.useCases!.filter(x => (x.partnerConnections && x.partnerConnections.length > 0) && x.selected !== Ternary.DISABLED);
      this.usecasesWithoutPartners = this.currentSubject.useCases!.filter(x => (!x.partnerConnections || x.partnerConnections!.length == 0) && x.selected !== Ternary.DISABLED);

      // All available partners for the current subject
      this.partners = [];
      clone(this.currentSubject.useCases!).filter(x => (x.selected !== Ternary.DISABLED)).flatMap(x => x.partnerConnections).forEach((p: PartnerConnection) => this.partners!.some(x => x.xid == p.xid) ? null : this.partners!.push(p), []);

      // Check partners for compatibility
      this.checkPartnerConnections();

      this.SetSideBarContent(this.selectedSubjects);
    }));
  }

  /**
   * Check which partners are compatible with all the selected usecases
   */
  public checkPartnerConnections() {

    //this.unselectedUsecasesWithPartners = clone(this.usecasesWithPartners!).filter(x => (x.selected !== Ternary.TRUE));
    this.selectedUsecasesWithPartners = clone(this.usecasesWithPartners!).filter(x => (x.selected === Ternary.TRUE));

    if(this.selectedUsecasesWithPartners.length > 0) {
      //this.unselectedPartners = [];
      //clone(this.unselectedUsecasesWithPartners).flatMap(x => x.partnerConnections).forEach((p: PartnerConnection) => this.unselectedPartners!.some(x => x.xid == p.xid) ? null : this.unselectedPartners!.push(p), []);
      this.selectedPartners = [];
      clone(this.selectedUsecasesWithPartners).flatMap(x => x.partnerConnections).forEach((p: PartnerConnection) => this.selectedPartners!.some(x => x.xid == p.xid) ? null : this.selectedPartners!.push(p), []);

      this.compatiblePartners = [];
      this.incompatiblePartners = [];

      this.partners?.forEach(partner => {

        let compatible = false;

        // Current partner is compatible if it's available for every selected usecase
        if(this.selectedUsecasesWithPartners.length > 0) {
          compatible = this.selectedUsecasesWithPartners.every(usecase => {
            let partnerCompatible =  usecase.partnerConnections!.some(usecasePartner => usecasePartner.xid == partner.xid);
            return partnerCompatible;
          });
        }

        compatible ?
        this.compatiblePartners?.push(partner) :
        this.incompatiblePartners?.push(partner);
      });
    } else {
      this.compatiblePartners = this.partners;
      this.incompatiblePartners = [];
    }
  }

  /**
   * Inform the user if one of the selected functionalities is incompatible with the newly chosen partner
   */
  checkChosenPartnerCompatibility(chosenPartner: PartnerConnection) {
    
    let incompatibleUsecases = clone(this.usecasesWithPartners).filter((usecase:Usecase) => usecase.partnerConnections!.every(partner => partner.xid != chosenPartner!.xid));
    let selectedIncompatibleUsecases: Usecase[] = [];

    this.selectedUsecasesWithPartners.forEach(usecaseWithPartner => {
      let matchingUsecase = incompatibleUsecases.find(u => u.xid == usecaseWithPartner.xid);

      if(matchingUsecase) {
        selectedIncompatibleUsecases.push(matchingUsecase);
      }
    });

    const dialogRef = this.dialog.open(PartnerCompatibilityModalComponent, {
      autoFocus: false, 
      data: {
        'incompatibleUsecases': selectedIncompatibleUsecases,
        'deactivateUsecases': false
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      minWidth: '775px'
    });

    dialogRef.afterClosed().subscribe(r => {
      if (r && r.deactivateUsecases) {
        // Save incompatible usecases as false
        selectedIncompatibleUsecases.forEach(usecaseWithPartner => {
          let matchingIncompatibleUsecase = this.usecasesWithPartners.find(x => x.xid == usecaseWithPartner.xid);

          if(matchingIncompatibleUsecase && matchingIncompatibleUsecase.selected != Ternary.FALSE) {
            matchingIncompatibleUsecase.selected = Ternary.FALSE;
            this.selectUsecaseState(matchingIncompatibleUsecase);
          }
        });
      }
    });
  }
  
  /**
 * Set usecase to required status
 * @param id usecase id
 * @param status required status for the given usecase
 */
   selectUsecaseState(currentUsecase: Usecase) {
    let projectId = this.activatedRoute.snapshot.params['project_id'];
    let useCaseString = currentUsecase.selected + '=' + currentUsecase.xid + '&';

    //Functionality analytics
    this.gaService.event('set_' + currentUsecase.selected, 'Functionality Status', currentUsecase.title!);

    // Update selected subjects
    this.subjectService.cachedSubjects.next(this.subjects);
    this.selectedSubjects = this.subjects.filter(x => x.selected === Ternary.TRUE);

    this.subjectService.selectUsecaseState(projectId, useCaseString);

    // Check partners for compatibility
    this.checkPartnerConnections();
  }

  /**
   * Open topic detail modal
   * @param topicId id of the topic to be opened in detail modal
   */
  openDialog(topicId: string) {
    this.dialog.open(TopicDetailComponent, {
      data: {
        'topic_id': topicId,
      },
      autoFocus: false,
      width: '80vh',
      maxHeight: '80vh',
      maxWidth: '80vw'
    });
  }

      /**
   * Open tooltip modal
   */
      public openTooltip(title: string = '', text: string) {
        const dialogRef = this.dialog.open(TooltipModalComponent, {
          autoFocus: false, 
          data: {
            'title': title,
            'text': text
          },
          maxWidth: '90vw',
          maxHeight: '90vh',
          minWidth: '775px'
        });
      }

  /**
   * Add dynamic content for sidebar navigation
   */
   SetSideBarContent(subjects: Subject[]) {
    this.sideBarContent = [];

    let currentSubjectId = this.activatedRoute.snapshot.params['topic_id'];

    console.log(this.previousSubjects);
    console.log(currentSubjectId);
    
    subjects.forEach(subject => {
      this.sideBarContent.push({
        route: '../../../../' + APP_ROUTES['FUNCTIONALITIES_USECASES'].value.replace(':topic_id', subject.xid), 
        breadcrumb: subject.title,
        complete: (this.previousSubjects?.find(x => x.xid == subject.xid)) ? true : false,
        active: (subject.xid == currentSubjectId) ? true : false,
        available: true,
        subitem: true
      });
    })
    
    this.sideBarContent.unshift({
      route: '../../../../' + APP_ROUTES['FUNCTIONALITIES_SUBJECTS'].value, 
      breadcrumb: this.translate.instant('PROJECT_FUNCTIONALITIES.NAV.SUBJECTS'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../../../' + APP_ROUTES['FUNCTIONALITIES_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_FUNCTIONALITIES.NAV.SUMMARY'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });
  }
}