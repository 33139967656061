<app-project-page-template>
  <div id="content">
    
      <h1>Digital doorbell with alarm <span class="greyed-out">- Security and Alarm</span></h1>

      <div class="usecase-wrapper">

        <img class="usecase-image" src="./assets/pictures/usecase/digitaldoorbell.png">
        
        <div class="usecase-info">
          <p>Imagine that you not only hear but also see who is ringing your doorbell, before you let them in. And this from anywhere: on the other side of your house or on the other side of the world. And imagine that you don't even need new cables for installation.</p>
          <p>With the 2-wire access control system from Niko, you always have the comfort and convenience of your videophone at hand. And because it works with a mobile app, you have complete control over your smart doorbell, even on your smartphone.</p>
        </div>

      </div>
      
  </div>
</app-project-page-template>