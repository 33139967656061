import { LocalizedRoutes } from "./model/LocalizedRoutes";

/**
 * App routes for the main app module
 * Date: 05/03/2020
 * Time: 14:30
 *
 * @author: thijsvm
 */
export const APP_ROUTES: LocalizedRoutes = {
    HOME:               { value: 'home' },
    PREFERENCES:        { value: 'preferences' },
    CONTACT:            { value: 'contact-details' },

    LOGIN:              { value: 'login' },

    ONBOARDING:         { value: 'onboarding' },

    ERROR:              { value: 'error' },

    PROJECTLIST:        { value: 'project-list' },
    PROJECT:            { value: 'project/:project_id' },

        DOCUMENTS:                  { value: 'documents' },

        INTAKE_CLIENT_DETAILS:      { value: 'intake/client-details' },
        INTAKE_PROJECT_DETAILS:     { value: 'intake/details' },
        INTAKE_ROOMS:               { value: 'intake/rooms' },
        INTAKE_UTILITIES:           { value: 'intake/utilities' },
        INTAKE_BUILDINGTYPE:        { value: 'intake/buildingtypes' },
        INTAKE_SUMMARY:             { value: 'intake/summary' },

        FUNCTIONALITIES_SUBJECTS:   { value: 'functionalities/subjects' },
        FUNCTIONALITIES_USECASES:   { value: 'functionalities/subject/:topic_id/usecases' },
        FUNCTIONALITIES_SUMMARY:    { value: 'functionalities/summary' },

        ROOMCONFIGURATION_VARIABLES:    { value: 'roomconfiguration' },

        FINISHING_STYLES:           { value: 'finishing/styles' },
        FINISHING_CATALOG:          { value: 'finishing/catalog' },
        FINISHING_RECOMMENDED:      { value: 'finishing/recommended' },
        FINISHING_GENERAL_INDOOR:   { value: 'finishing/indoor' },
        FINISHING_GENERAL_OUTDOOR:  { value: 'finishing/outdoor' },
        FINISHING_SUMMARY:          { value: 'finishing/summary' },
        
        RESULTS_CALCULATION:        { value: 'results/calculating' },
        RESULTS_INSTALLTYPES:       { value: 'results/systems' },
        RESULTS_PRODUCTS:           { value: 'results/products' },
        RESULTS_SUMMARY:            { value: 'results/summary' }
};

export class AppRouteObject {
    breadcrumb!: string;
    route!: string;
    complete!: boolean;
    active!: boolean;
    available!: boolean;
    subitem!: boolean;
}