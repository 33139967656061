<app-project-page-template 
  [breadcrumb]="'PROJECT_FINISHINGS.INTERIOR.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_FINISHINGS.INTERIOR.BREADCRUMB_PREV' | translate" 
  [routerlinkPrev]="'../../'"
  [breadcrumbNext]="(!selectedInteriorTypes || selectedInteriorTypes.length == 0) ? 
  ('PROJECT_FINISHINGS.NO_INTERIOR.BREADCRUMB_NEXT' | translate) :
  ('PROJECT_FINISHINGS.INTERIOR.BREADCRUMB_NEXT' | translate)"
  [routerlinkNext]="(!selectedInteriorTypes || selectedInteriorTypes.length == 0) ?
  '../../' + APP_ROUTES['FINISHING_CATALOG'].value :
  '../../' + APP_ROUTES['FINISHING_RECOMMENDED'].value"
  [sideBarContent]="sideBarContent">

  <div id="content" *ngIf="interiorTypes && interiorTypes.length > 0">

    <!-- <h1>{{ 'PROJECT_FINISHINGS.INTERIOR.TITLE' | translate }}</h1> -->
    <h1>{{ 'PROJECT_FINISHINGS.INTERIOR.CATALOG.TITLE' | translate }}</h1>
    <!-- <p class="margin-b dark-grey">{{ 'PROJECT_FINISHINGS.INTERIOR.SUBTITLE' | translate }}</p> -->
    <p class="margin-b dark-grey">{{ 'PROJECT_FINISHINGS.INTERIOR.CATALOG.SUBTITLE' | translate }}</p>

    <div class="catalog-banner">
      <div class="content-wrapper">
        <div class="title-wrapper">
          <h2 class="no-margin">{{ "PROJECT_FINISHINGS.INTERIOR.CATALOG" | translate}}</h2>
          <button [routerLink]="'../../' + APP_ROUTES['FINISHING_CATALOG'].value" class="btn btn-primary btn-small">{{ 'PROJECT_FINISHINGS.INTERIOR.CATALOG.BTN' | translate }}</button>  
        </div>
      </div> 
    </div>

    <hr>

    <!-- <h1>{{ 'PROJECT_FINISHINGS.INTERIOR.TITLE' | translate }}</h1> -->
    <h1>{{ 'PROJECT_FINISHINGS.INTERIOR.TYPES.TITLE' | translate }}</h1>
    <!-- <p class="margin-b dark-grey">{{ 'PROJECT_FINISHINGS.INTERIOR.SUBTITLE' | translate }}</p> -->
    <p class="margin-b dark-grey">{{ 'PROJECT_FINISHINGS.INTERIOR.TYPES.SUBTITLE' | translate }}</p>
   
    <div #typeGalleryContainer class="interior-container">

      <div class="outer-content-wrapper relative" *ngFor="let type of interiorTypes">
        <!-- scroll left in gallery -->
        <button class="back-btn" (click)="scrollLeft('gallery-' + type.xid)">
          <mat-icon svgIcon="back"  class="icon icon--small"></mat-icon>
        </button>

        <!-- scroll right in gallery -->
        <button class="next-btn" (click)="scrollRight('gallery-' + type.xid)">
          <mat-icon svgIcon="next"  class="icon icon--small"></mat-icon>
        </button>

        <div class="content-wrapper margin-b-30" >          
          <div #typeGallery class="inner-content-wrapper" [id]="'gallery-' + type.xid">
            <div class="image-container" [gallerize]="'gallery-' + type.xid">
              <img width="325" height="200" *ngFor="let img of type.images" [src]="img" [attr.imageSrc]="img"
                [attr.thumbSrc]="img">
            </div>         
          </div>   

          <div class="title-wrapper" gaCategory="Interior style Status">
            <h2 class="no-margin">{{ type.title }}</h2> 
            <div class="check-buttons">
              <div [class.active]="type.selected == Ternary.FALSE"
                gaEvent="set_no" [gaLabel]="type.title"
                class="btn-round-icon check-false " (click)="type.selected = Ternary.FALSE;
                selectInteriorType(type, Ternary.FALSE)">
                <mat-icon class="icon icon--small">close</mat-icon>
              </div>
              <div [class.active]="type.selected == Ternary.TRUE"
                gaEvent="set_yes" [gaLabel]="type.title"
                class="btn-round-icon check-true " (click)="type.selected = Ternary.TRUE;
              selectInteriorType(type, Ternary.TRUE)">
                <mat-icon class="icon icon--small">check</mat-icon>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>

  </div>
</app-project-page-template>