<app-overview-page-template>

  <div id="content" *ngIf="project">
    <!-- Title bar -->
    <div class="flex space-between margin-b-30">

      <h1 class="no-margin">{{ (project.properties && projectPropertyValue(project.properties, 'name')) ? projectPropertyValue(project.properties, 'name') : 'PROJECT' }}</h1>

      <div class="button-wrapper" *ngIf="!projectArchived">
        <!-- New project button-->
        <button class="btn-round-icon active" (click)="openOnboardingModal(true)">
          <mat-icon class="font-l">question_mark</mat-icon>
        </button>
      </div>
    </div>


    <!-- Status -->
    <div class="content-container margin-b-60" *ngIf="!projectArchived">

      <div class="flex space-between margin-b">

        <h2>{{ "PROJECT.STATUS" | translate}}</h2>
  
        <div class="button-wrapper">
  
          <!-- Export button -->
          <!-- <button class="btn btn-small btn-icon">
            <mat-icon svgIcon="share" class="icon icon--small"></mat-icon>{{ "PROJECT.EXPORT" | translate}}
          </button> -->
  
          <!-- Archive switch -->
          <button class="btn btn-small btn-icon" *ngIf="!propertyValueArchived() || propertyValueArchived() != 'true'" (click)="openArchiveDialog($event)">
            <mat-icon class="icon icon--small">archive</mat-icon>{{ "PROJECTS.LABEL_ARCHIVE" | translate}}
          </button>
        </div>
  
      </div>

      <div class="status-container" *ngIf="project.status">

        <!-- Status Intake -->
        <div 
        [class.disabled]="!project.status.accessibleSteps || (project.status.accessibleSteps && !project.status.accessibleSteps.includes('PHASE_INTAKE'))"
        [class.finished]="project.status.activeStep != 'PHASE_INTAKE' && project.status.accessibleSteps && project.status.accessibleSteps.includes('PHASE_INTAKE') && !checkStepIssues('PHASE_INTAKE')"
        [class.active]="project.status.activeStep == 'PHASE_INTAKE'" 
        class="status-wrapper"
          [routerLink]="'./'+ APP_ROUTES['INTAKE_CLIENT_DETAILS'].value">
          <div class="dot"></div>
          <button id="intake-btn" class="btn btn-circle btn-secondary">
            <mat-icon svgIcon="intake" class="icon"></mat-icon>
          </button>
          <p>{{ "PROJECT.INTAKE" | translate}}</p>

          <!-- Adjustments warning -->
          <label [routerLink]="['./']" fragment="issues" class="error-adjustment" *ngIf="checkStepIssues('PHASE_INTAKE')">
            <mat-icon svgIcon="error" class="icon icon-error icon--small"></mat-icon> {{getStepIssues('PHASE_INTAKE').length }} {{ getStepIssues('PHASE_INTAKE').length == 1 ? ("PROJECT.ADJUSTMENT" | translate) : ("PROJECT.ADJUSTMENTS" | translate)}} 
          </label>
        </div>

        <!-- Status Functionalities -->
        <div 
        [class.disabled]="!project.status.accessibleSteps || (project.status.accessibleSteps && !project.status.accessibleSteps.includes('PHASE_FUNCTIONALITIES'))"
        [class.finished]="project.status.activeStep != 'PHASE_FUNCTIONALITIES' && project.status.accessibleSteps && project.status.accessibleSteps.includes('PHASE_FUNCTIONALITIES') && !checkStepIssues('PHASE_FUNCTIONALITIES')"
        [class.active]="project.status.activeStep == 'PHASE_FUNCTIONALITIES'" class="status-wrapper"
        [routerLink]="'./'+ APP_ROUTES['FUNCTIONALITIES_SUBJECTS'].value">
          <div class="dot"></div>
          <button id="requirements-btn" class="btn btn-circle btn-secondary">
            <mat-icon svgIcon="requirements" class="icon"></mat-icon>
          </button>
          <p>{{ "PROJECT.FUNCTIONALITIES" | translate}}</p>

          <!-- Adjustments warning -->
          <label [routerLink]="['./']" fragment="issues" class="error-adjustment" *ngIf="checkStepIssues('PHASE_FUNCTIONALITIES')">
            <mat-icon svgIcon="error" class="icon icon-error icon--small"></mat-icon> {{getStepIssues('PHASE_FUNCTIONALITIES').length }} {{ getStepIssues('PHASE_FUNCTIONALITIES').length == 1 ? ("PROJECT.ADJUSTMENT" | translate) : ("PROJECT.ADJUSTMENTS" | translate)}} 
          </label>
        </div>

        <!-- Status Finishing -->
        <div 
        [class.disabled]="!project.status.accessibleSteps || (project.status.accessibleSteps && !project.status.accessibleSteps.includes('PHASE_FINISHING'))"
        [class.finished]="project.status.activeStep != 'PHASE_FINISHING' && project.status.accessibleSteps && project.status.accessibleSteps.includes('PHASE_FINISHING') && !checkStepIssues('PHASE_FINISHING')"
        [class.active]="project.status.activeStep == 'PHASE_FINISHING'" class="status-wrapper"
          [routerLink]="'./'+ APP_ROUTES['FINISHING_STYLES'].value">
          <div class="dot"></div>
          <button id="home-btn" class="btn btn-circle btn-secondary">
            <mat-icon svgIcon="home" class="icon"></mat-icon>
          </button>
          <p>{{ "PROJECT.APPEARANCE" | translate}}</p>

          <!-- Adjustments warning -->
          <label [routerLink]="['./']" fragment="issues" class="error-adjustment" *ngIf="checkStepIssues('PHASE_FINISHING')">
            <mat-icon svgIcon="error" class="icon icon-error icon--small"></mat-icon> {{getStepIssues('PHASE_FINISHING').length }} {{ getStepIssues('PHASE_FINISHING').length == 1 ? ("PROJECT.ADJUSTMENT" | translate) : ("PROJECT.ADJUSTMENTS" | translate)}} 
          </label>
        </div>

        <!-- Status Configuration -->
        <div 
        [class.disabled]="!project.status.accessibleSteps || (project.status.accessibleSteps && !project.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION'))"
        [class.finished]="project.status.activeStep != 'PHASE_ROOMCONFIGURATION' && project.status.accessibleSteps && project.status.accessibleSteps.includes('PHASE_ROOMCONFIGURATION') && !checkStepIssues('PHASE_ROOMCONFIGURATION')"
        [class.active]="project.status.activeStep == 'PHASE_ROOMCONFIGURATION'" class="status-wrapper"
          [routerLink]="'./'+ APP_ROUTES['ROOMCONFIGURATION_VARIABLES'].value">
          <div class="dot"></div>
          <button id="config-btn" class="btn btn-circle btn-secondary">
            <mat-icon svgIcon="config" class="icon"></mat-icon>
          </button>
          <p>{{ "PROJECT.CONFIGURATION" | translate}}</p>

          <!-- Adjustments warning -->
          <label [routerLink]="['./']" fragment="issues" class="error-adjustment" *ngIf="checkStepIssues('PHASE_ROOMCONFIGURATION')">
            <mat-icon svgIcon="error" class="icon icon-error icon--small"></mat-icon> {{getStepIssues('PHASE_ROOMCONFIGURATION').length }} {{ getStepIssues('PHASE_ROOMCONFIGURATION').length == 1 ? ("PROJECT.ADJUSTMENT" | translate) : ("PROJECT.ADJUSTMENTS" | translate)}} 
          </label>
        </div>

        <!-- Status Summary -->
        <div 
        [class.disabled]="!project.status.accessibleSteps || (project.status.accessibleSteps && !project.status.accessibleSteps.includes('PHASE_RESULTS'))"
        [class.finished]="project.status.activeStep != 'PHASE_RESULTS' && project.status.accessibleSteps && project.status.accessibleSteps.includes('PHASE_RESULTS') && !checkStepIssues('PHASE_RESULTS')"
        [class.active]="project.status.activeStep == 'PHASE_RESULTS'" class="status-wrapper"
          [routerLink]="'./'+ APP_ROUTES['RESULTS_CALCULATION'].value">
          <div class="dot"></div>
          <button id="overview-btn" class="btn btn-circle btn-secondary">
            <mat-icon svgIcon="clipboard" class="icon"></mat-icon>
          </button>
          <p>{{ "PROJECT.SUMMARY" | translate}}</p>

          <!-- Adjustments warning -->
          <label [routerLink]="['./']" fragment="issues" class="error-adjustment" *ngIf="checkStepIssues('PHASE_RESULTS')">
            <mat-icon svgIcon="error" class="icon icon-error icon--small"></mat-icon> {{getStepIssues('PHASE_RESULTS').length }} {{ getStepIssues('PHASE_RESULTS').length == 1 ? ("PROJECT.ADJUSTMENT" | translate) : ("PROJECT.ADJUSTMENTS" | translate)}} 
          </label>
        </div>
      </div>
    </div>

    <!-- Adjustments -->
    <div id="issues" *ngIf="
      !projectArchived &&
      (checkStepIssues('PHASE_INTAKE') || 
      checkStepIssues('PHASE_FUNCTIONALITIES') || 
      checkStepIssues('PHASE_FINISHING') || 
      checkStepIssues('PHASE_ROOMCONFIGURATION') || 
      checkStepIssues('PHASE_RESULTS'))">
      <h2 class="h1 margin-b">{{ "PROJECT.REQUIREDADJUSTMENTS" | translate}}</h2>

      <div class="adjustment-container">

        <div class="adjustment-column">

          <!-- Adjustments Intake -->
          <div class="adjustment-wrapper" [class.disabled]="!checkStepIssues('PHASE_INTAKE')">
            <div class="adjustment-title">
              <mat-icon svgIcon="intake" class="icon icon--medium"></mat-icon>
              <p>{{ "PROJECT.INTAKE" | translate}}</p>
            </div>
            <ng-container *ngIf="checkStepIssues('PHASE_INTAKE')">
              <div *ngFor="let issue of getStepIssues('PHASE_INTAKE')" class="content-container pointer" [routerLink]="issue && APP_ROUTES[issue.link] ? './' + APP_ROUTES[issue.link].value : '.'">
                <mat-icon *ngIf="issue.level == 'ERROR'" svgIcon="error_filled" class="icon icon-error icon--small"></mat-icon>
                <mat-icon *ngIf="issue.level == 'WARNING'" svgIcon="error_filled" class="icon icon-warning icon--small"></mat-icon>
                <p>{{ issue.name.trim() | translate}} </p>
                <button class="btn-proceed" >
                  <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                </button>
              </div>
            </ng-container>
            
            <div class="content-container" *ngIf="!checkStepIssues('PHASE_INTAKE')">
              <p>{{ 'PROJECT.NOADJUSTMENTS' | translate}}</p>
            </div>
          </div>

          <!-- Adjustments Functionalities -->
          <div class="adjustment-wrapper" *ngIf="getStepIssues('PHASE_INTAKE').length <= 7" [class.disabled]="!checkStepIssues('PHASE_FUNCTIONALITIES')">
            <div class="adjustment-title">
              <mat-icon svgIcon="requirements" class="icon icon--medium"></mat-icon>
              <p>{{ "PROJECT.FUNCTIONALITIES" | translate}}</p>
            </div>
            
            <ng-container *ngIf="checkStepIssues('PHASE_FUNCTIONALITIES')">
              <div *ngFor="let issue of getStepIssues('PHASE_FUNCTIONALITIES')" class="content-container pointer" [routerLink]="issue && APP_ROUTES[issue.link] ? './' + APP_ROUTES[issue.link].value : '.'">
                <mat-icon *ngIf="issue.level == 'ERROR'" svgIcon="error_filled" class="icon icon-error icon--small"></mat-icon>
                <mat-icon *ngIf="issue.level == 'WARNING'" svgIcon="error_filled" class="icon icon-warning icon--small"></mat-icon>
                <p>{{ issue.name.trim() | translate}} </p>
                <button class="btn-proceed" >
                  <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                </button>
              </div>
            </ng-container>

            <div class="content-container" *ngIf="!checkStepIssues('PHASE_FUNCTIONALITIES')">
              <p>{{ 'PROJECT.NOADJUSTMENTS' | translate}}</p>
            </div>
          </div>

          <!-- Adjustments Finishing -->
          <div class="adjustment-wrapper" *ngIf="getStepIssues('PHASE_INTAKE').length <= 2" [class.disabled]="!checkStepIssues('PHASE_FINISHING')">
            <div class="adjustment-title">
              <mat-icon svgIcon="home" class="icon icon--medium"></mat-icon>
              <p>{{ "PROJECT.APPEARANCE" | translate}}</p>
            </div>
            
            <ng-container *ngIf="checkStepIssues('PHASE_FINISHING')">
              <div *ngFor="let issue of getStepIssues('PHASE_FINISHING')" class="content-container pointer" [routerLink]="issue && APP_ROUTES[issue.link] ? './' + APP_ROUTES[issue.link].value : '.'">
                <mat-icon *ngIf="issue.level == 'ERROR'" svgIcon="error_filled" class="icon icon-error icon--small"></mat-icon>
                <mat-icon *ngIf="issue.level == 'WARNING'" svgIcon="error_filled" class="icon icon-warning icon--small"></mat-icon>
                <p>{{ issue.name.trim() | translate}} </p>
                <button class="btn-proceed" >
                  <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                </button>
              </div>
            </ng-container>

            <div class="content-container" *ngIf="!checkStepIssues('PHASE_FINISHING')">
              <p>{{ 'PROJECT.NOADJUSTMENTS' | translate}}</p>
            </div>
          </div>
        </div>

        <div class="adjustment-column">

                    <!-- Adjustments Functionalities -->
                    <div class="adjustment-wrapper" *ngIf="getStepIssues('PHASE_INTAKE').length > 7"  [class.disabled]="!checkStepIssues('PHASE_FUNCTIONALITIES')">
                      <div class="adjustment-title">
                        <mat-icon svgIcon="requirements" class="icon icon--medium"></mat-icon>
                        <p>{{ "PROJECT.FUNCTIONALITIES" | translate}}</p>
                      </div>
                      
                      <ng-container *ngIf="checkStepIssues('PHASE_FUNCTIONALITIES')">
                        <div *ngFor="let issue of getStepIssues('PHASE_FUNCTIONALITIES')" class="content-container pointer" [routerLink]="issue && APP_ROUTES[issue.link] ? './' + APP_ROUTES[issue.link].value : '.'">
                          <mat-icon *ngIf="issue.level == 'ERROR'" svgIcon="error_filled" class="icon icon-error icon--small"></mat-icon>
                          <mat-icon *ngIf="issue.level == 'WARNING'" svgIcon="error_filled" class="icon icon-warning icon--small"></mat-icon>
                          <p>{{ issue.name.trim() | translate}} </p>
                          <button class="btn-proceed" >
                            <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                          </button>
                        </div>
                      </ng-container>
          
                      <div class="content-container" *ngIf="!checkStepIssues('PHASE_FUNCTIONALITIES')">
                        <p>{{ 'PROJECT.NOADJUSTMENTS' | translate}}</p>
                      </div>
                    </div>

          <!-- Adjustments Finishing -->
          <div class="adjustment-wrapper" *ngIf="getStepIssues('PHASE_INTAKE').length > 2"  [class.disabled]="!checkStepIssues('PHASE_FINISHING')">
            <div class="adjustment-title">
              <mat-icon svgIcon="home" class="icon icon--medium"></mat-icon>
              <p>{{ "PROJECT.APPEARANCE" | translate}}</p>
            </div>
            
            <ng-container *ngIf="checkStepIssues('PHASE_FINISHING')">
              <div *ngFor="let issue of getStepIssues('PHASE_FINISHING')" class="content-container pointer" [routerLink]="issue && APP_ROUTES[issue.link] ? './' + APP_ROUTES[issue.link].value : '.'">
                <mat-icon *ngIf="issue.level == 'ERROR'" svgIcon="error_filled" class="icon icon-error icon--small"></mat-icon>
                <mat-icon *ngIf="issue.level == 'WARNING'" svgIcon="error_filled" class="icon icon-warning icon--small"></mat-icon>
                <p>{{ issue.name.trim() | translate}} </p>
                <button class="btn-proceed" >
                  <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                </button>
              </div>
            </ng-container>

            <div class="content-container" *ngIf="!checkStepIssues('PHASE_FINISHING')">
              <p>{{ 'PROJECT.NOADJUSTMENTS' | translate}}</p>
            </div>
          </div>

          <!-- Adjustments Configuration -->
          <div class="adjustment-wrapper" [class.disabled]="!checkStepIssues('PHASE_ROOMCONFIGURATION')">
            <div class="adjustment-title">
              <mat-icon svgIcon="config" class="icon icon--medium"></mat-icon>
              <p>{{ "PROJECT.CONFIGURATION" | translate}}</p>
            </div>
            
            <ng-container *ngIf="checkStepIssues('PHASE_ROOMCONFIGURATION')">
              <div *ngFor="let issue of getStepIssues('PHASE_ROOMCONFIGURATION')" class="content-container pointer" [routerLink]="issue && APP_ROUTES[issue.link] ? './' + APP_ROUTES[issue.link].value : '.'">
                <mat-icon *ngIf="issue.level == 'ERROR'" svgIcon="error_filled" class="icon icon-error icon--small"></mat-icon>
                <mat-icon *ngIf="issue.level == 'WARNING'" svgIcon="error_filled" class="icon icon-warning icon--small"></mat-icon>
                <p>{{ issue.name.trim() | translate}} </p>
                <button class="btn-proceed" >
                  <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                </button>
              </div>
            </ng-container>

            <div class="content-container" *ngIf="!checkStepIssues('PHASE_ROOMCONFIGURATION')">
              <p>{{ 'PROJECT.NOADJUSTMENTS' | translate}}</p>
            </div>
          </div>

          <!-- Adjustments Results -->
          <div class="adjustment-wrapper" [class.disabled]="!checkStepIssues('PHASE_RESULTS')">
            <div class="adjustment-title">
              <mat-icon svgIcon="requirements" class="icon icon--medium"></mat-icon>
              <p>{{ "PROJECT.SUMMARY" | translate}}</p>
            </div>
            
            <ng-container *ngIf="checkStepIssues('PHASE_RESULTS')">
              <div *ngFor="let issue of getStepIssues('PHASE_RESULTS')" class="content-container pointer" [routerLink]="issue && APP_ROUTES[issue.link] ? './' + APP_ROUTES[issue.link].value : '.'">
                <mat-icon *ngIf="issue.level == 'ERROR'" svgIcon="error_filled" class="icon icon-error icon--small"></mat-icon>
                <mat-icon *ngIf="issue.level == 'WARNING'" svgIcon="error_filled" class="icon icon-warning icon--small"></mat-icon>
                <p>{{ issue.name.trim() | translate}} </p>
                <button class="btn-proceed" >
                  <mat-icon svgIcon="next" class="icon icon--small"></mat-icon>
                </button>
              </div>
            </ng-container>

            <div class="content-container" *ngIf="!checkStepIssues('PHASE_RESULTS')">
              <p>{{ 'PROJECT.NOADJUSTMENTS' | translate}}</p>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!-- Documents -->
    <h2 class="h1">{{ "PROJECT_DOCUMENTS.TITLE" | translate}}</h2>

    <div class="documents content-container no-padding margin-b-50">

      <!-- Title bar -->
      <div class="flex space-between baseline padding-medium border-b">
        <h2 class="no-margin">{{ "PROJECT_DOCUMENTS.RECENT" | translate}}</h2>
        <a class="font-m font-underline pointer" [routerLink]="'./'+ APP_ROUTES['DOCUMENTS'].value">{{
          'PROJECT_DOCUMENTS.VIEW_ALL' | translate}}</a>
      </div>

      <!-- Document table - not available -->
      <div class="document-container flex flex-center" *ngIf="!project.status">
        <p class="greyed-out flex flex-center no-documents-wrapper">
          <mat-icon svgIcon="commercial_doc" class="icon greyed-out icon--medium"></mat-icon>
          <mat-icon svgIcon="product_list" class="icon greyed-out icon--medium"></mat-icon>
          {{ 'PROJECT_DOCUMENTS.NO_DOCUMENTS' | translate }}</p>
      </div>

      <div class="document-container" *ngIf="project.status">
        <!-- Commercial doc - not available -->
        <ng-container *ngIf="!currentCommercialDocument && (!project.status.accessibleSteps || (project.status.accessibleSteps && !project.status.accessibleSteps.includes('DOWNLOAD_COMMERCIAL')))">
          <div class="document-wrapper disabled">
            <div class="document-inner-wrapper">
              <mat-icon svgIcon="commercial_doc" class="document-icon icon icon--large margin-b-20"></mat-icon>
              <div class="text-wrapper">
                <p class="finishing-title font-l margin-b text-center">{{ 'PROJECT_DOCUMENTS.COMMERCIAL' | translate }}</p>
                <p class="text-center margin-b-20 text-center">
                  <span class="font-m greyed-out">
                    {{ 'PROJECT_DOCUMENTS.UNAVAILABLE' | translate }}
                  </span>
                </p>
              </div>
            </div>
          </div> 
        </ng-container>

        <!-- Commercial doc - update available -->
        <ng-container *ngIf="!currentCommercialDocument && project.status.accessibleSteps && project.status.accessibleSteps.includes('DOWNLOAD_COMMERCIAL')"> <!--TODO -->
          <div class="document-wrapper">
            <button class="btn-selected btn btn-primary btn-small default">
              {{ 'PROJECT_DOCUMENTS.UPDATE_AVAILABLE' | translate}}            </button>
    
            <div class="document-inner-wrapper">
              <mat-icon svgIcon="commercial_doc" class="document-icon icon icon--large margin-b-20"></mat-icon>
              <div class="text-wrapper">
                <p class="finishing-title font-l margin-b-20 text-center">{{'PROJECT_DOCUMENTS.COMMERCIAL' | translate}}</p>
                <p class="text-center margin-b-20 text-center">
                  <span class="font-m greyed-out">
                    {{ 'PROJECT_DOCUMENTS.UPDATE_AVAILABLE' | translate}}
                  </span>
                </p>
                
                <div class="flex flex-center" *ngIf="false">
                  <button class="btn btn-secondary btn-small">{{'PROJECT_DOCUMENTS.UPDATE' | translate}}</button>
                </div>
                <div class="flex flex-center baseline" *ngIf="!documentService.commercialDocumentGenerating">
                  <ng-container *ngIf="checkCommercialDocuments()">
                    <button class="btn btn-small" (click)="generateDocument(DocumentType.COMMERCIAL)">{{'PROJECT_DOCUMENTS.UPDATE' | translate}}</button>
                    <a class="font-m font-underline pointer" [routerLink]="'./'+ APP_ROUTES['DOCUMENTS'].value">{{
                      'View previous' | translate}}</a>
                  </ng-container>
                  <ng-container *ngIf="!checkCommercialDocuments()">
                    <button  class="btn btn-small"(click)="generateDocument(DocumentType.COMMERCIAL)">{{'PROJECT_DOCUMENTS.GENERATE' | translate}}</button>
                  </ng-container>
                </div>
                <div class="flex flex-center" *ngIf="documentService.commercialDocumentGenerating">
                  <span class="loader"></span>
                </div>
              </div>
            </div>
          </div> 
        </ng-container>

        <!-- Commercial doc - view -->
        <ng-container *ngIf="currentCommercialDocument"> <!--TODO -->
          <div class="document-wrapper">
    
            <div class="document-inner-wrapper">
              <mat-icon svgIcon="commercial_doc" class="document-icon icon icon--large margin-b-20"></mat-icon>
              <div class="text-wrapper">
                <p class="finishing-title font-l margin-b text-center">{{'PROJECT_DOCUMENTS.COMMERCIAL' | translate}}</p>
                
                <div class="flex flex-center">
                  <button *ngIf="!documentService.commercialDocumentGenerating" class="btn btn-small"(click)="downloadDocument(currentCommercialDocument.id)">{{'PROJECT_DOCUMENTS.DOWNLOAD' | translate}}</button>
                  <span *ngIf="documentService.commercialDocumentGenerating" class="loader"></span>
                </div>
              </div>
            </div>
          </div> 
        </ng-container>

        <!-- Product list - not available -->
        <ng-container *ngIf="!currentProductList && (!project.status.accessibleSteps || (project.status.accessibleSteps && !project.status.accessibleSteps.includes('DOWNLOAD_BOM')))">
          <div class="document-wrapper disabled">
    
            <div class="document-inner-wrapper">
              <mat-icon svgIcon="product_list" class="document-icon icon icon--large margin-b-20"></mat-icon>
              <div class="text-wrapper">
                <p class="finishing-title font-l margin-b text-center">{{'PROJECT_DOCUMENTS.BOM' | translate}}</p>
                <p class="text-center margin-b-20 text-center">
                  <span class="font-m greyed-out">
                    {{ 'PROJECT_DOCUMENTS.UNAVAILABLE' | translate }}
                  </span>
                </p>
              </div>
            </div>
          </div> 
        </ng-container> 
        
        <!-- Product list - update available -->
        <ng-container *ngIf="!currentProductList && project.status.accessibleSteps && project.status.accessibleSteps.includes('DOWNLOAD_BOM')">
          <div class="document-wrapper">
            <button class="btn-selected btn btn-primary btn-small default">
              {{ 'PROJECT_DOCUMENTS.UPDATE_AVAILABLE' | translate}}
            </button>

            <div class="document-inner-wrapper">
              <mat-icon svgIcon="product_list" class="document-icon icon icon--large margin-b-20"></mat-icon>
              <div class="text-wrapper">
                <p class="finishing-title font-l margin-b-20 text-center">{{'PROJECT_DOCUMENTS.BOM' | translate}}</p>
                <p class="text-center margin-b-20 text-center">
                  <span class="font-m greyed-out">
                    {{ 'PROJECT_DOCUMENTS.UPDATE_AVAILABLE' | translate}}
                  </span>
                </p>
                
                <div class="flex flex-center" *ngIf="false">
                  <button class="btn btn-secondary btn-small">{{'PROJECT_DOCUMENTS.UPDATE' | translate}}</button>
                </div>
                <div class="flex flex-center baseline" *ngIf="!documentService.bomDocumentGenerating">
                  <ng-container *ngIf="checkProductList()">
                    <button class="btn btn-small" (click)="generateDocument(DocumentType.BOM)">{{'PROJECT_DOCUMENTS.UPDATE' | translate}}</button>
                    <a class="font-m font-underline pointer" [routerLink]="'./'+ APP_ROUTES['DOCUMENTS'].value">{{
                      'View previous' | translate}}</a>
                  </ng-container>
                  <ng-container *ngIf="!checkProductList()">
                    <button  class="btn btn-small"(click)="generateDocument(DocumentType.BOM)">{{'PROJECT_DOCUMENTS.GENERATE' | translate}}</button>
                  </ng-container>
                </div>
                <div class="flex flex-center" *ngIf="documentService.bomDocumentGenerating">
                  <span class="loader"></span>
                </div>
              </div>
            </div>
          </div> 
        </ng-container>
        
        <!-- Product list - view -->
        <ng-container *ngIf="currentProductList">
          <div class="document-wrapper">
    
            <div class="document-inner-wrapper">
              <mat-icon svgIcon="product_list" class="document-icon icon icon--large margin-b-20"></mat-icon>
              <div class="text-wrapper">
                <p class="finishing-title font-l margin-b text-center">{{'PROJECT_DOCUMENTS.BOM' | translate}}</p>
                
                <div class="flex flex-center">
                  <button *ngIf="!documentService.bomDocumentGenerating" class="btn btn-small" (click)="downloadDocument(currentProductList.id)">{{'PROJECT_DOCUMENTS.DOWNLOAD' | translate}}</button>
                  <span *ngIf="documentService.bomDocumentGenerating" class="loader"></span>
                </div>
              </div>
            </div>
          </div> 
        </ng-container>
        
      </div>

      <div class="policy-wrapper">
        <p class="font-m greyed-out" [innerHTML]="'POLICIES.DISCLAIMER_TOOL' | translate">
        </p>
      </div>

    </div>

  </div>
</app-overview-page-template>