import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BaseComponent } from 'src/app/components/base.component';
import { OnboardingModalComponent } from 'src/app/components/modals/onboarding-modal/onboarding-modal.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { Project, ProjectModule, Property } from 'src/app/models/Project';
import { Article, Calculation, CalculationEntry, InstallType } from 'src/app/models/System';
import { User } from 'src/app/models/User';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { SystemService } from 'src/app/services/system/system.service';
import { UserService } from 'src/app/services/user/user.service';
import { cloneObject } from 'src/app/util/Util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent extends BaseComponent implements OnInit {

  public hideCalculationLog = true;

  public userData!: User;
  public showPrices = true;

  public projectId!: string;
  public projectData!: Project;

  public calculation?: Calculation;

  public installTypes!: InstallType[];

  public installTypePrice: {
    [installType: string]: string
  } = {};
  
  public toggleSelected: {
    [roomId: string]: boolean
  } = {};

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public systemService: SystemService,
    private propertyService: PropertyService,
    private projectService: ProjectService,
    private translate: TranslateService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private gaService: GoogleAnalyticsService,
    private dialog: MatDialog
  ) { 
    super(); 
  
    this.hideCalculationLog = environment.production;
  }

  ngOnInit(): void {
    this.getUserData();
    
    this.SetSideBarContent();
    
    this.projectId = this.activatedRoute.snapshot.params['project_id'];

    if(!this.projectService.calculation) {
      // If no calculation is available, redirect to calculation component
      this.router.navigate(['/' + APP_ROUTES['PROJECT'].value.replace(':project_id', this.projectId) + '/' + APP_ROUTES['RESULTS_CALCULATION'].value]);
    } else {
      // If calculation is available, get installtypes
      this.calculation = cloneObject(this.projectService.calculation);
      this.projectService.staleCalculation = this.calculation;
      this.projectService.calculation = undefined;

      this.addSubscription(this.systemService.getInstallTypes().subscribe((r) => {
        this.installTypes = r;

        // Calculate prices for each installtype
        this.installTypes.forEach(installType => {
          let price = this.calculateInstallTypeTotal(installType.xid);
        })

        //Get project data to show current values
        this.getProjectData();
      }));

    }
  }

  /**
   * Get User data to show current settings
   */
   private getUserData() {
    this.userData = this.userService.user.getValue();

    // If no user settings are available, redirect to login
    if(!this.userData.email) {
      this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
    } else if(this.userData.settings && this.userData.settings.find(p => p.name == 'user.showprices')) { 
      this.showPrices = this.userData.settings.find(p => p.name == 'user.showprices')?.value == 'true';
    }
   }

  /**
   * Get project data to show current values
   */
  private getProjectData() {
    this.projectService.getProjectById(this.projectId).subscribe(r => {
      this.projectData = r;
    });
  }

  /**
   * Get the current installType value
   * @returns saved installType value
   */
  public propertyValue(): string | undefined {
    let value = this.projectData.properties.find(p => {
      return p.name.includes('installtype');
    })?.value;
    return value;
  }

  /**
   * Save one or multiple property values to backend
   * @param name name of the formcontrol and the property to be saved
   */
  public saveProperty(value: string) {
    console.log('add analytics event');
    this.gaService.event('project_installtype', 'Project', 
      this.installTypes.find(installType => installType.xid == value)?.title || 'unknown',
      Number(this.installTypePrice[value]), undefined, {
        'project': this.projectId,
        'user_name': this.userData.firstname + ' ' + this.userData.lastname,
        'email': this.userData.email,
        'client_timestamp': Date.now()
      });
    console.log(this.installTypes.find(installType => installType.xid == value)?.title);
    console.log(Number(this.installTypePrice[value]));
    console.log('project: ' + this.projectId);


    let property = this.projectData.properties.find(p => {
      return p.name.includes('installtype')
    });

    if (property) {
      property.value = value;
    } else {
      property = new Property;
      property.name = 'project.installtype';
      property.value = value;

      this.projectData.properties.push(property);
    }

    this.propertyService.setProperty(this.projectId, ['project.installtype' + (value ? (':' + value) : '')]);
  }

  /**
   * Check if there are results in calculation for given installtype
   */
     public installTypeAvailable(type: string): boolean {
      let available = false;
  
      if(this.calculation &&
        this.calculation!.results!.find(r => r.installType.xid == type) && 
        this.calculation!.results!.find(r => r.installType.xid == type)!.entries && 
        this.calculation!.results!.find(r => r.installType.xid == type)!.entries!.length > 0) {
          available = true;
      }
  
      return available;
    }


  /**
   * Calculate total installtype price
   */
   public calculateInstallTypeTotal(type: string): string {
    let calculationData = this.calculation;
    let articles: Article[] = [];
    let entries: CalculationEntry[] = [];
    let articleList: Article[] = [];
    let totalPrice = 0;

    if(calculationData && calculationData.results && 
      calculationData!.results!.find(r => r.installType.xid == type) && 
      calculationData!.results!.find(r => r.installType.xid == type)!.entries && 
      calculationData!.results!.find(r => r.installType.xid == type)!.entries!.length > 0) {
      entries = calculationData.results!.find(r => r.installType.xid == type)!.entries!;
    }

    if(calculationData && calculationData.articles && calculationData!.articles.length > 0) {
      articleList = calculationData.articles;    
    }

    if(articleList.length > 0 && entries.length > 0) {
      // Array of article Ids
      let articleIds = entries.map(entry => entry.article); 

      let uniqueArticleIds = [...new Set(articleIds)];

      // Object with unique article Ids and amount of occurance
      const articleCount = entries.reduce((acc: any, curr: any) => (acc[curr.article] = (acc[curr.article] || null) + curr.count, acc), {}); 


      for(var articleId of uniqueArticleIds){

        let currentArticleType = articleList.find(article => article.number == articleId)!;

        let article = new Article;
        article.image = currentArticleType.image;
        article.name = currentArticleType.name;        
        article.description = currentArticleType.description;        
        article.number = articleId;
        article.price = currentArticleType.price;
        article.type = currentArticleType.type;
        article.count = articleCount[articleId];

        totalPrice = totalPrice + (article.price * article.count!);

        articles.push(article);
      }
    }

    this.installTypePrice[type] = totalPrice.toFixed(2);
    
    return this.installTypePrice[type];
  }

  /**
   * Copy the calculation log to the clipboard
   * @param log the calculated log text to add to the clipboard
   */
   copyLog(log:string) {
    navigator.clipboard.writeText(log).then( () => {
      this.snackBar.open('The calculation log has been copied to your clipboard.', undefined, {
        duration: 3000,
        horizontalPosition: 'right',
        panelClass: 'snackbar'
    });    }).catch( () => {
      this.snackBar.open('Something went wrong. The log was not copied to your clipboard.', undefined, {
        duration: 3000,
        horizontalPosition: 'right',
        panelClass: 'snackbar'
    });    });
  }

   /**
   * Open tooltip modal
   */
   public openTooltip(title: string = '', text: string) {
    const dialogRef = this.dialog.open(TooltipModalComponent, {
      autoFocus: false, 
      data: {
        'title': title,
        'text': text
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      minWidth: '775px'
    });
  }

  /**
   * Add dynamic content for sidebar navigation
   */
  SetSideBarContent() {
    this.sideBarContent = [];

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_CALCULATION'].value, 
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.CALCULATION'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_INSTALLTYPES'].value,
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.INSTALLTYPE'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_PRODUCTS'].value,
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.PRODUCTS'),
      complete: false,
      active: false,
      available: false,
      subitem: false
    });
  }

}
