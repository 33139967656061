import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, of, take } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { Finishing, FinishingRange, LOCATION } from 'src/app/models/Finishing';
import { Project } from 'src/app/models/Project';
import { Room, RoomInstance } from 'src/app/models/Room';
import { Ternary } from 'src/app/models/Subject';
import { Variable, VariableInstance, variables } from 'src/app/models/Variable';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { clone, projectPropertyValue } from 'src/app/util/Util';
import { environment } from 'src/environments/environment';
import { FinishingService } from '../finishing/finishing.service';
import { ProjectService } from '../project/project.service';
import { RoomService } from '../room/room.service';

@Injectable({
  providedIn: 'root'
})
export class VariableService extends BaseComponent {

  public cachedVariables: BehaviorSubject<Variable[]> = new BehaviorSubject<Variable[]>([]);

  constructor(
    private nikoApi: NikoApiService
  ) { super(); }

  /**
   * Get VariableList
   * @returns Observable list of Variables
   */
  public getVariables() {
    if(environment.mockData) {
      return of(variables);
    } else {
      return this.nikoApi.getVariables().pipe(
        map((variableList) => {
          //this.cachedVariables.next(VariableList.VariableList);
          //console.log(variableList);
          return variableList;
        })
      );
    }
  }

  public setVariables(projectId: string, clear: boolean, entries: string[]): Observable<any> {
    return this.nikoApi.setVariables(projectId, clear, entries);
  }

  /**
   *  Combine variables with project room instances
   * */ 
  public combineVariablesForProjectRooms(
    projectData: Project,
    variableList: Variable[],
    roomList: Room[],
    rooms: RoomInstance[]
    ): RoomInstance[] {

      // Filter only selected usecases
      let selectedUsecases: string[] = [];
      for(var usecase of projectData.useCases){
        if(usecase.selected == Ternary.TRUE) {
          selectedUsecases.push(usecase.xid); 
        }
      }

      // Filter applicable variables
      let applicableVariables = variableList
        .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        .filter(variable => {
          return variable.usecases.some(id => selectedUsecases.includes(id));
        });

      // Add required data from the roomlist to the room instances
      for(var roomInstance of rooms){
        
        let iterations = roomList.length;

        for(var room of roomList){
          // Set room image and default room title
          if(roomInstance.typeXid == room.xid) {
            roomInstance.imageFull = room.imageFull;
            roomInstance.location = room.location;
            roomInstance.title = room.name.toLowerCase().replace(/([a-z])/i, (str:string, firstLetter:string) => firstLetter.toUpperCase());

            break;
          }

          // Remove roominstance from array if the roomtype doesn't exist (anymore)
          // If the roomtype exists, the break has stopped the forloop
          if (!--iterations) {
            //console.log('last iterations', roomInstance.id);
            rooms = rooms.filter(instance => {
              return instance.id != roomInstance.id
            });
            //console.log(rooms);
          }
        }

        // Only allow variables without locations and with locations that match the room's locations
        let applicableVariablesForRoomLocation: Variable[] = 
        clone(applicableVariables)
        .filter((variable: Variable) => {
          return !variable.location || variable.location.length == 0 ||
          (variable.location && variable.location.some(location => roomInstance.location!.includes(location)));
        });

        roomInstance.variables = applicableVariablesForRoomLocation;

        // Replace variable defaults with projectdata variable values
        projectData.variables.forEach((variableData: VariableInstance) => {
          if(roomInstance.id == variableData.roomId) {
            roomInstance.variables?.forEach((variable) => {
              if(variable.xid == variableData.xid) {
                // If projectdata value for variable is 0, set to null to prevent presentation.
                if(parseInt(variableData.value) > 0) {
                  variable.values![roomInstance.typeXid] = parseInt(variableData.value);
                } else {
                  variable.values![roomInstance.typeXid] = null;
                }
              }
            })
          }
        });
      }

      return rooms;
  }

  /**
   * Persist all variable values on backend
   */
  public initVariablesForRooms(projectId: string, rooms: RoomInstance[]): Observable<any> {
    let entries: string[] = [];

    rooms.forEach((roomInstance: RoomInstance) => {
      roomInstance.variables?.forEach((variable: Variable) => {
        if(variable.values && variable.values[roomInstance.typeXid]! > 0) {
          entries.push(variable.xid + ':' + roomInstance.id + ':' + variable.values![roomInstance.typeXid]);
        } else if(variable.values && variable.values![roomInstance.typeXid] === null) {
          entries.push(variable.xid + ':' + roomInstance.id + ':' + 0);
        }
      })
    });

    return this.setVariables(projectId, true, entries);
  }

}