import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProjectModule, Property } from 'src/app/models/Project';
import { User } from 'src/app/models/User';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { SessionStorageKeys } from 'src/app/services/session-storage/session-storage-keys';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { UserService } from 'src/app/services/user/user.service';
import { setUserSettingValue } from 'src/app/util/Util';
import { BaseComponent } from '../../base.component';

export interface DialogData {
  'title': string;
  'confirmationData': any;
  'step': ProjectModule;
  'background': string;
}

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss']
})
export class OnboardingModalComponent  extends BaseComponent implements OnInit {

  public userData!: User;

  public onboardingStatus = 'visible';

  public onboardingListItems = [];

  public ProjectModule = ProjectModule;

  constructor(
    public dialogRef: MatDialogRef<OnboardingModalComponent>,
    private userService: UserService,
    private settingsService: SettingsService,
    private session: SessionStorageService,
    private localStorage: LocalStorageService,
    private translate: TranslateService,
    private gaService: GoogleAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    super();

    this.getUserData();
   }

  ngOnInit(): void {
    this.translate.get('ONBOARDING.' + this.data.step.toUpperCase() + '.LIST').subscribe(r => this.onboardingListItems = r);
  }

  /**
   * Get User data to show current answers
   */
   private getUserData() {
    this.userData = this.userService.user.getValue();

    this.checkOnboardingStatus();
  }

  /**
   * Check if onboarding needs to be shown
   */
  private checkOnboardingStatus() {    
    if(this.userData.settings) {
      this.onboardingStatus = this.userData.settings.find(p => p.name == 'user.onboarding.' + this.data.step)?.value ? this.userData.settings.find(p => p.name == 'user.onboarding.' + this.data.step)?.value! : 'visible'
    } else {
      let onboardingSettings: Property[] = this.localStorage.getObject(LocalStorageKeys.ONBOARDING) as Property[];
      if(onboardingSettings) {
        this.onboardingStatus = onboardingSettings.find(p => p.name == 'user.onboarding.' + this.data.step)?.value ? onboardingSettings.find(p => p.name == 'user.onboarding.' + this.data.step)?.value! : 'visible'
      } 
    }

    if(this.onboardingStatus == 'hidden') {
      this.closeDialog();
    }
  }

  /**
   * toggle hiding onboarding on user profile settings
   */
   public hideOnboardingOnProfile(hide: boolean) {
    
    this.gaService.event('checkbox_toggle', 'Onboarding Modal - ' + this.data.step, hide ? 'hide onboarding' : 'show onboarding');

    if(hide) {
      this.onboardingStatus = 'hidden';
    } else {
      this.onboardingStatus = 'visible';
    }

    this.settingsService.setSetting(['user.onboarding.' + this.data.step +':' + this.onboardingStatus]);

    setUserSettingValue(this.userData.settings, 'user.onboarding.' + this.data.step, this.onboardingStatus);

    this.userService.user.next(this.userData);

    let onboardingPropertyArray = (this.localStorage.getObject(LocalStorageKeys.ONBOARDING) ? this.localStorage.getObject(LocalStorageKeys.ONBOARDING) : []) as Property[];
    let onboardingProperty = new Property;
    onboardingProperty.name = 'user.onboarding.' + this.data.step;
    onboardingProperty.value = this.onboardingStatus;
    
    if(onboardingPropertyArray.some(x => x.name == onboardingProperty.name)) {
      onboardingPropertyArray = onboardingPropertyArray.filter(x => x.name != onboardingProperty.name)
    } 

    onboardingPropertyArray.push(onboardingProperty);

    this.localStorage.saveObject(LocalStorageKeys.ONBOARDING, onboardingPropertyArray);
  }

  /**
   * Close dialog and return the dialog data
   */
  public closeDialog() {
    this.dialogRef.close(this.data);
  }
}
