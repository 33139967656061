<div id="login" *ngIf="showLoginForm" gaCategory="Login">
  <div id="header" class="flex space-between">
    <img src="/assets/logo/niko_logo.svg" alt="Niko logo">

    <mat-form-field *ngIf="languages.length > 0">
      <mat-select [formControl]="language" disableRipple>
        <mat-option *ngFor="let option of languages" [value]="option">{{  'PREFERENCES.LANGUAGES.' + option | uppercase | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  
  <div id="content-wrapper">
    
    <p id="welcome" class="tooltip-trigger">{{ 'LOGIN.WELCOME' | translate}}
      <span class="tooltip-btn white" (click)="openTooltip('LOGIN.WELCOME','LOGIN.WELCOME.INFO')"></span>
    </p>

    <form [formGroup]="loginForm" (submit)="login($event)" autocomplete="on">
      <div class="username_wrapper relative">
        <label>{{ 'LOGIN.USERNAME' | translate}}</label>
        <input autocomplete="username" gaEvent="form_input" gaLabel="username" type="text" formControlName="username">
        <label class="error" *ngIf="formChecked && loginForm.get('username')?.invalid">{{ 'LOGIN.ERROR.USERNAME' | translate}}</label>
        <label class="error" *ngIf="accountNotFound && !loginForm.get('username')?.invalid">{{ 'LOGIN.ERROR.LOGIN' | translate}}</label>
      </div>
      <div class="password_wrapper relative">
        <label>{{ 'LOGIN.PASSWORD' | translate}}</label>
        <input autocomplete="current-password" gaEvent="form_input" gaLabel="password" type="password" formControlName="password">
        <label class="error" *ngIf="formChecked && loginForm.get('password')?.invalid">{{ 'LOGIN.ERROR.PASSWORD' | translate}}</label>
      </div>
      <div class="relative">
        <label class="checkbox"><span [innerHTML]="'LOGIN.TERMS' | translate"></span>
          <input type="checkbox" formControlName="terms">
          <span class="checkmark"></span>
        </label>
        <label class="error" *ngIf="formChecked && loginForm.get('terms')?.invalid">{{ 'LOGIN.ERROR.TERMS' | translate}}</label>
      </div>
      <div class="login_wrapper relative">
        <button [ngClass]="{disabled: loginForm.invalid}" type="submit" class="btn btn-primary btn--margin btn--shadow block-center">{{ 'LOGIN.LOGIN' | translate}}</button>
      </div>
    </form>
  </div>
</div>