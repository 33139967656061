

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Project, Property } from 'src/app/models/Project';
import { Room, RoomInstance } from 'src/app/models/Room';
import { Calculation, InstallType, CalculationEntry, Article } from 'src/app/models/System';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { RoomService } from 'src/app/services/room/room.service';
import { SystemService } from 'src/app/services/system/system.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/User';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-results-summary',
  templateUrl: './results-summary.component.html',
  styleUrls: ['./results-summary.component.scss']
})
export class ResultsSummaryComponent extends BaseComponent implements OnInit {

  public hideCalculationLog = true;

  public projectId!: string;

  public calculation?: Calculation;

  public toggleSelected: {
    [roomId: string]: boolean
  } = {};

  public sideBarContent: AppRouteObject[] = [];

  constructor(    
    private translate: TranslateService,
    public systemService: SystemService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private propertyService: PropertyService,
    private roomService: RoomService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) {
    super();

    this.hideCalculationLog = environment.production;

  }

  ngOnInit(): void {
    this.SetSideBarContent();

    this.projectId = this.activatedRoute.snapshot.params['project_id'];

    if(this.projectService.calculation) {
      this.calculation = this.projectService.calculation;
    }
  }

  /**
   * Copy the calculation log to the clipboard
   * @param log the calculated log text to add to the clipboard
   */
   copyLog(log:string) {
    navigator.clipboard.writeText(log).then( () => {
      this.snackBar.open('The calculation log has been copied to your clipboard.', undefined, {
        duration: 3000,
        horizontalPosition: 'right',
        panelClass: 'snackbar'
    });    }).catch( () => {
      this.snackBar.open('Something went wrong. The log was not copied to your clipboard.', undefined, {
        duration: 3000,
        horizontalPosition: 'right',
        panelClass: 'snackbar'
    });    });
  }

  /**
   * Add dynamic content for sidebar navigation
   */
  SetSideBarContent() {
    this.sideBarContent = [];

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_CALCULATION'].value, 
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.CALCULATION'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.CALCULATION_FAILED'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });
  }

}
