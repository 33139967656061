<app-overview-page-template 
[breadcrumbBack]="'PROJECT_DETAIL.TITLE' | translate"
[routerlinkBack]="'../'"
>
  <div id="content" *ngIf="project && project.documents">

    <h1 class="margin-b-30">{{ "PROJECT_DOCUMENTS.TITLE" | translate }}</h1>

    <div class="title-wrapper margin-b-30">    
      <mat-icon svgIcon="commercial_doc" class="icon"></mat-icon>
      <h2 class="h1 no-margin">{{ "PROJECT_DOCUMENTS.COMMERCIAL" | translate }}</h2>
    </div>

    <div class="margin-b-50" *ngIf="!CommercialDocuments || CommercialDocuments.length == 0">
      <p>{{ 'PROJECT_DOCUMENTS.NO_COMMERCIAL' | translate }}</p>
    </div>

    <table class="content-container margin-b-50 no-padding" *ngIf="CommercialDocuments && CommercialDocuments.length > 0">   
      <tr>
        <th class="timestamp-cell pointer yellow-text" (click)="sortCommercialDocuments('created')">
          {{ "PROJECT_DOCUMENTS.CREATED" | translate }}&nbsp;
          <mat-icon *ngIf="commercialAscending_created" class="icon icon--small">expand_more</mat-icon>
          <mat-icon *ngIf="!commercialAscending_created" class="icon icon--small">expand_less</mat-icon>
        </th>
        <th>{{ "PROJECT_DOCUMENTS.DESCRIPTION" | translate }}
        </th>
        <th colspan="2" class="button-cell">{{ "PROJECT_DOCUMENTS.ACTIONS" | translate }}
        </th>
      </tr>

      <ng-container *ngFor="let document of CommercialDocuments">
        <tr *ngIf="document.type == DocumentType.COMMERCIAL">
          <td class="no-wrap">{{ document.created | date: 'dd/MM/yyyy' }} - <span class="greyed-out">{{ document.created | date: 'HH:mm' }}</span></td>
          <td>
            
            <div>

              <span *ngIf="!toggleDescriptionEdit[document.id]">{{document.description}}</span>

              <mat-form-field *ngIf="toggleDescriptionEdit[document.id]">
                <textarea matInput [formControl]="description[document.id]" [placeholder]="'PROJECT_ROOMEDIT.PLACEHOLDER' | translate"></textarea>
              </mat-form-field>

              <span *ngIf="document.current && !toggleDescriptionEdit[document.id]" class="font-m greyed-out">
                <br>
                {{ 'PROJECT_DOCUMENTS.CURRENT' | translate}}
              </span>
            </div>

          </td>
          <td class="button-cell flex space-between gap-20">
            <div>
              <div class="action-container flex space-between gap-20">
                <button *ngIf="!toggleDescriptionEdit[document.id]" (click)="toggleDescriptionEdit[document.id] = true" class="btn-round-icon action-edit">
                  <mat-icon svgIcon="pencil" class="icon"></mat-icon>
                </button>
                <button *ngIf="toggleDescriptionEdit[document.id]" (click)="toggleDescriptionEdit[document.id] = false; setDescription(document.id);" class="btn-round-icon active action-edit">
                  <mat-icon class="icon">check</mat-icon>
                </button>
                <button *ngIf="toggleDescriptionEdit[document.id]" (click)="toggleDescriptionEdit[document.id] = false; " class="btn-round-icon action-edit">
                  <mat-icon class="icon">close</mat-icon>
                </button>
              </div></div>
            <div>
              <div class="button-wrapper">
                <button class="btn btn-small" (click)="downloadDocument(document.id)">
                  {{ 'PROJECT_DOCUMENTS.DOWNLOAD' | translate}}
                </button>
                <button class="btn btn-small btn-white" (click)="openRemoveDocumenDialog(document.id)">
                  {{ 'PROJECT_DOCUMENTS.DELETE' | translate}}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </table>

   
    <div class="title-wrapper margin-b-30">      
      <mat-icon svgIcon="product_list" class="icon"></mat-icon>
      <h2 class="h1 no-margin">{{ "PROJECT_DOCUMENTS.BOM" | translate }}</h2>
    </div>

    <div class="margin-b-50" *ngIf="!BomDocuments || BomDocuments.length == 0">
      <p>{{ 'PROJECT_DOCUMENTS.NO_BOM' | translate }}</p>
    </div>

    <table class="content-container margin-b-30 no-padding" *ngIf="BomDocuments && BomDocuments.length > 0">
      <tr>
        <th class="timestamp-cell pointer yellow-text" (click)="sortBomDocuments('created')">
          {{ "PROJECT_DOCUMENTS.CREATED" | translate }}&nbsp;
          <mat-icon *ngIf="bomAscending_created" class="icon icon--small">expand_more</mat-icon>
          <mat-icon *ngIf="!bomAscending_created" class="icon icon--small">expand_less</mat-icon>
        </th>
        <th>{{ "PROJECT_DOCUMENTS.DESCRIPTION" | translate }}
        </th>
        <th colspan="2" class="button-cell">{{ "PROJECT_DOCUMENTS.ACTIONS" | translate }}
        </th>
      </tr>

      <ng-container *ngFor="let document of BomDocuments">
        <tr *ngIf="document.type == DocumentType.BOM">
          <td class="no-wrap">{{ document.created | date: 'dd/MM/yyyy' }} - <span class="greyed-out">{{ document.created | date: 'HH:mm' }}</span></td>
          <td>
            <div>

              <span *ngIf="!toggleDescriptionEdit[document.id]">{{document.description}}</span>

              <mat-form-field *ngIf="toggleDescriptionEdit[document.id]">
                <textarea matInput [formControl]="description[document.id]" [placeholder]="'PROJECT_ROOMEDIT.PLACEHOLDER' | translate"></textarea>
              </mat-form-field>

              <span *ngIf="document.current && !toggleDescriptionEdit[document.id]" class="font-m greyed-out">
                <br>
                {{ 'PROJECT_DOCUMENTS.CURRENT' | translate}}
              </span>
            </div>
          </td>
          <td class="button-cell flex space-between gap-20">
            <div>
              <div class="action-container flex space-between gap-20">
                <button *ngIf="!toggleDescriptionEdit[document.id]" (click)="toggleDescriptionEdit[document.id] = true" class="btn-round-icon action-edit">
                  <mat-icon svgIcon="pencil" class="icon"></mat-icon>
                </button>
                <button *ngIf="toggleDescriptionEdit[document.id]" (click)="toggleDescriptionEdit[document.id] = false; setDescription(document.id);" class="btn-round-icon active action-edit">
                  <mat-icon class="icon">check</mat-icon>
                </button>
                <button *ngIf="toggleDescriptionEdit[document.id]" (click)="toggleDescriptionEdit[document.id] = false; " class="btn-round-icon action-edit">
                  <mat-icon class="icon">close</mat-icon>
                </button>
              </div></div>
            <div>
              <div class="button-wrapper">
                <button class="btn btn-small" (click)="downloadDocument(document.id)">
                  {{ 'PROJECT_DOCUMENTS.DOWNLOAD' | translate}}
                </button>
                <button class="btn btn-small btn-white" (click)="openRemoveDocumenDialog(document.id)">
                  {{ 'PROJECT_DOCUMENTS.DELETE' | translate}}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </table>

  </div>
</app-overview-page-template>