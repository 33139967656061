import { Injectable } from '@angular/core';
import { map, of, ReplaySubject, take } from 'rxjs';
import { PartnerConnection } from 'src/app/models/PartnerConnection';
import { Property } from 'src/app/models/Project';
import { Subject, subjects, Ternary, Usecase } from 'src/app/models/Subject';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { projectPropertyValue } from 'src/app/util/Util';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  public cachedSubjects: ReplaySubject<Subject[]> = new ReplaySubject<Subject[]>(1);
  public cachedSubjectsEmpty: boolean = true;
  
  public sessionSubjects: ReplaySubject<Subject[]> = new ReplaySubject<Subject[]>(1);

  public sessionIncompatibleUsecases: {
    [subjectId: string]: Usecase[]
  } = {};

  public sessionChosenPartner: {
    [subjectId: string]: PartnerConnection | undefined
  } = {};
  
  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get Subjects
   * @returns Observable list of subjects
   */
  public getSubjects() {
    if(environment.mockData) {
      return of(subjects);
    } else {
      return this.nikoApi.getSubjects().pipe(
        map((subjectList) => {
          this.cachedSubjectsEmpty = false;
          this.cachedSubjects.next(subjectList);
          return subjectList
        })
      );
    }
  }

  public selectUsecaseState(projectId: string, useCaseString: string) {
    this.nikoApi.selectUsecaseState(projectId, useCaseString).pipe(take(1)).subscribe(r => {
      return r;
    });
  }

  public selectSubjectState(projectId: string, subjectString: string) {
    this.nikoApi.SelectSubjectState(projectId, subjectString).pipe(take(1)).subscribe(r => {
      return r;
    });
  }


    /**
   * Change disabled status for usecases by conditions based on current project settings
   * @param subjects Array of  subjects to be filtered
   * @param properties Project properties to be checked
   * @returns subjects with correct conditionally disabled status on usecases
   */
     public disableUsecasesByConditions(projectId: string, subjects: Subject[], properties: Property[]): Subject[] {
      let useCaseString = '';

      // Solar panels
      let solarPanels = projectPropertyValue(properties, 'solarpanels');
      console.log('solarpanels: ',solarPanels)

      // Digital energy meter
      let digitalMeter = projectPropertyValue(properties, 'digitalenergymeter');
      console.log('digitalenergymeter: ',digitalMeter)

      // Check conditionallyDisabled for all subjects and usecases
      // If no conditions apply but the usecase is still disabled, set it to false
      subjects.forEach(subject => {
        subject.useCases!.forEach(useCase => {
          if(
            useCase.conditions?.includes('solarPanelsYes') && (solarPanels != Ternary.TRUE) ||
            useCase.conditions?.includes('solarPanelsNo') && (solarPanels != Ternary.FALSE) ||
            useCase.conditions?.includes('digitalMeterYes') && (digitalMeter != Ternary.TRUE) ||
            useCase.conditions?.includes('digitalMeterNo') && (digitalMeter != Ternary.FALSE) 
            ) {
            useCase.selected = Ternary.DISABLED;
            useCaseString = useCaseString + Ternary.DISABLED + '=' + useCase.xid + '&';
          } else if(useCase.selected == Ternary.DISABLED) {
            useCase.selected = Ternary.FALSE;
            useCaseString = useCaseString + Ternary.FALSE + '=' + useCase.xid + '&';
          }
        });
      });

      // Persist changes to backend
      if(useCaseString != '') {
        this.selectUsecaseState(projectId, useCaseString);
      }
  
      return subjects;
    }
}
