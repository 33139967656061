import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionStorageKeys } from 'src/app/services/session-storage/session-storage-keys';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

/**
 * Interceptor to add auth headers on requests before sending them to backend
 * @author: Lennart
 */

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {
  
  constructor(
      private userService: UserService,
      private session: SessionStorageService
  ) {}

    /**
     * Add authorization headers and jsessionid cookie to request as required
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Add authorization header on backend requests
        if (request.url.includes(environment.endpoint + '/user/profile')) {
          /* console.log('/user/profile matched'); */

          let user = this.userService.user.getValue();
          let authorizationData;

          if(user && user.username && user.password) {
            authorizationData = btoa(user.username + ':' + user.password);
            console.log('user has data: ', authorizationData);
          } else {
            authorizationData = this.session.getItem(SessionStorageKeys.USER);
            console.log('authdata from sessions: ', authorizationData);
          }

          if(authorizationData) {
            /* console.log('add authorizationdata header'); */

            request = request.clone({
                setHeaders: {  
                  'Authorization': 'Basic ' + authorizationData
                }
            });
          }
        }
        
        // Add withCredentials and content-type header on backend requests
        if (request.url.includes(environment.endpoint)) {
          request = request.clone({
            setHeaders:{
              'Content-Type': 'application/x-www-form-urlencoded'
            }, 
            withCredentials: true,
          }); 
        }
            
        return next.handle(request);
    }
}

export enum HttpMethod {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET'
}