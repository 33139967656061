import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends BaseComponent implements OnInit {

  constructor(
    private gaService: GoogleAnalyticsService
  ) { super(); }

  ngOnInit(): void {
    this.gaService.set({'project_id': null});
  }

}
