import { Finishing } from "./Finishing";
import { InteriorType } from "./InteriorType";
import { RoomInstance } from "./Room";
import { Subject, Usecase } from "./Subject";
import { VariableInstance } from "./Variable";

export class Project {
  id!: string;
  useCases!: Usecase[];
  subjects!: Subject[];
  rooms!: RoomInstance[];
  variables!: VariableInstance[];
  finishings!: Finishing[];
  interiorTypes!: InteriorType[];
  properties!: Property[];
  modified!: string;
  activeStep!: string;
  status!: ProjectStatus;
  documents?: Document[];
}

export class Property {
  name!: string;
  value!: string;
}

export class ProjectStatus {
  activeStep!: string;
  accessibleSteps!: string[];
  issues!: Issue[];
}

export class Issue {
  level!: string;
  link!: string;
  name!: string;
  step!: string;
  index!: number;
}

export class Document {
  created!: string;
  description!: string;
  id!: string;
  type!: DocumentType;
  current!: boolean;
}

export enum ProjectState {
  ACTIVE      = 'active',
  ARCHIVED    = 'archived'
}

export enum DocumentType {
  COMMERCIAL = 'COMMERCIAL',
  BOM = 'BOM' 
}

export enum ProjectModule {
  INTAKE = 'intake',
  FUNCTIONALITIES = 'functionalities',
  ROOMCONFIGURATION = 'roomconfiguration',
  FINISHING = 'finishing',
  RESULTS = 'results',
}
