

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BaseComponent } from 'src/app/components/base.component';
import { RoomInstance } from 'src/app/models/Room';
import { Usecase } from 'src/app/models/Subject';
import { RoomService } from 'src/app/services/room/room.service';

export interface DialogData {
  'incompatibleUsecases': Usecase[];
  'deactivateUsecases': boolean;
}

@Component({
  selector: 'app-partner-compatibility-modal',
  templateUrl: './partner-compatibility-modal.component.html',
  styleUrls: ['./partner-compatibility-modal.component.scss']
})
export class PartnerCompatibilityModalComponent extends BaseComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PartnerCompatibilityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private gaService: GoogleAnalyticsService,
    private translate: TranslateService
  ) {
    super();
    //dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.data)); // Data should only be returned if confirm is clicked
   }

  ngOnInit(): void {
    this.gaService.event('modal_open', 'Modals', 'Partner Compatibility Modal');
  }

  confirm() {
    this.data.deactivateUsecases = true;
    this.dialogRef.close(this.data);
  }
}
