import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/components/base.component';
import { Country } from 'src/app/models/Country';
import { PartnerConnection } from 'src/app/models/PartnerConnection';
import { Project } from 'src/app/models/Project';
import { Room, RoomInstance } from 'src/app/models/Room';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { BuildingTypeService } from 'src/app/services/building-type/building-type.service';
import { ConstructionTypeService } from 'src/app/services/construction-type/construction-type.service';
import { CountryService } from 'src/app/services/country/country.service';
import { PartnerConnectionService } from 'src/app/services/partner-connection/partner-connection.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { RoomService } from 'src/app/services/room/room.service';

@Component({
  selector: 'app-intake-summary',
  templateUrl: './intake-summary.component.html',
  styleUrls: ['./intake-summary.component.scss']
})
export class IntakeSummaryComponent extends BaseComponent implements OnInit {

  public projectId!: string;
  public projectData!: Project;

  public partners!: PartnerConnection[];

  public countries!: Country[];
  public currentCountry!: string;
  public currentLang!: string;

  public buildingType?: string;
  public constructionType?: string;

  public rooms:Room[] = [];
  public roomData:RoomInstance[] = [];

  public toggleSelected: {
    [id: string]: boolean
  } = {};

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private nikoApi: NikoApiService,
    private translate: TranslateService,
    private countryService: CountryService,
    private activatedRoute: ActivatedRoute,
    private buildingTypeService: BuildingTypeService,
    private constructionTypeService: ConstructionTypeService,
    private roomService: RoomService,
    private projectService: ProjectService
  ) { super(); }

  ngOnInit(): void {
    this.SetSideBarContent();

    this.projectId = this.activatedRoute.snapshot.params['project_id'];    
    this.getProjectData();

    // Get countries
    this.countryService.getCountries().subscribe((r:Country[]) => {
      this.countries = r.sort((a,b) => a.order - b.order);
    })
    
    // Default country
    this.currentCountry = this.nikoApi.countryCode.toUpperCase();
    
    // Default language
    this.translate.currentLang ? this.currentLang = this.translate.currentLang : this.currentLang = this.translate.defaultLang;
  }

  /**
   * Get project data to show current partners
   */
  private getProjectData() {
    this.projectService.getProjectById(this.projectId).subscribe(r => {
    this.projectData = r;
      this.showRooms();
    });
  }

  /**
   * Combine roomdata and populate rooms for presentation
   */
  private showRooms() {
      // Rooms
      let roomData = this.projectData.rooms;
      let buildingType = this.projectData.properties.find(p => p.name == 'project.buildingtype')!.value;
      
      // Get the project building type title
      this.buildingTypeService.getBuildingTypes().subscribe(buildingTypes => {
        this.buildingType = buildingTypes.find(type => type.xid == buildingType)?.title;
      });

      let constructionType = this.projectData.properties.find(p => p.name == 'project.projecttype')?.value;

      this.constructionTypeService.getConstructionTypes().subscribe(projectTypes => {
        this.constructionType = projectTypes.find(type => type.xid == constructionType)?.title;
      });

      // Get project rooms
      this.roomService.getRooms(buildingType).subscribe(roomList => {
        this.rooms = roomList;

        // Array of roomIds
        let currentRoomIds = roomData.map(room => room.typeXid); 

        // Object with unique roomIds and amount of occurance
        const roomCount = currentRoomIds.reduce((acc: any, curr: any) => (acc[curr] = (acc[curr] || 0) + 1, acc), {}); 

        this.rooms.forEach(room => {
          if(roomCount[room.xid] > 0) {
            room.count = roomCount[room.xid].toString();
          } else {
            room.count = '0';
          }
        })

        this.rooms = this.rooms.filter(x => parseInt(x.count) > 0);
      });
  }

  public countryName(code: string): string | undefined {
    let countryName;

    countryName = this.countries.find(country => country.code == code)?.name;

    return countryName
  }

  public propertyValue(name: string): string | undefined {
    let value = this.projectData.properties.find(p => {
      return p.name == 'project.' + name;
    })?.value;

    if(name == 'country' && !value){ value = this.currentCountry}
    if(name == 'language' && !value){ value = this.currentLang}

    return value;
  }

  /**
   * Add dynamic content for sidebar navigation
   */
   SetSideBarContent() {
    this.sideBarContent = [];
    
    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_CLIENT_DETAILS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.DETAIL_CLIENT'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_BUILDINGTYPE'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.BUILDINGTYPE'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_UTILITIES'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.UTILITIES'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });
    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_ROOMS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.ROOMS'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.SUMMARY'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });
  }
}
