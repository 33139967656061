<app-project-page-template [breadcrumb]="'PROJECT_FINISHINGS.CATALOG.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_FINISHINGS.CATALOG.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../../' + APP_ROUTES['FINISHING_STYLES'].value"
  [breadcrumbNext]="'PROJECT_FINISHINGS.CATALOG.BREADCRUMB_NEXT' | translate"
  [routerlinkNext]="'../../' + APP_ROUTES['FINISHING_GENERAL_INDOOR'].value"
  [sideBarContent]="sideBarContent">

  <div id="content" *ngIf="finishingRanges && finishingRanges.length > 0">
    <div class="title-wrapper margin-b">
      <h1 class="no-margin tooltip-trigger" >
        {{ 'PROJECT_FINISHINGS.CATALOG.TITLE' | translate }}
        <span class="tooltip-btn" (click)="openTooltip('PROJECT_FINISHINGS.CATALOG.TITLE','PROJECT_FINISHINGS.CATALOG.TITLE.INFO')"></span>
      </h1>
      <a class="font-m font-underline pointer"
        [routerLink]="'../../'+ APP_ROUTES['FINISHING_RECOMMENDED'].value">{{'PROJECT_FINISHINGS.CATALOG.RECOMMENDED' |
        translate}}</a>
    </div>
    <div *ngFor="let finishingRange of finishingRanges">
      <hr>
      <h3 class="margin-b dark-grey">{{ finishingRange.title }}</h3>
      <p class="margin-b dark-grey" [innerHTML]="finishingRange.description"></p>

      <div class="finishing-container">

        <div class="content-container" *ngFor="let finishing of finishingRange.finishings">
          <div class="finishing-wrapper">
            <img class="finishing-img"
              [src]="finishing.images ? finishing.images[0] : ''">
            <div class="text-wrapper">
              <p class="font-l">{{ finishing.title }}</p>
              
              <div class="check-button pointer" *ngIf="!finishing.usedInRoom && finishing.selected != Ternary.SELECTED">
                <div (click)="selectFinishing(finishing)" class="btn-icon">
                  <mat-icon *ngIf="finishing.selected == Ternary.TRUE"
                    [svgIcon]="'check'" class="icon"></mat-icon>
                  <mat-icon *ngIf="finishing.selected == Ternary.FALSE" [svgIcon]="'check_unchecked'" class="icon">
                  </mat-icon>
                </div>
              </div>

              <div class="check-button" *ngIf="finishing.usedInRoom || finishing.selected == Ternary.SELECTED">
                <div (click)="openFinishingUsedWarning(finishing.usedInRoom ? 'PROJECT_FINISHINGS.ERROR.FINISHING_USED' : 'PROJECT_FINISHINGS.ERROR.FINISHING_GENERAL')" class="btn-icon greyedout">
                  <mat-icon *ngIf="finishing.selected == Ternary.TRUE || finishing.selected == Ternary.SELECTED"
                    [svgIcon]="'check'" class="icon"></mat-icon>
                  <mat-icon *ngIf="finishing.selected == Ternary.FALSE" [svgIcon]="'check_unchecked'" class="icon">
                  </mat-icon>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</app-project-page-template>