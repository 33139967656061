import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule as NgxTranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Observable, forkJoin } from 'rxjs';

/**
 * Module responsible for translations, uses ngx-translate
 * Date: 05/03/2020
 * Time: 14:30
 *
 * @author: thijsvm
 */
function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [],
  imports: [
    NgxTranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  providers: [
    {
      // load translations before bootstrap application, this guarantees the instant method of the translation service will return a value
      provide: APP_INITIALIZER,
      useFactory: loadTranslations,
      multi: true,
      deps: [
        TranslateService
      ]
    }
  ],
  exports: [
    NgxTranslateModule
  ]
})
export class TranslationModule {
  constructor(private translate: TranslateService) {
    // Configure language
    translate.setDefaultLang(environment.defaultLanguage);
  }
}

/**
 * Load translations for ngx-translate
 * @param translate service
 */
export function loadTranslations(translate: TranslateService) {
  return () => {
    translate.addLangs(environment.languages);
    return new Promise((resolve: (value: unknown) => void, reject: (reason?: any) => void) => {
      const translations: Observable<any>[] = new Array();
      translate.getLangs().forEach((lang: string) => {
        translations.push(translate.getTranslation(lang));
      });
      forkJoin(translations).subscribe(() => {
        resolve(null);
      });
    });
  };
}
