import { Injectable } from '@angular/core';
import { BehaviorSubject, map, of } from 'rxjs';
import { Room, rooms } from 'src/app/models/Room';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  public cachedRooms: BehaviorSubject<Room[]> = new BehaviorSubject(rooms);
  /* public sessionRooms: BehaviorSubject<Room[]> = new BehaviorSubject(Rooms.RoomList); */

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get RoomList
   * @returns Observable list of Rooms
   */
  public getRooms(buildingType: string) {
    if(environment.mockData) {
      return of(rooms);
    } else {
      return this.nikoApi.getRooms(buildingType).pipe(
        map((roomList) => {
          this.cachedRooms.next(roomList);
          return roomList;
        })
      );
    }
  }

  public addRoom(projectId: string, roomString: string) {
    return this.nikoApi.addRoom(projectId, roomString).pipe(
      map((roomList) => {
        // this.cachedRooms.next(roomList);
        return roomList;
      })
    );
  }

  public editRoom(projectId: string, roomString: string) {
    return this.nikoApi.editRoom(projectId, roomString).subscribe(r => r);
  }

  public removeRoom(projectId: string, roomId: string) {
    return this.nikoApi.removeRoom(projectId, roomId);
  }
}