import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { Project, Property } from 'src/app/models/Project';
import { Subject, Ternary, Usecase } from 'src/app/models/Subject';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { clone } from 'src/app/util/Util';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent  extends BaseComponent implements OnInit {

  public projectId!: string;
  public projectData!: Project;

  public subjects: Subject[] = [];

  public extraInfo!: UntypedFormControl;

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private nikoApi: NikoApiService,
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private projectService: ProjectService,
    private subjectService: SubjectService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private _ngZone: NgZone
  ) { super(); }

  ngOnInit(): void {
    this.SetSideBarContent();

    this.projectId = this.activatedRoute.snapshot.params['project_id'];    
    this.getProjectData();
  }

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  /**
   * Get project data to show current answers
   */
     private getProjectData() {
      this.projectService.getProjectById(this.projectId).subscribe(r => {
        this.projectData = r;

        this.extraInfo = new UntypedFormControl(this.propertyValue('extrainformation'), [Validators.maxLength(500)]);

        // Set default for solarpanels if not set
        if(!this.propertyValue('solarpanels')) {
          this.saveProperty('solarpanels', Ternary.TRUE);
        }
        
        // Set default for gas if not set
        if(!this.propertyValue('gas')) {
          this.saveProperty('gas', Ternary.FALSE);
        }

        // Get subjects
        if(this.projectData.useCases.length > 0) {
          this.addSubscription(this.subjectService.cachedSubjects.subscribe((r: Subject[]) => {
            this.subjects = this.subjectService.disableUsecasesByConditions(this.projectId, clone(r), this.projectData.properties);
          }));
        }
      });
    }

   public propertyValue(name: string): string | undefined {
      let value = this.projectData.properties.find(p => {
        return p.name.includes(name)
      })?.value;
      return value;
    }

  /**
   * Save one or multiple property values to backend
   * @param name name of the formcontrol and the property to be saved
   */
  public saveProperty(name: string, value: string) {
    
    let property = this.projectData.properties.find(p => {
      return p.name.includes(name)
    });

    if(property) {
      property.value = value;
    } else {
      property = new Property;
      property.name = 'project.' + name;
      property.value = value;

      this.projectData.properties.push(property);
    }

    //console.log(['project.' + name + (value ? (':' + value) : '')]);
    this.propertyService.setProperty(this.projectId, ['project.' + name + (value ? (':' + value) : '')]);

    // If properties that are linked to usecase conditions are changed, check usecases and save to cachedSubjects
    if(this.subjects.length > 0 && (name == 'solarpanels' || name == 'digitalenergymeter')) {
      this.subjects = this.subjectService.disableUsecasesByConditions(this.projectId, this.subjects, this.projectData.properties);
      this.subjectService.cachedSubjects.next(this.subjects);
    }
  }

    /**
   * Open tooltip modal
   */
    public openTooltip(title: string = '', text: string) {
      const dialogRef = this.dialog.open(TooltipModalComponent, {
        autoFocus: false, 
        data: {
          'title': title,
          'text': text
        },
        maxWidth: '90vw',
        maxHeight: '90vh',
        minWidth: '775px'
      });
    }

  /**
   * Add dynamic content for sidebar navigation
   */
   SetSideBarContent() {
    this.sideBarContent = [];

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_CLIENT_DETAILS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.DETAIL_CLIENT'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_BUILDINGTYPE'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.BUILDINGTYPE'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_UTILITIES'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.UTILITIES'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_ROOMS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.ROOMS'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.SUMMARY'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });
  }
}
