import { Ternary } from "./Subject";

export class InteriorType {
  xid!: string;
  title!: string;
  description!: string;
  images!: string[];
  finishings!: string[] | null;
  selected?: Ternary;
}

export const interiorTypes: InteriorType[] = [
  {
      "xid": "54048",
      "title": "Cosy bubble",
      "description": "Your home is a safe haven where you can escape and leave the stress of the world around you. But you also want it to be a place where you can connect with your friends and family. That is why you fill your interior with soft and welcoming textures such as wool, fur or velvet. In your home, the outside world comes to a stop.",
      "images": [
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575494__DSA-InteriorTypes/FrancqColors%20TrendsTheNest1%20112593%20Intense%20white.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575495__DSA-InteriorTypes/FrancqColors%20TrendsTheNest1%20112113%20Intense%20white.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575496__DSA-InteriorTypes/FrancqColors%20TrendsTheNest1%20111744%20Pure%20natural%20soft%20grey.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575497__DSA-InteriorTypes/FrancqColors%20TrendsTheNest1%20112554%20Pure%20natural%20soft%20grey.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575526__DSA-InteriorTypes/FrancqColors%20TrendsSurreal2%2051108%20Intense%20anthracite.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575499__DSA-InteriorTypes/FrancqColors%20TrendsTheHub1%20116171%20Pure%20natural%20red.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575527__DSA-InteriorTypes/FrancqColors%20TrendsInstinctive2%2060078%20Original%20greige.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575528__DSA-InteriorTypes/FrancqColors%20TrendsAntropos%20193043%20Intense%20anthracite.jpg"
      ],
      "finishings": [
          "59644",
          "59641",
          "59646"
      ]
  },
  {
      "xid": "54049",
      "title": "Pure silence",
      "description": "The world around us is moving faster than ever, and we all need a little peace and quiet at times. You bring your interior back to the essence: natural materials, earthlike colours and subtle details. Every object, accessory or shape has a meaningful purpose. Your home offers you structure, peace, and silence.",
      "images": [
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575477__DSA-InteriorTypes/FrancqColors%20TrendsPrinciple%200011%20Pure%20white%20steel.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575478__DSA-InteriorTypes/FrancqColors%20TrendsPrinciple%200004%20Pure%20champagne%20steel.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575479__DSA-InteriorTypes/FrancqColors%20TrendsPrinciple%200001%20Pure%20champagne%20steel.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575489__DSA-InteriorTypes/FrancqColors%20TrendsTranquili2%20109067%20Intense%20matt%20black.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575530__DSA-InteriorTypes/FrancqColors%20TrendsTranquili2%20108212%20Intense%20matt%20black.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575491__DSA-InteriorTypes/FrancqColors%20TrendsTranquili2%20107158%20Original%20white.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575532__DSA-InteriorTypes/FrancqColors%20TrendsPrinciple2%2035411%20Pure%20black%20steel.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575492__DSA-InteriorTypes/FrancqColors%20TrendsTranquili1%2096341%20Pure%20natural%20soft%20grey.jpg"
      ],
      "finishings": [
          "59643",
          "59647",
          "59640"
      ]
  },
  {
      "xid": "54050",
      "title": "Refined focus",
      "description": "You know what you want and how to reach your goal. Even in times of stress and trouble, you stay calm and composed. Your home reflects this focus. You like contrast, natural colours, and qualitative materials such as wood, concrete and heavy fabrics. People that visit your home feel secure and reassured.",
      "images": [
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575531__DSA-InteriorTypes/FrancqColors%20TrendsTranquili1%2095279%20Pure%20natural%20soft%20grey_1.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575534__DSA-InteriorTypes/FrancqColors%20TrendsHemisphere%2028792%20Intense%20anthracite.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575536__DSA-InteriorTypes/FrancqColors%20TrendsEmpathic1%2058123%20Intense%20anthracite.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575537__DSA-InteriorTypes/FrancqColors%20TrendsOccult%20400729b%20Pure%20alu%20grey.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575509__DSA-InteriorTypes/FrancqColors%20TrendsOccult%20400586%20Pure%20Bakelite%20piano%20black.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575510__DSA-InteriorTypes/FrancqColors%20TrendsPastoral2%2067093%20Intense%20bronze.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575511__DSA-InteriorTypes/FrancqColors%20TrendsRevive2%20417657%20Pure%20dark%20bamboo%20on%20anthracite.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575512__DSA-InteriorTypes/FrancqColors%20TrendsTheNest1%20112593%20Intense%20white_1.jpg"
      ],
      "finishings": null
  },
  {
      "xid": "54051",
      "title": "Elegant power",
      "description": "Your home is a sophisticated take on classic glamour. The way you decorate it shows your ambition, determination and confidence. You don't compromise when choosing the materials and accessories in your home. You captivate your visitors with quality, authenticity and elegance.",
      "images": [
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575488__DSA-InteriorTypes/FrancqColors%20TrendsCynic%200004%20Pure%20dark%20bamboo%20on%20anthracite.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575535__DSA-InteriorTypes/FrancqColors%20TrendsForsake1%2077619%20Pure%20Bakelite%20piano%20black_1.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575487__DSA-InteriorTypes/FrancqColors%20TrendsCynic%200008%20Intense%20anthracite.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575515__DSA-InteriorTypes/FrancqColors%20TrendsOccult%20400898%20Intense%20anthracite.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575516__DSA-InteriorTypes/FrancqColors%20TrendsEnhance2%2082415%20Intense%20sterling.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575533__DSA-InteriorTypes/FrancqColors%20TrendsForsake1%2077572%20Pure%20Bakelite%20piano%20black_1.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575485__DSA-InteriorTypes/FrancqColors%20TrendsCynic%200019%20Pure%20Bakelite%20piano%20black.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575529__DSA-InteriorTypes/FrancqColors%20TrendsTranquili2%20108166%20Original%20white_1.jpg"
      ],
      "finishings": null
  },
  {
      "xid": "54052",
      "title": "Bold energy",
      "description": "Your home reflects your independence: you are strong, proud, and even a bit rebellious. You like strong colours and materials that stand out. That is why you are not afraid to add bold details to each space and combine materials in a daring but well thought-through way. In truth? There is no other interior like yours.",
      "images": [
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575518__DSA-InteriorTypes/FrancqColors%20TrendsEnhance2%2082709%20Intense%20white.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575519__DSA-InteriorTypes/FrancqColors%20TrendsInstinctive2%2060308%20Original%20white.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575522__DSA-InteriorTypes/FrancqColors%20TrendsSurreal2%2051176%20Pure%20alu%20gold.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575523__DSA-InteriorTypes/FrancqColors%20TrendsTranquili2%20107325%20Intense%20matt%20black.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575524__DSA-InteriorTypes/FrancqColors%20TrendsRevive2%20416985%20Pure%20dark%20bamboo%20on%20anthracite.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575520__DSA-InteriorTypes/FrancqColors%20TrendsInstinctive2%2060989%20Intense%20matt%20black.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575525__DSA-InteriorTypes/FrancqColors%20TrendsTheHub1%201332%20Intense%20anthracite_1.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575538__DSA-InteriorTypes/FrancqColors%20TrendsPrinciple2%2036199%20Pure%20stainless%20steel%20on%20anthracite.jpg"
      ],
      "finishings": null
  },
  {
      "xid": "54053",
      "title": "Sensual freedom",
      "description": "You live life to the fullest, and don't let anything stop you. Your enthusiastic, accessible and fun personality is welcoming to others. By combining large patterns with bright and soft colours and materials, you make everyone that visits you instantly happy.",
      "images": [
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575504__DSA-InteriorTypes/FrancqColors%20TrendsSurreal2%2050929%20Pure%20alu%20gold.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575481__DSA-InteriorTypes/FrancqColors%20TrendsComposite%2026808%20Pure%20bamboo.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575482__DSA-InteriorTypes/FrancqColors%20TrendsComposite%2026560%20Pure%20natural%20red.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575484__DSA-InteriorTypes/FrancqColors%20TrendsComposite%2026311%20Pure%20champagne%20steel.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575502__DSA-InteriorTypes/FrancqColors%20TrendsSurreal2%2050339%20Pure%20alu%20gold.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575480__DSA-InteriorTypes/FrancqColors%20TrendsComposite%2026366%20Pure%20black%20steel.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575483__DSA-InteriorTypes/FrancqColors%20TrendsComposite%2025867%20Pure%20white%20steel.jpg",
          "https://test.mdm.niko.eu/DSA/interiorTypes/image-thumb__575503__DSA-InteriorTypes/FrancqColors%20TrendsSurreal2%2050570%20Pure%20natural%20red.jpg"
      ],
      "finishings": null
  }
]