export class BuildingType {
  xid!: string;
  title!: string;
  image?: string;
}

export const buildings: BuildingType[] = [
  {
      "xid": "54046",
      "title": "Apartment"
  },
  {
      "xid": "61138",
      "title": "House"
  }
]