import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { Finishing, FinishingRange, LOCATION } from 'src/app/models/Finishing';
import { InteriorType } from 'src/app/models/InteriorType';
import { Ternary } from 'src/app/models/Subject';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { FinishingService } from 'src/app/services/finishing/finishing.service';
import { InteriorTypeService } from 'src/app/services/interior-type/interior-type.service';
import { ProjectService } from 'src/app/services/project/project.service';

@Component({
  selector: 'app-chosen',
  templateUrl: './chosen.component.html',
  styleUrls: ['./chosen.component.scss']
})
export class ChosenComponent extends BaseComponent implements OnInit {

  public projectId!: string;

  public interiorTypes!: InteriorType[];

  public finishings!: Finishing[];
  public generalFinishing?: Finishing;
  public outdoorFinishing?: Finishing;
  public secondaryFinishings?: Finishing[];

  public currentIndex = 0;

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private finishingService: FinishingService,
    private interiorTypeService: InteriorTypeService,
    private projectService: ProjectService,
    private translate: TranslateService
  ) { super(); }

  ngOnInit(): void {

    let finishings$ = this.finishingService.getFinishings();

    let projectId = this.activatedRoute.snapshot.params['project_id'];
    this.projectId = projectId;
    let projectData$ = this.projectService.getProjectById(projectId);

    // Join interior type list and projectdata after both observables have completed
    this.addSubscription(forkJoin({ finishings: finishings$, projectData: projectData$ }).subscribe(r => {

      this.finishingService.getFinishingData(r.projectData.finishings, r.finishings);

      this.finishings = this.finishingService.finishings;
      this.generalFinishing = this.finishingService.generalFinishing;
      this.secondaryFinishings = this.finishingService.secondaryFinishings;
      this.outdoorFinishing = this.finishingService.outdoorFinishing;

      this.SetSideBarContent();

    }));
  }


  /**
   * Add dynamic content for sidebar navigation
   */
  SetSideBarContent() {
    this.sideBarContent = [];
    
    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_STYLES'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.STYLES'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_CATALOG'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.CATALOG'),
      complete: true,
      active: false,
      available: true,
      subitem: true
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_RECOMMENDED'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.RECOMMENDED'),
      complete: true,
      active: false,
      available: true,
      subitem: true
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_GENERAL_INDOOR'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.INDOOR'),
      complete: true,
      active: false,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0,
      subitem: false
    });

    console.log(this.finishingService.generalFinishing);
    console.log(this.finishingService.generalFinishing !== undefined);

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_GENERAL_OUTDOOR'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.OUTDOOR'),
      complete: true,
      active: false,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0 && this.finishingService.generalFinishing !== undefined,
      subitem: false
    });

    console.log(this.finishingService.outdoorFinishing);
    console.log(this.finishingService.outdoorFinishing !== undefined);

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FINISHING_SUMMARY'].value,
      breadcrumb: this.translate.instant('PROJECT_FINISHINGS.NAV.SUMMARY'),
      complete: false,
      active: true,
      available: this.finishingService.finishings && this.finishingService.finishings.length > 0 && this.finishingService.generalFinishing !== undefined && this.finishingService.outdoorFinishing !== undefined,
      subitem: false
    });
  }
}
