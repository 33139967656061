import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { COLORS } from 'src/app/constants/Colors';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public APP_ROUTES = APP_ROUTES;
  public COLORS = COLORS;
  
  public projectId!: string;

  @Input() color: string = COLORS.white;
  @Input() bgcolor: string = COLORS.black;


  @Input() breadcrumb!: string;
  @Input() routerlinkPrevStep!: string;
  @Input() routerlinkNextStep!: string;

  @Input() content!: AppRouteObject[];

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.params['project_id'];
  }

}
