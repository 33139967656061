import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RoomInstance } from 'src/app/models/Room';
import { RoomService } from 'src/app/services/room/room.service';
import { BaseComponent } from '../../base.component';

export interface DialogData {
  'title': string;
  'confirmationData': any;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent extends BaseComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private gaService: GoogleAnalyticsService,
    private translate: TranslateService
  ) {
    super();
    //dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.data)); // Data should only be returned if confirm is clicked
   }

  ngOnInit(): void {
    this.gaService.event('modal_open', 'Modals', 'Confirmation Modal - ' + this.translate.instant(this.data.title));
  }

  confirm() {
    this.dialogRef.close(this.data);
  }
}
