import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { countries, Country } from 'src/app/models/Country';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  public countryList: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);
  public currentCountry!: Country;

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get CountryList
   * @returns Observable list of countries
   */
  public getCountries(): BehaviorSubject<Country[]> {
    if(environment.mockData) {
      this.countryList.next(countries);
  } else {
    this.nikoApi.getCountries().subscribe((r: Country[]) => {
      //console.log(r);
      this.countryList.next(r);
    }); 
  }

    return this.countryList;
  }
}
