// ANGULAR MODULES
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

// MODULES
import { AppRoutingModule } from './modules/app-routing/app-routing.module';
import { TranslationModule } from './modules/translation/translation.module';
import { MaterialModule } from './modules/material/material.module';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from  'ng-gallery/lightbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { NetworkModule } from './modules/network/network.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

// COMPONENTS
import { AppComponent } from './app.component';

import { LoginComponent } from './components/login/login.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { OverviewPageTemplateComponent } from './components/overview-page-template/overview-page-template.component';
import { ProjectPageTemplateComponent } from './components/project-page-template/project-page-template.component';
import { WarningComponent } from './components/warning/warning.component';
import { SpacesModalComponent } from './pages/project-intake/spaces/spaces-modal/spaces-modal.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { OnboardingModalComponent } from './components/modals/onboarding-modal/onboarding-modal.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { PartnerCompatibilityModalComponent } from './pages/project-requirements/topic/partner-compatibility-modal/partner-compatibility-modal.component';

// PAGES
import { PreferencesComponent } from './pages/preferences/preferences.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { UtilitiesComponent } from './pages/project-intake/utilities/utilities.component';
import { InspirationComponent } from './pages/project-requirements/inspiration/inspiration.component';
import { TopicComponent } from './pages/project-requirements/topic/topic.component';
import { TopicDetailComponent } from './pages/project-requirements/topic-detail/topic-detail.component';
import { UsecaseDetailComponent } from './pages/project-requirements/usecase-detail/usecase-detail.component';
import { OverviewSpaceComponent } from './pages/project-overview/space/space.component';
import { EmailComponent } from './pages/project-overview/email/email.component';
import { SpacesComponent } from './pages/project-intake/spaces/spaces.component';
import { SpaceComponent } from './pages/project-spaces/space/space.component';
import { FinishingComponent } from './pages/project-finishing/finishing/finishing.component';
import { FunctionalitiesComponent } from './pages/project-requirements/functionalities/functionalities.component';
import { SystemsComponent } from './pages/project-summary/systems/systems.component';
import { ProductsComponent } from './pages/project-summary/products/products.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { CatalogComponent } from './pages/project-finishing/catalog/catalog.component';
import { RecommendedComponent } from './pages/project-finishing/recommended/recommended.component';
import { SelectedComponent } from './pages/project-finishing/selected/selected.component';
import { ChosenComponent } from './pages/project-finishing/chosen/chosen.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { ContactComponent } from './pages/preferences/contact/contact.component';
import { DetailsClientComponent } from './pages/project-intake/details-client/details-client.component';
import { CalculationComponent } from './pages/project-summary/calculation/calculation.component';
import { FinishingModalComponent } from './pages/project-spaces/space/finishing-modal/finishing-modal.component';
import { VariableModalComponent } from './pages/project-spaces/space/variable-modal/variable-modal.component';
import { RoomModalComponent } from './pages/project-spaces/space/room-modal/room-modal.component';
import { BuildingTypeComponent } from './pages/project-intake/building-type/building-type.component';
import { IntakeSummaryComponent } from './pages/project-intake/intake-summary/intake-summary.component';
import { OutsideComponent } from './pages/project-finishing/outside/outside.component';
import { AddroomsModalComponent } from './pages/project-spaces/space/addrooms-modal/addrooms-modal.component';
import { ErrorComponent } from './components/error/error.component';
import { ResultsSummaryComponent } from './pages/project-summary/results-summary/results-summary.component';

// EXTRA LIBRARIES
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

// SERVICES
import { ALLOWED_LOG_CLASS, ALLOWED_LOG_LEVEL } from './services/logger/LogInjectionTokens';
import { ImagePreloadDirective } from './directives/image-preload/image-preload.directive';
import { SubjectResolverService } from './services/subject/subject-resolver.service';

// ENVIRONMENT
import { environment } from 'src/environments/environment';
import { ProjectStatusModalComponent } from './components/modals/project-status-modal/project-status-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { TooltipModalComponent } from './components/modals/tooltip-modal/tooltip-modal.component';



// Check for internet explorer
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PreferencesComponent,
    ProjectListComponent,
    ProjectDetailComponent,
    UtilitiesComponent,
    InspirationComponent,
    TopicComponent,
    TopicDetailComponent,
    UsecaseDetailComponent,
    OverviewSpaceComponent,
    EmailComponent,
    SpacesComponent,
    SpaceComponent,
    TopbarComponent,
    SidebarComponent,
    NavigationComponent,
    OverviewPageTemplateComponent,
    ProjectPageTemplateComponent,
    FunctionalitiesComponent,
    SystemsComponent,
    ProductsComponent,
    FinishingComponent,
    WarningComponent,
    DocumentsComponent,
    ImagePreloadDirective,
    CatalogComponent,
    RecommendedComponent,
    SelectedComponent,
    ChosenComponent,
    SpacesModalComponent,
    FinishingModalComponent,
    VariableModalComponent,
    RoomModalComponent,
    BuildingTypeComponent,
    IntakeSummaryComponent,
    OutsideComponent,
    AddroomsModalComponent,
    ErrorComponent,
    OnboardingComponent,
    ContactComponent,
    ConfirmationModalComponent,
    DetailsClientComponent,
    CapitalizePipe,
    OnboardingModalComponent,
    CalculationComponent,
    ResultsSummaryComponent,
    PartnerCompatibilityModalComponent,
    ProjectStatusModalComponent,
    TooltipModalComponent
  ],
  imports: [
    AuthenticationModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    TranslationModule,
    NgxJsonViewerModule,
    NgxGoogleAnalyticsModule.forRoot(environment.trackingId),
    NgxGoogleAnalyticsRouterModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    GalleryModule.withConfig({counter: false, thumb: false, imageSize: 'cover', navIcon: '<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52Z" fill="#1A1A1A"/><path d="M29.1763 26.0001L23.0642 35.3828C22.9354 35.5792 23.0111 35.8309 23.2341 35.9449C23.4544 36.0578 23.7411 35.992 23.8685 35.7944L30.116 26.2059C30.1983 26.0789 30.1983 25.9213 30.116 25.7943L23.8672 16.2058C23.7822 16.0741 23.6256 16 23.465 16C23.3854 16 23.3057 16.0176 23.2327 16.0553C23.0097 16.1693 22.9341 16.421 23.0628 16.6174L29.1763 26.0001Z" fill="white"/></svg>', }),
    LightboxModule.withConfig({closeIcon: '<svg width="100%" height="100%" viewBox="0 0 32 25" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M4.83752 2.09021L26.4524 23.705C26.7083 23.961 27.2804 23.8048 27.728 23.3572C28.1756 22.9096 28.3361 22.3361 28.0787 22.0787L6.46386 0.463862C6.20789 0.207889 5.63584 0.36416 5.18824 0.811758C4.74064 1.25936 4.58154 1.83424 4.83752 2.09021Z"></path><path d="M26.0787 0.880863L4.46386 22.4957C4.20789 22.7517 4.36416 23.3237 4.81176 23.7713C5.25936 24.2189 5.83141 24.3752 6.08738 24.1192L27.7022 2.50438C27.9582 2.24841 27.8019 1.67636 27.3543 1.22876C26.9067 0.781159 26.3361 0.623476 26.0787 0.880863Z"></path></svg>' })
  ],
  entryComponents: [
    TopicDetailComponent
  ],
  providers: [
    SubjectResolverService,
    // Logger
    {
        provide: ALLOWED_LOG_LEVEL,
        useValue: environment.logLevel
    },
    {
        provide: ALLOWED_LOG_CLASS,
        useValue: null
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
