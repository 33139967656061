import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { interiorTypes } from 'src/app/models/InteriorType';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InteriorTypeService {

 /* public cachedInteriorTypes: BehaviorSubject<InteriorType[]> = new BehaviorSubject(InteriorTypes.InteriorTypeList);
  public sessionInteriorTypes: BehaviorSubject<InteriorType[]> = new BehaviorSubject(InteriorTypes.InteriorTypeList); */

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get InteriorTypeList
   * @returns Observable list of InteriorTypes
   */
  public getInteriorTypes() {
    if(environment.mockData) {
      return of(interiorTypes);
    } else {
      return this.nikoApi.getInteriorTypes().pipe(
        map((interiorTypeList) => {
          //this.cachedInteriorTypes.next(InteriorTypeList.InteriorTypeList);
          //console.log(interiorTypeList);
          return interiorTypeList;
        })
      );
    }
  }

  public selectInteriorTypes(projectId: string, interiorTypeString: string) {
    this.nikoApi.selectInteriorTypes(projectId, interiorTypeString).subscribe(r => {
      return r;
    });
  }
}