import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, take } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { ProjectStatusModalComponent } from 'src/app/components/modals/project-status-modal/project-status-modal.component';
import { Document, DocumentType, Issue, Project, Property } from 'src/app/models/Project';
import { APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { DocumentDownloadService } from 'src/app/services/document-download/document-download.service';
import { FinishingService } from 'src/app/services/finishing/finishing.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { RoomService } from 'src/app/services/room/room.service';
import { SessionStorageKeys } from 'src/app/services/session-storage/session-storage-keys';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { VariableService } from 'src/app/services/variable/variable.service';
import { clone, projectPropertyValue } from 'src/app/util/Util';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent extends BaseComponent implements OnInit {
  
  public projectPropertyValue = projectPropertyValue;

  public project!: Project;
  public projectId!: string;
  public projectArchived: boolean = true;

  public commercialDocument!: string;
  public productList!: string;
  public currentCommercialDocument?: Document;
  public currentProductList?: Document;

  public today: Date = new Date;

  public previousStatus!: String[] | false;
  
  constructor(
    private projectService: ProjectService,
    private propertyService: PropertyService,
    private roomService: RoomService,
    private variableService: VariableService,
    private activatedRoute: ActivatedRoute,
    private session: SessionStorageService,
    private viewScroller: ViewportScroller,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public documentService: DocumentDownloadService
  ) {super(); }

  ngOnInit(): void {
    this.openOnboardingModal();
    
    this.projectId = this.activatedRoute.snapshot.params['project_id'];

    this.previousStatus = this.getPreviousStatus();

    this.addSubscription(this.projectService.getProjectById(this.projectId).pipe(take(1)).subscribe(r => {
      this.project = r;

      // Project Archive Status
      this.projectArchived = (projectPropertyValue(r.properties, 'archived') == 'true');

      if(!this.project?.status) {
        this.router.navigate(['/', APP_ROUTES['PROJECTLIST'].value]);
      }

      if(this.project.status && this.project.status.accessibleSteps) {
        //console.log('status saved to session');

        this.session.saveObject(SessionStorageKeys.STATUS, this.project.status.accessibleSteps);
      }

      // Current documents
      this.currentCommercialDocument = this.project.documents?.find(doc => doc.current && doc.type == DocumentType.COMMERCIAL);
      this.currentProductList = this.project.documents?.find(doc => doc.current && doc.type == DocumentType.BOM);

    }));
    
    // Track route fragment to scroll to an anchor
    this.addSubscription(this.route.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      if (element) element.scrollIntoView();
      location.hash = '';
    }));
    
  }


  /**
   * Check if there are issues for a specific module/step in the project object.
   * Issues only count if the step is accessible and wasn't added to the accessible steps just before init
   * @param step name of the project module to be checked
   * @returns array of issues for this module
   */
  checkStepIssues(step: string): boolean {
    let issues = this.project.status.issues;

    let stepHasIssues = issues.filter(issue => issue.step == step).length > 0;
    
    if(this.previousStatus && !this.previousStatus.includes(step)) {
      stepHasIssues = false;
    }

    return stepHasIssues;
  }

  /**
   * Get issues for a specific module/step from the project object
   * Issues only count if the step is accessible and wasn't added to the accessible steps just before init
   * @param step name of the project module to be checked
   * @returns array of issues for this module
   */
  getStepIssues(step: string): Issue[] {
    let issues = this.project.status.issues;

    issues = issues.filter(issue => issue.step == step).sort((a,b) => a.index - b.index);

    if(this.previousStatus && !this.previousStatus.includes(step)) {
      issues = [];
    }

    return issues;
  }

  /**
   * Gets previous accessibleSteps array from sessionstorage if available
   * @returns previous accessible steps of the project
   */
  getPreviousStatus(): string[] | false {
    let status:string[] | false = this.session.getObject(SessionStorageKeys.STATUS) ? this.session.getObject(SessionStorageKeys.STATUS)! : false;

    return status;
  }

  /**
   * Gets the property value for the archived property for this project
   * @returns property value of the archived property
   */
  public propertyValueArchived(): string | undefined {
    let value = this.project.properties.find(p => {
      return p.name.includes('archived');
    })?.value;
    return value;
  }

  /**
   * Toggle archived property for this project between archived and active
   * @param value value to be set to the archived property
   */
  toggleArchiveProject(value: string) {
    
    // Archiving a project is a one-way street
    if(value == 'true') {
      this.projectArchived = true;
    }

    let property = this.project.properties.find(p => {
      return p.name.includes('archived')
    });

    if(property) {
      property.value = value;
    } else {
      property = new Property;
      property.name = 'project.archived';
      property.value = value;

      this.project.properties.push(property);
    }

    this.propertyService.setProperty(this.projectId, ['project.archived' + (value ? (':' + value) : '')]);
  }


   /**
   * Open remove room modal
   */
  openArchiveDialog(e: Event) {
    e.preventDefault();

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      autoFocus: false, 
      data: {
        'title': 'CONFIRMATION_MODAL.ARCHIVE',
        'confirmationData': 'true'
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      minWidth: '775px'
    });

    dialogRef.afterClosed().subscribe(r => {
      if (r && r.confirmationData) {
        this.toggleArchiveProject(r.confirmationData);
      }
    });
  }

  generateDocument(document: DocumentType) {
    if(projectPropertyValue(this.project.properties, 'roomsconfigurated') != 'true') {

      // Subscription for variableList
      let variables$ = this.variableService.getVariables();

      // Subscription for roomlist
      let buildingType = projectPropertyValue(this.project.properties, 'buildingtype')!;
      let rooms$ = this.roomService.getRooms(buildingType);

      // Get variableList, finishingRangeList and roomlist
      this.addSubscription(forkJoin({variables: variables$, rooms: rooms$}).subscribe(r => {

        // Combine variables with project room instances
        let rooms = clone(this.project.rooms);
        rooms = this.variableService.combineVariablesForProjectRooms(this.project, r.variables, r.rooms, rooms);

        // Clear variables and set the new variables on backend
        this.addSubscription(this.variableService.initVariablesForRooms(this.projectId, rooms).pipe(take(1)).subscribe(() => {

          // Update project status
          this.previousStatus = this.getPreviousStatus();

          this.addSubscription(this.projectService.getProjectById(this.projectId).pipe(take(1)).subscribe(r => {
            this.project = r;
      
            // Project Archive Status
            this.projectArchived = (projectPropertyValue(r.properties, 'archived') == 'true');
      
            if(!this.project?.status) {
              this.router.navigate(['/', APP_ROUTES['PROJECTLIST'].value]);
            }
      
            if(this.project.status && this.project.status.accessibleSteps) {
              //console.log('status saved to session');
      
              this.session.saveObject(SessionStorageKeys.STATUS, this.project.status.accessibleSteps);
            }
            
            // Current documents              
            if(document == DocumentType.COMMERCIAL) {
              this.currentCommercialDocument = this.project.documents?.find(doc => doc.current && doc.type == DocumentType.COMMERCIAL);
              if(this.currentCommercialDocument){
                this.documentService.getDocumentDownload(this.currentCommercialDocument.id);
              } else {
                this.addSubscription(this.documentService.generateDocument(this.projectId, document).subscribe( r =>{
                  //console.log('add current commercial');
                  this.currentCommercialDocument = new Document;
                  this.currentCommercialDocument.id = r.id;
                }));
              }            
            } else if(document == DocumentType.BOM) {
              this.currentProductList = this.project.documents?.find(doc => doc.current && doc.type == DocumentType.BOM);
              if(this.currentProductList){
                this.documentService.getDocumentDownload(this.currentProductList.id);
              } else {
                this.addSubscription(this.documentService.generateDocument(this.projectId, document).subscribe( r =>{
                  this.currentProductList = new Document;
                  this.currentProductList.id = r.id;
                }));
              }
            } 
          }));
        }));
      }));
    } else {
      // Update project status
      this.previousStatus = this.getPreviousStatus();

      this.addSubscription(this.projectService.getProjectById(this.projectId).pipe(take(1)).subscribe(r => {
        this.project = r;
  
        // Project Archive Status
        this.projectArchived = (projectPropertyValue(r.properties, 'archived') == 'true');
  
        if(!this.project?.status) {
          this.router.navigate(['/', APP_ROUTES['PROJECTLIST'].value]);
        }
  
        if(this.project.status && this.project.status.accessibleSteps) {
          //console.log('status saved to session');
  
          this.session.saveObject(SessionStorageKeys.STATUS, this.project.status.accessibleSteps);
        }
        // Current documents              
        if(document == DocumentType.COMMERCIAL) {
          this.currentCommercialDocument = this.project.documents?.find(doc => doc.current && doc.type == DocumentType.COMMERCIAL);
          if(this.currentCommercialDocument){
            this.documentService.getDocumentDownload(this.currentCommercialDocument.id);
          } else {
            this.addSubscription(this.documentService.generateDocument(this.projectId, document).subscribe( r =>{
              this.currentCommercialDocument = new Document;
              this.currentCommercialDocument.id = r.id;
            }));
          }            
        } else if(document == DocumentType.BOM) {
          this.currentProductList = this.project.documents?.find(doc => doc.current && doc.type == DocumentType.BOM);
          if(this.currentProductList){
            this.documentService.getDocumentDownload(this.currentProductList.id);
          } else {
            this.addSubscription(this.documentService.generateDocument(this.projectId, document).subscribe( r =>{
              this.currentProductList = new Document;
              this.currentProductList.id = r.id;
            }));
          }
        } 
      }));
    }
  }

  checkCommercialDocuments():boolean {
    return this.project.documents!.some(doc => doc.type == DocumentType.COMMERCIAL);
  }

  checkProductList():boolean {
    return this.project.documents!.some(doc => doc.type == DocumentType.BOM);
  }

  downloadDocument(id: string) {
      this.documentService.getDocumentDownload(id);
  }

  /**
   * Open onboarding modal
   */
  openOnboardingModal(visible: boolean = false) {
    const dialogRef = this.dialog.open(ProjectStatusModalComponent, {
      autoFocus: false, 
      data: {onboardingVisible: visible},
      maxHeight: '95vh',
      minWidth: '900px',
      width: '95vw',
      maxWidth: '1400px'
    });

    dialogRef.afterClosed().subscribe(r => {
      /* if (r && r.room) {
        this.removeRoom(r.room.id)
      } */
    });
  }
}