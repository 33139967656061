
  <div id="confirmation-modal" gaCategory="Confirmation Modal - {{ (this.data.title ? this.data.title : 'CONFIRMATION_MODAL.CONFIRM') | translate }}">
    <div mat-dialog-title>
      <button gaEvent="button_click" gaLabel="modal_close" (click)="dialogRef.close()" class="btn-close">
        <mat-icon class="icon icon--small">close</mat-icon>
      </button>
  
      <h1 class="h1 text-center">{{ (this.data.title ? this.data.title : 'CONFIRMATION_MODAL.CONFIRM') | translate }}</h1>
    </div>

    <div mat-dialog-actions class="button-wrapper">
      <button gaEvent="button_click" gaLabel="cancel" (click)="dialogRef.close()" class="btn btn-border btn-small margin-r">{{ 'CONFIRMATION_MODAL.CANCEL' | translate }}</button>
      <button gaEvent="button_click" gaLabel="confirm" (click)="confirm()" class="btn btn-primary btn-small">{{ 'CONFIRMATION_MODAL.CONFIRM' | translate }}</button>
    </div>
  </div>