import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, take } from 'rxjs';
import { ProjectModule } from 'src/app/models/Project';
import { APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { projectPropertyValue } from 'src/app/util/Util';

@Injectable({
  providedIn: 'root'
})
export class ArchiveGuard implements CanActivate {
  constructor(
    private projectService: ProjectService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private router: Router,
  ) { }

  /**
   * Redirect to module summary page if  project is archived
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let projectModule = next.data['module'];
    let projectId = next.params['project_id'];

    return this.projectService.getProjectById(projectId).pipe(
      take(1),
      map(project => {

        if (project && projectModule) {
          if (projectPropertyValue(project.properties, 'archived') == 'true') {

            let urlTreeSegments = this.router.parseUrl(state.url).root.children['primary'].segments;
            let projectUrlTree = urlTreeSegments[0] + '/' + urlTreeSegments[1];

            switch (projectModule) {
              case ProjectModule.INTAKE:
                this.openSnackBar(this.translate.instant('PROJECT_ARCHIVE.GUARD.INTAKE'));

                return this.router.parseUrl(projectUrlTree + '/' + APP_ROUTES['INTAKE_SUMMARY'].value);

              case ProjectModule.FUNCTIONALITIES:
                this.openSnackBar(this.translate.instant('PROJECT_ARCHIVE.GUARD.FUNCTIONALITIES'));

                return this.router.parseUrl(projectUrlTree + '/' + APP_ROUTES['FUNCTIONALITIES_SUMMARY'].value);

              case ProjectModule.FINISHING:
                this.openSnackBar(this.translate.instant('PROJECT_ARCHIVE.GUARD.FINISHINGS'));

                return this.router.parseUrl(projectUrlTree + '/' + APP_ROUTES['FINISHING_SUMMARY'].value);

              case ProjectModule.ROOMCONFIGURATION:
                this.openSnackBar(this.translate.instant('PROJECT_ARCHIVE.GUARD.ROOMCONFIGURATION'));
                return true;

              case ProjectModule.RESULTS:
                this.openSnackBar(this.translate.instant('PROJECT_ARCHIVE.GUARD.RESULTS'));

                return this.router.parseUrl(projectUrlTree + '/' + APP_ROUTES['RESULTS_SUMMARY'].value);

              default:
                console.log('default')
                break;
            }
          }
          return true;
        }
        return false;
      })
    );
  }

  private openSnackBar(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 5000,
      horizontalPosition: 'right',
      panelClass: 'snackbar'
    });
  }
}

