import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BaseComponent } from 'src/app/components/base.component';
import { Project, ProjectState, Property } from 'src/app/models/Project';
import { APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { SessionStorageKeys } from 'src/app/services/session-storage/session-storage-keys';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { projectPropertyValue } from 'src/app/util/Util';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent  extends BaseComponent implements OnInit {
  
  public projects!: Project[];

  public projectPropertyValue = projectPropertyValue;
  public ProjectState = ProjectState;

  public sort = 'modified';
  public ascending_name = false;
  public ascending_address = false;
  public ascending_modified = false;
  public ascending_status = false;

  constructor(
    public projectService: ProjectService,
    public session: SessionStorageService,
    private router: Router,
    private gaService: GoogleAnalyticsService
  ) { super(); }

  ngOnInit(): void {
    this.gaService.set({'project_id': null});
    this.session.remove(SessionStorageKeys.STATUS);

    this.projectService.getProjects().subscribe(r => {
      this.projects = r;
      this.sortProjects('modified');
    });
  }

  /**
   * sort project list
   */
  sortProjects(property: string) {
    this.sort = property;

    if(property == 'name') {  
      this.ascending_name = !this.ascending_name;
  
      this.projects = this.projects.sort((a,b) => {
        let nameA: string = (projectPropertyValue(a.properties, 'name') && projectPropertyValue(a.properties, 'name') != '') ? projectPropertyValue(a.properties, 'name')! : '-';
        let nameB: string = (projectPropertyValue(b.properties, 'name') && projectPropertyValue(b.properties, 'name') != '') ? projectPropertyValue(b.properties, 'name')! : '-';
  
        if(this.ascending_name) {
          let result = (nameA > nameB) ? 1 : ((nameB > nameA) ? -1 : 0);
          return result;
  
        } else {
          let result = (nameA < nameB) ? 1 : ((nameB < nameA) ? -1 : 0);
          return result;
        }
      });
    }

    if(property == 'address') {  
      this.ascending_address = !this.ascending_address;
  
      this.projects = this.projects.sort((a,b) => {
        let zipA = (projectPropertyValue(a.properties, 'zip') && projectPropertyValue(a.properties, 'zip') != '') ? projectPropertyValue(a.properties, 'zip')! : '-';
        let zipB = (projectPropertyValue(b.properties, 'zip') && projectPropertyValue(b.properties, 'zip') != '') ? projectPropertyValue(b.properties, 'zip')! : '-';
  
        if(this.ascending_address) {
          let result = (zipA > zipB) ? 1 : ((zipB > zipA) ? -1 : 0);
          return result;
  
        } else {
          let result = (zipA < zipB) ? 1 : ((zipB < zipA) ? -1 : 0);
          return result;
        }
      });
    }

    if(property == 'modified') {  
      this.ascending_modified = !this.ascending_modified;
  
      this.projects = this.projects.sort((a,b) => {
        let modifiedA = new Date(a.modified);
        let modifiedB = new Date(b.modified);
  
        if(this.ascending_modified) {
          let result = (modifiedA < modifiedB) ? 1 : ((modifiedB < modifiedA) ? -1 : 0);
          return result;
  
        } else {
          let result = (modifiedA > modifiedB) ? 1 : ((modifiedB > modifiedA) ? -1 : 0);
          return result;
        }
      });
    }

    if(property == 'status') {  
      this.ascending_status = !this.ascending_status;
  
      this.projects = this.projects.sort((a,b) => {
        let statusA: string = a.activeStep;
        let statusB: string = b.activeStep;
  
        let stepOrder = ['PHASE_INTAKE', 'PHASE_FUNCTIONALITIES', 'PHASE_ROOMCONFIGURATION', 'PHASE_FINISHING', 'PHASE_RESULTS'];

        if(this.ascending_status) {
          let result = (stepOrder.indexOf(statusA) > stepOrder.indexOf(statusB)) ? 1 : (stepOrder.indexOf(statusB) > stepOrder.indexOf(statusA) ? -1 : 0);
          return result;
  
        } else {
          let result = (stepOrder.indexOf(statusA) < stepOrder.indexOf(statusB)) ? 1 : (stepOrder.indexOf(statusB) < stepOrder.indexOf(statusA) ? -1 : 0);
          return result;
        }
      });
    }
  }

  /**
   * Toggle between archive projects and active projects tab
   */
  toggleArchive() {
    //console.log(this.projectService.projectListState);
    this.projectService.projectListState == ProjectState.ARCHIVED ? this.projectService.projectListState = ProjectState.ACTIVE : this.projectService.projectListState = ProjectState.ARCHIVED
    //console.log(this.projectService.projectListState);
  }
  
  /**
   * Create a new project
   */
  createProject() {
    this.projectService.createProject().subscribe(r => {
      this.session.saveObject(SessionStorageKeys.STATUS, []);

      let projectId = r.id;

      this.router.navigate(['/' + APP_ROUTES['PROJECT'].value.replace(':project_id', projectId)]);
    });
  }
  
  /**
   * Get property value for the archived property of a project
   * @param project project to be checked
   * @returns value of the archived property of the project
   */
  public propertyValueArchived(project: Project): string | undefined {
    let value = project.properties?.find(p => {
      return p.name.includes('archived');
    })?.value;
    return value;
  }

  /**
   * Format the given project address to be presented in the project table
   * @param project project to be formatted
   * @returns formatted project address
   */
  getAddress(project: Project): string {
    let address = '';

    if(project.properties) {

      let properties = project.properties;

      projectPropertyValue(properties, 'address') ? address = address + projectPropertyValue(properties, 'address') : '';
      projectPropertyValue(properties, 'address2') ? address = address + '<br>' + projectPropertyValue(properties, 'address2') : '';

      (projectPropertyValue(properties, 'address') || projectPropertyValue(properties, 'address2')) &&  
      (projectPropertyValue(properties, 'zip') || projectPropertyValue(properties, 'city'))  
      ? address = address + ',<br>' : '';

      projectPropertyValue(properties, 'zip') ? address = address + projectPropertyValue(properties, 'zip') : '';
      projectPropertyValue(properties, 'city') ? address = address + ' ' + projectPropertyValue(properties, 'city') : '';
    }

    if(address == '') {
      address = '-';
    }

    return address;
  }
}
