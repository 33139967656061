
  <div id="tooltip-modal" gaCategory="Tooltip Modal - {{ (this.data.title ? this.data.title : 'TOOLTIP_MODAL.TITLE') | translate }}">
    <div mat-dialog-title>
      <button gaEvent="button_click" gaLabel="modal_close" (click)="dialogRef.close()" class="btn-close">
        <mat-icon class="icon icon--small">close</mat-icon>
      </button>
  
      <h1 class="h1" *ngIf="this.data.title">{{ this.data.title | translate }}</h1>
    </div>
    <div mat-dialog-content>
      <p *ngIf="this.data.text">{{ this.data.text | translate }}</p>
    </div>
  </div>