import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { Room, RoomInstance } from 'src/app/models/Room';
import { ProjectService } from 'src/app/services/project/project.service';
import { RoomService } from 'src/app/services/room/room.service';
import { clone, mergeArrays } from 'src/app/util/Util';

export interface DialogData {
  'projectId': string;
  'inactiveRooms': Room[];
  'roomData': RoomInstance[];
}

@Component({
  selector: 'app-spaces-modal',
  templateUrl: './spaces-modal.component.html',
  styleUrls: ['./spaces-modal.component.scss']
})
export class SpacesModalComponent extends BaseComponent implements OnInit {
  
  public space = '';

  public resultString = ''

  public search = new UntypedFormControl(null);

  public rooms!: Room[];

  constructor(
    public roomService: RoomService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<SpacesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      super();
  }

  ngOnInit(): void {
    this.rooms = clone(this.data.inactiveRooms);
  }

  /**
   * Filter matching room types
   */
  findRoom() {
    this.data.inactiveRooms = mergeArrays(this.data.inactiveRooms, this.rooms);

    if(this.search.value && this.search.value != '') {
      this.resultString = this.search.value;

      this.rooms = clone(this.data.inactiveRooms).filter(room => {
        return room.name.toLowerCase().includes(this.search.value.toLowerCase());
      })
    } else {
      this.rooms = clone(this.data.inactiveRooms);
    }
  }

  /**
   * Add room
   */
    addRoom(room: Room, amount: number) {
    room.count = (+room.count + amount).toString();

    let roomString = 'typeCount' + '=' + room.xid + ':' + amount + '&';

    this.roomService.addRoom(this.data.projectId, roomString).subscribe(r => {
      this.data.roomData = this.data.roomData.concat(r);
    });  
  }
  
  /**
   * Remove any matching room
   */
  removeRoom(room: Room) {
    if(+room.count > 0 ) {
      room.count = (+room.count - 1).toString();

      let roomId = '';
      
      this.data.roomData.some((roomInstance, index) => {
        if (roomInstance.typeXid == room.xid) {
          this.data.roomData.splice(index, 1);
          roomId = roomInstance.id;
          return true;
        } else {
          return false
        }
      });
  
      this.roomService.removeRoom(this.data.projectId, roomId).subscribe(r => r);  
    } else {
      room.count = '0'
    };
  }
}
