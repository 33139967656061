import { LOCATION } from "./Finishing";

export class Variable {
    xid!: string;
    name!: string;
    text!: string | null;
    usecases!: string[]
    values!: VariableValues | null;
    image!: string | null;
    location!: LOCATION[]
}

export interface VariableValues {
    [roomId: string]: number | null;
}

export class VariableInstance {
    xid!: string;
    roomId!: string;
    value!: string;
}

export const variables: Variable[] = [
    {
        "xid": "53833",
        "name": "nrSocketOutletsInside",
        "text": null,
        "usecases": [
            "53818"
        ],
        "values": {
            "53550": 14,
            "53552": 12,
            "53553": 6,
            "53555": 5,
            "53558": 4,
            "53559": 1,
            "53578": 2,
            "53581": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "53834",
        "name": "nrDevicesChargeInside",
        "text": "Charge Test",
        "usecases": [
            "59585"
        ],
        "values": {
            "53550": 1,
            "53552": 1,
            "53553": 2
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "53835",
        "name": "nrDigitalDecoders",
        "text": null,
        "usecases": [
            "53820"
        ],
        "values": {
            "53550": 1,
            "53553": 1,
            "53554": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "53837",
        "name": "nrLightingCircuitsSwitching",
        "text": null,
        "usecases": [
            "59587"
        ],
        "values": {
            "53550": 1,
            "53552": 1,
            "53554": 1,
            "53555": 1,
            "53556": 1,
            "53559": 1,
            "53564": 1,
            "53567": 1,
            "53570": 1,
            "53571": 1,
            "53574": 1,
            "53578": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "53838",
        "name": "nrCommandLocationsSwitching",
        "text": null,
        "usecases": [
            "59587"
        ],
        "values": {
            "53550": 2,
            "53552": 2,
            "53555": 1,
            "53556": 1,
            "53578": 2
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "53839",
        "name": "nrLightingCircuitsDimming",
        "text": null,
        "usecases": [
            "59587"
        ],
        "values": {
            "53550": 1,
            "53552": 1,
            "53553": 1,
            "53563": 1,
            "53578": 1,
            "53581": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "53840",
        "name": "nrCommandLocationsDimming",
        "text": null,
        "usecases": [
            "59587"
        ],
        "values": {
            "53550": 2,
            "53552": 2,
            "53553": 3,
            "53581": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "53841",
        "name": "nrOrientationLights",
        "text": null,
        "usecases": [
            "59667"
        ],
        "values": {
            "53578": 3
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "53843",
        "name": "nrDetectionPointsInside",
        "text": null,
        "usecases": [
            "59589",
            "59615",
            "59657"
        ],
        "values": {
            "53578": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59557",
        "name": "nrAccessControlTouchscreens",
        "text": "How many touchscreens for access control would you like in each area?",
        "usecases": [
            "59663",
            "59665"
        ],
        "values": {
            "53550": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59558",
        "name": "nrBicycleChargingPoints",
        "text": "How many electrical bicycles would you like to charge in each area?",
        "usecases": [
            "59676"
        ],
        "values": {
            "53556": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59559",
        "name": "nrComingHomeButtons",
        "text": "How many \"coming home\" buttons would you like in each area?",
        "usecases": [
            "59673"
        ],
        "values": {
            "53574": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59560",
        "name": "nrElectricalSlidingDoors",
        "text": null,
        "usecases": [
            "59654"
        ],
        "values": {
            "53556": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59561",
        "name": "nrElectricalWindows",
        "text": null,
        "usecases": [
            "59654"
        ],
        "values": {
            "53553": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59562",
        "name": "nrDevicesChargeOutside",
        "text": null,
        "usecases": [
            "59675"
        ],
        "values": {
            "53556": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59563",
        "name": "nrDevicesMeasureControl",
        "text": null,
        "usecases": [
            "59630",
            "59631",
            "59635",
            "59632"
        ],
        "values": {
            "53550": 2,
            "53552": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59564",
        "name": "nrDigitalMeters",
        "text": null,
        "usecases": [
            "59625",
            "59626"
        ],
        "values": {
            "59536": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59565",
        "name": "nrDigitalSwitches",
        "text": null,
        "usecases": [],
        "values": {
            "53550": 1,
            "53552": 1,
            "53553": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59566",
        "name": "nrElectricalFrontDoors",
        "text": null,
        "usecases": [
            "59661",
            "59664",
            "59666"
        ],
        "values": {
            "53579": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59567",
        "name": "nrElectricalGates",
        "text": null,
        "usecases": [
            "59664",
            "59666"
        ],
        "values": {
            "53573": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59568",
        "name": "nrElectricalRollDownShutters",
        "text": null,
        "usecases": [
            "59621"
        ],
        "values": {
            "53550": 1,
            "53552": 1,
            "53553": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59569",
        "name": "nrElectricalSunBlinds",
        "text": null,
        "usecases": [
            "59621"
        ],
        "values": {
            "53550": 1,
            "53552": 1,
            "53555": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59570",
        "name": "nrElectricalSmokeDetectors",
        "text": null,
        "usecases": [
            "59672",
            "59671"
        ],
        "values": {
            "53550": 1,
            "53552": 1,
            "53553": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59571",
        "name": "nrCountersElectricity",
        "text": null,
        "usecases": [
            "59628"
        ],
        "values": {
            "59536": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59572",
        "name": "nrCountersGas",
        "text": null,
        "usecases": [
            "59629"
        ],
        "values": {
            "59536": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59573",
        "name": "nrCountersWater",
        "text": null,
        "usecases": [
            "59627"
        ],
        "values": {
            "59536": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59574",
        "name": "nrEntrancesExits",
        "text": null,
        "usecases": [
            "59656",
            "59651",
            "59588",
            "59608",
            "59614"
        ],
        "values": {
            "53556": 1,
            "53574": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59575",
        "name": "nrInternetConnections",
        "text": null,
        "usecases": [
            "53821"
        ],
        "values": {
            "53550": 1,
            "53553": 1,
            "53570": 2
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59576",
        "name": "nrDetectionPointsOutside",
        "text": null,
        "usecases": [
            "59668"
        ],
        "values": {
            "53556": 1,
            "53573": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59577",
        "name": "nrMusicControlPoints",
        "text": null,
        "usecases": [
            "59649",
            "59650"
        ],
        "values": {
            "53550": 1,
            "53552": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59578",
        "name": "nrNonElectricalFrontDoors",
        "text": null,
        "usecases": [
            "59661"
        ],
        "values": null,
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59579",
        "name": "nrPanicButtons",
        "text": null,
        "usecases": [
            "59670"
        ],
        "values": {
            "53553": 2
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59580",
        "name": "nrSocketOutletsOutside",
        "text": null,
        "usecases": [
            "59677"
        ],
        "values": {
            "53556": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59581",
        "name": "nrSwimmingPoolCovers",
        "text": null,
        "usecases": [
            "59678",
            "59685",
            "59679"
        ],
        "values": {
            "53556": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59582",
        "name": "nrThermostats",
        "text": null,
        "usecases": [
            "59607"
        ],
        "values": {
            "53550": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59583",
        "name": "nrTouchscreens",
        "text": null,
        "usecases": [
            "59648"
        ],
        "values": {
            "53550": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59584",
        "name": "nrVentilationControlPoints",
        "text": null,
        "usecases": [
            "59611",
            "59612"
        ],
        "values": {
            "53552": 1,
            "53555": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    },
    {
        "xid": "59686",
        "name": "nrSocketOutletsOutsideWall",
        "text": null,
        "usecases": [
            "59677"
        ],
        "values": {
            "53556": 1
        },
        "image": null,
        "location": [LOCATION.INDOOR]
    }
]