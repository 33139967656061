/**
 * Keys available to store in local storage
 */
export enum LocalStorageKeys {
    /**
     * Description of value associated to key
     */
    EXAMPLE_KEY = 'example_key',
    ONBOARDING = 'onboarding'
}
