<div id="sidebar" [style.color]="color" [style.background-color]="bgcolor">
  <div class="sidebar-outer-wrapper">
    <div class="sidebar-inner-wrapper">
      <div class="sidebar-header flex space-between">
        <img gaCategory="Navigation" gaEvent="navigate" gaLabel="logo - {{projectId ? 'Project status' : 'Project list'}}" [routerLink]="projectId ? ('/'+ APP_ROUTES['PROJECT'].value.replace(':project_id', projectId)) : '/'+ APP_ROUTES['PROJECTLIST'].value" id="logo" src="/assets/logo/niko_logo.svg" alt="Niko logo">
        <app-navigation [sidebar]="true"></app-navigation>
      </div>
      <div class="sidebar-navigation">
        
        <!-- <button class="back-btn" [routerLink]="routerlinkPrevStep">
          <mat-icon svgIcon="back" class="icon icon--small"></mat-icon>
        </button> -->
    
        <p class="current-page">{{ breadcrumb }}</p>
    
       <!--  <button class="next-btn" [routerLink]="routerlinkNextStep">
          <mat-icon svgIcon="next"  class="icon icon--small"></mat-icon>
        </button> -->
    
      </div>
      <div class="sidebar-wrapper" gaCategory="Sidebar - {{ breadcrumb }}">
        <div class="sidebar-content" *ngIf="content && content.length > 0">
          <ng-container *ngFor="let routeObject of content">
            <button gaEvent="navigate" gaLabel="{{ breadcrumb }} - {{ routeObject.breadcrumb }}" class="sidenavItem" *ngIf="routeObject.available" [class.subitem]="routeObject.subitem" [class.complete]="routeObject.complete" [class.active]="routeObject.active" [routerLink]="routeObject.route"><span>{{ routeObject.breadcrumb }}</span></button>
            <div class="sidenavItem" *ngIf="!routeObject.available" [class.subitem]="routeObject.subitem" [class.unavailable]="!routeObject.available"><span>{{ routeObject.breadcrumb }}</span></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>