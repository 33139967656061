
  <div id="partner-compatibility-modal" gaCategory="Partner Compatibility Modal">
    <div mat-dialog-title>
      <button gaEvent="button_click" gaLabel="modal_close" (click)="dialogRef.close()" class="btn-close">
        <mat-icon class="icon icon--small">close</mat-icon>
      </button>
      
      <h2 class="warning text-center margin-b-70"><mat-icon svgIcon="error" class="icon icon--medium"></mat-icon><br>{{ 'PROJECT_FUNCTIONALITIES.PARTNER_COMPATIBILITY.TITLE' | translate }}</h2>
    </div>

    <div class="usecase-container block-center margin-b-100">
      <ng-container *ngFor="let usecase of data.incompatibleUsecases;let last = last">
        <p>{{usecase.title}}</p>
        <hr *ngIf="!last">
      </ng-container>
    </div>

    <p class="font-bold text-center margin-b-30">{{ 'PROJECT_FUNCTIONALITIES.PARTNER_COMPATIBILITY.DEACTIVATE' | translate }}</p>

    <div mat-dialog-actions class="button-wrapper">
      <button gaEvent="button_click" gaLabel="cancel" (click)="dialogRef.close()" class="btn btn-border btn-small margin-r">{{ 'PROJECT_FUNCTIONALITIES.PARTNER_COMPATIBILITY.CANCEL' | translate }}</button>
      <button gaEvent="button_click" gaLabel="confirm" (click)="confirm()" class="btn btn-primary btn-small">{{ 'PROJECT_FUNCTIONALITIES.PARTNER_COMPATIBILITY.CONFIRM' | translate }}</button>
    </div>
  </div>