
  <div id="finishing-component">
    <div mat-dialog-title>
      <button (click)="dialogRef.close()" class="btn-close">
        <mat-icon class="icon icon--small">close</mat-icon>
      </button>
  
      <h2 class="h1 margin-b">{{ 'PROJECT_FINISHINGS.CHANGE' | translate }}</h2>
    </div>

    <div mat-dialog-content class="finishing-container">

      <ng-container *ngIf="finishings && finishings.length > 0">
        <ng-container *ngFor="let finishing of finishings" >
          <div class="content-container relative">
            <button class="btn-selected btn btn-primary btn-small default" *ngIf="finishing.selected == Ternary.SELECTED">
              {{ 'PROJECT_FINISHINGS.LABEL_SELECTED' | translate}}
            </button>
    
            <div class="finishing-wrapper">
              <img class="finishing-img" 
              [src]="finishing.images ? finishing.images[0] : ''">
              <div class="text-wrapper">
                <p class="finishing-title font-l">{{ finishing.title }}</p>
                <p class="text-center margin-b-20">
                  <span class="font-m greyed-out" *ngIf="generalFinishing && finishing.xid == generalFinishing.xid">
                    ({{(location == 'outdoor' ? 'PROJECT_FINISHINGS.OUTDOOR.LABEL_GENERAL' : 'PROJECT_FINISHINGS.SELECTED.LABEL_GENERAL') | translate }})
                  </span>
                  <span *ngIf="!generalFinishing || finishing.xid != generalFinishing.xid">
                    <br>  
                  </span>
                </p>
                
                <div class="check-button pointer">
                  <div (click)="selectFinishing(finishing)" class="btn-icon">
                    <mat-icon *ngIf="finishing.selected == Ternary.SELECTED"  [svgIcon]="'radio'" class="icon"></mat-icon>
                    <mat-icon *ngIf="finishing.selected != Ternary.SELECTED" [svgIcon]="'radio_unchecked'" class="icon"></mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </ng-container>
      </ng-container>

    </div>

    <div class="button-wrapper">
      <button (click)="dialogRef.close()" class="btn btn-border btn-small margin-r">{{ 'PROJECT_ROOMEDIT.CANCEL' | translate }}</button>
      <button (click)="saveFinishing()" class="btn btn-primary btn-small">{{ 'PROJECT_FINISHINGS.CONFIRM' | translate }}</button>
    </div>

  </div>