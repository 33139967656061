import { PartnerConnection } from "./PartnerConnection";

export class Subject {
  xid!: string;
  order!: number;
  selected!: Ternary;
  title!: string;
  info!: string | null;
  imageFull!: string | null;
  video?: string | null;
  useCases?: Usecase[] | null;
}

export class Usecase {
  xid!: string;
  order!: number;
  selected!: Ternary;
  title!: string | null;
  info!: string | null;
  imageFull!: string | null;
  imageThumb!: string | null;
  partnerConnections?: PartnerConnection[] | null;
  conditions?: string[]
}

export class UsecaseSelect {
  xid!: string;
  selected!: Ternary;
}


export enum Ternary {
  TRUE = 'yes',
  FALSE   = 'no',
  MAYBE = 'maybe',
  SELECTED = 'general',
  DISABLED = 'disabled'
}

export const subjects: Subject[] = [

    {

      "xid": "53816",

      "order": 1,

      "selected": Ternary.TRUE,

      "title": "Connections",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "53820",

          "order": 1,

          "selected": Ternary.FALSE,

          "title": "Connect your digital decoder",

          "info": "<p>Each digital decoder needs two types of connections: a coax connection and an internet connection (LAN). This takes up a lot of space, when the number of connections you have behind your media or TV corner is often very limited. By choosing this option, we save you space by installing an all-in-one decoder connection.</p>\n",

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "53821",

          "order": 2,

          "selected": Ternary.FALSE,

          "title": "Connect to the internet",

          "info": "<p>Although good-quality Wi-Fi connections are quite normal these days, sometimes they are not as trustworthy or speedy as we would like them to be. Installing wall-mounted internet connections that are directly connected to your router via a cable, can offer better results in these situations.</p>\n",

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "53818",

          "order": 3,

          "selected": Ternary.FALSE,

          "title": "Power your electrical equipment",

          "info": "<p>Think of your vacuum cleaner, kitchen robot, TV, christmas tree, gaming console, reading lamp, and toaster. They have one thing in common: they need power. By choosing this option, you are saying yes to electrical socket outlets in the rooms you selected. Be free to use and recharge your devices and appliances wherever you like.</p>\n",

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59585",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Charge your devices",

          "info": "<p>Devices that need a USB A or USB C port are usually charged by plugging in their adapter in a normal socket outlet. By adding built-in chargers, you will never have to worry about finding your adapter. You can directly plug in the charging cable into the built-in charger.</p>\n",

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "53823",

      "order": 2,

      "selected": Ternary.TRUE,

      "title": "Lighting control",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59587",

          "order": 0,

          "selected": Ternary.TRUE,

          "title": "Switch or dim your lights",

          "info": "<p>Wall-mounted switches have been around for more than a century. You probably use them to switch the lights every day without even thinking. But did you know that you can make some rooms extra cosy or comfortable by using wall-mounted dimmers? You choose the brightness that you require for the task: studying, working out, or a cosy movie night or dinner party. It's all up to you.</p>\n",

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59588",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the lights off in one go",

          "info": "<p>When you leave the house, you want to be sure that everything is turned off properly. By choosing this option, you can push one button at your front or back door and your lighting in the entire house is turned off automatically.</p>\n",

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59589",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the lights when you enter or leave",

          "info": "<p>When you have children, they often forget to switch off the light when they leave the bathroom, causing unnecessary energy use. So what if we tell you you can switch them off automatically when no more motion is detected? By the way, switching the lights on automatically is very convenient when you have your hands full with groceries or with laundry. With this option, you'll always have light when and where you need it, and not a minute longer.</p>\n",

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59590",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the lights following a time schedule",

          "info": "<p>Sometimes you know exactly when and where you will need lights. By choosing this option, it will be possible to make your lights follow a time schedule, so they are switched on and off exactly when you want them to.</p>\n",

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59591",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the lights when the sun is down",

          "info": "<p>We all love long summer nights! But when the sun is out for a long time, why keep your lights switched on? Following a time schedule is not always the right solution. Did you know you can automate your lights using an automatic sunrise and sunset schedule? If this is for you, choose this option.</p>\n",

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59592",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the lights following a routine",

          "info": "<p>Create the atmosphere and ambiance you need, when you want it. Automate your daily activities by programming a routine once, and you will always have it available. To activate it, you can either push a button or use your smartphone. Or even have your installation decide it for you based on predefined conditions. The most popular routines are \"all-off\", \"welcome home\", \"goodnight\" and \"good morning\" and even \"warning\". The possibilities are only limited by your imagination!</p>\n",

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59547",

      "order": 3,

      "selected": Ternary.FALSE,

      "title": "Temperature control",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59606",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59607",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59608",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the heating or air conditioning off in one go",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59609",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the heating and air conditioning following a routine",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59548",

      "order": 4,

      "selected": Ternary.FALSE,

      "title": "Air quality control",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59610",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59611",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59612",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59614",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the ventilation off in one go",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59615",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the ventilation when you enter or leave",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59618",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59620",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the ventilation following a routine",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59549",

      "order": 5,

      "selected": Ternary.FALSE,

      "title": "Electrical roll-down shutters and sun blinds",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59621",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59622",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59623",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Close or open the shutters and blinds following a routine",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59624",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59550",

      "order": 6,

      "selected": Ternary.FALSE,

      "title": "Energy-efficient living",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59625",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59628",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59626",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59629",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59627",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59630",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59631",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59634",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59633",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59635",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59632",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59637",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59551",

      "order": 7,

      "selected": Ternary.FALSE,

      "title": "Audio control",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59649",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59650",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59648",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59651",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Switch the music off in one go",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59652",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59653",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59552",

      "order": 8,

      "selected": Ternary.FALSE,

      "title": "Electrical windows and doors",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59654",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59655",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59656",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Close the windows and doors in one go",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59657",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Close the windows and doors automatically when you enter or leave",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59658",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59659",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59660",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59553",

      "order": 9,

      "selected": Ternary.FALSE,

      "title": "Access control",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59661",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "See and hear that someone is at your front door",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59663",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Talk to your visitor with a touchscreen",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59662",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Talk to your visitor via your smartphone",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59665",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "See who has rung your doorbell",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59666",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Check if your electrical door or gate is open or closed",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59664",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Open your electrical door when you're not home",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59554",

      "order": 10,

      "selected": Ternary.FALSE,

      "title": "Living safely",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59669",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59673",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59670",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59668",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": "Turn on the lights when someone approaches your house",

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59674",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59671",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59667",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59672",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59555",

      "order": 11,

      "selected": Ternary.FALSE,

      "title": "Outdoor comfort",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59677",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59675",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59676",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59678",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59685",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59679",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    },

    {

      "xid": "59556",

      "order": 12,

      "selected": Ternary.TRUE,

      "title": "Commands and interfaces",

      "info": null,

      "imageFull": null,

      "useCases": [

        {

          "xid": "59680",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59681",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59682",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59683",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        },

        {

          "xid": "59684",

          "order": 0,

          "selected": Ternary.FALSE,

          "title": null,

          "info": null,

          "imageFull": null,

          "imageThumb": null

        }

      ]

    }

  ]