import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { debounceTime, distinctUntilChanged, first, take } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { Subject, Ternary, Usecase } from 'src/app/models/Subject';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { clone, projectPropertyValue } from 'src/app/util/Util';

@Component({
  selector: 'app-functionalities',
  templateUrl: './functionalities.component.html',
  styleUrls: ['./functionalities.component.scss']
})
export class FunctionalitiesComponent extends BaseComponent implements OnInit {

  public projectId!: string;
  public projectArchived: boolean = true;

  public toggleSelected = true;
  public toggleMaybe = true;
  public togglePossible = false;

  public subjects: Subject[] = [];
  public selectedSubjects: Subject[] = [];
  public selectedUsecases: Usecase[] = [];

  public maybeSubjects: Subject[] = [];
  public maybeUsecases: Usecase[] = [];

  public possibleSubjects: Subject[] = [];
  public possibleUsecases: Usecase[] = [];

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private translate: TranslateService,
    private subjectService: SubjectService,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private gaService: GoogleAnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.params['project_id'];   
     
    this.addSubscription(this.projectService.getProjectById(this.projectId).subscribe(projectData => {
      // Project Archive Status
      this.projectArchived = (projectPropertyValue(projectData.properties, 'archived') == 'true');

      // Subjects
      this.addSubscription(this.subjectService.cachedSubjects.subscribe((r: Subject[]) => {
        //console.log('get cache on functionalities');

        // Ordered array of all subjects
        this.subjects = this.subjectService.disableUsecasesByConditions(this.projectId, r, projectData.properties);

        // Subjects with selected usecases
        let selectedUseCaseArray = clone(this.subjects) as Subject[];
        this.selectedSubjects = this.checkSubjects(selectedUseCaseArray, Ternary.TRUE);
        this.selectedUsecases = selectedUseCaseArray.flatMap(x => x.useCases!.filter(y => (y.selected === Ternary.TRUE)));

        // Subjects with usecases under consideration
        let maybeUsecaseArray = clone(this.subjects) as Subject[];
        this.maybeSubjects = this.checkSubjects(maybeUsecaseArray, Ternary.MAYBE);
        this.maybeUsecases = maybeUsecaseArray.flatMap(x => x.useCases!.filter(y => (y.selected === Ternary.MAYBE)));

        // Subjects with possible usecases
        let possibleUseCaseArray = clone(this.subjects) as Subject[];
        this.possibleSubjects = this.checkSubjects(possibleUseCaseArray, Ternary.FALSE);
        this.possibleUsecases = possibleUseCaseArray.flatMap(x => x.useCases!.filter(y => (y.selected === Ternary.FALSE)));

        // Set sidebar content
        let selectedSubjects = this.subjects.filter(x => x.selected === Ternary.TRUE);
        this.SetSideBarContent(selectedSubjects);
      }));
    }));
  }

  /**
 * Add dynamic content for sidebar navigation
 */
    SetSideBarContent(subjects: Subject[]) {
    this.sideBarContent = [];
    
    subjects.forEach(subject => {
      this.sideBarContent.push({
        route: '../../' + APP_ROUTES['FUNCTIONALITIES_USECASES'].value.replace(':topic_id', subject.xid), 
        breadcrumb: subject.title,
        complete: true,
        active: false,
        available: true,
        subitem: true
      });
    })
    
    this.sideBarContent.unshift({
      route: '../../' + APP_ROUTES['FUNCTIONALITIES_SUBJECTS'].value, 
      breadcrumb: this.translate.instant('PROJECT_FUNCTIONALITIES.NAV.SUBJECTS'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['FUNCTIONALITIES_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_FUNCTIONALITIES.NAV.SUMMARY'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });
  }


  /**
   * Filter and combine usecases with the required selected status out of all given subjects
   * @param subjects subject array source
   * @param selected required selected status
   * @returns array of filtered subjects containing only the usecases with the required selected status
   */
  private checkSubjects(subjects: Subject[], selected: Ternary): Subject[] {
    let src: Subject[] = subjects;
    let res: Subject[] = [];

    if (selected == Ternary.TRUE) {
      src.forEach(subject => {
        subject.useCases = subject.useCases!.filter(useCase => useCase.selected !== Ternary.FALSE);
        if (subject.useCases.length > 0) {
          res = res.concat(subject);
        }
      });
    } else if (selected == Ternary.MAYBE) {
      src.forEach(subject => {
        subject.useCases = subject.useCases!.filter(useCase => useCase.selected === Ternary.MAYBE);
        if (subject.useCases.length > 0) {
          res = res.concat(subject);
        }
      });
    } else {
      src.forEach(subject => {
        subject.useCases = subject.useCases!.filter(useCase => (useCase.selected === Ternary.FALSE));
        if (subject.useCases.length > 0) {
          res = res.concat(subject);
        }
      });
    }

    return res;
  }

  /**
   * Check if subject contains selected usecases
   * @param subject subject to be checked for selected usecases
   * @returns boolean showing if subject contains selected usecases
   */
  public subjectContainsSelectedUsecases(subject:Subject): boolean {
    return subject.useCases!.some(usecase => usecase.selected == Ternary.TRUE);
  }

  /**
 * Set usecase to required status
 * @param id usecase id
 * @param status required status for the given usecase
 */
  selectUsecaseState(currentUsecase: Usecase) {
    let projectId = this.activatedRoute.snapshot.params['project_id'];

    let subjectString = '';
    let useCaseString = currentUsecase.selected + '=' + currentUsecase.xid + '&';

    //Functionality analytics
    this.gaService.event('set_' + currentUsecase.selected, 'Functionality Status', currentUsecase.title!);

    this.subjects.forEach(subject => {

      subject.useCases!.forEach(usecase => {
        if(usecase.xid == currentUsecase.xid) {

          // set subject as selected if currentusecase is set to true or maybe, and the subject is false, 
          if (currentUsecase.selected != Ternary.FALSE && subject.selected == Ternary.FALSE) {
            subject.selected = Ternary.TRUE;
            subjectString = subject.selected + '='+ subject.xid;

            //Subject analytics
            this.gaService.event('set_yes', 'Topic Status', subject.title);
          }

          usecase.selected = currentUsecase.selected;
          false; // stop current usecaseData iteration after a match
        }
      });
    });

    // Subjects with selected usecases
    let selectedUseCaseArray = clone(this.subjects) as Subject[];
    this.selectedSubjects = this.checkSubjects(selectedUseCaseArray, Ternary.TRUE); 
    
    // Update sidebar
    let selectedSubjects = this.subjects.filter(x => x.selected === Ternary.TRUE);
    this.SetSideBarContent(selectedSubjects);
    
    // Update cachedsubjects and backend
    this.subjectService.cachedSubjects.next(this.subjects);
    this.subjectService.selectSubjectState(projectId, subjectString);
    this.subjectService.selectUsecaseState(projectId, useCaseString);
  }
}