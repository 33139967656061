import { APP_ROUTES } from '../modules/app-routing/AppRoutes';
import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalizedRoutes } from '../modules/app-routing/model/LocalizedRoutes';
import { Ternary } from '../models/Subject';
import { LOCATION } from '../models/Finishing';
import { DocumentType } from '../models/Project';

@Injectable()
export class BaseComponent implements OnDestroy {

    // Routes object to use in component controller and view. With this object the route tags can be fetched
    public APP_ROUTES: LocalizedRoutes = APP_ROUTES;

    // Make class available for use in component controller and view
    Ternary = Ternary;
    LOCATION = LOCATION;
    DocumentType = DocumentType;


    // Object to keep subscriptions of child class
    private subscriptions: Subscription[] = [];

    // Screensize variable
    public screensize: string = 'medium';

    /**
     * Unsubscribe all subscriptions in the child class on destroy to avoid memory leaks
     * Based on unsubscribe functionality at https://malcoded.com/posts/angular-async-pipe
     */
    ngOnDestroy() {
        this.removeSubscriptions();
    }

    /**
     * With this method a child class can register it's subscriptions in the BaseComponent parent class
     * @param subscripiton to register
     */
    protected removeSubscriptions(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((s: Subscription, index: number) => {
                s.unsubscribe();
                // TODO check if necessary
                // s.remove(s);
            });
        }
    }

    /**
     * With this method a child class can register it's subscriptions in the BaseComponent parent class
     * @param subscripiton to register
     */
    protected addSubscription(subscripiton: Subscription): void {
        if (!this.subscriptions) {
            this.subscriptions = [];
        }
        this.subscriptions.push(subscripiton);
    }

    /**
     * Navigate back in the window history
     */
    public navigateBack() {
        window.history.back();
    }

    /**
     * Set variable screensize based on the windowsize
     */
    public windowSize(innerWidth: any = window.innerWidth) {
        if (innerWidth <= 767) {
            this.screensize = 'small-down';
        } else if (innerWidth <= 991) {
            this.screensize = 'medium';
        } else {
            this.screensize = 'large-up';
        }
    }
}
