import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, forkJoin, last, take } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { OnboardingModalComponent } from 'src/app/components/modals/onboarding-modal/onboarding-modal.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { Finishing, FinishingRange, LOCATION } from 'src/app/models/Finishing';
import { Project, ProjectModule } from 'src/app/models/Project';
import { Room, RoomInstance } from 'src/app/models/Room';
import { Ternary, Usecase } from 'src/app/models/Subject';
import { Variable, VariableInstance, VariableValues } from 'src/app/models/Variable';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { FinishingService } from 'src/app/services/finishing/finishing.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { RoomService } from 'src/app/services/room/room.service';
import { VariableService } from 'src/app/services/variable/variable.service';
import { clone, projectPropertyValue } from 'src/app/util/Util';
import { SpacesModalComponent } from '../../project-intake/spaces/spaces-modal/spaces-modal.component';
import { AddroomsModalComponent } from './addrooms-modal/addrooms-modal.component';
import { FinishingModalComponent } from './finishing-modal/finishing-modal.component';
import { RoomModalComponent } from './room-modal/room-modal.component';
import { VariableModalComponent } from './variable-modal/variable-modal.component';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.scss']
})
export class SpaceComponent extends BaseComponent implements OnInit {

  public projectId!: string;
  public projectData!: Project;
  public projectArchived: boolean = true;

  public variableList!: Variable[];

  public roomList!: Room[];

  public rooms!: RoomInstance[];

  public finishingRangeList!: FinishingRange[];

  public indoorFinishing!: Finishing;
  public outdoorFinishing!: Finishing;

  public indoorFinishings!: Finishing[];
  public outdoorFinishings!: Finishing[];

  public toggleSelected: {
    [roomId: string]: boolean
  } = {};

  public toggleRoomEdit: {
    [roomId: string]: boolean
  } = {};

  public name: {
    [roomId: string]: UntypedFormControl 
  } = {};

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private translate: TranslateService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    public roomService: RoomService,
    private finishingService: FinishingService,
    public variableService: VariableService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
    ) { super(); }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.params['project_id'];    

    this.SetSideBarContent();

    this.openOnboardingModal();

    // Get project data
    this.addSubscription(this.projectService.getProjectById(this.projectId).subscribe(r => {
      this.projectData = r;

      // Project Archive Status
      this.projectArchived = (projectPropertyValue(this.projectData.properties, 'archived') == 'true');

      // Subscriptions for variableList and finishingRangeList
      let variables$ = this.variableService.getVariables();
      let finishings$ = this.finishingService.getFinishings();

      // Subscription for roomlist
      let buildingType = projectPropertyValue(this.projectData.properties, 'buildingtype')!;
      let rooms$ = this.roomService.getRooms(buildingType);

      // Get variableList, finishingRangeList and roomlist
      this.addSubscription(forkJoin({variables: variables$, finishings: finishings$, rooms: rooms$}).subscribe(r => {

        // VariableList
        this.variableList = r.variables;

        // FinishingRangeList
        this.finishingRangeList = r.finishings;

        // Roomlist
        this.roomList = r.rooms;

        // Combine variables with project room instances
        let rooms = clone(this.projectData.rooms);
        this.rooms = this.variableService.combineVariablesForProjectRooms(this.projectData, this.variableList, this.roomList, rooms);

        // Finishing defaults
        this.replaceFinishingDefaults(this.finishingRangeList);

        // For each roomInstance, set finishings matching one or more variables with a value > 0 
        this.rooms.forEach(room => {
          this.setRoomFinishings(room, this.indoorFinishing, this.outdoorFinishing, this.indoorFinishings, this.outdoorFinishings);

          // Initialize form control
          this.name[room.id] = new UntypedFormControl(room.name);
        })

        // Sort rooms
        this.sortRoomsAlphabetically();

        // Clear variables and set the new variables on backend
        this.addSubscription(this.variableService.initVariablesForRooms(this.projectId, this.rooms).pipe(take(1)).subscribe(() => {
          
        }));
      }));
    }));
  }

  private replaceFinishingDefaults(finishingRangeList: FinishingRange[]) {
    // Replace defaults by finishings projectdata
    let finishingData = this.projectData.finishings;

    finishingRangeList.forEach(range => {
      range.finishings?.forEach(finishing => {
        finishing.title = range.title + ' - ' + finishing.title;

        if(finishingData && finishingData.length > 0 ) {
          finishing.selected = finishingData?.find((data:Finishing) => data.xid === finishing.xid)?.selected || Ternary.FALSE;
        } else {
          finishing.selected = Ternary.FALSE;
        }
      })
    });

    // Flatten and filter finishings by location
    this.indoorFinishings = finishingRangeList.filter(finishing => !finishing.location.includes(LOCATION.OUTDOOR)).flatMap(x => x.finishings!.filter(y => y));
    this.outdoorFinishings = finishingRangeList.filter(finishing => finishing.location.includes(LOCATION.OUTDOOR)).flatMap(x => x.finishings!.filter(y => y));

    // Get the general finishings
    this.indoorFinishing = finishingRangeList.filter(finishing => !finishing.location.includes(LOCATION.OUTDOOR)).flatMap(x => x.finishings!.filter(y => (y.selected === Ternary.SELECTED)))[0];
    this.outdoorFinishing = finishingRangeList.filter(finishing => finishing.location.includes(LOCATION.OUTDOOR)).flatMap(x => x.finishings!.filter(y => (y.selected === Ternary.SELECTED)))[0];
  }

  /**
   * Change room instance finishings matching one or more of the room's variables with a value > 0
   * @param room the roominstance to be changed
   * @param indoorDefault the default indoor finishing
   * @param outdoorDefault the default outdoor finishing
   * @param indoorFinishings the list of indoor finishings
   * @param outdoorFinishings the list of outdoor finishings
   */
   public setRoomFinishings(room:RoomInstance, indoorDefault: Finishing, outdoorDefault: Finishing, indoorFinishings: Finishing[], outdoorFinishings: Finishing[]) {
    if(room.variables?.some(variable => {
      return variable.location?.includes(LOCATION.INDOOR) && variable.values![room.typeXid] && variable.values![room.typeXid]! > 0
    })) {
      //console.log(room.name + ' requires indoorfinishing');
      let indoorFinishing;

      if(room.finishingIndoor && room.finishingIndoor !== '') {
        //console.log(room.name + ' has custom indoorfinishing');

        indoorFinishing = indoorFinishings.find(finishing => finishing.xid == room.finishingIndoor);
        //console.log(room.name + ' has custom indoorfinishing: ' + indoorFinishing?.title);
      }
      if(!indoorFinishing) {
        //console.log(room.name + ' has general indoorfinishing');
        indoorFinishing = indoorDefault;
      }

      room.indoorFinishing = indoorFinishing;
    } else {
      room.indoorFinishing ? delete room.indoorFinishing : false;
    }

    if(room.variables?.some(variable => {
      return variable.location?.includes(LOCATION.OUTDOOR) && variable.values![room.typeXid] && variable.values![room.typeXid]! > 0
    })) {
      //console.log(room.name + ' requires outdoorfinishing');
      let outdoorFinishing;

      if(room.finishingOutdoor && room.finishingOutdoor !== '') {
        //console.log(room.name + ' has custom outdoorfinishing');

        outdoorFinishing = outdoorFinishings.find(finishing => finishing.xid == room.finishingOutdoor);
        //console.log(room.name + ' has custom outdoorfinishing: ' + outdoorFinishing?.title);
      }
      if(!outdoorFinishing) {
        //console.log(room.name + ' has general outdoorfinishing');
        outdoorFinishing = outdoorDefault;
      }
      room.outdoorFinishing = outdoorFinishing;
    } else {
      room.outdoorFinishing ? delete room.outdoorFinishing : false;
    }

    //console.log(room.name + ' has indoorfinishing: ' + room.indoorFinishing?.title);
    //console.log(room.name + ' has outdoorfinishing: ' + room.outdoorFinishing?.title);
  }

  /**
   * Update a variable on backend
   */
  setVariable(room:RoomInstance, variableId: string, value: number) {
    // Update finishings matching one or more variables with a value > 0 
    this.setRoomFinishings(room, this.indoorFinishing, this.outdoorFinishing, this.indoorFinishings, this.outdoorFinishings);
  
    // Update variable on backend
    let entries: string[] = [];
    entries.push(variableId + ':' + room.id + ':' + value);

    this.addSubscription(this.variableService.setVariables(this.projectId, false, entries).pipe(take(1)).subscribe(() => {
    }));
  }

  /**
   * Open onboarding modal
   */
   openOnboardingModal() {
    const dialogRef = this.dialog.open(OnboardingModalComponent, {
      autoFocus: false, 
      data: {
        'step':  ProjectModule.ROOMCONFIGURATION,
        'background': '/assets/pictures/onboarding/onboarding_installer.jpg'
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      width: '900px'
    });

    dialogRef.afterClosed().subscribe(r => {
      /* if (r && r.room) {
        this.removeRoom(r.room.id)
      } */
    });
  }

      /**
   * Open tooltip modal
   */
      public openTooltip(title: string = '', text: string) {
        const dialogRef = this.dialog.open(TooltipModalComponent, {
          autoFocus: false, 
          data: {
            'title': title,
            'text': text
          },
          maxWidth: '90vw',
          maxHeight: '90vh',
          minWidth: '775px'
        });
      }
    
  /**
   * Add dynamic content for sidebar navigation
   */
  SetSideBarContent() {
    this.sideBarContent = [];
    
    this.sideBarContent.push({
      route: '../' + APP_ROUTES['ROOMCONFIGURATION_VARIABLES'].value, 
      breadcrumb: this.translate.instant('PROJECT_VARIABLES.NAV'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });
  }


  /**
   * Remove room
   */
  removeRoom(roomId: string) {
    this.rooms.find((roomInstance, index) => {
      if(roomInstance.id == roomId) {
        this.rooms.splice(index, 1);
        return true;
      } else {
        return false;
      }
    });

    this.addSubscription( this.roomService.removeRoom(this.projectId, roomId).pipe(take(1)).subscribe(r => {

    }));  
  }

  /**
   * Sort rooms alphabetically
   */
  sortRoomsAlphabetically() {
    //console.log(this.rooms);
      this.rooms = this.rooms.sort((a,b) => ((a.name ? a.name : a.title) > (b.name ? b.name : b.title)) ? 1 : (((b.name ? b.name : b.title) > (a.name ? a.name : a.title)) ? -1 : 0));
  }

  /**
   * Open add rooms modal
   */
  openAddRoomsDialog() {
    const dialogRef = this.dialog.open(AddroomsModalComponent, {
      autoFocus: false, 
      data: {
        'projectId': this.projectId,
        'roomList': this.roomList,
        'rooms': this.rooms
      },
      width: '90vw',
      height: '90vh',
    });

    dialogRef.afterClosed().subscribe(r => {
      if(this.rooms.length !== r.rooms.length) {
        this.rooms = this.variableService.combineVariablesForProjectRooms(this.projectData, this.variableList, this.roomList, r.rooms);

        // For each roomInstance, set finishings matching one or more variables with a value > 0 
        this.rooms.forEach(room => {
          this.setRoomFinishings(room, this.indoorFinishing, this.outdoorFinishing, this.indoorFinishings, this.outdoorFinishings);
        })

        // Sort rooms
        this.sortRoomsAlphabetically();

        // Clear variables and set the new variables on backend
        this.addSubscription(this.variableService.initVariablesForRooms(this.projectId, this.rooms).pipe(take(1)).subscribe(() => {
        }));
      }
    });
  }

  /**
   * Set new room name
   * @param roomId  Room for which the name should be set
   */
  setRoomName(roomId: string) {
    if(this.name[roomId].valid) {
      let name = this.name[roomId].value.toLowerCase().replace(/([a-z])/i, (str:string, firstLetter:string) => firstLetter.toUpperCase());

      this.rooms.forEach(roomInstance => {
        if(roomInstance.id == roomId) {
          
          roomInstance.name = name;
      
          let roomString = 'id=' + roomId

          // Add room name to room string if available
          if(roomInstance.name && roomInstance.name != 'null') {
            roomString = roomString + '&name=' + roomInstance.name;
          }
          // Add existing finishing data for outdoor to room string
          if(roomInstance.finishingOutdoor && roomInstance.finishingOutdoor != 'null') {
            roomString = roomString + '&finishing_outdoor=' + roomInstance.finishingOutdoor;
          }
          // Add existing finishing data for indoor to room string
          if(roomInstance.finishingIndoor && roomInstance.finishingIndoor != 'null') {
            roomString = roomString + '&finishing_indoor=' + roomInstance.finishingIndoor;
          }

          this.roomService.editRoom(this.projectId, roomString);
        }
      });

    }
  }

  /**
   * Open remove room modal
   */
  openRemoveRoomDialog(room: RoomInstance) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      autoFocus: false, 
      data: {
        'title': 'CONFIRMATION_MODAL.REMOVE.ROOM',
        'confirmationData': room
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      minWidth: '775px'
    });

    dialogRef.afterClosed().subscribe(r => {
      if (r && r.confirmationData) {
        this.removeRoom(r.confirmationData.id)
      }
    });
  }

  /**
   * Open variable modal
   */
  openVariableDialog(room: RoomInstance) {
    const dialogRef = this.dialog.open(VariableModalComponent, {
      autoFocus: false, 
      data: {
        'room': room,
        'entries': []
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      minWidth: '775px'
    });

    // Save variable changes to room
    dialogRef.afterClosed().subscribe(r => {
      if(r && r.room) {
        this.rooms.forEach(roomInstance => {
          if(r && roomInstance.id == r.room.id) {
            roomInstance = r.room;
          }
        });
        
        if(r.entries && r.entries.length > 0) {
          this.addSubscription(this.variableService.setVariables(this.projectId, false, r.entries).pipe(take(1)).subscribe(() => {
          }));     
        }
      }
    });
  }

  /**
   * Open finishing modal
   */
  openFinishingDialog(room: RoomInstance, location: LOCATION) {
    const dialogRef = this.dialog.open(FinishingModalComponent, {
      autoFocus: false, 
      data: {
        'projectId': this.activatedRoute.snapshot.params['project_id'],
        'room': room,
        'location': location
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      minWidth: '935px',
      minHeight: '490px'
    });

    dialogRef.afterClosed().subscribe(r => {
      if(r && r.room) {
        this.rooms.forEach(roomInstance => {
          if(r && roomInstance.id == r.room.id) {
            roomInstance.finishingOutdoor = r.room.finishingOutdoor;
            roomInstance.outdoorFinishing = r.room.outdoorFinishing;
            roomInstance.finishingIndoor = r.room.finishingIndoor;
            roomInstance.indoorFinishing = r.room.indoorFinishing;            //console.log(this.rooms);
          }
        });
      }
    });
  }
}
