/**
 * Keys available to store in session storage
 */
export enum SessionStorageKeys {
   /**
     * Description of value associated to key
     */
    EXAMPLE_KEY = 'example_key',
    USER = 'user',
    LOCALE = 'locale',
    COUNTRY = 'country',
    STATUS = 'status',
    NAVIGATIONS = 'totalNavigations'
  }
