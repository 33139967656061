import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { forkJoin, take, TimeoutConfig } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { OnboardingModalComponent } from 'src/app/components/modals/onboarding-modal/onboarding-modal.component';
import { Project, ProjectModule, Property } from 'src/app/models/Project';
import { Room, RoomInstance } from 'src/app/models/Room';
import { Article, Calculation, CalculationEntry, InstallType } from 'src/app/models/System';
import { User } from 'src/app/models/User';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { RoomService } from 'src/app/services/room/room.service';
import { SystemService } from 'src/app/services/system/system.service';
import { UserService } from 'src/app/services/user/user.service';
import { VariableService } from 'src/app/services/variable/variable.service';
import { clone, projectPropertyValue } from 'src/app/util/Util';

@Component({
  selector: 'app-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss']
})
export class CalculationComponent extends BaseComponent implements OnInit, OnDestroy {

  public userData!: User;

  public projectId!: string;
  public projectData!: Project;

  public progressBar = 0;

  public sideBarContent: AppRouteObject[] = [];

  private calculationTimeout!: NodeJS.Timeout;
  private calcTracker: number = 0;

  constructor(
    private translate: TranslateService,
    public systemService: SystemService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private variableService: VariableService,
    private propertyService: PropertyService,
    private roomService: RoomService,
    private dialog: MatDialog,
    private nikoApi: NikoApiService,  
    private userService: UserService,
    private gaService: GoogleAnalyticsService
  ) { super(); }

  ngOnInit(): void {
    this.projectId = this.activatedRoute.snapshot.params['project_id'];

    this.openOnboardingModal();

    this.getUserData();

    this.SetSideBarContent();

    this.addSubscription(this.projectService.getProjectById(this.projectId).subscribe(r => {
      this.projectData = r;
      this.checkCalculationAccessible();
    }));
  }

  override ngOnDestroy(): void {  
    this.removeSubscriptions();
    clearTimeout(this.calculationTimeout);
  }

  public checkCalculationAccessible() {
    if(projectPropertyValue(this.projectData.properties, 'roomsconfigurated') != 'true') {

      // Subscription for variableList
      let variables$ = this.variableService.getVariables();

      // Subscription for roomlist
      let buildingType = projectPropertyValue(this.projectData.properties, 'buildingtype')!;
      let rooms$ = this.roomService.getRooms(buildingType);

      // Get variableList, finishingRangeList and roomlist
      this.addSubscription(forkJoin({variables: variables$, rooms: rooms$}).subscribe(r => {

        // Combine variables with project room instances
        let rooms = clone(this.projectData.rooms);
        rooms = this.variableService.combineVariablesForProjectRooms(this.projectData, r.variables, r.rooms, rooms);

        // Clear variables and set the new variables on backend
        this.addSubscription(this.variableService.initVariablesForRooms(this.projectId, rooms).pipe(take(1)).subscribe(() => {
          this.getCalculationResults();
        }));
      }));
    } else {
      this.getCalculationResults();
    }
  }

  public getCalculationResults() {

    // Set calculation timeout to 30 seconds
    this.calculationTimeout = setTimeout(() => {
      console.log('CALCULATIONERROR: initial calculationTimeout');
      this.gaService.event('client_timeout', 'Calculation', this.projectId + ' - ' + this.userData.email);
      this.router.navigate(['/' + APP_ROUTES['PROJECT'].value.replace(':project_id', this.projectId) + '/' + APP_ROUTES['RESULTS_SUMMARY'].value]);
    }, 55000);

    // Get calculation results
    this.addSubscription(this.projectService.calculateProjectResultsById(this.projectId).subscribe((r) => {
      this.calcTracker = this.calcTracker + 1;

      // Clear and reset calculation timeout every time there's progress in the calculation call
      clearTimeout(this.calculationTimeout);
      this.calculationTimeout = setTimeout(() => {
        console.log('CALCULATIONERROR: calculationTimeout cycle ' + r.progress);
        this.gaService.event('client_timeout', 'Calculation', this.projectId + ' - ' + this.userData.email);
        this.router.navigate(['/' + APP_ROUTES['PROJECT'].value.replace(':project_id', this.projectId) + '/' + APP_ROUTES['RESULTS_SUMMARY'].value]);
      }, 55000);

      // Progress
      if(r.progress) {
        // Show progress on progressbar
        this.progressBar = r.progress;
      }
      
      console.log(r);

      // Results
      if(r.results) {

        this.progressBar = 100;

        // Proceed to installtype page
        setTimeout(() => { // Timeout gives smoother transition from full progressbar to next page
          this.projectService.calculation = r;
          clearTimeout(this.calculationTimeout);
          this.router.navigate(['/' + APP_ROUTES['PROJECT'].value.replace(':project_id', this.projectId) + '/' + APP_ROUTES['RESULTS_INSTALLTYPES'].value]);
        }, 500);
      }

      // Error
      if(r.status! == 'failure') {
        this.gaService.event('failure', 'Calculation', this.projectId + ' - ' + this.userData.email);
        
        this.progressBar = 100;
        // Proceed to calculation error page: calculation error
        setTimeout(() => { // Timeout gives smoother transition from full progressbar to next page
          this.projectService.calculation = r;
          clearTimeout(this.calculationTimeout);
          this.router.navigate(['/' + APP_ROUTES['PROJECT'].value.replace(':project_id', this.projectId) + '/' + APP_ROUTES['RESULTS_SUMMARY'].value]);
        }, 500);
      }

      // Error
      if(r.status! == 'error') {
        this.gaService.event('error', 'Calculation', this.projectId + ' - ' + this.userData.email);
        
        // Proceed to calculation error page: technical error
        setTimeout(() => { // Timeout gives smoother transition from full progressbar to next page
          this.projectService.calculation = r;
          clearTimeout(this.calculationTimeout);
          console.log('CALCULATIONERROR: status is error');
          this.router.navigate(['/' + APP_ROUTES['PROJECT'].value.replace(':project_id', this.projectId) + '/' + APP_ROUTES['RESULTS_SUMMARY'].value]);
        }, 500);
      }
    }));
  }

  /**
   * Get User data to show current settings
   */
   private getUserData() {
    this.userData = this.userService.user.getValue();

    // If no user settings are available, redirect to login
    if(!this.userData.email) {
      this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
    }
   }

  /**
  * Open onboarding modal
  */
   openOnboardingModal() {
    const dialogRef = this.dialog.open(OnboardingModalComponent
    , {
      autoFocus: false,
      data: {
        'step': ProjectModule.RESULTS,
        'background': '/assets/pictures/onboarding/onboarding_installer.jpg'
      },
      maxWidth: '90vw',
      maxHeight: '90vh',
      width: '900px'
    });

    dialogRef.afterClosed().subscribe(r => {
      /* if (r && r.room) {
        this.removeRoom(r.room.id)
      } */
    });
  }

  /**
   * Add dynamic content for sidebar navigation
   */
   SetSideBarContent() {
    this.sideBarContent = [];

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_CALCULATION'].value, 
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.CALCULATION'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_INSTALLTYPES'].value,
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.INSTALLTYPE'),
      complete: false,
      active: false,
      available: false,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['RESULTS_PRODUCTS'].value,
      breadcrumb: this.translate.instant('PROJECT_RESULTS.NAV.PRODUCTS'),
      complete: false,
      active: false,
      available: false,
      subitem: false
    });
  }
}
