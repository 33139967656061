import { LogLevel } from "src/app/services/logger/LogLevel";

export interface Environment {
  ProjectName: string;
  appVersion: string;
  version: string;
  production: boolean;
  mockData: boolean;
  defaultLanguage: string;
  defaultCountry: string;
  languages: string[];
  users: { username: string, password: string }[];
  endpoint: string;
  trackingId: string;
  logLevel: LogLevel;
}

export const environment: Environment = {
  ProjectName: 'Niko project wizard',
  appVersion: 'Test: ' + require('../../package.json').version,
  version: require('../../package.json').version,
  production: false,
  mockData: false,
  defaultLanguage: 'en_GB',
  defaultCountry: 'BE',
  languages: [
    'nl_BE',
    'en_GB'
  ],
  users: [
    {
      username: 'tim@installateur.be',
      password: 'niko'
    }
  ],
  endpoint: 'https://api.test.projectwizard.niko.eu/api',
  trackingId: 'G-T8G7FZ9RZ3',
  logLevel: LogLevel.WARN
};
