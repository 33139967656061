<app-project-page-template [breadcrumb]="'PROJECT_INTAKE.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_DETAIL_CLIENT.BREADCRUMB_PREV' | translate" [routerlinkPrev]="'../../'"
  [breadcrumbNext]="'PROJECT_DETAIL_CLIENT.BREADCRUMB_NEXT' | translate"
  [routerlinkNext]="'../../' + APP_ROUTES['INTAKE_BUILDINGTYPE'].value"
  [sideBarContent]="sideBarContent"
  gaCategory="Intake - Client details">
  <!--
    Adres 1 en 2 ipv straat en nummer
    
  -->
  <form id="content" [formGroup]="projectDetails" *ngIf="projectData" autocomplete="off">
    <div>
      <h1 class="margin-b-60">{{ 'PROJECT_DETAIL.TITLE' | translate }}</h1>
      <div class="form-wrapper">
        <div class="input45">
          <mat-form-field class="full-width tooltip-trigger">
            <mat-label>{{ 'PROJECT_DETAIL.NAME' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="name" (focusout)="saveProperty('name')">
            <span class="tooltip-btn tooltip-input" (click)="openTooltip('PROJECT_DETAIL.NAME','PROJECT_DETAIL.NAME.INFO')"></span>
          </mat-form-field>
        </div>
        <div class="input35">
          <mat-form-field class="full-width tooltip-trigger">
            <mat-label>{{ 'PROJECT_DETAIL.DATE' | translate }}</mat-label>
            <input autocomplete="new-password" type="date" matInput formControlName="startdate" (focusout)="saveProperty('startdate')">
            <span class="tooltip-btn tooltip-input" (click)="openTooltip('PROJECT_DETAIL.DATE','PROJECT_DETAIL.DATE.INFO')"></span>
          </mat-form-field>
        </div>
      </div>
    </div>

    <hr>
    
    <div>
      <h1 class="margin-b-60">{{ 'PROJECT_DETAIL.CLIENT' | translate }}</h1>
      <div class="form-wrapper">
        <div class="input45">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.FNAME' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="firstname" (focusout)="saveProperty('firstname')">
          </mat-form-field>
        </div>
        <div class="input45">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.LNAME' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="lastname" (focusout)="saveProperty('lastname')">
          </mat-form-field>
        </div>
        <div class="input45">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.PHONE' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="phone" (focusout)="saveProperty('phone')">
          </mat-form-field>
        </div>
        <div class="input45">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.EMAIL' | translate }}</mat-label>
            <input autocomplete="new-password" type="email" matInput formControlName="email" (focusout)="saveProperty('email')">
          </mat-form-field>
        </div>
        <div class="input55">
          <mat-form-field class="full-width tooltip-trigger">
            <mat-label>{{ 'PROJECT_DETAIL.GREETING' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="greeting" (focusout)="saveProperty('greeting')">
            <span class="tooltip-btn tooltip-input" (click)="openTooltip('PROJECT_DETAIL.GREETING','PROJECT_DETAIL.GREETING.INFO')"></span>
          </mat-form-field>
        </div>
        <div class="input35">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.LANG' | translate }}</mat-label>
            <mat-select formControlName="language">
              <mat-option (click)="saveProperty('language')" *ngFor="let option of languages" [value]="option">{{
                'PREFERENCES.LANGUAGES.' +
                option.toUpperCase() | uppercase | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <hr>

    <div>
      <h2 class="h1 margin-b-60">{{ 'PROJECT_DETAIL.CONTACT' | translate }}</h2>
      <div class="form-wrapper">
        <div class="input55 order1">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.ADDRESS' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="contact_address" (focusout)="saveProperty('contact_address')">
          </mat-form-field>
        </div>
        <div class="input55 order3">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.ADDRESS2' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="contact_address2" (focusout)="saveProperty('contact_address2')">
          </mat-form-field>
        </div>
        <div class="input35 order2">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.ZIP' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="contact_zip" [matAutocomplete]="clientZipAutocomplete" appAutocomplete="trigger"
              (keyup)="clientCitySet = false"
              (focusout)="clientCities.length > 0 || clientCitySet = true; clientCities.length > 0 || saveProperty('contact_zip')">
              <!-- Only  save typed input if no cities were available for the automcomplete -->
          </mat-form-field>
          <mat-autocomplete #clientZipAutocomplete="matAutocomplete">
            <mat-option *ngFor="let city of filteredClientPostalCodes | async"
              (click)="currentClientCity = city; clientCitySet = true; saveProperty('contact_zip')"
              [value]="city.postalCode">
              {{city.postalCode + ' - ' + city.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="input35 order4">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.CITY' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="contact_city" [matAutocomplete]="clientCityAutocomplete" appAutocomplete="trigger"
              (keyup)="clientCitySet = false"
              (focusout)="clientCities.length > 0 || clientCitySet = true; clientCities.length > 0 || saveProperty('contact_city');">
              <!-- Only  save typed input if no cities were available for the automcomplete -->
          </mat-form-field>
          <mat-autocomplete #clientCityAutocomplete="matAutocomplete">
            <mat-option *ngFor="let city of filteredClientCities | async" 
              (click)="currentClientCity = city; clientCitySet = true; saveProperty('contact_city')" 
              [value]="city.name">
              {{city.postalCode + ' - ' + city.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="input35 order5">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.COUNTRY' | translate }}</mat-label>
            <mat-select formControlName="contact_country">
              <mat-option (click)="saveProperty('contact_country')" *ngFor="let country of countries" [value]="country['code']">
                {{ 'PREFERENCES.COUNTRIES.' +
                country['code'] | uppercase | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <hr>

    <div>
      <div class="title-wrapper flex space-between margin-b-60">
        <h2 class="h1 no-margin">{{ 'PROJECT_DETAIL.INSTALLATION' | translate }}</h2>

        <label class="checkbox">
          <input autocomplete="new-password" #sameAddressCheckbox type="checkbox" [checked]="sameAddress == true" type="checkbox" (click)="sameAddressCheckbox.checked ? matchContactAddress(true) : matchContactAddress(false)">
          <span class="checkmark"></span>
          {{'PROJECT_DETAIL.SAMEADDRESS' | translate}}
        </label>
      </div>
      <form class="form-wrapper" *ngIf="!sameAddress" autocomplete="off">
        <div class="input55 order1">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.ADDRESS' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="address" (focusout)="saveProperty('address')">
          </mat-form-field>
        </div>
        <div class="input55 order3">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.ADDRESS2' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="address2" (focusout)="saveProperty('address2')">
          </mat-form-field>
        </div>
        <div class="input35 order2">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.ZIP' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="zip" [matAutocomplete]="zipAutocomplete" appAutocomplete="trigger"
              (keyup)="citySet = false"
              (focusout)="cities.length > 0 || citySet = true; cities.length > 0 || saveProperty('zip')">
              <!-- Only  save typed input if no cities were available for the automcomplete -->
          </mat-form-field>
          <mat-autocomplete #zipAutocomplete="matAutocomplete">
            <mat-option *ngFor="let city of filteredPostalCodes | async"
              (click)="currentCity = city; citySet = true; saveProperty('zip')"
              [value]="city.postalCode">
              {{city.postalCode + ' - ' + city.name }}

            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="input35 order4">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.CITY' | translate }}</mat-label>
            <input autocomplete="new-password" matInput formControlName="city" [matAutocomplete]="cityAutocomplete" appAutocomplete="trigger"
              (keyup)="citySet = false"
              (focusout)="cities.length > 0 || citySet = true; cities.length > 0 || saveProperty('city');">
              <!-- Only  save typed input if no cities were available for the automcomplete -->
          </mat-form-field>
          <mat-autocomplete #cityAutocomplete="matAutocomplete">
            <mat-option *ngFor="let city of filteredCities | async" 
              (click)="currentCity = city; citySet = true; saveProperty('city')" 
              [value]="city.name">
              {{city.postalCode + ' - ' + city.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="input35 order5">
          <mat-form-field class="full-width">
            <mat-label>{{ 'PROJECT_DETAIL.COUNTRY' | translate }}</mat-label>
            <mat-select formControlName="country">
              <mat-option (click)="saveProperty('country')" *ngFor="let country of countries" [value]="country['code']">
                {{ 'PREFERENCES.COUNTRIES.' +
                country['code'] | uppercase | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    
  </form>
</app-project-page-template>