export enum NikoSystem {
  CLASSIC = 'klassiek',
  TRADITIONAL   = 'traditional',
  BUS = 'bus'
}


export class Calculation {
  progress?: number;
  articles?: Article[];
  results?: CalculationResult[];
  transcript?: string;
  status?: string;
}

export class Article {
  image!: string;
  name!: string;
  description!: string;
  number!: string;
  price!: number;
  count?: number;
  site?: string;
  type!: string;
}

export class CalculationResult { 
  entries?: CalculationEntry[];
  installType!: InstallType;
}

export class CalculationEntry { 
  article!: string;
  count!: number;
  room!: string;
}

export class InstallType { 
  description?: string;
  image?: string;
  title?: string;
  name?: string;
  xid!: string;
}
