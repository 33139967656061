export class City {
  name!: string;
  postalCode!: string;
}

export const cities:City[] = [
  {
      "name": "Izegem",
      "postalCode": "8870"
  },
  {
      "name": "Gent",
      "postalCode": "9000"
  }
]