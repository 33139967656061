import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { DocumentType } from 'src/app/models/Project';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';

/**
 * A service to dowanload documents
 */
@Injectable({ providedIn: 'root' })
export class DocumentDownloadService {

    public commercialDocumentGenerating = false;
    public bomDocumentGenerating = false;

    constructor(
        private nikoApi: NikoApiService
    ) { }

    public generateDocument(projectId: string, type: DocumentType): Observable<{id: string}> {

        if (type == DocumentType.BOM) {
            this.bomDocumentGenerating = true;
        }

        if (type == DocumentType.COMMERCIAL) {
            this.commercialDocumentGenerating = true;
        }

        return this.nikoApi.generateDocument(projectId, type).pipe(
            catchError((err) => {

                if (type == DocumentType.BOM) {
                    this.bomDocumentGenerating = false;
                }

                if (type == DocumentType.COMMERCIAL) {
                    this.commercialDocumentGenerating = false;
                }
                console.error(err);
                throw err;
            })
        ).pipe(map(r => {

            if (type == DocumentType.BOM) {
                this.bomDocumentGenerating = false;
            }

            if (type == DocumentType.COMMERCIAL) {
                this.commercialDocumentGenerating = false;
            }

            this.getDocumentDownload(r.id);

            return r;
        }));
    }

    public getDocumentDownload(documentId: string) {
        this.nikoApi.downloadDocument(documentId);
    }


    public editDocument(projectId: string, documentString: string) {
        return this.nikoApi.editDocument(projectId, documentString).subscribe(r => r);
    }

    public deleteDocumentFromHistory(documentId: string) {
        this.nikoApi.deleteDocument(documentId).subscribe(r => {
            console.log(r);
        });
    }
}
