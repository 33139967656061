import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/components/base.component';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { Document, DocumentType, Project } from 'src/app/models/Project';
import { DocumentDownloadService } from 'src/app/services/document-download/document-download.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent extends BaseComponent implements OnInit {

  public environment = environment;

  public project!: Project;
  public projectId!: string;

  public bomAscending_created = true;
  public commercialAscending_created = true;

  public CommercialDocuments: Document[] = [];
  public BomDocuments: Document[] = [];

  public toggleDescriptionEdit: {
    [documentId: string]: boolean
  } = {};

  public description: {
    [documentId: string]: UntypedFormControl
  } = {};

  constructor(
    private dialog: MatDialog,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentDownloadService
  ) {
    super();
  }

  ngOnInit(): void {

    this.projectId = this.activatedRoute.snapshot.params['project_id'];

    this.addSubscription(this.projectService.getProjectById(this.projectId).subscribe(r => {
      this.project = r;
      this.getProjectDocuments(this.project);
    }));
  }

  /**
   * Get documents from project data and split into commercial and bom document arrays
   * @param project 
   */
  getProjectDocuments(project: Project) {

    // initialize formcontrol for each project document
    project.documents?.forEach(doc => {
      this.description[doc.id] = new UntypedFormControl(doc.description);
    });

    if(project.documents?.some(doc => doc.type == DocumentType.COMMERCIAL)) {
      this.CommercialDocuments = project.documents?.filter(doc => doc.type == DocumentType.COMMERCIAL)!;
    } else {
      this.CommercialDocuments = [];
    }

    if(project.documents?.some(doc => doc.type == DocumentType.BOM)) {
      this.BomDocuments = project.documents?.filter(doc => doc.type == DocumentType.BOM)!;
    } else {
      this.BomDocuments = [];
    }


    this.bomAscending_created = !this.bomAscending_created;
    this.commercialAscending_created = !this.commercialAscending_created;

    this.sortCommercialDocuments('created')
    this.sortBomDocuments('created')  }

  /**
   * Download document with the given id
   * @param id id of the document to be downloaded
   */
  downloadDocument(id: string) {
    this.documentService.getDocumentDownload(id);
  }


  /**
   * Remove document with the given id
   * @param id id of the document to be removed
   */
   removeDocument(id: string) {
    this.documentService.deleteDocumentFromHistory(id);
    this.project.documents = this.project.documents?.filter(doc => doc.id != id);
    this.getProjectDocuments(this.project);
    console.log(this.project.documents);
  }

  /**
   * Open remove document modal
   */
    openRemoveDocumenDialog(id: string) {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        autoFocus: false, 
        data: {
          'title': 'CONFIRMATION_MODAL.REMOVE.DOCUMENT',
          'confirmationData': id
        },
        maxWidth: '90vw',
        maxHeight: '90vh',
        minWidth: '775px'
      });
  
      dialogRef.afterClosed().subscribe(r => {
        if (r && r.confirmationData) {
          this.removeDocument(r.confirmationData)
        } 
      });
    }

  /**
   * sort commercial documents list
   */
  sortCommercialDocuments(property: string) {
    if(property == 'created') {  
      this.commercialAscending_created = !this.commercialAscending_created;
  
      this.CommercialDocuments = this.CommercialDocuments.sort((a,b) => {
        let createdA = new Date(a.created);
        let createdB = new Date(b.created);
  
        if(this.commercialAscending_created) {
          let result = (createdA < createdB) ? 1 : ((createdB < createdA) ? -1 : 0);
          return result;
  
        } else {
          let result = (createdA > createdB) ? 1 : ((createdB > createdA) ? -1 : 0);
          return result;
        }
      });
    }
  }

  /**
   * sort bom documents list
   */
  sortBomDocuments(property: string) {
    if(property == 'created') {  
      this.bomAscending_created = !this.bomAscending_created;
  
      this.BomDocuments = this.BomDocuments.sort((a,b) => {
        let createdA = new Date(a.created);
        let createdB = new Date(b.created);
  
        if(this.bomAscending_created) {
          let result = (createdA < createdB) ? 1 : ((createdB < createdA) ? -1 : 0);
          return result;
  
        } else {
          let result = (createdA > createdB) ? 1 : ((createdB > createdA) ? -1 : 0);
          return result;
        }
      });
    }
  }


  /**
   * Set new document description
   * @param documentId  document for which the description should be set
   */
  setDescription(documentId: string) {
    if(this.description[documentId].valid) {
      let description = this.description[documentId].value.toLowerCase().replace(/([a-z])/i, (str:string, firstLetter:string) => firstLetter.toUpperCase());

      this.CommercialDocuments.forEach(doc => {
        if(doc.id == documentId) {
          
          doc.description = description;
      
          let documentString = 'id=' + documentId

          // Add document description to document string if available
          if(doc.description && doc.description != 'null') {
            documentString = documentString + '&description=' + doc.description;
          }

          this.documentService.editDocument(this.projectId, documentString);
        }
      });

      this.BomDocuments.forEach(doc => {
        if(doc.id == documentId) {
          
          doc.description = description;
      
          let documentString = 'id=' + documentId

          // Add document description to document string if available
          if(doc.description && doc.description != 'null') {
            documentString = documentString + '&description=' + doc.description;
          }

          this.documentService.editDocument(this.projectId, documentString);
        }
      });

    }
  }

}
