import { Property } from "../models/Project";
import {HttpEvent, HttpEventType, HttpResponse, HttpDownloadProgressEvent} from '@angular/common/http'

export function slugify(value: string) {
    return value.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

export function filter(value: Object[]) {
    return value.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

export function capitalize(value: string) {
    let result = value.replace(/([a-z])/i, (str:string, firstLetter:string) => firstLetter.toUpperCase());
    return result;
}

// Deep clone an array
export function clone(arrayToClone: any[]) {

    var newArray: any[] = [];

    newArray = JSON.parse(JSON.stringify(arrayToClone));

    return newArray;
}

// Deep clone an object without functions
export function cloneObject(objectToClone: any) {

    var newObject = JSON.parse(JSON.stringify(objectToClone));

    return newObject;
}

export const mergeArrays = (arr1: any[], arr2: any[]) => 
   arr1 && arr1.map(obj => arr2 && arr2.find(p => p.xid === obj.xid) || obj);

export const mergeArrayWithObject = (arr: any[], obj: any) => 
arr && arr.map(t => t.id === obj.id ? obj : t);

export const projectPropertyValue = (properties: Property[] | undefined, name: string): string | undefined => {
    if(properties) {
        return properties.find(p => p.name == 'project.' + name)?.value;
    }
    
    return '';
}

export const userSettingValue = (settings: Property[] | undefined, name: string): string | undefined => {
    if(settings) {
        return settings.find(p => p.name == 'user.' + name)?.value;
    }
    
    return '';
}

/**
 * Change value of setting or add setting to local property array
 * @param settings local property array
 * @param name name of setting
 * @param value value of setting
 */
export const setUserSettingValue = (settings: Property[] | undefined, name: string, value: string) => {
    if(settings && settings.some(p => p.name == name)) {
        settings.find(p => p.name == name)!.value = value
    } else if(settings) {
        let p: Property = new Property;
        p.name = name;
        p.value = value
        settings.push(p);
    }
}


export function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

export function isHttpDownloadProgressEvent(event: HttpEvent<unknown>): event is HttpDownloadProgressEvent {
  return event.type === HttpEventType.DownloadProgress 
}