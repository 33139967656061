<app-project-page-template [breadcrumb]="'PROJECT_VARIABLES.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_VARIABLES.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../'"
  [breadcrumbNext]="'PROJECT_VARIABLES.BREADCRUMB_NEXT' | translate" 
  [routerlinkNext]="'../'"
  [sideBarContent]="sideBarContent">
  <div id="content">

    <div class="relative">
      <div class="title-wrapper">
        <h1 class="no-margin">{{ 'PROJECT_VARIABLES.TITLE' | translate }}</h1>
      </div>
      <div class="flex space-between margin-b">
        <p class="dark-grey tooltip-trigger">
          {{ 'PROJECT_VARIABLES.SUBTITLE' | translate }}
          <span class="tooltip-btn" (click)="openTooltip('PROJECT_VARIABLES.TITLE','PROJECT_VARIABLES.SUBTITLE.INFO')"></span>
        </p>
        <div class="button-wrapper" *ngIf="!projectArchived">
          <button (click)="sortRoomsAlphabetically()" class="btn btn-grey btn-small margin-r">
            Sort rooms
          </button>
          <button (click)="openAddRoomsDialog()" class="btn btn-grey btn-small">
            Add rooms
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="rooms && rooms.length > 0"> 
      <div class="content-wrapper margin-b" *ngFor="let room of rooms">
        <div class="flex space-between">
          <h2 class="no-margin">
            <img src="{{room.imageFull}}" class="icon icon--large">
            
            <span *ngIf="!toggleRoomEdit[room.id]">{{room.name ? room.name : room.title}}</span>

            <mat-form-field *ngIf="toggleRoomEdit[room.id]">
              <input autocomplete="new-password" matInput [formControl]="name[room.id]" maxlength="30" [placeholder]="'PROJECT_ROOMEDIT.PLACEHOLDER' | translate">
            </mat-form-field>
          </h2>

          <div class="action-container flex space-between">
            <button *ngIf="!projectArchived && !toggleRoomEdit[room.id]" (click)="toggleRoomEdit[room.id] = true" class="btn-round-icon action-edit">
              <mat-icon svgIcon="pencil" class="icon"></mat-icon>
            </button>
            <button *ngIf="!projectArchived && toggleRoomEdit[room.id]" (click)="toggleRoomEdit[room.id] = false; setRoomName(room.id);" class="btn-round-icon active action-edit">
              <mat-icon class="icon">check</mat-icon>
            </button>
            <button *ngIf="!projectArchived && toggleRoomEdit[room.id]" (click)="toggleRoomEdit[room.id] = false; " class="btn-round-icon action-edit">
              <mat-icon class="icon">close</mat-icon>
            </button>
            <button *ngIf="!projectArchived" (click)="openRemoveRoomDialog(room)" class="btn-round-icon action-delete">
              <mat-icon svgIcon="trash" class="icon"></mat-icon>
            </button>
            <button (click)="toggleSelected[room.id] = false" *ngIf="toggleSelected[room.id]" class="btn-expand btn-transparent btn-icon">
              <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
            </button>
            <button (click)="toggleSelected[room.id] = true" *ngIf="!toggleSelected[room.id]" class="btn-expand btn-transparent btn-icon">
              <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
            </button>
          </div>
        </div>

      <ng-container *ngIf="toggleSelected[room.id]">
        <hr>

        <!-- Finishings for this room -->
        <div *ngIf="room.indoorFinishing || room.outdoorFinishing">

          <div class="variable-wrapper margin-b" *ngIf="room.indoorFinishing">
            <div class="variable-content">
              <div class="variable-picture">
                <svg class="icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <pattern [id]="'indoor_primaryColour'+room.id" patternUnits="userSpaceOnUse" width="75" height="75">
                      <image [attr.href]="room.indoorFinishing.primaryColour.image" x="0" y="0" width="75" height="75" />
                    </pattern>
                    <pattern [id]="'indoor_secondaryColour'+room.id" patternUnits="userSpaceOnUse" width="75" height="75">
                      <image [attr.href]="room.indoorFinishing.secondaryColour.image" x="0" y="0" width="75" height="75" />
                    </pattern>
                  </defs>
                  <path d="M0.999525 1.00009H37.9995V38.0001H0.999525V1.00009Z" [attr.fill]="'url(#indoor_primaryColour'+room.id+')'" />
                  <path d="M9.99953 10.0001H28.9995V29.0001H9.99953V10.0001Z" [attr.fill]="'url(#indoor_secondaryColour'+room.id+')'" />
                  <path d="M38.2225 0.019142L0.824088 0.000488384C0.379142 0.000266453 0.0188768 0.360172 0.018656 0.802825L1.01529e-07 38.2058C-0.000220686 38.6485 0.359685 39.0087 0.802338 39.009L38.203 39.0276C38.6457 39.0278 39.006 38.6702 39.0062 38.2253L39.0248 0.82228C39.0251 0.379628 38.6674 0.019364 38.2225 0.019142ZM37.4034 37.424L1.60817 37.4062L1.62603 1.60636L37.4213 1.62422L37.4034 37.424Z" fill="#4C4C4C"/>
                  <path d="M9.43036 30.3896L29.5837 30.3996C30.0263 30.3998 30.3866 30.0422 30.3868 29.5973L30.3969 9.44168C30.3971 8.99903 30.0395 8.63877 29.5945 8.63855L9.44122 8.62849C8.99856 8.62827 8.6383 8.98818 8.63808 9.43083L8.62802 29.5887C8.6278 30.0314 8.98771 30.3893 9.43036 30.3896ZM10.2432 10.2344L28.791 10.2436L28.7817 28.796L10.2339 28.7868L10.2432 10.2344Z" fill="#4C4C4C"/>
                </svg>   
              </div>
              <div>
                <p>{{ ('PROJECT_FINISHINGS.SELECTED.LABEL_GENERAL' | translate) + ": " + room.indoorFinishing.title }} <span class="greyed-out" *ngIf="room.indoorFinishing.xid == indoorFinishing.xid">(default)</span></p>
              </div>
            </div>
            <div class="button-wrapper">
              <button *ngIf="!projectArchived" (click)="openFinishingDialog(room, LOCATION.INDOOR)" class="btn btn-secondary btn-small">{{ 'Change' | translate }}</button>
            </div>
          </div>

          <div class="variable-wrapper margin-b" *ngIf="room.outdoorFinishing">
            <div class="variable-content">
              <div class="variable-picture">
                <svg class="icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <pattern [id]="'outdoor_primaryColour'+room.id" patternUnits="userSpaceOnUse" width="75" height="75">
                      <image [attr.href]="room.outdoorFinishing.primaryColour.image" x="0" y="0" width="75" height="75" />
                    </pattern>
                    <pattern [id]="'outdoor_secondaryColour'+room.id" patternUnits="userSpaceOnUse" width="75" height="75">
                      <image [attr.href]="room.outdoorFinishing.secondaryColour.image" x="0" y="0" width="75" height="75" />
                    </pattern>
                  </defs>
                  <path d="M0.999525 1.00009H37.9995V38.0001H0.999525V1.00009Z" [attr.fill]="'url(#outdoor_primaryColour'+room.id+')'" />
                  <path d="M9.99953 10.0001H28.9995V29.0001H9.99953V10.0001Z" [attr.fill]="'url(#outdoor_secondaryColour'+room.id+')'" />
                  <path d="M38.2225 0.019142L0.824088 0.000488384C0.379142 0.000266453 0.0188768 0.360172 0.018656 0.802825L1.01529e-07 38.2058C-0.000220686 38.6485 0.359685 39.0087 0.802338 39.009L38.203 39.0276C38.6457 39.0278 39.006 38.6702 39.0062 38.2253L39.0248 0.82228C39.0251 0.379628 38.6674 0.019364 38.2225 0.019142ZM37.4034 37.424L1.60817 37.4062L1.62603 1.60636L37.4213 1.62422L37.4034 37.424Z" fill="#4C4C4C"/>
                  <path d="M9.43036 30.3896L29.5837 30.3996C30.0263 30.3998 30.3866 30.0422 30.3868 29.5973L30.3969 9.44168C30.3971 8.99903 30.0395 8.63877 29.5945 8.63855L9.44122 8.62849C8.99856 8.62827 8.6383 8.98818 8.63808 9.43083L8.62802 29.5887C8.6278 30.0314 8.98771 30.3893 9.43036 30.3896ZM10.2432 10.2344L28.791 10.2436L28.7817 28.796L10.2339 28.7868L10.2432 10.2344Z" fill="#4C4C4C"/>
                </svg>                 
              </div>
              <div>
                <p>{{ ('PROJECT_FINISHINGS.OUTDOOR.LABEL_GENERAL' | translate) + ": " + room.outdoorFinishing.title }} <span class="greyed-out" *ngIf="room.outdoorFinishing.xid == outdoorFinishing.xid">(default)</span></p>
              </div>
            </div>
            <div class="button-wrapper">
              <button *ngIf="!projectArchived" (click)="openFinishingDialog(room, LOCATION.OUTDOOR)" class="btn btn-secondary btn-small">{{ 'Change' | translate }}</button>
            </div>
          </div>

          <hr>
        </div>

        <!-- Variables for this room -->
        <div *ngIf="room.variables && room.variables.length > 0" class="functionalities-container">

          <ng-container *ngFor="let variable of room.variables" >
            <div class="variable-wrapper margin-b" *ngIf="variable.values && variable.values![room.typeXid] != null && variable.values![room.typeXid]! >= 0">
              <div class="variable-content">
                <div class="variable-picture">
                  <img [src]="variable.image!" class="icon">
                </div>
                <div>
                  <p>{{ variable.text }}</p>
                </div>
              </div>
              <div class="edit-buttons">
                <div *ngIf="!projectArchived" (click)="
                  variable.values![room.typeXid]! > 0 ? (variable.values![room.typeXid] = variable.values![room.typeXid]! - 1) : undefined;
                  setVariable(room, variable.xid, variable.values![room.typeXid]!)" 
                class="btn-round-icon edit-remove">
                  <mat-icon class="icon icon--small">remove</mat-icon>
                </div>
                <p class="amount h2">{{ variable.values![room.typeXid] }}</p>
                <div *ngIf="!projectArchived" (click)="
                  variable.values![room.typeXid] = variable.values![room.typeXid]! + 1;
                  setVariable(room, variable.xid, variable.values![room.typeXid]!)" 
                class="btn-round-icon edit-add">
                  <mat-icon class="icon icon--small">add</mat-icon>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="button-wrapper">
            <button *ngIf="!projectArchived" class="btn btn-primary btn-small" (click)="openVariableDialog(room)">{{ 'PROJECT_VARIABLES.ADD' | translate }}</button>
          </div>

        </div>

        <!-- No variables for  this room -->
        <div *ngIf="(!room.variables || room.variables.length == 0)" class="functionalities-container">
          <p>{{'PROJECT_VARIABLES.NO_VARIABLES' | translate}}</p>
        </div>

      </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="rooms && rooms.length == 0" >
      <p>{{'PROJECT_VARIABLES.NO_ROOMS' | translate}}</p>
    </ng-container>

  </div>
</app-project-page-template>