import {Directive, Input, HostBinding} from '@angular/core'
@Directive({
    selector: 'img',
    host: {
      '(error)':'replaceBrokenImage()',
      '[src]':'src'
     }
  })
  
 export class ImagePreloadDirective {
    @Input() src!: string;
  
    replaceBrokenImage() {
      this.src = '/assets/logo/niko_logo_placeholder.svg';
    }
  }