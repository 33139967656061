<app-project-page-template [breadcrumb]="'PROJECT_RESULTS.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_RESULTS.PRODUCTS.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../../' + APP_ROUTES['RESULTS_INSTALLTYPES'].value"
  [breadcrumbNext]="'PROJECT_RESULTS.PRODUCTS.BREADCRUMB_NEXT' | translate" [routerlinkNext]="'../../'"
  [sideBarContent]="sideBarContent">

  <div id="content" *ngIf="projectData && installType">

    <div class="technology-container">
      <h2 class="h1">{{ 'PROJECT_RESULTS.PRODUCTS.INSTALLTYPE.TITLE' | translate }}</h2>

      <div class="content-wrapper margin-b">
        <h2>
          <mat-icon svgIcon="config" class="icon icon--medium"></mat-icon>{{'PROJECT_RESULTS.PRODUCTS.INSTALLTYPE' | translate}}
        </h2>

        <ng-container *ngIf="installType">
          <hr>

          <div class="system-wrapper content-container">
            <div class="system-picture">
              <img [src]="installType.image!">
            </div>
            <div class="system-information content-wrapper">
              <div class="flex space-between margin-b-30">
                <h2>{{ installType.title! | capitalize }}</h2>
              </div>
              <div class="cms" [class.margin-b-20]="showPrices" [innerHTML]="installType.description"></div>
              
              <!-- Price -->
              <div *ngIf="showPrices" class="project-price flex flex-end baseline">
                <p>{{ 'PROJECT_RESULTS.INSTALLTYPE.PRICE' | translate}}</p>
                <p><strong>{{ (totalPrice | number : '1.2-2')?.replace(',','')?.replace('.',',')}} €</strong></p>
              </div>

            </div>
          </div>
        </ng-container>

      </div>

    </div>

    <div class="product-container" *ngIf="calculation">
      <hr>
      <h2 class="h1">{{ 'PROJECT_RESULTS.PRODUCTS.TITLE' | translate }}</h2>

      <!-- no products -->
      <ng-container *ngIf="calculation && calculation.results?.length == 0">
        <p class="warning">{{ 'PROJECT_RESULTS.INSTALLTYPE.UNAVAILABLE' | translate }}</p>
      </ng-container>

      <!-- no system selected -->
      <ng-container *ngIf="calculation && calculation.results?.length! > 0 && !installType">
        <p class="dark-grey">{{ 'PROJECT_RESULTS.INSTALLTYPE.NO_INSTALLTYPE' | translate }}</p>
      </ng-container>

      <!-- products -->
      <ng-container *ngIf="calculation && calculation.results?.length! > 0 && installType">
        <div class="products-container">

          <!-- Product list without rooms -->
          <ng-container *ngIf="combineRooms">
            
            <div class="flex space-between margin-b">
              <p class="dark-grey">{{'PROJECT_RESULTS.PRODUCTS.SUBTITLE' | translate}}</p>      
              <a (click)="combineRooms = false" class="font-s font-underline pointer">{{ 'PROJECT_RESULTS.PRODUCTS.ROOM_LIST' | translate}} </a>
            </div>

            <div class="content-wrapper margin-b">
              <div class="pointer" (click)="toggleSelected['productlist'] = !toggleSelected['productlist']">

                <button *ngIf="toggleSelected['productlist']" class="btn-expand btn-transparent btn-icon">
                  <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
                </button>
                <button *ngIf="!toggleSelected['productlist']" class="btn-expand btn-transparent btn-icon">
                  <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
                </button>

                <h2>
                  <mat-icon svgIcon="general" class="icon icon--medium"></mat-icon>{{'PROJECT_RESULTS.PRODUCTS.PRODUCT_LIST' | translate}}
                </h2>
                <p class="amount grey6-text">{{ getArticleList(installType.xid).length }} {{'PROJECT_RESULTS.PRODUCTS.ITEMS' | translate}}</p>
              </div>

              <ng-container *ngIf="toggleSelected['productlist']" class="functionalities-container">
                <hr>

                <ng-container>
                  <ng-container *ngIf="getArticleList(installType.xid).length > 0">

                    <div class="product-container">
                      <div class="product-wrapper content-container" *ngFor="let article of getArticleList(installType.xid)">
                        <div class="product-count"><strong>{{article.count}}x</strong></div>
                        <div class="product-picture">
                          <img [class.placeholder]="!article.image" [src]="article.image ? article.image : '/assets/logo/niko_logo_placeholder.svg'">
                        </div>
                        <div class="product-description">
                          <a *ngIf="article.site && article.site != ''" target="_blank" [href]="article.site" class="pointer text-default"><p><strong>{{article.number + ': ' + article.name}}</strong></p></a>
                          <p *ngIf="!article.site || article.site == ''"><strong>{{article.number + ': ' + article.name}}</strong></p>
                          <p>{{article.description}}</p>
                        </div>
                        <p *ngIf="showPrices" class="product-price"><strong>{{(article.price * article.count! | number : '1.2-2')?.replace(',','')?.replace('.',',')}} €</strong></p>
                      </div>
                    </div>
                  </ng-container>

                  <hr *ngIf="showPrices">
                  <div *ngIf="showPrices" class="room-price flex space-between baseline">
                    <p>{{ 'PROJECT_RESULTS.INSTALLTYPE.TOTAL_PRICE' | translate}}</p>
                    <p><strong>{{( totalPrice | number : '1.2-2')?.replace(',','')?.replace('.',',')}} €</strong></p>
                  </div>
                </ng-container>

                <!-- <p>{{'Products for this setup have not been calculated yet.' | translate}}</p> -->

              </ng-container>
            </div>    
          </ng-container>  

          <ng-container *ngIf="!combineRooms && rooms">

            <div class="flex space-between margin-b">
              <p class="dark-grey">{{'PROJECT_RESULTS.PRODUCTS.SUBTITLE' | translate}}</p>      
              <a (click)="combineRooms = true" class="font-s font-underline pointer">{{ 'PROJECT_RESULTS.PRODUCTS.PRODUCT_LIST' | translate}} </a>
            </div>

            <!-- general-->
            <ng-container *ngIf="getArticleList(installType.xid, 'general').length > 0">
              <div class="content-wrapper margin-b">
                <div class="pointer" (click)="toggleSelected['general'] = !toggleSelected['general']">
                  <button *ngIf="toggleSelected['general']" class="btn-expand btn-transparent btn-icon">
                    <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
                  </button>
                  <button *ngIf="!toggleSelected['general']" class="btn-expand btn-transparent btn-icon">
                    <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
                  </button>
        
                  <h2>
                    <mat-icon svgIcon="general" class="icon icon--medium"></mat-icon>{{'PROJECT_RESULTS.PRODUCTS.GENERAL' | translate}}
                  </h2>
                  <p class="amount grey6-text">{{ getArticleList(installType.xid, 'general').length }} {{'PROJECT_RESULTS.PRODUCTS.ITEMS' | translate}}</p>
                </div>

                <ng-container *ngIf="toggleSelected['general']" class="functionalities-container">

                  <ng-container>
                    <ng-container>
                      
                      <hr>
                      <div class="product-container">
                        <div class="product-wrapper content-container" *ngFor="let article of getArticleList(installType.xid, 'general')">
                          <div class="product-count"><strong>{{article.count}}x</strong></div>
                          <div class="product-picture">
                            <img [class.placeholder]="!article.image" [src]="article.image ? article.image : '/assets/logo/niko_logo_placeholder.svg'">
                          </div>
                          <div class="product-description">
                            <a *ngIf="article.site && article.site != ''" target="_blank" [href]="article.site" class="pointer text-default"><p><strong>{{article.number + ': ' + article.name}}</strong></p></a>
                            <p *ngIf="!article.site || article.site == ''"><strong>{{article.number + ': ' + article.name}}</strong></p>
                            <p>{{article.description}}</p>
                          </div>
                          <p *ngIf="showPrices" class="product-price"><strong>{{(article.price * article.count! | number : '1.2-2')?.replace(',','')?.replace('.',',')}} €</strong></p>
                        </div>
                      </div>
                    </ng-container>
      
                    <hr *ngIf="showPrices">
                    <div *ngIf="showPrices" class="room-price flex space-between baseline">
                      <p>{{ 'PROJECT_RESULTS.INSTALLTYPE.TOTAL_PRICE' | translate}}</p>
                      <p><strong>{{( roomPrice['general'] | number : '1.2-2')?.replace(',','')?.replace('.',',')}} €</strong></p>
                    </div>
                  </ng-container>
      
                  <!-- <p>{{'Products for this setup have not been calculated yet.' | translate}}</p> -->
      
                </ng-container>
              </div>
            </ng-container>

            <!-- rooms -->
            <ng-container *ngFor="let room of sortRoomsAlphabetically(rooms)">
              <div class="content-wrapper margin-b" *ngIf="getArticleList(installType.xid, room.id).length > 0">
                <div class="pointer" (click)="toggleSelected[room.id] = !toggleSelected[room.id]">
                  <button *ngIf="toggleSelected[room.id]" class="btn-expand btn-transparent btn-icon">
                    <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
                  </button>
                  <button *ngIf="!toggleSelected[room.id]" class="btn-expand btn-transparent btn-icon">
                    <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
                  </button>

                  <h2>
                    <img src="{{room.imageFull}}" class="icon icon--medium">{{room.name ? room.name : room.title}}
                  </h2>
                  <p class="amount grey6-text">{{ getArticleList(installType.xid, room.id).length }} {{'PROJECT_RESULTS.PRODUCTS.ITEMS' | translate}}</p>
                </div>

                <ng-container *ngIf="toggleSelected[room.id]" class="functionalities-container">

                  <ng-container>
                    <ng-container>
                      <hr>
                      <div class="product-container">
                        <div class="product-wrapper content-container" *ngFor="let article of getArticleList(installType.xid, room.id)">
                          <div class="product-count"><strong>{{article.count}}x</strong></div>
                          <div class="product-picture">
                            <img [class.placeholder]="!article.image" [src]="article.image ? article.image : '/assets/logo/niko_logo_placeholder.svg'">
                          </div>
                          <div class="product-description">
                            <a *ngIf="article.site && article.site != ''" target="_blank" [href]="article.site" class="pointer text-default"><p><strong>{{article.number + ': ' + article.name}}</strong></p></a>
                            <p *ngIf="!article.site || article.site == ''"><strong>{{article.number + ': ' + article.name}}</strong></p>
                            <p>{{article.description}}</p>
                          </div>
                          <p *ngIf="showPrices" class="product-price"><strong>{{(article.price * article.count! | number : '1.2-2')?.replace(',','')?.replace('.',',')}} €</strong></p>
                        </div>
                      </div>
                    </ng-container>

                    <hr *ngIf="showPrices">
                    <div *ngIf="showPrices" class="room-price flex space-between baseline">
                      <p>{{ 'PROJECT_RESULTS.INSTALLTYPE.TOTAL_PRICE' | translate}}</p>
                      <p><strong>{{(roomPrice[room.id] | number : '1.2-2')?.replace(',','')?.replace('.',',')}} €</strong></p>
                    </div>
                  </ng-container>

                  <!-- <p>{{'Products for this setup have not been calculated yet.' | translate}}</p> -->

                </ng-container>
              </div>
            </ng-container>

          </ng-container>

        </div>
      </ng-container>

    </div>

    <ng-container *ngIf="!hideCalculationLog && rooms && rooms.length > 0 && this.calculation?.transcript">
      <hr style="margin: 40px 0"> 

      <div id="calculationlog" class="content-wrapper margin-b"> 
        <div class="flex space-between">
          <h2 class="no-margin" (click)="toggleSelected['log'] = !toggleSelected['log']">
            <mat-icon svgIcon="clipboard" class="icon icon--medium"></mat-icon> {{ 'CALCULATION_LOG' | translate }}
          </h2>

          <div class="action-container flex space-between">
            <button (click)="copyLog(this.calculation!.transcript!)" class="btn-round-icon action-copy">
              <mat-icon svgIcon="copy" class="icon"></mat-icon>
            </button>
            <button (click)="toggleSelected['log'] = false" *ngIf="toggleSelected['log']" class="btn-expand btn-transparent btn-icon">
              <mat-icon svgIcon="expand_less" class="icon"></mat-icon>
            </button>
            <button (click)="toggleSelected['log'] = true" *ngIf="!toggleSelected['log']" class="btn-expand btn-transparent btn-icon">
              <mat-icon svgIcon="expand_more" class="icon"></mat-icon>
            </button>
          </div>
        </div>
        <ng-container *ngIf="toggleSelected['log']">
          <hr>
          <div id="calculations">
            <!-- <ngx-json-viewer [json]="someObject" [depth]="3"></ngx-json-viewer> -->
            <pre [innerHTML]="this.calculation!.transcript"></pre>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container>
      <hr style="margin: 40px 0"> 

      <div class="content-wrapper margin-b"> 
        <div class="policy-wrapper">
          <p class="font-m greyed-out" [innerHTML]="'POLICIES.DISCLAIMER_TOOL' | translate">
          </p>
        </div>
      </div>
    </ng-container>

  </div>
</app-project-page-template>