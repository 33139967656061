import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { buildings } from 'src/app/models/BuildingType';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuildingTypeService {

  constructor(
    private nikoApi: NikoApiService
  ) { }

  /**
   * Get BuildingTypeList
   * @returns Observable list of BuildingTypes
   */
  public getBuildingTypes() {
    if(environment.mockData) {
      return of(buildings);
    } else {
      return this.nikoApi.getBuildingTypes().pipe(
        map((BuildingTypeList) => {
          //this.cachedBuildingTypes.next(BuildingTypeList.BuildingTypeList);
          //console.log(BuildingTypeList);
          return BuildingTypeList;
        })
      );
    }
  }
}