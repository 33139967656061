import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BaseComponent } from 'src/app/components/base.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { Country } from 'src/app/models/Country';
import { Locale, LocaleObject } from 'src/app/models/Locale';
import { ProjectModule } from 'src/app/models/Project';
import { User } from 'src/app/models/User';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { CountryService } from 'src/app/services/country/country.service';
import { LocalStorageKeys } from 'src/app/services/local-storage/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { MessageService } from 'src/app/services/message/message.service';
import { SessionStorageKeys } from 'src/app/services/session-storage/session-storage-keys';
import { SessionStorageService } from 'src/app/services/session-storage/session-storage.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { UserService } from 'src/app/services/user/user.service';
import { setUserSettingValue } from 'src/app/util/Util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent extends BaseComponent implements OnInit {

  public userData!: User;

  public countries: Country[] = [];
  public country!: UntypedFormControl;
  
  public languages: string[] = [];
  public language!: UntypedFormControl;

  public currentCountry!: string;
  public currentLang!: string;

  public showPrices = true;

  public appVersion = environment.appVersion;

  constructor(
    private translate: TranslateService,
    private countryService:CountryService,
    private settingsService: SettingsService,
    private nikoApi: NikoApiService,
    private http: HttpClient,
    private session: SessionStorageService,
    private localStorage: LocalStorageService,
    private router: Router,
    private userService: UserService,
    private gaService: GoogleAnalyticsService,
    private dialog: MatDialog
  ) { super(); }

  ngOnInit(): void {
    this.gaService.set({'project_id': null});

    this.countryService.getCountries().subscribe((r:Country[]) => {
      this.countries = r.sort((a,b) => a.order - b.order);
    });

    this.nikoApi.getLocales().subscribe((r:LocaleObject[]) => {
      r.forEach(locale => {
        this.languages.push(locale.locale);
      })
    });

    //this.currentCountry = this.translate.instant(environment.defaultCountry.toUpperCase());
    this.currentCountry = this.nikoApi.countryCode;
    this.country = new UntypedFormControl(this.currentCountry.toUpperCase());

    //this.translate.currentLang ? this.currentLang = this.translate.currentLang : this.currentLang = this.translate.defaultLang;
    this.currentLang = this.nikoApi.locale;
    this.language = new UntypedFormControl(this.currentLang);
    

    this.country.valueChanges.subscribe((value: string) => {
      //console.log(value);
      this.changeCountry(value);
    });

    this.language.valueChanges.subscribe((value: string) => {
      //console.log(value);
      this.changeLang(value);
    });

    this.getUserData();
  }

  /**
   * Get User data to show current settings
   */
   private getUserData() {
    this.userData = this.userService.user.getValue();

    // If no user settings are available, redirect to login
    if(!this.userData.email) {
      this.nikoApi.logout();
      this.router.navigate(['/', APP_ROUTES['LOGIN'].value]);
    } else if(this.userData.settings && this.userData.settings.find(p => p.name == 'user.showprices')) { 
      this.showPrices = this.userData.settings.find(p => p.name == 'user.showprices')?.value == 'true';
    }
   }

  /**
   * Get all available locales from Niko API, replace selected language 
   * file used by ngx-translate by online language file and load the selected language
   * * @param language language selected
   */
   public changeLang(language: string): void {
    if (this.translate.langs.indexOf(language) > -1) {
      this.currentLang = language;

      this.nikoApi.locale = language as Locale;
      this.settingsService.setSetting(['user.locale:' + language]);
      this.session.saveItem(SessionStorageKeys.LOCALE, language);

      this.nikoApi.getLocales().subscribe((locales: LocaleObject[]) => {
        locales.forEach((locale: LocaleObject) => {
          if(locale.locale == language) {
            this.http.get(locale.translations).subscribe(langFile => {
              locale.langFile = langFile;
              this.translate.setTranslation(locale.locale, langFile, false);
              this.translate.use(locale.locale);
            });
          }
        });
      });
    }
  }

  /**
   * Change the default country
   * * @param country country selected
   */
  public changeCountry(countryCode: string): void {
    this.nikoApi.countryCode = countryCode;
    this.settingsService.setSetting(['user.country:' + countryCode]);
    this.session.saveItem(SessionStorageKeys.COUNTRY, countryCode);
  }


  /**
   * Reset all user onboarding and intro screen states from hidden to visible
   */
  public resetOnboarding() {
    this.localStorage.remove(LocalStorageKeys.ONBOARDING);

    this.settingsService.setSetting(
      [
        'user.onboarding:visible',
        'user.onboarding.' + ProjectModule.INTAKE + ':visible',
        'user.onboarding.' + ProjectModule.FUNCTIONALITIES + ':visible',
        'user.onboarding.' + ProjectModule.FINISHING + ':visible',
        'user.onboarding.' + ProjectModule.ROOMCONFIGURATION + ':visible',
        'user.onboarding.' + ProjectModule.RESULTS + ':visible',
        'user.onboarding.projectstatus' + ':visible'
      ]
    );

    setUserSettingValue(this.userData.settings, 'user.onboarding', 'visible');
    setUserSettingValue(this.userData.settings, 'user.onboarding.' + ProjectModule.INTAKE, 'visible');
    setUserSettingValue(this.userData.settings, 'user.onboarding.' + ProjectModule.FUNCTIONALITIES, 'visible');
    setUserSettingValue(this.userData.settings, 'user.onboarding.' + ProjectModule.FINISHING, 'visible');
    setUserSettingValue(this.userData.settings, 'user.onboarding.' + ProjectModule.ROOMCONFIGURATION, 'visible');
    setUserSettingValue(this.userData.settings, 'user.onboarding.' + ProjectModule.RESULTS, 'visible');
    setUserSettingValue(this.userData.settings, 'user.onboarding.projectstatus', 'visible');

    this.userService.user.next(this.userData);
  }

  public togglePrices(showPrices: boolean) {
    this.showPrices = showPrices;
    this.settingsService.setSetting(['user.showprices:' + this.showPrices]);
    setUserSettingValue(this.userData.settings, 'user.showprices', (this.showPrices ? 'true' : 'false'));
    this.userService.user.next(this.userData);
  }

      /**
   * Open tooltip modal
   */
      public openTooltip(title: string = '', text: string) {
        const dialogRef = this.dialog.open(TooltipModalComponent, {
          autoFocus: false, 
          data: {
            'title': title,
            'text': text
          },
          maxWidth: '90vw',
          maxHeight: '90vh',
          minWidth: '775px'
        });
      }
}
