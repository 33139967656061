<app-project-page-template [breadcrumb]="'PROJECT_FINISHINGS.CHOSEN.BREADCRUMB' | translate"
  [breadcrumbPrev]="'PROJECT_FINISHINGS.CHOSEN.BREADCRUMB_PREV' | translate"
  [routerlinkPrev]="'../../'+ APP_ROUTES['FINISHING_GENERAL_OUTDOOR'].value"
  [breadcrumbNext]="'PROJECT_FINISHINGS.CHOSEN.BREADCRUMB_NEXT' | translate" 
  [routerlinkNext]="'../../'"
  [sideBarContent]="sideBarContent">

  <div id="content">
    <h1>{{ 'PROJECT_FINISHINGS.CHOSEN.TITLE' | translate }}</h1>

    <div>
      <hr>

        <div *ngIf="generalFinishing || outdoorFinishing" class="general-wrapper">
          <h3 class="margin-b dark-grey">{{'PROJECT_FINISHINGS.CHOSEN.GENERAL_SET' | translate}}</h3>

          <div class="finishing-container">

            <div *ngIf="generalFinishing" class="content-container">
              <button class="btn-selected btn btn-primary btn-small default">
                {{'PROJECT_FINISHINGS.SELECTED.LABEL_GENERAL' | translate}}
              </button>

              <div class="finishing-wrapper">
                <img class="finishing-img" 
                [src]="generalFinishing.images ? generalFinishing.images[0] : ''">
                <div class="text-wrapper">
                  <p class="finishing-title font-l">{{ generalFinishing.title }}</p>
                  <p class="text-center margin-b-20">
                    <span class="font-m greyed-out">
                      ({{ 'PROJECT_FINISHINGS.SELECTED.LABEL_GENERAL' | translate }})
                    </span>
                  </p>                  
                </div>
              </div>
            </div>

            <div *ngIf="outdoorFinishing" class="content-container">
              <button class="btn-selected btn btn-primary btn-small default">
                {{'PROJECT_FINISHINGS.OUTDOOR.LABEL_GENERAL' | translate}}
              </button>

              <div class="finishing-wrapper">
                <img class="finishing-img" 
                [src]="outdoorFinishing.images ? outdoorFinishing.images[0] : ''">
                <div class="text-wrapper">
                  <p class="finishing-title font-l">{{ outdoorFinishing.title }}</p>
                <p class="text-center margin-b-20">
                  <span class="font-m greyed-out">
                    ({{ 'PROJECT_FINISHINGS.OUTDOOR.LABEL_GENERAL' | translate }})
                  </span>
                </p>
                </div>
              </div>
            </div>

          </div>
        </div>

      <ng-container *ngIf="secondaryFinishings && secondaryFinishings.length > 0">
        <hr>
        <h3 class="margin-b dark-grey">{{'PROJECT_FINISHINGS.CHOSEN.SECONDARY' | translate}}</h3>

        <div class="finishing-container">

          <ng-container *ngFor="let finishing of secondaryFinishings">
            <div class="content-container" *ngIf="finishing.selected == Ternary.TRUE">

              <div class="finishing-wrapper">
                <img class="finishing-img"
                [src]="finishing.images ? finishing.images[0] : ''">
                <div class="text-wrapper">
                  <p class="font-l">{{ finishing.title }}</p>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </ng-container>
    </div>



  </div>
</app-project-page-template>