export class PartnerConnection {
  xid!: string;
  title!: string;
  description?: string;
  image?: string;
  type?: string;
}

export const partners: PartnerConnection[] = [
  {
      "xid": "1",
      "title": "Bose"
  },
  {
      "xid": "2",
      "title": "Google"
  }
]