import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { first, forkJoin, Observable, take } from 'rxjs';
import { Subject, Ternary, Usecase } from 'src/app/models/Subject';
import { clone } from 'src/app/util/Util';
import { ProjectService } from '../project/project.service';
import { SubjectService } from './subject.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Subject resolver for routes requiring a subject list
 */
 @Injectable()
 export class SubjectResolverService implements Resolve<Subject[]>{
 
   public projectId!: string;
   public currentLang!: string;
 
     constructor(
       private subjectService:SubjectService,
       private projectService: ProjectService,
       private translate: TranslateService     
       ) {
     }
  
     resolve(activatedRoute: ActivatedRouteSnapshot,
             state: RouterStateSnapshot): Observable<any> {

         let currentModule = activatedRoute.data['module'];
 
         let projectId     = activatedRoute.params['project_id'];

         if(this.subjectService.cachedSubjectsEmpty || this.projectId != projectId || this.currentLang != this.translate.currentLang) {
           console.log('get subjects from backend');
 
           let subjects$     = this.subjectService.getSubjects();
 
           let projectData$  = this.projectService.getProjectById(projectId);
 
           // Join subject list and projectdata after both observables have completed
           forkJoin({subjects: subjects$, projectData: projectData$ }).pipe(take(1)).subscribe(r => {
             let subjects = r.subjects;
 
             let subjectData = r.projectData.subjects;
             let usecaseData = r.projectData.useCases;
 
             // Pass all subject defaults to backend if current projectID is not the same one as the last one
             // and the current module is not the intake module (usecases are only checked in utilities component if already set)
             if(!subjectData || subjectData.length == 0 || !usecaseData || usecaseData.length == 0) {
               //console.log('original:',clone(subjects));

               if(currentModule != 'intake') {
                
                // Keep track of projectId for current project and current language for usecase translations
                this.projectId    = projectId;
                this.currentLang  = this.translate.currentLang;

                this.initSubjectSelectedStatus(projectId, subjects);
               }
               //console.log('project data applied:',clone(subjects));
 
             } else {
               //console.log('original:',clone(subjects));
                
               // Keep track of projectId for current project and current language for usecase translations
               this.projectId    = projectId;
               this.currentLang  = this.translate.currentLang;

               this.replaceDefaults(projectId, subjectData, usecaseData, subjects);
               //console.log('project data applied:',clone(subjects));
             }
             this.subjectService.cachedSubjects.next(subjects);
           });
         } 
 
         // Subjects don't complete so can't be resolved as is
         return this.subjectService.cachedSubjects.asObservable().pipe(first());
     }
 
 
   /**
    * Init subject defaults on backend.
    * If a subject is set to TRUE, subject and defaults for usecases in that subject are set on server,
    * if set to FALSE, subject and usecases are set to FALSE on server, but defaults are kept in session
    */
   initSubjectSelectedStatus(projectId: string, subjects: Subject[]) {
     console.log('init  subject selected status');
     let sessionSubjects = clone(subjects);
     this.subjectService.sessionSubjects.next(sessionSubjects);

     let subjectString = '';
     let useCaseString = '';
 
     subjects.forEach(subject => {
      subjectString = subjectString + subject.selected + '='+ subject.xid + '&';

       if(subject.selected == Ternary.TRUE) {
         subject.useCases!.forEach(useCase => {
           useCaseString = useCaseString + useCase.selected + '=' + useCase.xid + '&';
         })
       } else {
         subject.useCases!.forEach(useCase => {
           useCase.selected = Ternary.FALSE;
           useCaseString = useCaseString + Ternary.FALSE + '=' + useCase.xid + '&';
         })
       } 
     });
 
     this.subjectService.selectSubjectState(projectId, subjectString);
     this.subjectService.selectUsecaseState(projectId, useCaseString);
   }
 
   /**
    * Replace subject and usecase default selections with projectdata for subjects and usecases
    */
   replaceDefaults(projectId: string, subjectData: Subject[], usecaseData: Usecase[], subjects: Subject[]) {
     console.log('replace defaults');
     let sessionSubjects = clone(subjects);
     this.subjectService.sessionSubjects.next(sessionSubjects);
 
     let useCaseString = '';

     subjects.forEach(subject => {

       subjectData.forEach(subjectData => {
          if(subject.xid == subjectData.xid) {
            subject.selected = subjectData.selected;
            false; // stop current subject iteration after a match
          }
       });

       // If subject is selected, replace usecase defaults with projectdata, else set usecases to false
       if(subject.selected == Ternary.TRUE) {
        subject.useCases!.forEach(usecase => {
          usecaseData.forEach(usecaseData => {
            if(usecase.xid == usecaseData.xid) {
              usecase.selected = usecaseData.selected;
              false; // stop current usecaseData iteration after a match
            }
          });
        });
       } else {
        subject.useCases!.forEach(usecase => {
            usecase.selected = Ternary.FALSE;

            console.log('set usecases to false if their subject is set to false');
            useCaseString = useCaseString + Ternary.FALSE + '=' + usecase.xid + '&';
        });
       }
      });
      
      // If subject is set to false, set usecases to false
      this.subjectService.selectUsecaseState(projectId, useCaseString);
   }
 }
 
 