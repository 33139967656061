import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, merge } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { TooltipModalComponent } from 'src/app/components/modals/tooltip-modal/tooltip-modal.component';
import { BuildingType } from 'src/app/models/BuildingType';
import { ConstructionType } from 'src/app/models/ConstructionType';
import { Project, Property } from 'src/app/models/Project';
import { NikoApiService } from 'src/app/modules/api/niko-api.service';
import { AppRouteObject, APP_ROUTES } from 'src/app/modules/app-routing/AppRoutes';
import { BuildingTypeService } from 'src/app/services/building-type/building-type.service';
import { ConstructionTypeService } from 'src/app/services/construction-type/construction-type.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { projectPropertyValue } from 'src/app/util/Util';

@Component({
  selector: 'app-building-type',
  templateUrl: './building-type.component.html',
  styleUrls: ['./building-type.component.scss']
})
export class BuildingTypeComponent extends BaseComponent implements OnInit {

  public projectPropertyValue = projectPropertyValue;

  public projectId!: string;
  public projectData!: Project;

  public buildingTypes!: BuildingType[];
  public projectTypes!: ConstructionType[];

  public sideBarContent: AppRouteObject[] = [];

  constructor(
    private nikoApi: NikoApiService,
    private activatedRoute: ActivatedRoute,
    private buildingTypeService: BuildingTypeService,
    private constructionTypeService: ConstructionTypeService,
    private propertyService: PropertyService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { super(); }

  ngOnInit(): void {
    this.SetSideBarContent();

    this.projectId = this.activatedRoute.snapshot.params['project_id'];  
    
    let buildingTypes$ = this.buildingTypeService.getBuildingTypes();
        
    let projectTypes$ = this.constructionTypeService.getConstructionTypes();

    let projectData$ = this.projectService.getProjectById(this.projectId);

    this.addSubscription(forkJoin({buildingTypes: buildingTypes$, projectTypes: projectTypes$, projectData: projectData$}).subscribe(r => {
      this.buildingTypes = r.buildingTypes;
      this.projectTypes = r.projectTypes;
      this.projectData = r.projectData
    }));
  }

  /**
   * Save one or multiple property values to backend
   * @param name name of the formcontrol and the property to be saved
   */
  public saveProperty(value: string, name: string) {
    
    let property = this.projectData.properties.find(p => {
      return p.name.includes(name)
    });

    if(property) {
      property.value = value;
    } else {
      property = new Property;
      property.name = 'project.' + name;
      property.value = value;

      this.projectData.properties.push(property);
    }

    //console.log(['project.' + name' + (value ? (':' + value) : '')]);
    this.propertyService.setProperty(this.projectId, ['project.' + name  + (value ? (':' + value) : '')]);
  }

      /**
   * Open tooltip modal
   */
      public openTooltip(title: string = '', text: string) {
        const dialogRef = this.dialog.open(TooltipModalComponent, {
          autoFocus: false, 
          data: {
            'title': title,
            'text': text
          },
          maxWidth: '90vw',
          maxHeight: '90vh',
          minWidth: '775px'
        });
      }
  
  /**
   * Add dynamic content for sidebar navigation
   */
   SetSideBarContent() {
    this.sideBarContent = [];
    
    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_CLIENT_DETAILS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.DETAIL_CLIENT'),
      complete: true,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_BUILDINGTYPE'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.BUILDINGTYPE'),
      complete: false,
      active: true,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_UTILITIES'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.UTILITIES'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_ROOMS'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.ROOMS'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });

    this.sideBarContent.push({
      route: '../../' + APP_ROUTES['INTAKE_SUMMARY'].value, 
      breadcrumb: this.translate.instant('PROJECT_INTAKE.NAV.SUMMARY'),
      complete: false,
      active: false,
      available: true,
      subitem: false
    });
  }
}
